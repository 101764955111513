export const SINGLE= 'SINGLE';
export const SERIES= 'SERIES';
export const YOUTUBE_VIDEO= 'youtube#video';
export const YOUTUBE_CHANNEL= 'youtube#channel';
export const CP_CONTENT= 'CP_CONTENT';
export const APP= 'APP';
export const PLAN= 'PLAN';
export const ADD_PLAN= 'ADD_PLAN';
export const MORE= 'MORE';

