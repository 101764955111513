import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import Icon from '@enact/sandstone/Icon';
import classNames from 'classnames';
import Image from '@enact/sandstone/Image';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import Marquee from '@enact/sandstone/Marquee';
import css from './GoalAddContent.module.less';
import { $L } from '../../utils/common';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import * as Utils from '../../utils/common';
import * as ContentType from '../../data/ContentType';
import TButton from '../../components/TButton';
import TAlert from '../../components/TAlert';
import TimePopup from '../../components/Popup/TimePopup';
import Spotlight from '@enact/spotlight';

const SpottableComponent = Spottable('div');
const Container = SpotlightContainerDecorator({enterTo: '', restrict: 'self-only'}, 'div');

const GoalAddContent = ({ type, isEditMode, contentContainer, contentChangeBtn, title, contentInfo, isFocus, onGoalContentFocus, onGoalContentBlur,
	order, onKeyHandleContent, selectDays, initialization, activated, goalId, onKeyHandleAlarmTButton, previousFocusNum, currentFocusNum, ...rest }) => {

	const dispatch = useDispatch();
	const previousFocus = useRef();
	const appStatus  = useSelector(state => state.appStatus);
	const [TimePopupOpen, setTimePopupOpen] = useState(false);
	const [checkPopupOpen, setCheckPopupOpen] = useState({ open: false, target: "" });

	const isContentExist = contentInfo && Object.keys(contentInfo.info).length > 0 && contentInfo.info.items;
	const contentTitle = isContentExist && contentInfo.info.items.title;
	const disabled = contentInfo && contentInfo.disabled;
	const hours = contentInfo.info.alarm && contentInfo.info.alarm.hours;
	const minutes = contentInfo.info.alarm && contentInfo.info.alarm.minutes;
	let time = new Date(2000, 1, 1, hours, minutes);
	time = time.toLocaleTimeString(appStatus.country === 'KR' ? 'ko-KR' : 'en-US', { hour: '2-digit', minute: '2-digit' });

	const alarm = new Date(2000, 1, 1, hours, minutes);

	const panelInfo = useMemo(() => {
		return { type, isEditMode, goalId, title, day:order, selectDays, activated, alarm : hours !== undefined && alarm };
	}, [ type, isEditMode, goalId, title, order, selectDays, activated, hours, alarm ]);

	const onListFocus = useCallback(() => {
		onGoalContentFocus(order, true);
	}, [onGoalContentFocus, order]);

	const onListBlur = useCallback(() => {
		onGoalContentBlur();
	}, [onGoalContentBlur]);

	useEffect(() => {
		if (contentInfo && contentInfo.info.contentId) {
			dispatch(CmsActions.getContentInfo(contentInfo.info.contentId));
		}
	}, [dispatch, contentInfo]);

	const addContent = useCallback(() => {
		if (initialization || Object.keys(contentInfo.info).length < 1 || !contentInfo.info.items) {
			dispatch(PanelActions.pushPanel('settingsMediaPicker', panelInfo));
		}
	}, [dispatch, panelInfo, contentInfo, initialization]);

	const onClickAlarmBtn = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		onGoalContentFocus(order, true);
		if (ev.type === 'keyup') {
			setTimePopupOpen(true);
		} else {
			if (previousFocusNum === currentFocusNum) {
				setTimePopupOpen(true);
			}
		}
	}, [onGoalContentFocus, previousFocusNum, currentFocusNum, order ]);

	const onCloseTimePopup = useCallback((ev) => {
		Spotlight.focus(previousFocus.current);
		setTimePopupOpen(false);
		if (ev.type === 'click') {
			onGoalContentFocus(-1);
		}
	}, [onGoalContentFocus]);

	const onCheckpopupCancel = useCallback((ev) => {
		Spotlight.focus(previousFocus.current);
		setCheckPopupOpen({ open: false });
		if (ev.type === 'click') {
			onGoalContentFocus(-1);
		}
	}, [onGoalContentFocus]);

	const openCheckPopup = useCallback((ev) => {

		previousFocus.current = Spotlight.getCurrent();
		onGoalContentFocus(order, true)
		if (ev.type === 'keyup') {
			setCheckPopupOpen({ open: true });
		} else {
			if (previousFocusNum === currentFocusNum) {
				setCheckPopupOpen({ open: true });
			}
		}
	}, [onGoalContentFocus, previousFocusNum, currentFocusNum, order]);

	const onCloseCheckPopup = useCallback((num, ev) => {
		if (num === 0) {
			dispatch(PanelActions.pushPanel('settingsMediaPicker', panelInfo));
				onCheckpopupCancel(ev);
		} else {
			onCheckpopupCancel(ev);
		}
	}, [dispatch, onCheckpopupCancel, panelInfo]);

	const onHandleContent = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			const isContent = Object.keys(contentInfo.info).length !== 1;
			onKeyHandleContent(isContent);
		} if (ev.key === 'ArrowUp') {
			onListBlur();
		}
	},[onKeyHandleContent, onListBlur, contentInfo]);

	const thumbnailUrl = useCallback(() => {
		if (contentInfo.info.items) {
			if (contentInfo.info.items.contentType === ContentType.PLAN) {
				return contentInfo.info.items.items[0].thumbnailImageUrl;
			} else if ("thumbnailUrl" in contentInfo.info.items) {
				return contentInfo.info.items.thumbnailUrl;
			} else if ("thumbnailImageUrl" in contentInfo.info.items) {
				return contentInfo.info.items.thumbnailImageUrl;
			}
		}
	}, [contentInfo]);

	const contentDetailInfo = useCallback(() => {
		const detailInfo = contentInfo.info.items;
		const playtime = detailInfo.playtime ? Utils.transSecToText(detailInfo.playtime): "";
		let calorie = detailInfo.calorie ? detailInfo.calorie + " kcal"  : "";
		if(playtime && calorie){
			calorie = " | "+calorie;
		}
		let category = detailInfo.categoryList && detailInfo.categoryList.length !== 0 ? detailInfo.categoryList[0].title : "";
		if((playtime || calorie) && category){
			category = " | "+category;
		}
		return playtime + calorie + category + " ";
	}, [contentInfo]);

	return (
		<>
			<Container className={classNames(css.itemContainer, isFocus && css.focusList)} onKeyDown={onHandleContent} onFocus={onListFocus} onBlur={onListBlur} {...rest} >
				<SpottableComponent
					className={classNames(css.list, disabled && css.disabledList)}
					spotlightId={contentContainer}
					spotlightDisabled={disabled}
					onClick={addContent}
					data-webos-voice-intent={'Select'}
					data-webos-voice-label={isContentExist ? contentTitle : $L('Add Content')}
				>
					{isContentExist ?
						<>
							<Image
								className={classNames(css.content, css.contentUrl)}
								src={thumbnailUrl()}
								placeholder={"thumbnailImageUrl"}
							/>
							{contentInfo.info.items.contentType === ContentType.YOUTUBE_VIDEO &&
								<div className={css.youtubelogo} />
							}
						</>
						:
						<div className={classNames(css.content, css.addContent)}>
							<div className={classNames(css.iconWrap)}>
							<Icon className={css.icon} size={100}>plus</Icon>
							</div>
							<div className={css.text}>{$L('Add Content')}</div>
						</div>
					}
					<div className={css.infoContainer}>
						{isContentExist ?
							<>
								<Marquee className={css.title} marqueeOn={isFocus ? "render" : null}>
									{Utils.convertUtf8ToUni(contentInfo.info.items.title)}
								</Marquee>
								<Marquee
									className={classNames(css.subtitle)}
									marqueeOn={isFocus ? "render" : null}
								>
									{contentDetailInfo()}
								</Marquee>
								<div className={css.goalAlarm}>{time}</div>
							</> :
							<div className={classNames(css.alarm)}>{$L('00:00 AM')}</div>

						}
					</div>
				</SpottableComponent>
				{isContentExist && !disabled &&
					<div className={css.buttonWrap}>
						<TButton
							className={classNames(css.edit, isFocus ? css.focusBtn : null)}
							disabled={disabled}
							onClick={openCheckPopup}
							spotlightId={contentChangeBtn}
							data-webos-voice-label={isFocus ? $L('Edit') : " "}
						>
							{$L('Edit')}
						</TButton>
						<TButton
							className={classNames(css.time, isFocus ? css.focusBtn : null)}
							disabled={disabled}
							onClick={onClickAlarmBtn}
							onKeyDown={onKeyHandleAlarmTButton}
							data-webos-voice-label={isFocus ? $L('Alarm') : " "}
						>
							{time}
						</TButton>
					</div>
				}
			</Container>
			{checkPopupOpen.open &&
				<TAlert
					message={$L("Do you want to set another exercise?")}
					button1text={$L('OK')}
					button2text={$L('Cancel')}
					onClose={onCheckpopupCancel}
					onClick={onCloseCheckPopup}
				/>
			}
			{TimePopupOpen &&
				<TimePopup
					type={"onlyEditTime"}
					onClosePopup={onCloseTimePopup}
					open={TimePopupOpen}
					onlyEditTimeContentInfo={contentInfo}
					preSetTime={alarm}
				/>
			}
		</>
	)
};

export default GoalAddContent;