import {types} from '../actions/actionTypes';
import * as Config from '../data/Config';
import SpotlightIds from '../data/SpotlightIds';
import {HOME_GROUP_IDS} from '../data/Constants';
import {$L} from '../utils/common';
import ICON_CATEGORY_FAV_S from '../../assets/list/icon/icon_category_fav_s.png';
import ICON_CATEGORY_FAV_F from '../../assets/list/icon/icon_category_fav_f.png';
import ICON_CATEGORY_FAV_O from '../../assets/list/icon/icon_category_fav_o.png';

const workstylesList = (state = [], action) => {
	switch (action.type) {
		case types.GET_WORKSTYLES_LIST:
			return action.payload;
		default:
			return state;
	}
};
const background = (state = {}, action) => {
	switch (action.type) {
		case types.GET_BACKGROUND:
			return action.payload;
		default:
			return state;
	}
};
const activeBanner = (state = null, action) => {
	switch (action.type) {
		case types.GET_ACTIVE_BANNER:
			return action.payload;
		default:
			return state;
	}
};

const accountInfo = (state = {accountId: '', workoutStyleId: Config.UNKNOWN_WORKOUTSTYLEID}, action) => {
	switch (action.type) {
		case types.GET_ACCOUNT_INFO:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const myFavorit = [{categoryId: -1, key: -1, title: $L('My Favorite'),
	largeImageUrl: ICON_CATEGORY_FAV_S,
	mediumImageUrl:	ICON_CATEGORY_FAV_F,
	smallImageUrl: ICON_CATEGORY_FAV_O
}];
const categoriesTablePicker = (state = [], action) => {
	switch (action.type) {
		case types.GET_CATEGORIES_TABLE:
			return myFavorit.concat(action.payload);
		default:
			return state;
	}
};
const categoriesTable = (state = [], action) => {
	switch (action.type) {
		case types.GET_CATEGORIES_TABLE:
			return action.payload;
		default:
			return state;
	}
};
const categoriesTableSelected = (state = -1, action) => {
	switch (action.type) {
		case types.GET_CATEGORIES_TABLE_SELECTED:
			return action.payload;
		default:
			return state;
	}
};
const contentsOfCategory = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_CATEGORY:
			if(action.append && state[action.categoryId]){
				state[action.categoryId].concat(action.payload);
				return Object.assign({}, state);
			}
			return Object.assign({}, state, {[action.categoryId]: action.payload});
		default:
			return state;
	}
};
const defaultHomeGroup = [SpotlightIds.LIST_TODAYTOP, SpotlightIds.LIST_RECENTLY_WATCHED, SpotlightIds.LIST_MYFAVORITE
	, SpotlightIds.LIST_RECOMMANDED, SpotlightIds.LIST_MYWORKOUTROUTINE, SpotlightIds.LIST_RECENTLY_ADDED, SpotlightIds.LIST_YOUTUBE];
const homeGroup = (state = defaultHomeGroup, action) => {
	switch (action.type) {
		case types.GET_HOME_GROUP:
			return action.payload;
		default:
			return state;
	}
};
const homeGroup2 = (state = [], action) => {
	switch (action.type) {
		case types.GET_HOME_GROUP2:
			return action.payload;
		default:
			return state;
	}
};
const homeGroupLists = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_TAG:
			return Object.assign({}, state, action.payload);
		case types.GET_CONTENTS_TOPS:
			return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_TODAY_TOP]: action.payload});
		case types.GET_CONTENTS_RECENTLY_WATCHED:
			return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_RECENTLY_WATHCHED]: action.payload});
		// case types.GET_CONTENTS_RECOMMENDED:
		// 	return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_RECOMMEND]: action.payload});
		case types.GET_CONTENTS_MY_FAVORITES:
			if(action.append){
				return state[HOME_GROUP_IDS.HOME_MY_FAVORITE].concat(action.payload);
			}else{
				return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_MY_FAVORITE]: action.payload});
			}
		case types.GET_PLANS_OF_ACCOUNT:
			return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_MY_TRAINING_PLAN]: action.payload});
		case types.GET_CONTENTS_RECENTLY_ADDED:
			return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_RECENTLY_ADDED]: action.payload});
		case types.GET_YOUTUBE_CHANNELS:
			if(action.append){
				return state[HOME_GROUP_IDS.HOME_YOUTUBE].concat(action.payload);
			}else{
				return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_YOUTUBE]: action.payload});
			}
		case types.GET_CONTENTS_THIRDPARTY_APPS:
			return Object.assign({}, state, {[HOME_GROUP_IDS.HOME_THIRD_PARTY]: action.payload});
		case types.GET_CUSTOMIZED_WORKOUT_SCHEDULE:
			return Object.assign({}, state, {[HOME_GROUP_IDS.CUSTOMIZED_WORKOUT]: action.payload});
		default:
			return state;
	}
};
const contentsTops = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_TOPS:
			return action.payload;
		default:
			return state;
	}
};
const contentsRecommended = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECOMMENDED:
			return action.payload;
		default:
			return state;
	}
};
const contentsRecentlyWatched = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECENTLY_WATCHED:
			return action.payload;
		default:
			return state;
	}
};
const contentsMyFavorites = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_MY_FAVORITES:
			if(action.append){
				return state.concat(action.payload);
			}else{
				return action.payload;
			}
		default:
			return state;
	}
};
const contentsRecentlyAdded = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RECENTLY_ADDED:
			return action.payload;
		default:
			return state;
	}
};
const contentsRelated = (state=[], action) => {
	switch (action.type) {
		case types.GET_CONTENTS_RELATED:
			return action.payload;
		case types.CLEAR_CONTENTS_RELATED:
			return [];
		default:
			return state;
	}
};
const orderedTagList = (state = {}, action) => {
	switch (action.type) {
		case types.GET_ORDERED_TAG_LIST:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const contentsOfTag = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_TAG:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
}

const goalsOfAccount = (state = [], action) => {
	switch (action.type) {
		case types.GET_GOALS_OF_ACCOUNT:
			return action.payload;
		case types.CLEAR_ALARM_AND_GOALS:
			return [];
		default:
			return state;
	}
};
const goalOfAccount = (state = [], action) => {
	switch (action.type) {
		case types.GET_GOAL_OF_ACCOUNT:
			return action.payload;
		default:
			return state;
	}
};

const contentInfos = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENT_INFO:
			state[action.payload.key] = action.payload.content;
			return state;
		default:
			return state;
	}
}
const updatedContentInfo = (state = {}, action) => {
	switch (action.type) {
		case types.GET_CONTENT_INFO:
			return action.payload.content;
		default:
			return state;
	}
}
const contentsYoutube = (state = [], action) => {
	switch (action.type) {
		case types.GET_YOUTUBE_VIDEOS:
			if(action.append){
				return state.concat(action.payload);
			}else{
				return action.payload;
			}
			case types.UPDATE_YOUTUBE_VIDEOINFO:{
			for(let i=0; i<state.length;i++){
				if(state[i].contentId === action.payload.youtubeId){
					state[i].contentId = action.payload.contentId;
					state[i].youtubeId = action.payload.youtubeId;
				}
			}
			return state;
		}
		default :
			return state;
	}
}
const contentsYoutubeChannels = (state = [], action) => {
	switch (action.type) {
	case types.GET_YOUTUBE_CHANNELS:
		if(action.append){
			return state.concat(action.payload);
		}else{
			return action.payload;
		}
		default :
			return state;
	}
}
const YoutubeSearchResult = (state = { keyword: "", data: [] }, action) => {
	switch (action.type) {
		case types.GET_YOUTUBE_SEARCH:
			return action.payload;
		case types.GET_YOUTUBE_SEARCH_RESET:
			return { keyword: "", data: [] };
		case types.UPDATE_YOUTUBE_VIDEOINFO:{
			for(let i=0; i<state.length;i++){
				if(state[i].contentId === action.payload.youtubeId){
					state[i].contentId = action.payload.contentId;
					state[i].youtubeId = action.payload.youtubeId;
				}
			}
			return state;
		}
		default :
			return state;
	}
}

const contentsOfKeyword = (state = { keyword: "", data: [] }, action) => {
	switch (action.type) {
		case types.GET_CONTENTS_KEYWORD:
			return action.payload;
		case types.GET_CONTENTS_KEYWORD_RESET:
			return { keyword: "", data: [] }
		default:
			return state;
	}
};

const contentsCustomizedWorkout = (state = [], action) => {
  switch (action.type) {
    case types.GET_CUSTOMIZED_WORKOUT_SCHEDULE:
      return action.payload;
    default:
      return state;
  }
};

const alarmOfAccount = (state = {}, action) => {
	switch (action.type) {
		case types.GET_ALARM_OF_ACCOUNT:
			return Object.assign({}, state, action.payload);
		case types.CLEAR_ALARM_AND_GOALS:
			return {};
		default:
			return state;
	}
};

const workoutSummary = (state = {}, action) => {
	switch (action.type) {
		case types.GET_WORKOUT_SUMMARY:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const workoutSummaryWeek = (state = {}, action) => {
	switch (action.type) {
		case types.GET_WORKOUT_SUMMARY_WEEK:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const goalAchievement = (state = {}, action) => {
	switch (action.type) {
		case types.GET_GOAL_ACHIEVEMENT:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const goalAchievementWeek = (state = {}, action) => {
	switch (action.type) {
		case types.GET_GOAL_ACHIEVEMENT_WEEK:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};
const contentUsages = (state = [], action) => {
	switch (action.type) {
		case types.GET_CONTENT_USAGES:
			if(action.append){
				return state.concat(action.payload);
			}else{
				return action.payload;
			}
		default:
			return state;
	}
};

const defaultGoal = {
	selectDay: [0, 1, 2, 3, 4, 5, 6],
	goals: [
        { disabled: false, info: { day: 0 }},
        { disabled: false, info: { day: 1 }},
        { disabled: false, info: { day: 2 }},
        { disabled: false, info: { day: 3 }},
        { disabled: false, info: { day: 4 }},
        { disabled: false, info: { day: 5 }},
        { disabled: false, info: { day: 6 }}
	]
};

const tempGoalInfo = (state = defaultGoal, action) => {
	switch (action.type) {
		case types.SET_TEMPORARY_GOAL:
			return action.payload;
		case types.RESET_TEMPORARY_GOAL:
			return defaultGoal;
		default:
			return state;
	}
};

const selectContentInfo = (state = {}, action) =>{
	switch (action.type) {
		case types.SELECT_CONTENT_INFO:
			return action.payload;
		default:
			return state;
	}
};

const defaultPlan = {
	plans: [
		{ disabled: false, order: 0, info: [] },
		{ disabled: false, order: 1, info: [] },
		{ disabled: false, order: 2, info: [] },
		{ disabled: false, order: 3, info: [] },
		{ disabled: false, order: 4, info: [] },
	]
};

const tempPlanInfo = (state = defaultPlan, action) => {
	switch (action.type) {
		case types.SET_TEMPORARY_PLAN:
			return action.payload
		case types.RESET_TEMPORARY_PLAN:
			return defaultPlan
		default:
			return state
	}
}

const plansOfAccount = (state = [], action) => {
	switch (action.type) {
		case types.GET_PLANS_OF_ACCOUNT:
			return action.payload;
		case types.GET_CONTENT_INFO:
			for (let i = 0; i < state.length; i++) {
				if(!state[i].thumbnailImageUrl && state[i].items && state[i].items[0] && state[i].items[0].contentId === action.payload.content.contentId){
					state[i].thumbnailImageUrl = action.payload.content.thumbnailImageUrl;
					state[i].postImageUrl = action.payload.content.postImageUrl;
				}
			}
			return state;
		case types.CLEAR_ALARM_AND_GOALS:
			return [];
		default:
			return state;
	}
};

const termsModification = (state = [], action) => {
	switch (action.type) {
		case types.GET_TERMS_MODIFICATION:
			return action.payload;
		default:
			return state;
	}
};

const noticeList = (state = [], action) => {
	switch (action.type) {
		case types.GET_NOTICE_LIST:
			if(action.append) {
				return state.concat(action.payload);
			}else {
				return action.payload;
			}
		default:
			return state
	}
};

// const customizedUserInfo =  (state = {gender:1, tall: 34, weight: 34, age: 34, goal: 2, plan: 3, program: 11, level: 1}, action) => {
const customizedUserInfo =  (state = {gender:0, tall: 0, weight: 0, age: 0, goal: 0, plan: 0, program: 0, level: 0}, action) => {
	switch (action.type) {
		case types.GET_CUSTOMIZED_USERINFO:
			return Object.assign({}, state, action.payload);
		case types.CLEAR_ALARM_AND_GOALS:
			return {gender:0, tall: 0, weight: 0, age: 0, goal: 0, plan: 0, program: 0, level: 0};
		default:
			return state;
	}
};

const floatingMessages = (state = {}, action) => {
	switch (action.type) {
		case types.GET_FLOATING_MESSAGE_LIST:
			return action.payload;
		default:
			return state;
	}
};


const cmsReducer = {
	accountInfo,
	workstylesList,
	background,
	activeBanner,
	categoriesTableSelected,
	contentsOfCategory,
	categoriesTablePicker,
	categoriesTable,
	homeGroup,
	homeGroup2,
	homeGroupLists,
	contentsTops,
	contentsRecommended,
	contentsRecentlyWatched,
	contentsMyFavorites,
	contentsRecentlyAdded,
	contentsRelated,
	orderedTagList,
	contentsOfTag,
	contentInfos,
	updatedContentInfo,
	goalOfAccount,
	goalsOfAccount,
	contentsYoutube,
	contentsYoutubeChannels,
	YoutubeSearchResult,
	contentsOfKeyword,
	contentsCustomizedWorkout,
	alarmOfAccount,
	workoutSummary,
	workoutSummaryWeek,
	goalAchievement,
	goalAchievementWeek,
	contentUsages,
	tempGoalInfo,
	selectContentInfo,
	tempPlanInfo,
	plansOfAccount,
	termsModification,
	noticeList,
	customizedUserInfo,
	floatingMessages
};

export default cmsReducer;
