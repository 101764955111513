/**
 * HomeBanner
 *
 * @module HomeBanner
 */
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { Job } from '@enact/core/util';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import Marquee from '@enact/sandstone/Marquee';
import Spottable from '@enact/spotlight/Spottable';

import { $L } from '../../utils/common';
import * as CommonActions from '../../actions/commonActions';
import * as Utils from '../../utils/common'
import * as Config from '../../data/Config';
import * as ContentType from '../../data/ContentType';
import * as PanelActions from '../../actions/panelActions';
import SpotlightIds from '../../data/SpotlightIds';
import TAlert from '../TAlert';
import BannerSummary from './BannerSummary';
import CustomImage from '../CustomImage';
import ContentDetailInfo from '../ContentDetailInfo';
import VideoPlayer from '../VideoPlayer';
import css from './HomeBanner.module.less';

import { types } from '../../actions/actionTypes';
import * as CmsActions from '../../actions/cmsActions';
import * as LogTypes from '../../data/LogTypes';
import violet_bannerCoverBig from '../../../assets/banner/violet_videocover_big.png';
import violet_bannerCoverMedium from '../../../assets/banner/violet_videocover_medium.png';
import violet_bannerCoverSmall from '../../../assets/banner/violet_videocover_small.png';
import red_bannerCoverBig from '../../../assets/banner/red_videocover_big.png';
import red_bannerCoverMedium from '../../../assets/banner/red_videocover_medium.png';
import red_bannerCoverSmall from '../../../assets/banner/red_videocover_small.png';

const SpottableComponent = Spottable('div');

const Container = SpotlightContainerDecorator({
	enterTo: 'default-element',
}, 'div');

const delayedPlayJob = new Job((videoCurrent, recommendedBanner) => {
	if (videoCurrent && !videoCurrent.state.sourceUnavailable) {
		if (Config.ADMIN_PREVIEW_MODE && recommendedBanner.previewStart !== undefined) {
			videoCurrent.seek(recommendedBanner.previewStart);
		} else {
			videoCurrent.seek(0);
		}
		videoCurrent.play();
	}
});

const HomeBanner = ({ isOnTop, spotlightId, onScrollTop, onScrollShelf, ...rest }) => {
	const dispatch = useDispatch();
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const supportLoginWithDeviceId = useSelector(state => state.supportLoginWithDeviceId);
	const background = useSelector(state => state.background);
	const { accountId } = useSelector(state => state.accountInfo);
	const homeBannerSize = useSelector(state => state.appStatus.homeBannerSize);
	const isAppForeground = useSelector(state => state.appStatus.isAppForeground);
	const webOSVersion = useSelector(state => state.appStatus.webOSVersion);
	const activeBanner = useSelector(state => state.activeBanner);
	const focusedBannerItem = useSelector(state => state.focusedBannerItem); //contentId
	const contentInfos = useSelector(state => state.contentInfos);
	const panels = useSelector(state => state.panels);
	const showLoadingView = useSelector(state => state.showLoadingView.show);
	const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const [showFavLoginPopup, setShowFavLoginPopup] = useState(false);
	const [showVideo, setShowVideo] = useState(false);
	const videoPlayer = useRef(null);
	const [bannerImageLoaded, setBannerImageLoaded] = useState(false);
	const customizedUserInfo = useSelector(state => state.customizedUserInfo);
	const goalsOfAccount = useSelector(state => state.goalsOfAccount);
	const planInfo = useSelector(state => state.plansOfAccount);
	const alarmInfo = useSelector(state => state.alarmOfAccount);
	const homeGroupLists = useSelector(state => state.homeGroupLists, shallowEqual);
	const homeGroup2 = useSelector(state => state.homeGroup2, shallowEqual);

	useEffect(() => {
		return () => {
			delayedPlayJob.stop();
		};
	}, []);

	const bannerContent = useMemo(() => {
		let content = null,
			playInfo = {},
			bannerInfo = {},
			isImgBanner = false,
			showBannerBtn = false;

		if (panels.length <= 0) {
			if (focusedBannerItem !== null) {
				content = focusedBannerItem;
				// choose first content if it is series content
				if (content && content.contentType === ContentType.SERIES) {
					const firstContent = contentInfos[content.clipList[0].contentId];
					if (firstContent) {
						content.previewUrl = firstContent.previewUrl;
					}
				} else if (content && content.contentType === ContentType.PLAN) {
					const firstContent = contentInfos[content.items[0].contentId];
					if (firstContent) {
						content.previewUrl = firstContent.previewUrl;
						content.postImageUrl = firstContent.postImageUrl;
					}
				} else if (content) {
					const c = contentInfos[content.contentId];
					if (c) {
						content = c;
					}
				}
				if (content) {
					const youtubeSubTitle = $L("Check out the trending workout YouTuber we selected.{br}Enjoy other workout content on YouTube.");
					const isYoutube = content.contentType && content.contentType.startsWith('youtube');
					playInfo = {
						contentType: isYoutube ? 'youtube' : content.contentType,
						title: isYoutube ? $L('YouTube') : content.title,
						subTitle: isYoutube ? youtubeSubTitle : content.description,
						poster: content.postImageUrl ? content.postImageUrl : content.thumbnailImageUrl,
						source: content.previewUrl,
						moreInfo: ''
					};
					if (Config.ADMIN_PREVIEW_MODE) {
						if (!content.previewUrl && content.playUrl) {
							playInfo.source = content.playUrl.replace('.m3u8', '.mp4');
							playInfo.previewStart = content.previewStart ? Utils.stringTimetoSec(content.previewStart) : undefined;
							playInfo.previewEnd = content.previewEnd ? Utils.stringTimetoSec(content.previewEnd) : undefined;
							playInfo.showLocalGuide = true;
						}
					}
				};
			} else if (activeBanner) {
				bannerInfo = activeBanner;
				isImgBanner = true;
				if(bannerInfo.bannerLinkType === 'CONTENT'){
					content = contentInfos[bannerInfo.bannerLinkId];
					if(content){
						showBannerBtn = true;
					}
				}else if(bannerInfo.bannerLinkType === 'SETTING_MENU' && supportLogin){
					showBannerBtn = true;
				}else if(bannerInfo.bannerLinkType === 'SETTING_MENU' && bannerInfo.bannerLinkId === 'notice'){
					showBannerBtn = true;
				}
				else if(bannerInfo.bannerLinkType === 'HOME_GROUP'){
					if(Object.keys(homeGroupLists).includes(bannerInfo.bannerLinkId)){
						showBannerBtn = true;
					}
					homeGroup2.forEach(item => {
						if(item.id === bannerInfo.bannerLinkId){
							if(item.homeGroupType === 'TAG') {
								bannerInfo.homeGroupType = 'TAG';
							}
						}
					});
				}
			}
		}
		return { content, playInfo, bannerInfo, isImgBanner, showBannerBtn };
	}, [activeBanner, focusedBannerItem, homeBannerSize, contentInfos, panels, supportLogin, homeGroupLists, homeGroup2]);

	const previewMaskStyle = useMemo(() => {
		let style = {};
		if (homeBannerSize === 'big') {
			if (background.bigBannerCoverUrl) {
				style = { backgroundImage: `url(${background.bigBannerCoverUrl})` }
			} else {
				style = deviceAccountInfo.language === 'ko' ? { backgroundImage: `url(${red_bannerCoverBig})` } : { backgroundImage: `url(${violet_bannerCoverBig})` };
			}
		} else if (homeBannerSize === 'medium') {
			if (background.mediumBannerCoverUrl) {
				style = { backgroundImage: `url(${background.mediumBannerCoverUrl})` }
			} else {
				style = deviceAccountInfo.language === 'ko' ? { backgroundImage: `url(${red_bannerCoverMedium})` } : { backgroundImage: `url(${violet_bannerCoverMedium})` };
			}
		} else {
			if (background.smallBannerCoverUrl) {
				style = { backgroundImage: `url(${background.smallBannerCoverUrl})` }
			} else {
				style = deviceAccountInfo.language === 'ko' ? { backgroundImage: `url(${red_bannerCoverSmall})` } : { backgroundImage: `url(${violet_bannerCoverSmall})` };
			}
		}
		return { style, homeBannerSize };
	}, [homeBannerSize, background, deviceAccountInfo]);

	const blurStyle = useMemo(() => {
		let style = { zIndex: 0 };
		return style
	})

	useEffect(() => {
		delayedPlayJob.stop();
		setShowVideo(false);
	}, [bannerContent]);

	useEffect(() => {
		if (!showLoadingView && isAppForeground) {
			delayedPlayJob.startAfter(Config.HOME_PREVIEW_DELAY, videoPlayer.current, bannerContent.playInfo);
		} else if (videoPlayer.current && !videoPlayer.current.state.paused) {
			videoPlayer.current.pause();
		}
	}, [showLoadingView, isAppForeground]);


	const onClickFavLoginPopup = useCallback((ev) => {
		setShowFavLoginPopup(false);
		Spotlight.focus('bannerBtn');
		if (ev === 0) {
			dispatch(CommonActions.launchMembershipApp('Favorite'));
		}
	}, [dispatch]);

	const getPlayer = useCallback((ref) => {
		videoPlayer.current = ref;
	}, []);

	const mediainfoHandler = useCallback((ev) => {
		const type = ev.type;
		switch (type) {
			case 'loadedmetadata':
				{
					if (!showLoadingView) {
						delayedPlayJob.startAfter(Config.HOME_PREVIEW_DELAY, videoPlayer.current, bannerContent.playInfo);
					}
					break;
				}
			case 'loadeddata':
				{
					break;
				}
			case 'playing':
			case 'timeupdate':
				{
					let endTime = Config.HOME_PREVIEW_LIMIT_SEC;
					if (Config.ADMIN_PREVIEW_MODE && bannerContent.playInfo.previewEnd !== undefined && bannerContent.playInfo.previewStart !== undefined) {
						endTime = bannerContent.playInfo.previewStart + Config.HOME_PREVIEW_LIMIT_SEC;
						if (endTime > bannerContent.playInfo.previewEnd) {
							endTime = bannerContent.playInfo.previewEnd;
						}
					}
					if (ev.currentTarget.currentTime > endTime) {
						videoPlayer.current.pause();
						setShowVideo(false);
					} else {
						if (!showVideo && ev.currentTarget.currentTime > 0) {
							setShowVideo(true);
						}
					}
				}
				break;
			case 'ended':
				{
					setShowVideo(false);
				}
				break;
		}
	}, [showVideo, showLoadingView, bannerContent]);

	const onSpotlightUp = useCallback((ev) => {
		if (Spotlight.focus(SpotlightIds.MAIN_HOME_BTN)) {
			ev.stopPropagation();
		}
	}, []);
	const onSpotlightDown = useCallback((ev) => {
		if (Spotlight.focus(SpotlightIds.HOME_SCROLLER)) {
			ev.stopPropagation();
		}
	}, []);

	const hideButton = useMemo(() => {
		// return homeBannerSize !== 'big' || !isOnTop;
		return homeBannerSize !== 'medium' || !isOnTop;
	}, [homeBannerSize, isOnTop]);

	const onClickBannerButton = useCallback((bannerButtonClick=false) => {
		let type = bannerContent.bannerInfo.bannerLinkType;
		dispatch(CmsActions.appLog(LogTypes.Types.BANNERBUTTON, bannerContent.bannerInfo.bannerLinkType, bannerContent.bannerInfo.bannerLinkId, bannerContent.bannerInfo.bannerLinkTitle));
		if(bannerButtonClick) {
			switch (type) {
				case 'CONTENT': {
					dispatch(CommonActions.setPlayLaunchPath('banner'));
					dispatch(CommonActions.handlePlayItem(bannerContent.content));
				}
				break;
				case 'SETTING_MENU':{
					switch(bannerContent.bannerInfo.bannerLinkId){
						case 'customizedworkout':{
								if(customizedUserInfo.age > 0 && customizedUserInfo.program > 0){
									dispatch(PanelActions.pushPanel('interviewModify'));
								}else{
									dispatch(PanelActions.pushPanel('interview'));
								}
							}
							break;
						case 'goal': {
								if (goalsOfAccount && goalsOfAccount.length > 0) {
										dispatch(PanelActions.pushPanel('goallist'));
									} else {
										dispatch({ type: types.RESET_TEMPORARY_GOAL });
										dispatch(PanelActions.pushPanel('goalsettings', { title: "goal1", activated: true, isEditMode:false}));
									}
							}
							break;
						case 'alarm': {
								if(alarmInfo && alarmInfo.alarm1 !== undefined) {
									dispatch(PanelActions.pushPanel('alarmlist'));
								} else {
									dispatch(PanelActions.pushPanel('alarmsettings', {alarmTitle: "alarm1"}));
								}
							}
							break;
						case 'routine': {
								if (planInfo && planInfo.length > 0) {
									dispatch(PanelActions.pushPanel('myworkoutroutinelist'));
								} else {
									dispatch(PanelActions.pushPanel('myworkoutroutinesettings'));
								}
							}
							break;
						case 'favorite': {
								if(!accountId){
									setShowFavLoginPopup(true);
								}else{
									dispatch(PanelActions.pushPanel('morelist', {title: $L('My Favorite'), list: contentsMyFavorites, listId: SpotlightIds.LIST_MYFAVORITE}));
								}
							}
							break;
						case 'notice': {
								dispatch(CmsActions.getNoticeList());
								dispatch(PanelActions.pushPanel('notice'));
							}
							break;
					}
				}
				break;
				case 'HOME_GROUP': onScrollShelf(bannerContent.bannerInfo.bannerLinkId, bannerContent.bannerInfo.homeGroupType === 'TAG');
					break;
			}
		} else {
			onScrollTop();
		}
	}, [dispatch, onScrollShelf, onScrollTop,bannerContent, customizedUserInfo, goalsOfAccount, planInfo, alarmInfo, accountId]);

	const onClickBanner = useCallback(() => {
		if(homeBannerSize !== 'medium' || !isOnTop) {
			onClickBannerButton(false);
		} else {
			onClickBannerButton(true);
		}
	}, [onClickBannerButton, homeBannerSize, isOnTop]);

	const onEnterBanner = useCallback(() => {
		if(focusedBannerItem){
			dispatch(CommonActions.clearFocusedBannerItem());
		}
	}, [dispatch, focusedBannerItem]);

	return (
		<Container {...rest}
			spotlightId={spotlightId}
			className={classNames(css.homebanner, homeBannerSize === 'small' ? css.small : homeBannerSize === 'medium' ? css.medium : null)}
		>
			<div className={css.bannerWrap} onMouseOver={onEnterBanner} >
				{bannerContent.isImgBanner ?
						<CustomImage className={css.bannerPreview} delay={0} onClickBanner={onClickBanner} onImageLoaded={setBannerImageLoaded} src={bannerContent.bannerInfo.bannerImageUrl} hide={showVideo || homeBannerSize !== previewMaskStyle.homeBannerSize}/>
					:
					<>
						<div className={classNames(css.infoArea, bannerContent.playInfo.contentType === 'youtube' ? css.youtube: null)}>
						<Marquee  className={css.title} marqueeOn={"render"}>
							{bannerContent.playInfo.title}
						</Marquee>
						{ (homeBannerSize !== 'small' || bannerContent.playInfo.contentType === 'youtube'
							|| bannerContent.playInfo.contentType === ContentType.ADD_PLAN || bannerContent.playInfo.contentType === ContentType.CP_CONTENT
							|| bannerContent.playInfo.contentType === ContentType.APP) &&
							<div className={css.subtitle}>{Utils.convertNormalStr(bannerContent.playInfo.subTitle)}</div>
						}
						<ContentDetailInfo className={css.moreInfo} size={homeBannerSize}>{bannerContent.content}</ContentDetailInfo>
						</div>
						<VideoPlayer
							className={classNames(css.preview, (homeBannerSize !== previewMaskStyle.homeBannerSize || !showVideo) ? css.hide: null)}
							setApiProvider={getPlayer}
							muted={webOSVersion==='local'}
							disabled
							spotlightDisabled
							noAutoShowMediaControls
							noSpinner
							noAutoPlay
							noMiniFeedback
							onLoadedData={mediainfoHandler}
							onLoadedMetadata={mediainfoHandler}
							onPlaying={mediainfoHandler}
							onTimeUpdate={mediainfoHandler}
							onEnded={mediainfoHandler}
						>
							<source
								src={bannerContent.playInfo.source ? bannerContent.playInfo.source: ""}
								type="video/mp4"
							/>
						</VideoPlayer>
						{showVideo && bannerContent.playInfo.showLocalGuide &&
							<div className={classNames(css.preview, css.localGuide)}>{$L('Will be played high quality on TV.')}</div>
						}
						<CustomImage className={css.preview} src={bannerContent.playInfo.poster} hide={showVideo || homeBannerSize !== previewMaskStyle.homeBannerSize}/>
						<div className={classNames(css.preview, css.previewMask)} style={previewMaskStyle.style}/>
					</>
				}
				{ bannerContent.isImgBanner && bannerContent.showBannerBtn && !showVideo && homeBannerSize === 'medium' &&
					<div className={css.bannerBtnWrap}>
						<SpottableComponent spotlightId='bannerBtn' className={classNames(css.bannerDeepButton, !bannerImageLoaded ? css.hidden : null)} onClick={onClickBannerButton}>
							<div className={css.text}>{bannerContent.bannerInfo.bannerLinkTitle}</div>
						</SpottableComponent>
					</div>
				}
			</div>
			{(supportLogin || (supportLoginWithDeviceId && accountId))&&
				<BannerSummary onSpotlightUp={onSpotlightUp} onSpotlightDown={onSpotlightDown} hideButton={hideButton}/>
			}
			{showFavLoginPopup &&
				<TAlert message={$L("If you log in, you can manage your favorites and activities.")}
					button1text={$L('Login')} button2text={$L("Cancel")} onClick={onClickFavLoginPopup}/>
			}
		</Container>
	)
};
export default React.memo(HomeBanner, shallowEqual);