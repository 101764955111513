import React, {useCallback, useEffect, useState, useMemo} from 'react';
import classNames from 'classnames';

import Marquee from '@enact/sandstone/Marquee';
import Spottable from '@enact/spotlight/Spottable';

import css from './AnswerItem.module.less';
import diet from  '../../../../../assets/interview/diet.png'
import strength from  '../../../../../assets/interview/strength.png'
import health from  '../../../../../assets/interview/health.png'
import posture from  '../../../../../assets/interview/posture.png'


const SpottableComponent = Spottable('div');

 const TYPES=["normal", "icon"];
 const ICON=[diet, strength, health, posture]

 const AnswerItem = ({type="normal", goal, children, selected, selectedIndex, marqueeOn='focus', alignment='center', spotlightId, className, onClick,disabled, onDisabled, ...rest}) => {
	const [focused, setFocused] = useState(false);

   useEffect(() => {
     if(TYPES.indexOf(type) < 0 ){
       console.error('AnswerItem type error');
     }
   }, [type]);

   const onFocus = useCallback((ev) => {
		setFocused(true);
		if(rest.onFocus){
			rest.onFocus(ev);
		}
	}, [rest]);
	const onBlur = useCallback(() => {
		setFocused(false);
	}, []);

   const _onClick = useCallback((ev) => {
     if (onClick) {
       onClick(ev, type);
     }
     ev.persist();
   }, [onClick, type]);

   const marqueeOnOption = useMemo(()=>{
		if(marqueeOn === 'focus'){
			if(focused){
				return 'render';
			}else{
				return 'focus';
			}
		}
		return marqueeOn;
	}, [marqueeOn,  focused]);

   return (
     <SpottableComponent
       className={classNames(css.answerItem, css[type], selected ? css.active : null, className ? className: null)}
       onFocus={onFocus}
       onBlur={onBlur}
       onClick={_onClick}
       data-webos-voice-intent={'Select'}
       data-webos-voice-label={children}
       spotlightId={spotlightId}
     >
				<Marquee  className={classNames(css.text, type === 'icon' ? css.hasIcon: null)} marqueeOn={marqueeOnOption} alignment={alignment}>
          {children}
        </Marquee>
        {type === 'icon' && <img className={css.icon} src={ICON[goal-1]} />}
     </SpottableComponent>
   );
 };

export default AnswerItem;