import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useMemo} from 'react';

import Alert from '@enact/sandstone/Alert';
import Spotlight from '@enact/spotlight';
import TButton from '../../../../components/TButton';
import TInputField from '../../../../components/TInputField';
import css from './InputPopup.module.less';
import {$L} from '../../../../utils/common';

/**
 *
 * @param inputInfo {key: 'tall', title: $L("Height"), tail: 'cm'}
 * @returns
 */
const InputPopup = ({open, inputInfo={}, originValue, onClose, ...rest}) => {
	const [inputValue, setInputValue] = useState('');
	useEffect(() => {
		if(open){
			setInputValue(originValue);
			setTimeout(() => {
				Spotlight.focus('inputfield');
			}, 1);
		}
    },[open]);
	useEffect(() => {
		setInputValue(originValue);
    },[originValue]);

	const isInvalid = useMemo(()=>{
		return (inputValue < inputInfo.min || inputValue > inputInfo.max || inputValue === 'NaN')
	},[inputValue]);

	const onChangeInput = useCallback((ev)=>{
		setInputValue(String(parseInt(ev.value)));
	},[]);

	const onCancel = useCallback(() => {
		if(onClose){
			onClose({});
		}
	}, [onClose]);
	const onDone = useCallback(() => {
		if(onClose){
			let value = inputValue;
			if(isInvalid){
				value = originValue;
			}
			onClose({key: inputInfo.key, value: parseInt(value)});
		}
	}, [onClose, inputValue, originValue, ]);

	const onHandleInputField = useCallback((ev) => {
		if (ev.key === 'Enter') {
			onDone();
		}
	}, [onDone]);

	return (
		<Alert {...rest}
			className={classNames(css.inputPopup)}
			open={open}
			onClose={onClose}
			type={"overlay"}>
			<div className={css.infoLayer}>
				<div className={css.title}>{inputInfo.title}</div>
				<TInputField
					className={css.inputField}
					spotlightId={'inputfield'}
					type={"number"}
					value={inputValue}
					invalid={isInvalid}
					autoFocus
					dismissOnEnter
					onKeyDown={onHandleInputField}
					onChange={onChangeInput}
				/>
				<div>{inputInfo.tail}</div>
			</div>
			<div className={css.buttonlayer}>
					<TButton withMarquee onClick={onDone}>{$L("Done")}</TButton>
					<TButton spotlightId={'cancelBtn'} onClick={onCancel}>{$L("Cancel")} </TButton>
				</div>
		</Alert>
	);
};

export default InputPopup;
