/**
 * CircleChart
 *
 * @module CircleChart
 */

import classNames from 'classnames';
import {$L} from '../../../utils/common';
import React, {useEffect, useMemo, createRef} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import css from './CircleChart.module.less';
import * as Utils from '../../../utils/common';
import ChartTitle from '../ChartTitle';

const RADIUS = Utils.scaleW(140);
const MARGIN_W = Utils.scaleW(70);
const MARGIN_H = Utils.scaleH(50);
const FONTSIZE = Utils.scaleW(18);
const CIRCLE_CENTER_X = RADIUS+MARGIN_W;
const CIRCLE_CENTER_Y = RADIUS+MARGIN_H;
const CANVAS_WIDTH = RADIUS*2 + MARGIN_W*2;
const CANVAS_HEIGHT = RADIUS*2 + MARGIN_H*2;
const COLORS = ['rgb(251,55,123)', 'rgb(251,216,55)', 'rgb(55,251,193)', 'rgb(92,55,251)', 'rgb(207,55,251)', 'rgb(83,251,55)', 'rgb(251,97,55)', 'rgb(55,138,251)'];

const CircleChart = ({className, workoutDate}) => {
	const workoutSummary = useSelector(state => state.workoutSummary);
	let canvas;
	let canvasRef = createRef();

	const categoryUsages = useMemo(() => {
		const summary = workoutSummary[workoutDate.firstDate];
		return summary && summary.categoryUsages ? summary.categoryUsages:[];
	}, [workoutSummary, workoutDate]);

	/**
	 * @param {*} ctx
	 * @param {*} startAngle 360 degree == 2*PI
	 * @param {*} endAngle
	 * @param {*} color '#ff0000'
	 */
	const drawPieSlice = (ctx, startAngle, endAngle, color ) => {
		ctx.fillStyle = color;
		ctx.beginPath();
		ctx.moveTo(CIRCLE_CENTER_X, CIRCLE_CENTER_Y);
		ctx.arc(CIRCLE_CENTER_X, CIRCLE_CENTER_Y, RADIUS, startAngle, endAngle);
		ctx.closePath();
		ctx.fill();
	};

	useEffect(() => {
		canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		let total_value = 0;
		let categ, val;

		let usages = categoryUsages;
		if(usages.length <= 0){
			//empty data
			usages = [{timeSpent: 35}, {timeSpent: 25}, {timeSpent: 15}, {timeSpent: 15}, {timeSpent: 10}];
		}
		// todo sort data
		for ( categ in usages) {
			total_value += usages[categ].timeSpent;
		}
		let start_angle = Math.PI + Math.PI / 2, i = 0;
		const start_angles = [];
		const slice_angles = [];
		const values = [];

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		for ( categ in usages) {
			val = usages[categ].timeSpent;
			const slice_angle = 2 * Math.PI * val / total_value;
			values.push(val);
			start_angles.push(start_angle);
			slice_angles.push(slice_angle);
			start_angle += slice_angle;
		}
		// draw arc
		for (i = 0; i < start_angles.length; i++) {
			drawPieSlice(ctx, start_angles[i], start_angles[i] + slice_angles[i], COLORS[i % COLORS.length]);
		}
		// draw text
		if(categoryUsages.length >0 ){
			for (i = 0; i < start_angles.length; i++) {
				let labelX = CIRCLE_CENTER_X + (RADIUS+MARGIN_W/2) * Math.cos(start_angles[i] + slice_angles[i] / 2);
				let labelY = CIRCLE_CENTER_Y + (RADIUS+MARGIN_H/3) * Math.sin(start_angles[i] + slice_angles[i] / 2);
				// if(labelX > CIRCLE_CENTER_X) labelX -= MARGIN_W/3;
				labelX -= MARGIN_W/3;
				labelY += MARGIN_H/6;

				let labelText = Math.round(100 * values[i] / total_value);
				ctx.fillStyle = 'white';
				ctx.font = FONTSIZE+'px LG SmartFont';
				ctx.fillText(labelText + '%', labelX, labelY);
			}
		}
	}, [categoryUsages]);

	return (
		<div className={className}>
			<ChartTitle>{$L('Category Usage Rate')}</ChartTitle>
			<div className={css.chart}>
				<canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} className={css.canvas} />
				<div className={css.info}>
					{categoryUsages.map((item, index) => (
						<div key={index} className={css.itemContainer}>
							<div className={css.itembox} style={{backgroundColor: COLORS[index % COLORS.length]}}/>
							<div className={css.itemStr} >{item.title}</div>
							<div className={classNames(css.itemStr, css.itemStr2)} >{Utils.durationText(item.timeSpent)}</div>
						</div>
					))}
				</div>
				{categoryUsages.length <=0 &&
					<div className={css.empty_comment}>{$L('You will see your category usage rate here.')}</div>
				}
			</div>
		</div>
	);
};

export default React.memo(CircleChart, shallowEqual);