/**
 * MediaList
 *
 * @module MediaList
 */
import {Job} from '@enact/core/util';
import css from './MediaList.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import platform from '@enact/core/platform';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import Spotlight from '@enact/spotlight';
import {types} from '../../actions/actionTypes';
import * as PanelActions from '../../actions/panelActions';
import * as CommonActions from '../../actions/commonActions';
import * as CmsActions from '../../actions/cmsActions';

import MediaItem from '../MediaItem';
import * as Config from '../../data/Config';
import KeyCode from '../../data/KeyCode';
import * as ContentType from '../../data/ContentType';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import SpotlightIds from '../../data/SpotlightIds';

const SpottableComponent = Spottable('div');
const itemWidth= Utils.scaleW(360);
const itemHeight = Utils.scaleH(304);
const verticalScrollitemHeight = Utils.scaleH(310);
const xTranslateGap = Utils.scaleW(-11);
const itemMargin= 0;



const leftRightAutoScrollJob = new Job((eventCallback) => {
	eventCallback();
},Config.AUTO_SCROLL_DELAY);

const MediaList = ({className, visibleList=null, noLimit=false, itemSize="small", orientation='horizontal',onListFocus, supportFavBtn, title,
	spotlightId, viewList, selectMode=false, onListItemClick,onFocusedIndexChanged, restoreIndex, playingIndex=-1, logHint, contentsYoutube=null, homeGroupId, ...rest}) => {
	const dispatch = useDispatch();
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible, shallowEqual);
	const [visibleIndexes, setVisibleIndexes] = useState({firstVisibleIndex: -1, lastVisibleIndex: -1});
	const [nodeSize, setNodeSize] = useState({w:0, h:0});
	const [isListFull, setIsListFull] = useState(false);
	const [isFavFocused, setFavFocused] = useState(false);
	const [showingIndexs, setShowingIndexs] = useState([]); //only for horizontal
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [savedFocusIndex, setSavedFocusIndex] = useState(-1);
	const [autoScrollState, setAutoScrollState] = useState("none"); //left, right
	const [progressInfo, setProgressInfo] = useState({text: '', percent: 0});
	const resetFocus = useRef(false);

	//only for horizontal
	const listVisibleInfo = useMemo(() => {
		const ret = {visibleOrder: -1}
		if(visibleList && visibleList.indexOf(spotlightId) >=0){
			ret.visibleOrder = visibleList.indexOf(spotlightId);
			if(orientation === 'horizontal' && ret.visibleOrder === 1){
				let node = document.querySelector(`[data-spotlight-id="${visibleList[0]}"]`);
				if(node && node.children[1]){
					ret.orderStart = node.children[1].childElementCount;
				}
			}
		}else if(!visibleList){//scroller 내부에 list가 하나만 있거나 scroller 내부가 아닌 경우
			ret.visibleOrder = 0;
		}
		return ret;
	}, [visibleList, orientation, spotlightId]);

	const convertedViewList = useMemo(() => {
		let list= viewList;
		if(orientation === 'vertical' || noLimit || selectMode){
			list = viewList;
		}else if(viewList.length > Config.HLIST_HOME_MAX){
			list = viewList.slice(0, Config.HLIST_HOME_MAX);
			list.push({contentType: ContentType.MORE, title: $L('View More')});
		}
		return list;
	}, [orientation, noLimit, selectMode, viewList]);

	useEffect(() => {
		const node = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
		const nodeRect = node.getBoundingClientRect();
		setNodeSize({w:nodeRect.width, h:nodeRect.height});
		return () => leftRightAutoScrollJob.stop();
	}, [spotlightId]);

	useEffect(() => {
		if(savedFocusIndex < 0 && restoreIndex >= 0){
			setSavedFocusIndex(restoreIndex);
		}
	}, [restoreIndex]);

	useEffect(() => {
		let index = playingIndex >=0 ? playingIndex: restoreIndex;
		if(index >= convertedViewList.length){
			index = convertedViewList.length-1;
		}
		if(playingIndex >=0){
			calculateVisibleIndex(0, false, index, false);
		}else{
			calculateVisibleIndex(0, false, index);
		}
	}, [convertedViewList]);

	useEffect(() => {
		if(visibleIndexes.firstVisibleIndex >= 0 && playingIndex >= 0 && showingIndexs.indexOf(playingIndex)<0 ){
			calculateVisibleIndex(playingIndex-visibleIndexes.firstVisibleIndex, false, null, false);
		}
	  }, [playingIndex]);

	  useEffect(() => {
		if (homeGroupId === "CUSTOMIZED_WORKOUT") {
			viewList.map((item, idx) => {
				if(item.isToday === true) {
					let regex = /[^0-9]/g;
					let day, percent, text;
					day = item.dayTitle.split(':')
					day = Number(day[0].replace(regex, ''));
					text = $L('{day} DAY').replace('{day}', day);
					percent = ((day-1) / 27 * 100).toFixed(0);
					text = text + ' (' + percent + '%)';
					setProgressInfo({text, percent});
					return;
				}
			});
		}
	  }, [viewList]);

	useEffect(()=>{
		if(focusedIndex >=0){
			const
				item = convertedViewList[focusedIndex];
			if(item){
				if(item.contentType && item.contentType.startsWith('youtube')){
					if(item.contentType === ContentType.YOUTUBE_VIDEO){
						if(item.contentId && isNaN(item.contentId)){
							dispatch(CmsActions.registYoutubeVideo(item));
						}
					}else{
						dispatch({type: types.GET_CONTENT_INFO, payload: {key: item.contentId, content : item}});
					}
				}else if(item.contentType === ContentType.CP_CONTENT){
					// cp content has no need to regist on focus
					// if(item.contentId && isNaN(item.contentId)){
					// 	dispatch(CmsActions.registCpContent(item));
					// }
				}else if(item.contentType === ContentType.PLAN){
					// if(item.items){
					// 	for(let i=0; i< item.items.length; i++){
					// 		dispatch(CmsActions.getContentInfo(item.items[i].contentId, true));
					// 	}
					// }
				}else{
					dispatch(CmsActions.getContentInfo(item.contentId));
				}
				if(item.contentType !== ContentType.MORE){
					dispatch(CommonActions.handleItemFocus({...item}));
				}
				if(onFocusedIndexChanged){
					onFocusedIndexChanged(spotlightId, focusedIndex);
				}
			}
		}
	}, [dispatch, focusedIndex, convertedViewList]);

	// only for scroll
	// withKey only horizontal
	const calculateVisibleIndex = useCallback((to, withKey, _restoreIndex, spot=true) => {
		let containerSize = nodeSize;
		let calWidth = 0, calHeight = 0,
			first = withKey ? focusedIndex+to : visibleIndexes.firstVisibleIndex+to,
			size= convertedViewList ? convertedViewList.length: 0,
			full = isListFull,
			last=visibleIndexes.lastVisibleIndex,
			i;
		if(first < 0)first=0;
		if(size > 0)first%=size;

		if(to===0){ //list changed
			const node = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
			containerSize = {w:node.offsetWidth, h:node.offsetHeight};
			setNodeSize(containerSize);
			full = false;
			for(i=0; i< size; i++){
				calWidth += (itemWidth+itemMargin);
				last = i;
				if(calWidth>node.offsetWidth){
					if( orientation === 'horizontal'){
						full=true;
						break;
					}else{ //vertical : next line
						calHeight += (itemHeight+itemMargin);
					}
				}
				if(calHeight>node.offsetHeight){//vertical
					full=true;
				}
			}
			setIsListFull(full);
			if(orientation === 'horizontal' && _restoreIndex >=0){
				first = _restoreIndex;
			}
			if(!full)first = 0;
		}
		if( orientation === 'horizontal'){
			if(full){
				calWidth = 0;
				for(i=first; calWidth<containerSize.w; i++){
					last=i;
					calWidth += (itemWidth+itemMargin);
				}
				if(!withKey && to === 1 && spot){
					setFocusedIndex((last-1)%size);
				}else if(to!==0 && spot){
					setFocusedIndex(first); //todo for mouse
				}
			}else{ // list is too short
				const index = (focusedIndex+to) < 0 ? 0 : (focusedIndex+to) >= size ? size-1 : (focusedIndex+to);
				if(withKey && spot){
					setFocusedIndex(index);
				}
				first = 0;
			}
		}
		const showingindexs=[];
		if(size>0){
			setVisibleIndexes({firstVisibleIndex: first, lastVisibleIndex: last%size});
		}else{
			setVisibleIndexes({firstVisibleIndex: -1, lastVisibleIndex: -1});
		}
		if(size>0){
			for(let k=first; k<=last; k++){
				showingindexs.push(k%size);
			}
		}
		setShowingIndexs(showingindexs);
		//case remove favorite
		if(orientation === 'horizontal' && to===0 && focusedIndex >=0 && showingindexs.indexOf(focusedIndex)<0 && spot){
			setFocusedIndex(showingindexs[0]);
		}
		if(orientation === 'vertical' && to===0 && focusedIndex >=0 && focusedIndex >= convertedViewList.length && spot){
			setFocusedIndex(convertedViewList.length-1);
		}
		if(_restoreIndex >=0 && (showingindexs.indexOf(_restoreIndex)>=0 || orientation === 'vertical') && spot){
			console.log('restore...',_restoreIndex);
			setFocusedIndex(_restoreIndex);
		}
	}, [nodeSize, focusedIndex, visibleIndexes, isListFull, convertedViewList, orientation, spotlightId]);

	const moveLeft = useCallback((withKey) => {
		if(orientation === 'horizontal'){
			calculateVisibleIndex(-1, withKey);
		}else{
			if(withKey){
				const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
				if(currentNode){
					const node = Utils.getNearestTarget('left', currentNode);
					if(node){
						setFocusedIndex(parseInt(node.getAttribute('index')));
						return true;
					}
				}
			}
		}
	}, [orientation, spotlightId, focusedIndex, calculateVisibleIndex]);
	const moveRight = useCallback((withKey) => {
		if(orientation === 'horizontal'){
			calculateVisibleIndex(1, withKey);
		}else{
			if(withKey){
				const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
				if(currentNode){
					const node = Utils.getNearestTarget('right', currentNode);
					if(node){
						setFocusedIndex(parseInt(node.getAttribute('index')));
						return true;
					}
				}
			}
		}
	}, [orientation, spotlightId, focusedIndex, calculateVisibleIndex]);

	const moveUp = useCallback((withKey) => {
		if(withKey && orientation === 'horizontal'){ //reset focusedIndex
			resetFocus.current = true;
		}
		if(withKey){
			const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
			if(currentNode){
				const node = Utils.getNearestTarget('up', currentNode);
				if(node){
					setFocusedIndex(parseInt(node.getAttribute('index')));
					return true;
				}
			}
		}
	}, [orientation, spotlightId, focusedIndex]);

	const moveDown = useCallback((withKey) => {
		if(withKey && orientation === 'horizontal'){ //reset focusedIndex
			resetFocus.current = true;
		}
		if(withKey){
			const currentNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + focusedIndex}"]`);
			if(currentNode){
				const node = Utils.getNearestTarget('down', currentNode);
				if(node){
					setFocusedIndex(parseInt(node.getAttribute('index')));
					return true;
				}
			}
		}

	}, [orientation, spotlightId, focusedIndex]);

	const doLeftRightAutoScroll = useCallback(() => {
		if(!cursorVisible){
			setAutoScrollState("none");
			return;
		}
		switch(autoScrollState){
			case 'left': moveLeft(); break;
			case 'right': moveRight(); break;
		}
	}, [autoScrollState, cursorVisible,moveLeft,moveRight]);

	useEffect(() => {
		leftRightAutoScrollJob.stop();
		if(autoScrollState !== 'none'){
			leftRightAutoScrollJob.start(doLeftRightAutoScroll);
		}
	}, [autoScrollState, visibleIndexes, cursorVisible]);

	const onItemClick = useCallback((ev) => {
		let type = "", item, index, favBtnclicked;
		if(ev && ev.currentTarget){
			type = 'mouse';
			index = parseInt(ev.currentTarget.getAttribute('index'));
			item = convertedViewList[index];
			favBtnclicked = ev.target.parentNode.getAttribute('isfavbtn') || ev.target.getAttribute('isfavbtn')
		}else if(focusedIndex >=0){
			type = 'key';
			index = focusedIndex;
			item = convertedViewList[focusedIndex];
		}else{
			//invalid case
			return;
		}
		if(type === 'mouse'){
			if(!cursorVisible && platform.touchscreen && focusedIndex !== index){
				Spotlight.setPointerMode(false);
				Spotlight.focus(spotlightId);
				setFocusedIndex(index);
				return;
			}else if(favBtnclicked){
				setFavFocused(true);
				dispatch(CmsActions.addOrRemoveFavorites(item.contentId));
				setFocusedIndex(index);
				return;
			}
		}else if(type === 'key'){
			if(isFavFocused){
				dispatch(CmsActions.addOrRemoveFavorites(item.contentId));
				return;
			}
		}

		if (onListItemClick) {
			const info = JSON.parse(JSON.stringify(item));
			if (info.contentType===ContentType.YOUTUBE_VIDEO && info.contentId && isNaN(info.contentId)) {
				dispatch(CmsActions.registYoutubeVideo(info, true, (data) => {
					onListItemClick(data, index);
				}));
			}else if (info.contentType===ContentType.CP_CONTENT && info.contentId && isNaN(info.contentId)) {
				dispatch(CmsActions.registCpContent(info, (data) => {
					onListItemClick(data, index);
				}));
			}else{
				onListItemClick(info, index);
			}
		}else if(item.contentType===ContentType.MORE){
			dispatch(PanelActions.pushPanel('morelist', {title: title, list: viewList, listId: spotlightId}));
		}else if(item.contentType === ContentType.YOUTUBE_CHANNEL && spotlightId === SpotlightIds.LIST_YOUTUBE ){
			dispatch(PanelActions.pushPanel('moreformedlist', {title: title, list: contentsYoutube, listId: ContentType.YOUTUBE_VIDEO
				,focusedListIndex: index }));
		}else if(item.contentType===ContentType.ADD_PLAN){
			dispatch({ type: types.RESET_TEMPORARY_PLAN });
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings'));
		}else if(spotlightId === SpotlightIds.LIST_CUSTOMIZED_WORKOUT){
			dispatch(CommonActions.setPlayLaunchPath(logHint ? logHint : spotlightId));
			dispatch(CommonActions.handlePlayItem(item, convertedViewList));
		}else {
			dispatch(CommonActions.setPlayLaunchPath(logHint ? logHint : spotlightId));
			dispatch(CommonActions.handlePlayItem(item));
		}

		if(type === 'mouse'){
			setFocusedIndex(index);
		}
	}, [dispatch, focusedIndex, convertedViewList, isFavFocused, selectMode, onListItemClick, cursorVisible, spotlightId, logHint, title, viewList, contentsYoutube]);

	const _onKeyDown = useCallback((ev) => {
		resetFocus.current = false;
		//todo fav button
		if(ev.key === 'ArrowLeft'){
			setFavFocused(false);
			moveLeft(true);
			ev.stopPropagation();
			ev.preventDefault();
		}else if(ev.key === 'ArrowRight'){
			setFavFocused(false);
			moveRight(true)
			ev.stopPropagation();
			ev.preventDefault();
		}else if(ev.key === 'ArrowUp'){
			if(isFavFocused){
				setFavFocused(false);
				ev.stopPropagation();
				ev.preventDefault();
				return true;
			}
			if(moveUp(true)){
				ev.stopPropagation();
				ev.preventDefault();
			}
		}else if(ev.key === 'ArrowDown'){
			const item = convertedViewList[focusedIndex];
			let _supportFavBtn = supportFavBtn;
			if(item && (item.contentType === ContentType.YOUTUBE_CHANNEL || item.contentType === ContentType.MORE )){
				_supportFavBtn = false;
			}
			if(_supportFavBtn && !isFavFocused){
				setFavFocused(true);
				ev.stopPropagation();
				ev.preventDefault();
				return true;
			}
			if(moveDown(true)){
				ev.stopPropagation();
				ev.preventDefault();
			}
		} else if (ev.key === 'Enter') {
			onItemClick();
			ev.stopPropagation();
			ev.preventDefault();
			return true;
		}else if(ev.keyCode === KeyCode.PLAY){
			onItemClick();
			ev.stopPropagation();
			ev.preventDefault();
			return true;
		}
		if(ev.key === 'PageDown' || ev.key === 'PageUp'){
			if(orientation==='vertical'){
				if(ev.key === 'PageDown'){
					moveDown(true);
				}else{
					moveUp(true);
				}
			}else{//this keys will processed by parent
				ev.stopPropagation();
				ev.preventDefault();
				return true;
			}
		}
		if(rest.onKeyDown){
			if(rest.onKeyDown(ev)){
				console.log('stop prop...');
				setFavFocused(false);
				ev.stopPropagation();
				ev.preventDefault();
				return true;
			}
		}
	}, [moveLeft, moveRight, moveDown, moveUp, onItemClick, orientation, isFavFocused, setFavFocused, supportFavBtn, rest, convertedViewList, focusedIndex]);
	const onFocus = useCallback((ev) => {
		resetFocus.current = false;
		if(savedFocusIndex !== -1 && showingIndexs.includes(savedFocusIndex)){
			setFocusedIndex(savedFocusIndex);
		}else if(!showingIndexs.includes(focusedIndex)){
			setFocusedIndex(visibleIndexes.firstVisibleIndex);
		}
		if(onListFocus){
			onListFocus(ev);
		}
	}, [focusedIndex, savedFocusIndex, showingIndexs, onListFocus, visibleIndexes]);

	const onBlur = useCallback((ev) => {
		if(resetFocus.current || (ev&&ev.type==='mouseleave')){
			setSavedFocusIndex(-1);
		}else{
			setSavedFocusIndex(focusedIndex);
		}
		setFocusedIndex(-1);
		setFavFocused(false);
	}, [focusedIndex, setFocusedIndex, setSavedFocusIndex,setFavFocused]);

	const onFavBtnFocused = useCallback((ev) => {
		console.log("MediaList onFavBtnFocused", ev);
		if(supportFavBtn){
			setFavFocused(ev);
		}
	}, [setFavFocused, supportFavBtn]);

	const onMouseOver = useCallback((ev) => {
		if(cursorVisible){
			for(let i in showingIndexs){
				const itemNode = document.querySelector(`[data-spotlight-id="${spotlightId + '_' + showingIndexs[i]}"]`);
				if(itemNode && itemNode.contains(ev.target)){
					setFocusedIndex(showingIndexs[i]);
					break;
				}
			}
		}
	}, [setFocusedIndex, showingIndexs, spotlightId, cursorVisible]);

	const onLeftScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveLeft();
			setAutoScrollState("left");
		}
	}, [moveLeft, cursorVisible]);

	const onLeftScrollAreaClick = useCallback(() => {
		if(platform.touchscreen){
			moveLeft();
		}
	}, [moveLeft]);

	const onRightScrollAreaOver = useCallback(() => {
		if(cursorVisible){
			moveRight();
			setAutoScrollState("right");
		}
	}, [moveRight, cursorVisible]);

	const onRightScrollAreaClick = useCallback(() => {
		if(platform.touchscreen){
			moveRight();
		}
	}, [moveRight]);

	const onScrollAreaOut = useCallback(() => {
		setAutoScrollState("none");
	}, []);

	const onVoiceHandle = useCallback((ev) => {
		const
			index = parseInt(ev.currentTarget.getAttribute('index')),
			item = convertedViewList[index],
			evType = ev.detail.intent;

		switch (evType) {
			case 'Select':
			case 'PlayContent':
				Spotlight.focus(spotlightId);
				if(orientation==='horizontal' && index !== focusedIndex){
					calculateVisibleIndex(index-focusedIndex);
				}else{
					setFocusedIndex(index);
				}
				if (onListItemClick) {
					const info = JSON.parse(JSON.stringify(item));
					if (info.contentType===ContentType.YOUTUBE_VIDEO && info.contentId && isNaN(info.contentId)) {
						dispatch(CmsActions.registYoutubeVideo(info, true, (data) => {
							onListItemClick(data, index);
						}));
					}else if (info.contentType===ContentType.CP_CONTENT && info.contentId && isNaN(info.contentId)) {
						dispatch(CmsActions.registCpContent(info, (data) => {
							onListItemClick(data, index);
						}));
					}else{
						onListItemClick(info, index);
					}
				}else if(item.contentType===ContentType.MORE){
					dispatch(PanelActions.pushPanel('morelist', {title: title, list: viewList, listId: spotlightId}));
				}else if(item.contentType===ContentType.ADD_PLAN){
					dispatch({ type: types.RESET_TEMPORARY_PLAN });
					dispatch(PanelActions.pushPanel('myworkoutroutinesettings'));
				}else if(spotlightId === SpotlightIds.LIST_CUSTOMIZED_WORKOUT){
					dispatch(CommonActions.setPlayLaunchPath(logHint ? logHint : spotlightId));
					dispatch(CommonActions.handlePlayItem(item, convertedViewList));
				}else {
					dispatch(CommonActions.setPlayLaunchPath(logHint ? logHint : spotlightId));
					dispatch(CommonActions.handlePlayItem(item));
				}
				ev.preventDefault();
				break;
		}
	}, [convertedViewList, dispatch, spotlightId, logHint, focusedIndex, calculateVisibleIndex, selectMode, onListItemClick, title, viewList, orientation]);

	const renderItem = useCallback(({index, order}) => {
		let
			voiceLabelIndex = null,
			voiceLabel = null,
			voiceIntent = null;
		const itemInfo = convertedViewList && convertedViewList[index];

		let caption = itemInfo && itemInfo.title;
		const isYoutubeChannel = (itemInfo && itemInfo.contentType === ContentType.YOUTUBE_CHANNEL);
		const isSpecialButton = itemInfo && (itemInfo.contentType === ContentType.MORE || itemInfo.contentType === ContentType.ADD_PLAN);
		const isCpContent = (itemInfo && itemInfo.contentType === ContentType.CP_CONTENT);
		const forceFocus = index===focusedIndex;
		let additionalStyle={};
		if(forceFocus){
			additionalStyle = {marginRight: itemMargin+'px', marginBottom: itemMargin+'px', transform: "translateX("+xTranslateGap*(order%5)+"px)"};
		}else if(order === -1){
			additionalStyle = {position: "absolute", left: "-298px", opacity: 0.5};
		}
		if(orientation === 'horizontal'){
			if(listVisibleInfo.visibleOrder < 0 || order < 0 || order >= 5){
				//do nothing
			}else{
				voiceIntent = 'Select PlayContent';
				voiceLabel = caption;
				if(listVisibleInfo.orderStart !== undefined){
					voiceLabelIndex = listVisibleInfo.orderStart + order;
				}else{
					voiceLabelIndex = listVisibleInfo.visibleOrder * 5 + order;
				}
			}
		}else{ //vertical
			if(!visibleList || visibleList.indexOf(spotlightId + '_' + index)>=0){
				voiceIntent = 'Select PlayContent';
				voiceLabel = caption;
				voiceLabelIndex = visibleList.indexOf(spotlightId + '_' + index);
			}
		}
		if(itemInfo && itemInfo.dayTitle){
			caption = itemInfo.dayTitle;
		}
		if(caption){
			caption = Utils.convertUtf8ToUni(caption);
		}
		return (
			<MediaItem
				style={additionalStyle}
				key={index}
				index={index}
				playing={playingIndex === index}
				itemInfo={itemInfo}
				itemSize={itemSize}
				listspotlightid={spotlightId}
				supportFavBtn={supportFavBtn && !isYoutubeChannel && !isSpecialButton && !isCpContent}
				data-webos-voice-intent={voiceIntent}
				data-webos-voice-label={voiceLabel}
				data-webos-voice-label-index={voiceLabelIndex}
				onItemClick={onItemClick}
				// onItemFocus={onItemFocus}
				spotlightId={spotlightId + '_' + index}
				onVoice={onVoiceHandle}
				forceFocus={forceFocus}
				favBtnFocused={forceFocus && isFavFocused}
				onFavBtnFocused={onFavBtnFocused}
				caption={caption}
				selectMode={selectMode}
			/>
		);
	}, [orientation, itemSize, spotlightId, onItemClick, onVoiceHandle, onFavBtnFocused, convertedViewList, focusedIndex, isFavFocused, supportFavBtn, listVisibleInfo, playingIndex, visibleList, selectMode]);

	const verticalTransform = orientation === 'vertical' ? {/*transform: "translateY(-300px)"*/} : undefined;
	return (
		<SpottableComponent
			{...rest}
			// data-webos-voice-intent={listVoiceIntent}
			// data-webos-voice-label={listVoiceLabel}
			data-focused-index={focusedIndex}
			itemsize={itemSize}
			spotlightId={spotlightId}
			className={classNames(className, css.listContainer, orientation==='vertical' ? css.vertical: null)}
			onKeyDown={_onKeyDown}
			onFocus={onFocus}
			onBlur={onBlur}
			onMouseLeave={onBlur}
		>
			{title &&
				<div className={css.listTitlelayer}>
					<div className={css.listicon}/>
					<div className={css.listTitle}>{title}</div>
				</div>
			}
			{homeGroupId === "CUSTOMIZED_WORKOUT" && progressInfo.text &&
				<div className={css.achievementGraphlayer}>
					<div className={css.textWrap} style={{ "left": progressInfo.percent* Utils.scaleW(6.5) + "px" }}>
						<div className={css.icon} />
						<div className={progressInfo.percent > 50 ? css.leftText : css.text}>{progressInfo.text}</div>
					</div>
					<div className={css.progressgraph}></div>
					<div className={css.progressbar} style={{ "width": progressInfo.percent* Utils.scaleW(6.5) + "px" }}></div>
				</div>
			}
			<div className={classNames(css.listMain, itemSize ==='big'? css.bigItem: itemSize ==='plan' ? css.bigItem :null)} style={verticalTransform} onMouseOver={onMouseOver}>
			{
				showingIndexs.length> 0 && showingIndexs.map((item, order) => {
					return renderItem({index:item, order: order});
				})
			}
			{/* left dummy item */}
			{orientation==='horizontal' && showingIndexs && showingIndexs[0] > 0 &&
				renderItem({index: (showingIndexs[0]-1), order: -1})
			}
			</div>
			{ orientation==='horizontal' &&
				<div className={classNames(css.leftRightScrollArea, css.leftScrollArea, !Config.SHOW_TOUCH_GUIDE ? css.hide: null)} onMouseOver={onLeftScrollAreaOver} onMouseOut={onScrollAreaOut} onClick={onLeftScrollAreaClick}/>
			}
			{ orientation==='horizontal' &&
				<div className={classNames(css.leftRightScrollArea, css.rightScrollArea, !Config.SHOW_TOUCH_GUIDE ? css.hide: null)} onMouseOver={onRightScrollAreaOver} onMouseOut={onScrollAreaOut}  onClick={onRightScrollAreaClick}/>
			}
		</SpottableComponent>
	);
};
const listPropsAreEqual = (prev, next) => {
	const keys = Object.keys(prev);
	const nextKeys = Object.keys(next);
	if(keys.length !== nextKeys.length){
		return false;
	}
	for(let i=0; i<keys.length; i++){
		if(prev[keys[i]] !== next[keys[i]]){
			if(keys[i] === 'visibleList' && (prev[keys[i]].toString() === next[keys[i]].toString())){
				continue;
			}
			return false;
		}
	}
	return true;
}
export default React.memo(MediaList, listPropsAreEqual);
