/**
 * History
 *
 * @module History
 */
import React from 'react';
import {shallowEqual} from 'react-redux';
import css from './History.module.less';
import {$L} from '../../../utils/common';
import ChartTitle from '../ChartTitle/ChartTitle';
import HistoryItem from './HistoryItem';

const History = ({className, contentUsages}) => {

	return (
		<div className={className}>
			<ChartTitle>{$L('History')}</ChartTitle>
			<div className={css.chart}>
				{contentUsages.map((item, index) => {
					let sameDay = false;
					if(index > 0){
						if(contentUsages[index-1].date === item.date){
							sameDay = true;
						}
					}
					return <HistoryItem key={index} historyData={item} index={index} hideDate={sameDay}/>
				})}
				{contentUsages.length <=0 &&
					<div className={css.empty_comment}>{$L('You will see your watch history here.')}</div>
				}
			</div>
		</div>
	);
};

export default React.memo(History, shallowEqual);
