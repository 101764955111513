/**
 * TSwitchItem
 *
 * @module TSwitchItem
 */

import classNames from 'classnames';
import React from 'react';
import css from './TSwitchItem.module.less';
import SwitchItem from '@enact/sandstone/SwitchItem';

const TSwitchItem = ({className, children, selected, ...rest}) => {
	return (
		<SwitchItem
				className={classNames(className, css.tswitchitem, selected ? css.selected : null)}
				{...rest}
				selected={selected}
			>
			{children}
		</SwitchItem>
	);
};

export default TSwitchItem;
