import React,{useMemo,useCallback,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import css from './LoginGuide.module.less'
import TButton from "../TButton/TButton";
import { $L } from "../../utils/common";
import * as CommonActions from '../../actions/commonActions';
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import Spotlight from "@enact/spotlight";
import SpotlightIds from "../../data/SpotlightIds";

const Container = SpotlightContainerDecorator({enterTo: 'Self-first',}, 'div');

const LoginGuide = ({firmwareType,accountId,panelName}) => {
    const dispatch = useDispatch();
	const onClickLogin = useCallback(() => {
		dispatch(CommonActions.launchMembershipApp('Dashboard'));
	}, [dispatch]);

	useEffect(() => {
		setTimeout(() => {
			if(!Spotlight.focus(SpotlightIds.LOGIN_GUIDE_BUTTON)){
				Spotlight.focus(SpotlightIds.BACK_BUTTON);
			}
		}, 50);
	}, [])
	// let firmwareType = false,accountId=true;
    return (
        <div>
			{!firmwareType && !accountId &&
					<Container className={css.notLogin}>
						<div className={css.title}>
							{$L("Log in for the Best Experience.")}
						</div>
						<div className={css.subtitle}>
							{panelName === 'interview' ? $L("Log in to try customized workout.") : $L("Log in to see your workout summary.")}
						</div>
						<TButton className={css.button} onClick={onClickLogin} spotlightId={SpotlightIds.LOGIN_GUIDE_BUTTON}>{$L('Login')}</TButton>
					</Container>
				}
				{firmwareType &&
					<Container className={css.notLogin}>
						<div className={css.title}>
							{$L("Update your firmware and try more features.")}
						</div>
						<div className={css.subtitle}>
							{$L("Disclaimer: Some webOS TV has been not software update yet, please bear with me.")}
						</div>
					</Container>
				}
            </div>

		)
}


export default LoginGuide;