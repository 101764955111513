import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import TPanel from '../../components/TPanel';
import THeader from '../../components/TPanel/THeader';
import TButton from '../../components/TButton';
import TopButton from '../MainPanel/TopLayer/TopButton';
import TCheckBox from '../../components/TCheckBox';
import SpotlightIds from '../../data/SpotlightIds';
import css from './TermsConditions.module.less';
import TAlert from '../../components/TAlert';
import TSwitchItem from '../../components/TSwitchItem';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import * as Utils from '../../utils/common';
import {types} from '../../actions/actionTypes';
import {$L} from '../../utils/common';
import Spotlight from '@enact/spotlight';
import classNames from 'classnames';

const Container = SpotlightContainerDecorator({enterTo: "last-focused", preserveId: true}, 'div');

const TermsConditions = ({...props}) => {
	const getTermsDoc = useSelector(state => state.getTermsDoc);
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const dispatch = useDispatch();
	const [panelState] = useState(props.panelInfo.panelState);
	const [checkPopupOpen, setCheckPopupOpen] = useState({open: false, target: ""});
	const [exitPoPupOpen, setExitPoPupOpen] = useState(false);
	const termsInfo = useSelector(state => state.getTermsStatus); //version, accepted1, accepted2, accepted
	const spotId = props.panelInfo.spotlightId;

	//should be here for translate
	const title = {
		'termsCondition': $L("Welcome to LG Fitness"),
		'withdraw': $L("Terms & Policies")
	};

	const subTitle = {
		'termsCondition': $L("Check out various types of workout at LG Fitness"),
		'termsCondition2': $L("By agreeing to the LG Fitness Terms and Conditions, and the Privacy Policy"),
		'withdraw' : $L("You can find the terms and policies that apply to you."),
		'withdraw2' : $L("You can withdraw your consent at any time.")
	};

	useEffect(() => {
		if(Object.keys(getTermsDoc).length <= 0){
			dispatch(CmsActions.getTermsDoc());
		}
	}, [getTermsDoc, dispatch]);

	useEffect(() => {
		if(!props.hideChildren){
			if(spotId){
				Spotlight.focus(spotId);
			}else{
				Spotlight.focus('btn0');
				Spotlight.focus('btn2');
				Spotlight.focus('checkbox3');
			}
		}
	}, [props.hideChildren, spotId]);

	const onClickTermBtn = useCallback(() => {
		dispatch(PanelActions.pushPanel('termsConditionsDetail', {title: "Terms & Conditions", btnNum: "btn0", panelState}));
	}, [dispatch, panelState]);

	const onClickPrivacyBtn = useCallback(() => {
		dispatch(PanelActions.pushPanel('termsConditionsDetail', {title: "Privacy Policy", btnNum: "btn1", panelState}));
	}, [dispatch, panelState]);

	const onClickServiceBtn = useCallback(() => {
		dispatch(PanelActions.pushPanel('termsConditionsDetail', {title: "개인정보 처리방침", btnNum: "btn2", panelState}));
	}, [dispatch, panelState]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if(panelState === "withdraw"){
			dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted1:true, accepted2:true, accepted:true}});
			dispatch(PanelActions.popPanel('termsConditions'));
		}else{
			setCheckPopupOpen({open: true});
		}
	},[dispatch, panelState] );

	const onClickAgree = useCallback(() => {
		if(panelState === "withdraw"){
			if(!termsInfo.accepted1 || !termsInfo.accepted2 || !termsInfo.accepted){
				setExitPoPupOpen(true);
				dispatch(CmsActions.updateTerms());
				setTimeout(() => {
					Utils.windowClose();
				}, 3000);
			}else{
				onBack();
			}
			return;
		}
		dispatch(CmsActions.updateTerms());
		dispatch(PanelActions.popPanel('termsConditions'));
	}, [dispatch, panelState, onBack, termsInfo]);

	const onCheckpopupCancel = useCallback(() => {
		setCheckPopupOpen({open: false});
		if(panelState === "withdraw"){
			Spotlight.focus('termsItemsLayer');
		}else{
			Spotlight.focus('cancelBtn');
		}
	},[panelState]);

	const onCloseCheckPopup = useCallback((ev) => {
		if(ev === 0){
			if(panelState === "withdraw"){
				dispatch({ type: types.GET_TERMS_STATUS, payload: {[checkPopupOpen.target]:false} });
				setCheckPopupOpen({open: false});
				Spotlight.focus(checkPopupOpen.target === 'accepted1' ? 'switch1' : 'switch2');
			}else{
				setTimeout(() => {
					Utils.windowClose();
				}, 1000);
			}
		}else{
			onCheckpopupCancel();
		}
	}, [dispatch, panelState, checkPopupOpen, onCheckpopupCancel]);

	const openClickExit = useCallback(() => {
		Utils.windowClose();
	}, []);

	const onToggleTermsAgree = useCallback(() => {
		if(panelState === "withdraw" && termsInfo.accepted1){
			setCheckPopupOpen({open: true, target: "accepted1"});
		}else{
			dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted1:!termsInfo.accepted1} });
		}
	},[dispatch, termsInfo, panelState]);

	const onTogglePrivacyAgree = useCallback(() => {
		if(panelState === "withdraw" && termsInfo.accepted2){
			setCheckPopupOpen({open: true, target: "accepted2"});
		}else{
			dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted2:!termsInfo.accepted2} });
		}
	},[dispatch, termsInfo]);

	const onToggleAgeAgree = useCallback(() => {
		dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted:!termsInfo.accepted} });
	},[dispatch, termsInfo]);

	const onAllAgree = useCallback(() => {
		if(termsInfo.accepted1 && termsInfo.accepted2 && termsInfo.accepted){
			dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted1:false, accepted2:false, accepted:false} });
		}else{
			dispatch({ type: types.GET_TERMS_STATUS, payload: {accepted1:true, accepted2:true, accepted:true} });
		}
	},[dispatch, termsInfo]);

	const onSpotlightUpCheck1 = useCallback((ev)=>{
		if(Spotlight.focus("checkbox0")){
			ev.stopPropagation();
		}
	}, []);

	const onSpotlightDownExit = useCallback((ev)=>{
		if(Spotlight.focus("btn2")){
			ev.stopPropagation();
		}else if(Spotlight.focus("btn0")){
			ev.stopPropagation();
		}
	}, []);

	const onSpotlightCheckBox1 = useCallback((ev)=>{
		if(Spotlight.focus("checkbox1")){
			ev.stopPropagation();
		}
	}, []);

	const onSpotlightDownOverAge = useCallback((ev)=>{
		if(Spotlight.focus("checkbox3")){
			ev.stopPropagation();
		}
	}, []);
	const onSpotlightUpAllAgreeBtn = useCallback((ev)=>{
		if(Spotlight.focus("checkbox2")){
			ev.stopPropagation();
		}
	}, []);

	const alertMsg = (panelState === "withdraw") ? $L('If you withdraw from the terms and conditions, you will not be able to use the service. Would you like to continue?')
		: $L('Are you sure you want to exit LG Fitness?');
	const btn1text = (panelState === "withdraw") ? $L('OK') : $L('Exit');
	const okBtnDisabled =  panelState !== 'withdraw' && (!termsInfo.accepted1 || !termsInfo.accepted2 || !termsInfo.accepted);
	return (
		<TPanel className={css.panel} handleCancel={onBack}>
			{ panelState === "withdraw" ?
				<THeader onClick={onBack}/>
				:
				<TopButton className={css.exitBtn} spotlightId={SpotlightIds.MAIN_EXITBTN} onSelect={openClickExit} onSpotlightDown={onSpotlightDownExit}/>
			}
			<div className={classNames(css.title, deviceAccountInfo.language === 'ko' ? css.title_ko : null)}>
				{panelState === "withdraw" ? title.withdraw : title.termsCondition}
			</div>
			<div className={classNames(css.subTitle, deviceAccountInfo.language === 'ko'? css.subTitle_ko: null)}>
				{panelState === 'withdraw' ?
					<>
						<div>{subTitle.withdraw}</div>
						<div>{subTitle.withdraw2}</div>
					</>
					:
					<>
						<div>{subTitle.termsCondition}</div>
						{deviceAccountInfo.language === 'en' &&
							<div>{subTitle.termsCondition2}</div>
						}
						<div className={css.text_explain}>{$L("Even if you delete the app terms you agreed to are maintained.")}</div>
					</>
				}
			</div>
			<Container spotlightId={"termsItemsLayer"} className={classNames(css.termsItemsLayer, deviceAccountInfo.language === 'ko' ? css.ko : null
				, panelState === 'withdraw' ? css.withdraw: null)}>
				{deviceAccountInfo.language === 'ko' &&
					<div className={css.termsItemsRow}>
						<TButton spotlightId={"btn2"} type={"withArrow"} onClick={onClickServiceBtn}>
								{"개인정보 처리방침"}
						</TButton>
					</div>
				}
				<div className={css.termsItemsRow}>
					<TButton spotlightId={"btn0"} type={"withArrow"} onClick={onClickTermBtn}>{$L("Terms & Conditions")}</TButton>
					{panelState === 'withdraw' ?
						<TSwitchItem data-webos-voice-label={$L('Terms & Conditions')} spotlightId={"switch1"} selected={termsInfo.accepted1} onToggle={onToggleTermsAgree} className={css.switch}>{termsInfo.accepted1 ? $L('Agree') : $L({value: 'Do Not Agree', key: 'do_not_agree2'})}</TSwitchItem>
						:
						<TCheckBox className={css.checkbox} spotlightId={"checkbox0"} selected={termsInfo.accepted1} onToggle={onToggleTermsAgree}  onSpotlightDown={onSpotlightCheckBox1}/>
					}
				</div>
				<div className={css.termsItemsRow}>
					<TButton spotlightId={"btn1"} type={"withArrow"} onClick={onClickPrivacyBtn}>{$L("Privacy Policy")}</TButton>
					{panelState === 'withdraw' ?
						<TSwitchItem data-webos-voice-label={$L('Privacy Policy')} spotlightId={"switch2"} selected={termsInfo.accepted2} onToggle={onTogglePrivacyAgree} className={css.switch}>{termsInfo.accepted2 ? $L('Agree') : $L({value: 'Do Not Agree', key: 'do_not_agree2'})}</TSwitchItem>
						:
						<TCheckBox className={css.checkbox} spotlightId={"checkbox1"} selected={termsInfo.accepted2}
							onToggle={onTogglePrivacyAgree} onSpotlightUp={onSpotlightUpCheck1}/>
					}
				</div>
				{panelState !== 'withdraw' &&
					<div className={css.termsItemsRow}>
						<div className={css.imoverage}>{$L("I’m over 16 years old.")}</div>
						<TCheckBox className={css.checkbox} spotlightId={"checkbox2"} selected={termsInfo.accepted} onToggle={onToggleAgeAgree} onSpotlightUp={onSpotlightCheckBox1} onSpotlightDown={onSpotlightDownOverAge}/>
					</div>
				}
			</Container>

			{panelState !== 'withdraw' &&
				<TCheckBox className={css.checkbox_allAgree} spotlightId={"checkbox3"} selected={termsInfo.accepted1 && termsInfo.accepted2 && termsInfo.accepted} onToggle={onAllAgree} onSpotlightUp={onSpotlightUpAllAgreeBtn}>{$L("I agree to all")}</TCheckBox>
			}
			<div className={css.bottomBtnLayer}>
				<TButton className={css.nomalBtn} disabled={okBtnDisabled} spotlightId={"aggreeBtn"} onClick={onClickAgree}>
					{$L("OK")}
				</TButton>
				<TButton className={css.nomalBtn} spotlightId={"cancelBtn"} onClick={onBack}>
					{$L("Cancel")}
				</TButton>
			</div>
			{checkPopupOpen.open &&
				<TAlert message={alertMsg} button1text={btn1text} button2text={$L('Cancel')} onClick={onCloseCheckPopup} onClose={onCheckpopupCancel}/>
			}
			{ exitPoPupOpen &&
				<TAlert message={$L("Thank you for using LG Fitness and hope to see you again. The application well close in 3 seconds.")}  />
			}
		</TPanel>
	);
};

export default TermsConditions;