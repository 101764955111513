import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { FixedPopupPanels, Panel } from '@enact/sandstone/FixedPopupPanels';
import css from './RepeatSettingPopup.module.less';
import {$L} from '../../utils/common';
import * as PanelActions from '../../actions/panelActions';
import { types } from '../../actions/actionTypes';
import { InputField } from '@enact/sandstone/Input';
import Item from '@enact/sandstone/Item';
import TButton from '../TButton/TButton';
import SpotlightIds from '../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';

const RepeatSettingPopup = ({ type, open, onClosePopup, selectedContent, onlyEditRepeatCountContent,
	isEditMode, planId, previousFocus, title, order, ...rest }) => {

	const dispatch = useDispatch();
	const [repeatCount, setRepeatCount] = useState(1);

	const tempPlanInfo = useSelector(state => state.tempPlanInfo);

	const onHide = useCallback((ev) => {
		console.log('onHide', ev);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus("inputRepeatCountBox")
		}, 100);
	}, [open])

	const onClose = useCallback((ev) => {
		if (onClosePopup) {
			onClosePopup(ev);
			if (type !== 'onlyEditRepeatCount') {
				Spotlight.focus(previousFocus);
			}
		}
	}, [onClosePopup, previousFocus, type]);

	useEffect(() => {
		if (onlyEditRepeatCountContent) {
			setRepeatCount(onlyEditRepeatCountContent.info.repeatCount);
		}
	}, [onlyEditRepeatCountContent]);

	useEffect(() => {
		if (selectedContent) {
			selectedContent.repeatCount = repeatCount;
		} else {
			onlyEditRepeatCountContent.info.repeatCount = repeatCount;
		}
	}, [repeatCount]);

	const contentInfo = { contentChanged: true, order: order, items: [selectedContent] };
	const onlyEditRepeatCountInfo = { order: onlyEditRepeatCountContent && onlyEditRepeatCountContent.order, items: onlyEditRepeatCountContent && onlyEditRepeatCountContent.info };

	const onCompletedRepeatSetting = useCallback((ev) => {
		if (type==='onlyEditRepeatCount') {
			let newArr = [];
			tempPlanInfo.plans.map((item, i) => {
				if (onlyEditRepeatCountContent && onlyEditRepeatCountContent.order === i) {
					newArr.push({ ...item, ["order"]:onlyEditRepeatCountInfo.order, ["info"]: onlyEditRepeatCountInfo.items });
				} else {
					newArr.push({ ...item });
				}
			});
			dispatch({ type: types.SET_TEMPORARY_PLAN, payload: { plans: newArr } });
			if (ev.type === 'click') {
				onClosePopup({ type: 'click' });
			} else {
				onClosePopup({ type : 'keyup'});
			}
		} else {
			dispatch(PanelActions.popPanel('settingsMediaPicker'));
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { title, isEditMode, planId, info: contentInfo }));
		}
	}, [dispatch, contentInfo, isEditMode, planId, onClosePopup, onlyEditRepeatCountInfo, tempPlanInfo, title, type]);

	const onHandleInput = useCallback((ev) => {
		if (ev.key === 'Enter') {
			if (repeatCount <= 3 && repeatCount >= 1) {
				onCompletedRepeatSetting(ev);
			}
		}
	}, [repeatCount, onCompletedRepeatSetting]);

	const onCountChange = useCallback((ev) => {
		setRepeatCount(ev.value.slice(1));
	}, []);

	const onClickRepetCount = useCallback((count) => (ev) => {
		ev.persist();
		setRepeatCount(count)
		setTimeout(() => {
			onCompletedRepeatSetting(ev)
		}, 100);
	}, [onCompletedRepeatSetting]);

	return (
		<FixedPopupPanels className={css.fixedpanel} position="center" open={open} onHide={onHide} onClose={onClose}>
			<Panel {...rest} className={css.panel}>
				<TButton className={css.backBtn } spotlightId={SpotlightIds.BACK_BUTTON} type={'back'} onClick={onClose} />
				<div className={css.text}>{$L('How many times do you want to repeat this content? (Repeatable up to 3 times.)')}</div>
				<div className={css.inputWrap}>
					<InputField
						className={css.inputField}
						type="number"
						length={1}
						spotlightId={"inputRepeatCountBox"}
						autoFocus
						dismissOnEnter
						onChange={onCountChange}
						onKeyDown={onHandleInput}
						value={repeatCount}
					/>
				</div>
				<div className={css.repetCount}>
					<Item className={css.repeatNumber} centered onClick={onClickRepetCount(1)}>1</Item>
					<Item className={css.repeatNumber} centered onClick={onClickRepetCount(2)}>2</Item>
					<Item className={css.repeatNumber} centered onClick={onClickRepetCount(3)}>3</Item>
				</div>
			</Panel>
		</FixedPopupPanels>
	);
};

export default RepeatSettingPopup;
