export const HOMEEGYM_BASEURL_KO_TWIN = 'http://13.209.63.176:8080/api';

//Sequired domain

export const HOMEGYM_BASEURL_KO_PRD = "https://kic.homegym.lgappstv.com/api";

export const HOMEGYM_BASEURL_EN_PRD = "https://aic.homegym.lgappstv.com/api";
export const HOMEGYM_BASEURL_KO_ADMIN = "https://kic-admin.homegym.lgappstv.com/api";
export const HOMEGYM_BASEURL_EN_ADMIN = "https://aic-admin.homegym.lgappstv.com/api";

// Sequired domain
export const HOMEGYM_BASEURL_QA_ADMIN = "https://qt-kic-admin.homegym.lgappstv.com/api";
export const HOMEGYM_BASEURL_QA = "https://qt-kic.homegym.lgappstv.com/api";
export const HOMEGYM_BASEURL_DEV_ADMIN = "https://qt2-kic.homegym.lgappstv.com/api";
export const HOMEGYM_BASEURL_DEV = "https://qt2-kic.homegym.lgappstv.com/api";

export const YOUTUBE_BASEURL = 'https://www.googleapis.com/youtube/v3/';


export const WORKSTYLES = '/1.0/workoutStyles';

export const BACKGROUND = '/1.0/background';
export const ACCOUNT = '/1.0/accounts';
export const GET_ACTIVE_CATEGORIES = '/1.0/categories';
// export const GET_ACTIVE_BANNERS = '/1.0/banners';
export const GET_ACTIVE_BANNERS = '/2.0/banners';

/* Notice */
export const GET_NOTICE = '/1.0/notice';
export const GET_NOTICE_LIST = '/1.0/notice/all';

/* Contents */
export const UNKNOWN_WORKOUTSTYLEID=1;
export const GET_CONTENTS_OF_CATEGORY = '/1.0/contents/category';
export const GET_CONTENTS_TOPS = '/1.0/contents/tops';
export const GET_CONTENTS_RECOMMENDED = '/1.0/contents/recommended';
export const GET_CONTENTS_FAVORITE = '/1.0/contents/favorites';
export const GET_CONTENTS_RECENTLY_ADDED = '/1.0/contents/recently';
export const GET_CONTENTS_OF_TAG = '/1.0/contents/tag';
export const GET_CONTENTINFO = '/1.0/contents';
export const GET_CONTENTS_RECENTLYWATCHED = '/1.0/contents/watched';
export const GET_RELATEDCONTENT_TAG_CATEGORY = '/1.0/contents/related/tagAndCategory';
export const GET_RELATEDCONTENT_TAG = '/1.0/contents/related/tag';
export const GET_RELATEDCONTENT_CATEGORY = '/1.0/contents/related/category';
export const PLAYLOG = '/1.0/playLog';
export const APPLOG = '/1.0/applog';
export const GET_ORDEREDTAGLIST = '/1.0/tagpositions';
export const GET_CONTENTS_OF_KEYWORD = '/1.0/contents/search';
export const REGIST_CONTENTS_YOUTUBE = '/1.0/contents/youtube';
export const REGIST_CONTENTS_CPCONTENT = '/1.0/contents/cpContent';

export const GET_HOME_GROUP = '/1.0/home';
export const GET_HOME_GROUP2 = '/2.0/homeGroup';

/* Last Content */
export const GET_RECENTLY_WATCHED_CONTENT = '/1.0/contents/watchedLast';

/* Workout Summary*/
export const GET_WEEKLY_WORKOUT_SUMMARY = '/1.0/workoutSummary/weekly';
export const GET_MONTHLY_WORKOUT_SUMMARY = '/1.0/workoutSummary/monthly';
export const GET_YEARLY_WORKOUT_SUMMARY = '/1.0/workoutSummary/yearly';

/* Goal ACHIEVEMENTS*/
export const GET_WEEKLY_ACHIEVEMENT = '/1.0/goals/achievements/weekly';
export const GET_MONTHLY_ACHIEVEMENT = '/1.0/goals/achievements/monthly';
export const GET_YEARLY_ACHIEVEMENT = '/1.0/goals/achievements/yearly';

export const GET_CONTENT_USAGES = '/1.0/contentUsages';

/* Favorite */
export const GET_DEFAUILT_FAVORITE_OF_ACCOUNT = '/1.0/favorites/default';
export const MY_FAVORITES = '/1.0/favorites';

/* */
export const LOGIN = '/1.0/logAction/login';
export const LOGOUT = '/1.0/logAction/logout';

/* Terms */
export const TERMSCONDITION = '/1.0/terms';
export const GET_TERMSCONDITION_DOCS = '/1.0/terms/docs';
export const GET_TERMSMODIFICATION = '/1.0/termsmodification';

/* Alram */
export const GET_ALARMS = '/1.0/alarms';

/* Goal */
export const GET_GOALS_OF_ACCOUNT = '/1.0/goals';

/* Plan */
export const GET_PLANS_OF_ACCOUNT = '/1.0/plans';

/* Youtube */
export const GET_YOUTUBE_CHANNELIDS = '/1.0/youtube';
export const GET_YOUTUBE_VIDEOS = '/1.0/youtube/ex';

export const HOMEGYM_SUPPORT_COUNTRYS = {US:'en', KR:'ko'};
export const CMS_AUTHORIZATION = 'Bearer lckfjdojkvgdmfkfjkdflfjksd';
export const YOUTUBE_AUTHORIZATION = 'AIzaSyBSy2LEQuqfaFu4QaWriMx8ZL8bnLn5A9o';
export const YOUTUBE_AUTHORIZATION_PESONAL = 'AIzaSyBN52XQ9P7ZzDFGxn8VMl99xJhMh_8Q_cU';//personal key
export const GET_YOUTUBE_SEARCH_CHANNELS_URL = YOUTUBE_BASEURL + 'channels';
export const GET_YOUTUBE_SEARCH_VIDEOS_URL = YOUTUBE_BASEURL + 'search';

/* Customizedworkout */
export const GET_CUSTOMIZED_WORKOUT_OF_ACCOUNT = '/2.0/customizedworkout';
export const GET_CUSTOMIZED_USERINFO = '/2.0/customizedworkout/account';
export const GET_THIRD_PARTY_APPS = '/2.0/thirdparty';

export const GET_ADMIN_PREVIEW = '/1.0/preview';

/* Floating messages */
export const GET_FLOATING_MESSAGES = '/2.0/floatingmessages/active';

/* Homegym url */
export const GYMURL = (language, endStr, useQAServerURL) => {
	let base ="";
	switch (language){
		case 'ko':
			if(useQAServerURL === 'qa'){
				base = HOMEGYM_BASEURL_QA;
			}else if (useQAServerURL === 'dev') {
				base = HOMEGYM_BASEURL_DEV;
			}else if(useQAServerURL === 'twin'){
				base = HOMEEGYM_BASEURL_KO_TWIN;
			}else{
				base = HOMEGYM_BASEURL_KO_PRD;
			}
			break;
		default:
			if(useQAServerURL === 'qa'){
				base = HOMEGYM_BASEURL_QA;
			}else if (useQAServerURL === 'dev') {
				base = HOMEGYM_BASEURL_DEV;
			}else if(useQAServerURL === 'twin'){
				base = HOMEEGYM_BASEURL_KO_TWIN;
			}else{
				base = HOMEGYM_BASEURL_EN_PRD;
			}
			break;
	}
	if(ADMIN_PREVIEW_MODE){
		switch (ADMIN_PREVIEW_MODE){
			case 'twin':
				base = HOMEEGYM_BASEURL_KO_TWIN;
				break;
			case 'dev':
				base = HOMEGYM_BASEURL_DEV_ADMIN;
				break;
			case 'kr':
				base = HOMEGYM_BASEURL_KO_ADMIN;
				break;
			case 'us':
				base = HOMEGYM_BASEURL_EN_ADMIN;
				break;
			case 'qa':
				base = HOMEGYM_BASEURL_QA_ADMIN;
				break;
		}
	}
	return base+endStr;
};

/* debug */
export const DEFAULT_SERVERTYPE='prd'; //'qa', 'prd'
export const USE_DUMMY= false;
export const USE_YOUTUBE_DUMMY= false;
export const SUPPORT_LOGIN= true;
export const SUPPORT_WORKOUTSTYLE = false;
export const SUPPORT_INTERVIEW = true;
export const DEBUG_SHOW_TERMS = false;
export const SHOW_TOUCH_GUIDE=false;

export const DEBUG_KEY="5286";
export const TESTPANEL_KEY="5325";

export const CMS_SIZE_LIMIT=30; //max 30
export const HLIST_HOME_MAX=15;

/* for admin */
export const ADMIN_PREVIEW_MODE=false;  //false, "twin", "dev", "kr", "us", "qa"
// export const DEBUG_WINDOW_COUNTRY = ADMIN_PREVIEW_MODE === "us" ? "US": "KR";
// export const DEBUG_WINDOW_ACCOUNTID_KR = "";
// export const DEBUG_WINDOW_ACCOUNTID_US = "";

//local window test
export const DEBUG_WINDOW_COUNTRY = "KR"; //KR,US
export const DEBUG_WINDOW_ACCOUNTID_KR = "KR2021090200002";
export const DEBUG_WINDOW_ACCOUNTID_US = "US2021090200001";

export const DEBUG_WINDOW_DEVICEID = "twintestdeviceid";
export const DEBUG_WINDOW_ACCOUNT_DISPLAYNAME = "twin@t-win.kr";
// virtual permission for tv
//export const DEBUG_VIRTUAL_PERMISSION = false; //DEBUG_WINDOW_COUNTRY, DEBUG_WINDOW_DEVICEID, DEBUG_WINDOW_ACCOUNTID_KR
export const DEBUG_VIDEO_SUBTITLE_TEST = false;

export const HOME_PREVIEW_DELAY = 1000;
export const HOME_PREVIEW_LIMIT_SEC = 30;
export const AUTO_SCROLL_DELAY = 600;
export const CALORIE_ACHIEVE_RATE = 0.8;

export const CPCONTENTS_TEST = false;
export const USE_NEW_TODAY_ICON = true;