import classNames from 'classnames';
import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { on, off } from '@enact/core/dispatcher';
import { Job } from '@enact/core/util';
import platform from '@enact/core/platform';
import Scroller from '@enact/sandstone/Scroller';
import Spotlight from '@enact/spotlight';
import css from './MoreFormedListPanel.module.less';
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/TPanel/THeader';
import MediaList from '../../components/MediaList/MediaList';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import * as PanelActions from '../../actions/panelActions';
import VerticalScrollGuide from '../../components/VerticalScrollGuide/VerticalScrollGuide';
import SpotlightIds from '../../data/SpotlightIds';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';

const SCROLLER_ID = "detail_scroller";
const MAX_VISIBLE_ITEM = 15;
const calculateVisibleListJob = new Job((func) => {
  func();
}, 2000);

const Container = SpotlightContainerDecorator({ leaveFor: { left: '', right: '', down: '' }, enterTo: '', spotlightRestrict: 'self-only' }, 'div');

const MoreFormedListPanel = ({ hideChildren, panelInfo, ...props }) => {
  const dispatch = useDispatch();
  const scrollTo = useRef(null);
  const cursorVisible = useSelector(state => state.appStatus.cursorVisible);
  const { accountId } = useSelector(state => state.deviceAccountInfo);
  const supportLogin = useSelector(state => state.supportLogin);
  const [scrollOnBottom, setScrollOnBottom] = useState(false);
  const [scrollOnTop, setScrollOnTop] = useState(true);
  const [visibleList, setVisibleList] = useState([]);
  const [restoreIndex, setRestoreIndex] = useState(-1);
  const scrollTop = useRef(0);
  const [channelList, setChannelList] = useState([]);

  const logHint = useMemo(() => {
    return panelInfo.title;
  }, [panelInfo]);

  const supportFavBtn = useMemo(() => {
    if (supportLogin && accountId) {
      return true;
    }
    return false;
  }, [supportLogin, accountId]);

  useEffect(() => {
    if (!panelInfo.list || panelInfo.list.length <= 0) {
      Spotlight.focus(SpotlightIds.BACK_BUTTON);
    } else {
      /** Set List by Channel
       * 하나의 ContentList 를 Channel 단위 리스트로 변경
       */

      let tempList = []
      let temp = []

      panelInfo.list.forEach((content, idx) => {
        if (content.contentType == "youtube#channel") {
          temp = []
          tempList.push(temp)
        }
        temp.push(content);
      });
      setChannelList(tempList);
    }


  }, [panelInfo.list]);

  useEffect(() => {
    Spotlight.focus(SCROLLER_ID);
    on('wheel', handleWheel);
    calculateVisibleListJob.start(calculateVisibleList);
    return () => {
      off('wheel', handleWheel);
      if (calculateVisibleListJob) {
        calculateVisibleListJob.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!hideChildren) {
      Spotlight.focus(SpotlightIds.BACK_BUTTON);
      setRestoreIndex(panelInfo.restoreIndex);
      setTimeout(() => {
        Spotlight.focus(SCROLLER_ID);
        if (panelInfo.focusedListIndex >=0 && scrollTo.current) {
          for(let i=0; i<panelInfo.focusedListIndex; i++){
            moveBottom(true);
          }
          Spotlight.focus(SpotlightIds.LIST_MORE+panelInfo.focusedListIndex);
        }
      }, 100);
      setTimeout(() => {
        setRestoreIndex(-1);
      }, 500);
    }
  }, [hideChildren]);

  const onBack = useCallback(() => {
    dispatch(PanelActions.popPanel('moreformedlist'));
  }, [dispatch]);

  const calculateVisibleList = useCallback(() => {
    const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
    let listArray = scroller ? scroller.children[0].children[0].children : [];
    const itemList = [];
    for (let index in listArray) {
      if (!isNaN(index)) {
        const item = listArray[index];
        if (item.offsetTop >= scrollTop.current && (item.offsetTop + item.offsetHeight) <= scrollTop.current + scroller.offsetHeight) {
          itemList.push(item.getAttribute('data-spotlight-id'));
        }
      }
    }
    setVisibleList(itemList);
  }, []);

  const getScrollTo = useCallback((cbScrollTo) => {
    scrollTo.current = cbScrollTo;
  }, [scrollTo]);

  const onScrollStop = useCallback((ev) => {
    scrollTop.current = Math.round(ev.scrollTop);
    const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
    if (scrollTop.current + scroller.offsetHeight + 300 /*dummy*/ > scroller.children[0].scrollHeight) {
      setScrollOnBottom(true);
    } else {
      setScrollOnBottom(false);
    }
    if (scrollTop.current <= 0) {
      setScrollOnTop(true);
    } else {
      setScrollOnTop(false);
    }
    calculateVisibleList();
  }, [scrollTop, setScrollOnBottom, setScrollOnTop, calculateVisibleList, dispatch]);



  const moveBottom = useCallback((wheel = false) => {
    if (scrollTo.current !== null) {
      const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
      let listArray = scroller ? scroller.children[0].children[0].children : [];
      if (!wheel) {
        const currentItem = Spotlight.getCurrent();
        const nextItem = currentItem && currentItem.nextElementSibling;
        Spotlight.focus(nextItem);
        if (currentItem && nextItem && nextItem !== listArray[listArray.length - 1]) { //check dummy
          const offsetBottom = nextItem.offsetTop + nextItem.offsetHeight;
          if (offsetBottom < (scrollTop.current + scroller.offsetHeight)) { //contain
            return true;
          } else {
            const targetOffset = currentItem.offsetTop - currentItem.offsetHeight;
            scrollTop.current = targetOffset;
            scrollTo.current({ position: { y: targetOffset }, animate: true });
            return true;
          }
        }
        return false;
      }
      for (let i = 0; i < listArray.length; i++) {
        if (scrollTop.current < listArray[i].offsetTop) {
          if (i < (listArray.length - 3)) { //ignore dummy, lastitem
            Spotlight.focus(listArray[i]);
            scrollTop.current = listArray[i].offsetTop;
            scrollTo.current({ position: { y: listArray[i].offsetTop }, animate: true });
          } else if (i === (listArray.length - 4)) { //last item
            let prevItem = listArray[i].previousElementSibling;
            if (prevItem) {
              Spotlight.focus(listArray[i]);
              scrollTop.current = listArray[i].offsetTop;
              scrollTo.current({ position: { y: listArray[i].offsetTop }, animate: true });
            }
          }
          return true;
        }
      }
    }
  }, []);
  const moveTop = useCallback((wheel = false) => {
    if (scrollTo.current !== null) {
      const listView = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
      let listArray = listView ? listView.children[0].children[0].children : [];
      if (!wheel) {
        const currentItem = Spotlight.getCurrent();
        let nextItem = currentItem && currentItem.previousElementSibling;
        if (currentItem && nextItem) {
          Spotlight.focus(nextItem);
          if (nextItem.offsetTop === scrollTop.current) { //contain
            return true;
          } else {
            scrollTop.current = nextItem.offsetTop;
            scrollTo.current({ position: { y: nextItem.offsetTop - nextItem.offsetHeight }, animate: true });
            return true;
          }
        }
        return false;
      }

      for (let i = listArray.length - 1; i >= 0; i--) {
        if (listArray[i].offsetTop < scrollTop.current) {
          if (i == listArray.length - 1) {
            i -= 1
          }
          scrollTop.current = listArray[i].offsetTop;
          scrollTo.current({ position: { y: listArray[i].offsetTop }, animate: true });
          Spotlight.focus(listArray[i]);
          return true;
        }
      }
    }
  }, []);

  const onClickTopGuide = useCallback(() => {
    moveTop(true);
  }, [moveTop]);

  const onClickBottomGuide = useCallback(() => {
    moveBottom(true);
  }, [moveBottom]);

  const onKeyDown = useCallback((ev) => {
    if (ev.key === 'ArrowDown' || ev.key === 'PageDown') {
      return moveBottom(false);
    } else if (ev.key === 'ArrowUp' || ev.key === 'PageUp') {
      return moveTop(false);
    }
    return false;
  }, [moveTop, moveBottom]);

  const handleWheel = useCallback((ev) => {
    console.log('onWheel ', ev);
    if (ev.deltaY < 0) {
      onClickTopGuide();
    } else {
      onClickBottomGuide()
    }
  }, [onClickTopGuide, onClickBottomGuide]);

  const onFocusedIndexChanged = useCallback((id, index) => {
    if(id && Number(id[id.length-1]) !== NaN){
      dispatch(PanelActions.updatePanel('moreformedlist', { focusedListIndex: Number(id[id.length-1]), restoreIndex: index }));
    }
  }, [dispatch]);

  const renderList = useCallback(({ idx, spotlightId, viewList, supportFavBtn, visibleList, restoreIndex, ...rest }) => {

    if (viewList && viewList.length > 0) {
      return (<MediaList {...rest} visibleList={visibleList} supportFavBtn={supportFavBtn} restoreIndex={restoreIndex} spotlightId={spotlightId} viewList={viewList}
          onKeyDown={onKeyDown} key={idx}/>);
    }
    return null;
  }, [false, onKeyDown, visibleList]);

  return (
    <TPanel {...props} className={css.panel} handleCancel={onBack}>
      <THeader allowClickOnPreview title={panelInfo.title} onClick={onBack}/>
      {channelList && channelList.length > 0 &&
        <Scroller
          cbScrollTo={getScrollTo}
          className={classNames(css.medialistlayer)}
          spotlightId={SCROLLER_ID}
          direction="vertical"
          horizontalScrollbar="hidden"
          verticalScrollbar="hidden"
          scrollMode="translate"
          noScrollByWheel
          noScrollByDrag
          onScrollStop={onScrollStop}
        >
          <Container>
            {
              channelList.map((channelContentsList, idx) => (
                renderList({ spotlightId: SpotlightIds.LIST_MORE+idx, viewList: channelContentsList, visibleList, supportFavBtn, onFocusedIndexChanged, logHint, idx, restoreIndex})
              ))
            }
            <div className={css.dummy}/>
          </Container>
        </Scroller>
      }
      {(cursorVisible || platform.touchscreen) && !scrollOnTop &&
        <VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
      }
      { (cursorVisible || platform.touchscreen) && !scrollOnBottom &&
        <VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
      }
    </TPanel >
  );
};

export default MoreFormedListPanel;