/**
 * TScroller
 *
 * @module TScroller
 */

import classNames from 'classnames';
import Scroller from '@enact/sandstone/Scroller/Scroller';
import React, {useState, useCallback, useEffect, useRef} from 'react';
import Spotlight from '@enact/spotlight';
const TScroller = ({className, children, ...rest}) => {
	const isScrolling = useRef(false);
	const scrollPosition = useRef("top");
	const _onScrollStart = useCallback((ev) => {
		if(rest.onScrollStart){
			rest.onScrollStart(ev);
		}
		isScrolling.current = true;
		console.log('_onScrollStart',ev);
	}, []);
	const _onScrollStop = useCallback((ev) => {
		if(rest.onScrollStop){
			rest.onScrollStop(ev);
		}
		isScrolling.current = false;
		if(ev.reachedEdgeInfo){
			if(ev.reachedEdgeInfo.top){
				scrollPosition.current = "top";
			}else if(ev.reachedEdgeInfo.bottom){
				scrollPosition.current = "bottom";
			}else{
				scrollPosition.current = "middle";
			}
		}else{
			scrollPosition.current = "middle";
		}
	}, []);
	const _onKeyDown = useCallback((ev) => {
		if(rest.onKeyDown){
			rest.onKeyDown(ev);
		}
		if( isScrolling.current){
			return;
		}
		if(ev.key === 'ArrowUp' && scrollPosition.current === 'top'){
			ev.stopPropagation();
			ev.preventDefault();
			Spotlight.move('up');
		}else if(ev.key === 'ArrowDown' && scrollPosition.current === 'bottom'){
			ev.stopPropagation();
			ev.preventDefault();
			Spotlight.move('down');
		}
	}, []);
	return (
		<Scroller
			scrollMode='translate'
			focusableScrollbar
			className={classNames(className)}
			direction='vertical'
			horizontalScrollbar='hidden'
			noScrollByDrag
			fadeOut={false}
			verticalScrollbar='auto'
			overscrollEffectOn={{
				arrowKey: false,
				drag: false,
				pageKey: false,
				track: false,
				wheel: false
			}}
			onScrollStart = {_onScrollStart}
			onScrollStop = {_onScrollStop}
			onKeyDown={_onKeyDown}
			{...rest}
		>
			{children}
		</Scroller>
	);
};

export default TScroller;
