/**
 * ChartTitle
 *
 * @module ChartTitle
 */
import React from 'react';
import {shallowEqual} from 'react-redux';
import css from './ChartTitle.module.less';


const ChartTitle = ({children}) => {
	return (
		<div className={css.titleContainer}>
			<div className={css.dot}/>
			<div className={css.title}>{children}</div>
		</div>
	);
};

export default React.memo(ChartTitle, shallowEqual);
