/**
 * TopButton
 *
 * @module TopButton
 */

 import {Job} from '@enact/core/util';
import css from './TopButton.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useMemo, useEffect} from 'react';
import Spottable from '@enact/spotlight/Spottable';
import {VoiceControlDecorator} from '@enact/webos/speech';
import {$L} from '../../../utils/common';
import * as Utils from '../../../utils/common';
import * as Config from '../../../data/Config';
import SpotlightIds from '../../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../../actions/commonActions';

const SpottableComponent = Spottable('div');
const VoiceComp = VoiceControlDecorator(SpottableComponent);
const TopButton = ({isOnTop=true, className,onSelect, spotlightId, selected, extended=true,...rest}) => {
	const dispatch = useDispatch();
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const webOSVersion  = useSelector(state => state.appStatus.webOSVersion);
	const cmsAccountId = useSelector(state => state.accountInfo.accountId, shallowEqual);
	const showTooltip = useSelector(state => state.appStatus.showTooltip);
	const floatingMessages = useSelector(state => state.floatingMessages, shallowEqual);
	const buttonInfo = useMemo(() => {
		const info = {title: 'Unknown', css: null, showUnderBar: false, voiceIntent: '', voiceLabel: ''};

		switch(spotlightId){
			case SpotlightIds.MAIN_CATEGORY_BTN:
				info.title = $L("Category").toUpperCase();
				info.css = css.category;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Category");
				break;
			case  SpotlightIds.MAIN_HOME_BTN:
				info.title = $L("Home").toUpperCase();
				info.css = css.home;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Home");
				break;
			case SpotlightIds.MAIN_DASHBOARD_BTN:
				info.title = $L("Dashboard").toUpperCase();
				info.css = css.dashboard;
				info.showUnderBar = true;
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Dashboard");
				break;
			case SpotlightIds.MAIN_EXITBTN:
				info.title = $L("Exit").toUpperCase();
				info.css = classNames(css.icon,css.exit);
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Exit");
				break;
			case SpotlightIds.MAIN_SEARCHBTN:
				info.title = $L("Search").toUpperCase();
				info.css = classNames(css.icon,css.search);
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Search");
				break;
			case SpotlightIds.MAIN_SETTINGSBTN:
				info.title = $L("Settings").toUpperCase();
				info.css = classNames(css.icon,css.settings);
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Settings");
				break;
			case SpotlightIds.MAIN_LOGINBTN:   // 로그인 버튼 디자인 방식 변경
				info.title = $L("Login").toUpperCase();
				info.css = classNames(css.icon,css.login);
				info.voiceIntent = "Select";
				info.voiceLabel = $L("Login");
				break;
			case SpotlightIds.MAIN_LOGOUTBTN:   // 로그아웃 버튼 디자인 방식 변경
			{
				let userName = deviceAccountInfo.displayName;
				if(Number(webOSVersion) >= 6){
					userName = Utils.isEmailAddress(deviceAccountInfo.displayName) ? deviceAccountInfo.displayName : "";
				}
				if(Utils.isEmailAddress(userName)){
					userName = userName.split('@')[0];
				}
				if(!userName){
					userName = $L("Logged in via SNS").toUpperCase();
				}
				info.title = userName;
				info.css = classNames(css.icon,css.logout);
				info.voiceIntent = "Select";
				info.voiceLabel = userName;
				break;
			}
		}
		return info;
	}, [spotlightId, deviceAccountInfo, webOSVersion]);

	const [pressed, setPressed] = useState(false);

	const canShowToolTip = useMemo(()=>{
		if((!cmsAccountId && floatingMessages && floatingMessages.openCondition !== 'ALL')) {
			return false;
		}
		return showTooltip;
	},[cmsAccountId, showTooltip, floatingMessages]);

	const onClick = useCallback((ev) => {
		console.log('onClick ev...', ev);
		if(Config.ADMIN_PREVIEW_MODE){
			return;
		}
		setPressed(true);
		new Job(() => {
			setPressed(false);
			if (onSelect) {
				onSelect(spotlightId, rest['data-index']);
			}
		}, 200).start();
	}, [spotlightId, onSelect, rest, setPressed]);
	const onVoiceHandle = useCallback((ev) => {
		Spotlight.focus(ev.target);
		onClick();
		ev.preventDefault();
	}, [onClick]);

	const childProps = { ...rest };
	delete childProps.inline;
	delete childProps.onClick;
	return (
		<VoiceComp
			{...childProps}
			spotlightId={spotlightId}
			className={classNames(css.buttonContainer, buttonInfo.css, selected ? css.selected: null
				, pressed ? css.pressed: null, extended ? css.extended: null, className)}
			onClick={onClick}
			data-webos-voice-intent={isOnTop ? buttonInfo.voiceIntent: null}
			data-webos-voice-label={isOnTop ? buttonInfo.voiceLabel: null}
			onVoice={onVoiceHandle}
		>
			<div className={css.button}/>
			{spotlightId === SpotlightIds.MAIN_SETTINGSBTN && canShowToolTip &&
				<div className={css.tooltip}>
					<Tooltip  msg={floatingMessages.message}/>
				</div>
			}
			{!canShowToolTip &&
				<div className={css.name}>{buttonInfo.title}</div>
			}
			{extended && buttonInfo.showUnderBar &&
				<div className={css.selectBar}/>
			}
		</VoiceComp>
	);
};

export default TopButton;
