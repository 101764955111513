/**
 * YearlyView
 *
 * @module YearlyView
 */
import classNames from 'classnames';
import React from 'react';
import {shallowEqual} from 'react-redux';
import {$L} from '../../../utils/common';
import css from './GoalInfo.module.less';


const YearlyView = ({goalData}) => {
	const MONTHES = [$L("January"),$L("February"),$L("March"),$L("April"),$L("May"),
		$L("June"),$L("July"),$L("August"),$L("September"),$L("October"), $L( "November"),$L("December")];

	return(
		<div className={css.yearlyView}>
			{MONTHES.map((item, index) => (
				<div key={index} className={classNames(css.item)}>
					<div className={css.title}>{item}</div>
					<div className={css.datalayer}>
						{goalData.showingType === 'all' ?
							<div className={css.data}>
								{goalData.monthlyAchieved[index] ? goalData.monthlyAchieved[index]: 0}
							</div>
							:
							<div className={css.data}>
								{goalData.monthlyTotal[index] >0 ? Math.round((goalData.monthlyAchieved[index]/goalData.monthlyTotal[index])*100): 0}
							</div>
						}
						{goalData.showingType === 'all' &&
							<div className={css.data2}>
								{"/"}
							</div>
						}
						{goalData.showingType === 'all' ?
							<div className={css.data2}>
								{goalData.monthlyTotal[index] ? goalData.monthlyTotal[index]: 0}
							</div>
							:
							<div className={css.data2}>
								{"%"}
							</div>
						}
					</div>
				</div>
			))}
		</div>
	);
};

export default React.memo(YearlyView, shallowEqual);
