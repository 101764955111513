import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import * as PanelActions from '../../../actions/panelActions';
import css from './AlarmListPanel.module.less';
import {$L} from '../../../utils/common';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import AlarmListItem from '../../../components/AlarmItem/AlarmListItem';

const AlarmListPanel = props => {
    const dispatch = useDispatch();
    const alarmInfo = useSelector(state => state.alarmOfAccount);

    useEffect(() => {
        if (!props.hideChildren) {
            if (props.panelInfo.spotlightId) {
                Spotlight.focus(props.panelInfo.spotlightId)
            } else {
                Spotlight.focus("alarm1");
            }
        }
	}, [props.hideChildren, props.panelInfo]);

    const onBack = useCallback((ev) => {
        if (ev) {
			ev.stopPropagation();
		}
        dispatch(PanelActions.popPanel());
    }, [dispatch]);

    const onHandleBackBtn = useCallback((ev) => {
        if (ev.key === 'ArrowDown') {
            Spotlight.focus("alarm1");
            ev.stopPropagation();
        }
    }, []);

    return (
        <TPanel {...props} className={css.panel} handleCancel={onBack}>
            <THeader title={$L('Alarm Settings')} onClick={onBack}  onKeyDown={onHandleBackBtn}/>
            <div className={css.text}>{$L('Set an alarm to get notified.')}</div>
            <div className={css.alarmListBg}>
                <div className={css.listContainer}>
                    <AlarmListItem spotlightId={'alarm1'} alarmTitle="alarm1" alarmInfo={alarmInfo['alarm1']}/>
                    <AlarmListItem spotlightId={'alarm2'} alarmTitle="alarm2" alarmInfo={alarmInfo['alarm2']}/>
                </div>
            </div>
        </TPanel >
    );
};

export default AlarmListPanel;