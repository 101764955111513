import classNames from 'classnames';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Job } from '@enact/core/util';
import { $L } from '../../../utils/common';
import PageTitle from '../components/PageTitle/PageTitle';
import TPopup from '../../../components/TPopup/TPopup';
import diet from '../../../../assets/interview/interview_diet.png';
import strength from '../../../../assets/interview/interview_strength.png';
import health from '../../../../assets/interview/interview_health.png';
import css from './OneMinuitTest.module.less';
import OneMinuitButton from '../components/OneMinuitButton/OneMinuitButton';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import Spotlight from '@enact/spotlight';

const Timer = ({count}) => {
    const conicBackground = `conic-gradient(#363636 0deg ${count*6}deg , #fff 0deg ${360 - count*6}deg)`
    const rotation = `rotate(-${count/60}turn)`
    return (
        <div className={css.timerContainer}>
            <div className={css.pointerRotated} style={{transform: rotation, background: conicBackground}}>
                <div className={css.pointer}  />
            </div>
            <div className={css.pointer} />
            <div className={css.timer}>{count}</div>
        </div>
    )
}

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const OneMinuitTest = ({ pageInfo, isReady, spotlightId, direction, next, className, onAnimationEnd }) => {
    const interval = useRef(null);
    const [isTimerStart, setIsTimerStart] = useState(false);
    const [isTimerReset, setIsTimerReset] = useState(false);
    const [count, setCount] = useState(60);
    const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);

    useEffect(() => {
        if(isReady){
            if(direction === 1){
                setShowInterviewStartPopup(true);
            }
        }
    }, [pageInfo, isReady, direction]);
    const onClickStartBtn = useCallback((ev) => {
        setShowInterviewStartPopup(false);
        if(ev === 1 && next){
            next();
        }
    }, [next]);

    useEffect(() => {
        if(isReady && showInterviewStartPopup === false){
            const container = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
            if(container){
                Spotlight.focus(container);
            }
        }
    },[pageInfo, isReady, showInterviewStartPopup])

    const stopWatch = useCallback(() => {
        let time = interval.current;
        time = count;
        time--;
        setCount(time);
    }, [count, isTimerReset]);
    const timer = new Job(stopWatch, 1000);

    useEffect(() => {
        if (count === 0) {
            timer.stop();
            setIsTimerReset(true);
            setIsTimerStart(false);
        }
        else if (isTimerStart) {
            timer.start();
        }
    }, [count, isTimerStart]);

    const handleTimerBtn = useCallback(
        (isStart) => {
            if (!isTimerReset) {
                setIsTimerReset(isStart);
            }
            if (isTimerReset) {
                setCount(60);
                return setIsTimerReset(isStart);
            }

            setIsTimerStart(!isTimerStart);
            if (!isStart) {
                timer.start();
            } else if (isStart) {
                timer.stop();
            }
        },
        [isTimerStart, count]
    );

    const imageMap = useMemo(() => {
        let image = "";
        if(pageInfo.oneMinuitTest && pageInfo.oneMinuitTest.image){
            image = pageInfo.oneMinuitTest.image;
        }
        if (image === 'diet') {
            image = diet;
        } else if (image === 'health') {
            image = health;
        } else if (image === 'strength') {
            image = strength;
        }

        return { image };
    }, [pageInfo]);

    const type = useMemo(() => {
        if(isTimerReset){
            return 'reset'
        }
        else {
            if(isTimerStart){
                return 'stop'
            }
            else {
                return 'start'
            }
        }
    },[isTimerReset, isTimerStart])

    const _onAnimationEnd = useCallback(() => {
        if(onAnimationEnd){
            onAnimationEnd(direction)
        }
    },[onAnimationEnd, direction])

    return (
        <>
            <div className={classNames(css.pageMain, className? className: null, showInterviewStartPopup ? css.blur: null)} onAnimationEnd={_onAnimationEnd}>
                <PageTitle>{$L(pageInfo.title)}</PageTitle>
                    <Container className={css.container}>
                        <img className={classNames(css.sampleImage, (pageInfo.oneMinuitTest && pageInfo.oneMinuitTest.image === 'strength') ? css.bigImage : null )} src={imageMap.image} />
                        <OneMinuitButton spotlightId={spotlightId} className={css.buttonPosition} type={type} onClick={() => handleTimerBtn(isTimerStart)}>
                            <div className={css.buttonText}>{isTimerReset ? $L('Reset') : isTimerStart ? $L('Stop') : $L('Start')}</div>
                        </OneMinuitButton>
                        <Timer count={count}/>
                    </Container>
            </div>
            {showInterviewStartPopup && <TPopup message={$L(pageInfo.oneMinuitTest.title)} button1text={$L('Start')} button2text={$L('Skip')} onClick={onClickStartBtn} />}
        </>
    );
};
export default OneMinuitTest;
