import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import css from './InterviewModifyView.module.less';
import classNames from 'classnames';
import * as PanelActions from '../../actions/panelActions';
import TPanel from '../../components/TPanel/TPanel';
import TPopup from '../../components/TPopup/TPopup';
import THeader from '../../components/TPanel/THeader';
import TButton from '../../components/TButton/TButton';
import {$L} from '../../utils/common';
import * as CP875Table from '../../data/CP875Table';

import Level from '../../../assets/interview/level.png';
import Program from '../../../assets/interview/program.png';
import Goal from '../../../assets/interview/goal.png';
import TBody from '../../components/TPanel/TBody';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused'}, 'div');
/**
 *
 * @returns
 */
const InterviewModify = ({hideChildren, panelInfo}) => {
    const dispatch = useDispatch();
    const customizedUserInfo = useSelector(state => state.customizedUserInfo);
    const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);

    const ITEMS_TO_BE_CHANGED = useMemo(() => {
        return [
            {id: 'level', title: $L("Difficulty"), children: $L('Level {num}').replace('{num}', customizedUserInfo.level), icon: Level},
            {id: 'program', title: $L("Workout Program"), children: CP875Table.getProgramNames()[customizedUserInfo.program], icon: Program},
            {id: 'goal', title: $L("Fitness Goals"), children: CP875Table.getGoals()[customizedUserInfo.goal], icon: Goal}
        ]
    }, [customizedUserInfo]);

    const BASIC_INFO = useMemo(() => {
        return [
            {id: 'gender', title: $L("Gender"), children:customizedUserInfo.gender === CP875Table.GENDER_MAN ? $L("Male"): $L("Female")},
            {id: 'height', title: $L("Height"), children: customizedUserInfo.tall+' cm'},
            {id: 'weight', title: $L("Weight"), children: customizedUserInfo.weight+' kg'},
            {id: 'age', title: $L("Age"), children: customizedUserInfo.age+' '+$L({value:"", key:"__years old"})}
        ]
    }, [customizedUserInfo]);

    useEffect(() => {
		if(!hideChildren){
			Spotlight.focus('interviewModifyContainer');
            Spotlight.focus(panelInfo.restoreSpot);
		}
	}, [hideChildren]);

	const onBack = useCallback((ev) => {
        if (ev) {
            ev.stopPropagation();
		}
        dispatch(PanelActions.popPanel('interviewModify'));
	}, [dispatch]);

    const onClickAction = useCallback((type)=>(ev) => {
        dispatch(PanelActions.updatePanel('interviewModify', {restoreSpot: type}));
        switch(type){
            case "userInfo":
                dispatch(PanelActions.pushPanel('interview', {type: 'singletone'}));
                break;
            case "level":
                dispatch(PanelActions.pushPanel('interview', {type: 'singletone', id: 'modifyLevel', selected: customizedUserInfo.level-1}));
                break;
            case "program":{
                const id = 'modifyProgram' + customizedUserInfo.goal;
                let selected = -1;
                const SEQUENCE = CP875Table.getSequence();
                if(SEQUENCE[id]){
                    for(let i=0; i< SEQUENCE[id].radio.length; i++){
                        if(SEQUENCE[id].radio[i].program === customizedUserInfo.program){
                            selected = i;
                            break;
                        }
                    }
                    dispatch(PanelActions.pushPanel('interview', {type: 'singletone', id: id, selected: selected}));
                }else{
                    console.log('InterViewModify program invalid');
                }
                break;
            }
            case "goal":
                setShowInterviewStartPopup(true);
                break;
        }
	}, [dispatch, customizedUserInfo]);

    const onClickStartAlertBtn = useCallback((ev) => {
        setShowInterviewStartPopup(false);
        Spotlight.focus('interviewModifyContainer');
		if (ev === 0) {
            dispatch(PanelActions.pushPanel('interview', {id: 'Q1'}));
        }
    }, []);

    return (
        <TPanel spotlightId={'interviewModify'} handleCancel={onBack} className={css.panel}>
            <THeader className={css.header} onClick={onBack}/>
            <TBody>
                <div className={css.mainTitle}>{$L('Please select an item to change.')}</div>
                <Container spotlightId={'interviewModifyContainer'} className={css.btnContainer}>
                    <TButton type={"interviewModifyBtn"} spotlightId={"userInfo"} className={css.btnStyle} onClick={onClickAction('userInfo')}>
                        <div className={classNames(css.title, css.basicInfo)}>{$L('Basic Information')}</div>
                        {BASIC_INFO.map((item, index)=>{
                                return (
                                    <div className={css.infoBox} key={item.id}>
                                        <div className={css.qBox}>{item.title}</div>
                                        <div className={css.aBox}>{item.children}</div>
                                    </div>
                                );
                            })
                        }
                    </TButton>
                    {ITEMS_TO_BE_CHANGED.map((item, index) => {
                        return (
                            <TButton type={"interviewModifyBtn"} key={item.id} spotlightId={item.id} className={css.btnStyle} onClick={onClickAction(item.id)}>
                                <div className={css.title}>{item.title}</div>
                                <div className={css.icon} style={{ backgroundImage : `url(${item.icon})`}}></div>
                                <div className={css.detail}>{item.children}</div>
                            </TButton>
                            );
                        })
                    }
                </Container>
                {showInterviewStartPopup &&
                    <TPopup
                        darkBackground={true}
                        message={$L("Do you want to answer a questionnaire and changing your workout plan?")}
                        button1text={$L('Start')}
                        button2text={$L({value: "Go Back", key: "__Go Back"})}
                        onClick={onClickStartAlertBtn}
                    />
                }
            </TBody>
        </TPanel>
    );
};

export default InterviewModify;
