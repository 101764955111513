import classNames from 'classnames';
import React, { useState, useCallback, useEffect, Component } from 'react';
import Spotlight from '@enact/spotlight';
import Spottable from '@enact/spotlight/Spottable';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import PageTitle from '../components/PageTitle/PageTitle';
import { $L } from '../../../utils/common';
import TRadioItem from '../../../components/TRadioItem/TRadioItem';
import InputPopup from '../components/InputPopup';
import css from './BasicPage.module.less';
import * as CP875Table from '../../../data/CP875Table';
const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const SpottableComponent = Spottable('div');
let INPUT_INFO = null;
const BasicPage = ({ pageInfo, isReady, onChanged, className, spotlightId, popupBlurEffect, onAnimationEnd, direction, ...rest }) => {
    const [userInfo, setUserInfo] = useState(pageInfo.userInfo);
    const [showInputPopup, setShowInputPopup] = useState(false);
    const [selectedInputInfo, setSelectedInputInfo] = useState({});
    const [inputPopupValue, setInputPopupValue] = useState(0);

    if(!INPUT_INFO){
        INPUT_INFO = [
            {key: 'tall', title: $L("Height"), tail: 'cm', min: 1, max: 999},
            {key: 'weight', title: $L("Weight"), tail: 'kg', min: 1, max: 999},
            {key: 'age', title: $L("Age"), tail: $L({value: "", key: "__years old"}), min: 2, max: 999}
        ];
    }
    useEffect(() => {
        setUserInfo(pageInfo.userInfo);
        if(isReady){
            if(onChanged){
               onChanged(pageInfo.userInfo);
            }
            setTimeout(() => {
                if(spotlightId){
                    Spotlight.focus(spotlightId);
                }
            }, 0);
        }
    }, [pageInfo, isReady]);
    useEffect(() => {
        if(onChanged){
            onChanged(userInfo);
        }
    }, [userInfo]);

    const onClickRadio = useCallback((gender)=> () =>{
        setUserInfo({...userInfo, gender});
    },[userInfo]);
    const onClickInput = useCallback((index)=> () =>{
        const info = INPUT_INFO[index];
        setSelectedInputInfo(info);
        setInputPopupValue(userInfo[info.key]);
        setShowInputPopup(true);
    },[userInfo]);
    const onCloseInputPopup = useCallback((ev) =>{
        console.log('oncloseinputpopup ', ev);
        if(ev.key){
            setUserInfo({...userInfo, [ev.key]: ev.value});
        }
        setShowInputPopup(false);
        setTimeout(() => {
            Spotlight.focus('basicpagemain');
        }, 0);
    },[userInfo]);

    const _onAnimationEnd = useCallback(() => {
        if(onAnimationEnd){
            onAnimationEnd(direction)
        }
    },[onAnimationEnd, direction])

    return (
        <Container className={classNames(css.pagemain, className ? className: null, popupBlurEffect ? css.blur : null)} spotlightId={'basicpagemain'} onAnimationEnd={_onAnimationEnd}>
            <PageTitle hasIcon={false}>{pageInfo.title}</PageTitle>
            <Container className={css.container} spotlightId={spotlightId}>
                <div className={css.menuName}>{$L("Gender")}</div>
                <TRadioItem selected={userInfo.gender===CP875Table.GENDER_MAN} className={css.radio} onClick={onClickRadio(CP875Table.GENDER_MAN)}>
                    {$L('Male')}
                </TRadioItem>
                <TRadioItem selected={userInfo.gender===CP875Table.GENDER_WOMAN} className={css.radio} onClick={onClickRadio(CP875Table.GENDER_WOMAN)}>
                    {$L('Female')}
                </TRadioItem>
            </Container>
            {INPUT_INFO.map((item, index) => {
                return (
                    <Container key={item.key} className={css.container}>
                        <div className={css.menuName}>{item.title}</div>
                        <SpottableComponent className={css.inputbtn} onClick={onClickInput(index)}>{userInfo[item.key]}</SpottableComponent>
                        <div className={css.tailstr}>{item.tail}</div>
                    </Container>
                );
            })}
            {showInputPopup &&
                <InputPopup open inputInfo={selectedInputInfo} originValue={inputPopupValue} onClose={onCloseInputPopup}/>
            }
        </Container>
    );
};

export default BasicPage;
