import classNames from 'classnames';
import React, { useState, useCallback, useEffect } from 'react';
import Spotlight from '@enact/spotlight';
import { SpotlightContainerDecorator } from '@enact/spotlight/SpotlightContainerDecorator';
import AnswerItem from '../components/AnswerItem/AnswerItem';
import PageTitle from '../components/PageTitle/PageTitle';
import TScroller from '../../../components/TScroller/TScroller';
import css from './RadioPage.module.less';
import {$L} from '../../../utils/common';

const Container = SpotlightContainerDecorator({ enterTo: 'last-focused'}, 'div');

const RadioPage = ({ pageInfo, isReady, onSelected, className, spotlightId, onAnimationEnd, direction}) => {
    const [selectedIndex, setSelectedIndex] = useState(pageInfo.selected);

    useEffect(() => {
        setSelectedIndex(pageInfo.selected);
        if(isReady){
            setTimeout(() => {
                if(onSelected){
                    onSelected(pageInfo.selected, pageInfo.radio[pageInfo.selected]);
                }
                const container = document.querySelector(`[data-spotlight-id="${spotlightId}"]`);
                if(container && container.children){
                    if(pageInfo.selected >= 0){
                        Spotlight.focus(container.children[pageInfo.selected]);
                    }else{
                        Spotlight.focus(container.children[0]);
                    }
                }
            }, 0);
        }
    }, [pageInfo, isReady]);

    const handleRadioOptionClick = useCallback((item, index) => () => {
        setSelectedIndex(index);
        if(onSelected){
            onSelected(index, item);
        }
    }, [onSelected]);

    const _onAnimationEnd = useCallback(() => {
        if(onAnimationEnd){
            onAnimationEnd(direction)
        }
    },[onAnimationEnd, direction])

    return (
        <div className={classNames(css.pagemain, className ? className: null)} onAnimationEnd={_onAnimationEnd}>
            <PageTitle>{$L(pageInfo.title)}</PageTitle>
            <TScroller className={css.scroller} focusableScrollbar={false}>
                <Container spotlightId={spotlightId}>
                    {pageInfo && pageInfo.radio && pageInfo.radio.map((item, index) => {
                        return (
                            <AnswerItem
                                key={'radio'+index}
                                type={item.goal !== undefined ? 'icon' : 'normal'}
                                goal={item.goal}
                                className={css.radioItem}
                                selected={selectedIndex === index}
                                selectedIndex={selectedIndex}
                                onClick={handleRadioOptionClick(item, index)}
                            >
                                {$L(item.title)}
                            </AnswerItem>
                        );
                    })}
                </Container>
            </TScroller>
        </div>
    );
};

export default RadioPage;
