import React, { useCallback, useEffect, useState } from 'react';
import Scroller from '@enact/sandstone/Scroller';
import Spotlight from '@enact/spotlight';
import { useDispatch, useSelector } from 'react-redux';
import TButton from '../../components/TButton';
import TPanel from '../../components/TPanel';
import THeader from '../../components/TPanel/THeader';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import TAlert from '../../components/TAlert';
import css from './TermsConditionsDetail.module.less';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import {types} from '../../actions/actionTypes';

const TermsConditionsDetail = ({...props}) => {
	const dispatch = useDispatch();
	const getTermsDoc = useSelector(state => state.getTermsDoc);
	const adminPreviewData = useSelector(state => state.adminPreviewData);
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const termsInfo = useSelector(state => state.getTermsStatus); //version, accepted1, accepted2, accepted

	const [termsCondition, setTermsCondition] = useState('');
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [exitPoPupOpen, setExitPoPupOpen] = useState(false);

	const panelState = props.panelInfo.panelState;
	const title = props.panelInfo.title;
	const btnNum = props.panelInfo.btnNum;
	const updateTerms = props.panelInfo.panelState === "reAgreed" && props.panelInfo.updateTerms;
	const alertMsg = (panelState === 'reAgreed') ? $L('If you do not agree Terms and Conditions, you will not be able to use LG Fitness service. Would you like to continue?')
	: $L('If you withdraw from the terms and conditions, you will not be able to use the service. Would you like to continue?');

	useEffect(() => {
		if (!props.hideChildren) {
			if (panelState === 'reAgreed') {
				Spotlight.focus("agreeBtn");
			} else {
				Spotlight.focus("closeBtn");
			}
		}
	}, [props.hideChildren]);

	useEffect(() => {
		if(deviceAccountInfo.language === 'ko') {
			switch (title) {
				case "개인정보 처리방침":
					setTermsCondition(getTermsDoc.page2 ? Utils.convertNormalStr(getTermsDoc.page2): "");
					break;
				case "Terms & Conditions":
					setTermsCondition(getTermsDoc.page1 ? Utils.convertNormalStr(getTermsDoc.page1): "");
					break;
				case "Privacy Policy":
					console.log("terms Privacy Policy")
					setTermsCondition(getTermsDoc.page3 ? Utils.convertNormalStr(getTermsDoc.page3): "");
					break;
				default:
					break;
			}
		} else {
			switch (title) {
				case "Terms & Conditions":
						setTermsCondition(getTermsDoc.page1 ? Utils.convertNormalStr(getTermsDoc.page1): "");
					break;
					case "Privacy Policy":
						setTermsCondition(getTermsDoc.page2 ? Utils.convertNormalStr(getTermsDoc.page2): "");
					break;
				default:
					break;
			}
		}
    }, [getTermsDoc, title, adminPreviewData]);

	const goBackToTermsConditions = useCallback(() => {
		const acceptedKey = btnNum === 'btn0' ? "accepted1": "accepted2";
		dispatch({ type: types.GET_TERMS_STATUS, payload: {[acceptedKey]: !termsInfo[acceptedKey]}});
		dispatch(PanelActions.pushPanel('termsConditions', {spotlightId: btnNum, panelState}));
		dispatch(PanelActions.popPanel('termsConditionsDetail'));
	},[dispatch, btnNum, termsInfo, panelState]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if(Config.ADMIN_PREVIEW_MODE){
			return;
		}
		if (panelState === 'reAgreed'){
			return;
		}
		dispatch(PanelActions.pushPanel('termsConditions', {spotlightId: btnNum, panelState}));
		dispatch(PanelActions.popPanel('termsConditionsDetail'));
	}, [dispatch, btnNum, panelState]);

	const onAgree = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if(btnNum === "btn0") {
			if(termsInfo.accepted1){
				setShowConfirmPopup(true);
				return;
			}
		}else if(btnNum === "btn1") {
			if(termsInfo.accepted2){
				setShowConfirmPopup(true);
				return;
			}
		}
		goBackToTermsConditions();
	},[termsInfo, btnNum, goBackToTermsConditions]);

	const onCheckpopupCancel = useCallback(() => {
		setShowConfirmPopup(false);
		Spotlight.focus("agreeBtn");
	},[]);

	const onClickConfirmBtn = useCallback((ev) => {
		console.log('onClickConfirmBtn');
		if (ev === 0) {
			if (panelState !== 'reAgreed') {
				goBackToTermsConditions();
			}
		} else {
			if (panelState === 'reAgreed') {
				setExitPoPupOpen(true);
				setTimeout(() => {
					Utils.windowClose();
				}, 3000);
			}
		}
		onCheckpopupCancel();
	},[goBackToTermsConditions, onCheckpopupCancel]);

	let agreed,agreeBtnStr;
	if( props.panelInfo.btnNum === 'btn0'){
		agreed = termsInfo.accepted1;
	}else if( props.panelInfo.btnNum === 'btn1'){
		agreed = termsInfo.accepted2;
	}
	if(agreed){
		agreeBtnStr = (panelState === "withdraw") ? $L({value: 'Do Not Agree', key: 'do_not_agree2'}):  $L("Do Not Agree");
	}else{
		agreeBtnStr = $L("Agree");
	}

	const onReAgreed = useCallback(() => {
		const acceptedKey = btnNum === 'btn0' ? "accepted1" : "accepted2";
		if (updateTerms === 1) {
			dispatch({ type: types.GET_TERMS_STATUS, payload: { [acceptedKey]: !termsInfo[acceptedKey] } });
			dispatch(CmsActions.updateTerms());
			dispatch(PanelActions.popPanel('termsConditionsDetail'));
		} else {
			dispatch({ type: types.GET_TERMS_STATUS, payload: { [acceptedKey]: !termsInfo[acceptedKey] } });
			dispatch(PanelActions.popPanel('termsConditionsDetail'));
			dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Privacy Policy", btnNum: "btn1", panelState: "reAgreed", updateTerms: 1 }));
		}
	}, [dispatch, btnNum, termsInfo]);

	const onCalcel = useCallback(() => {
		setShowConfirmPopup(true);
	}, []);

	return (
		<TPanel className={css.panel} handleCancel={onBack}>
			<THeader title={$L(title)} onClick={onBack} noBackButton={panelState === 'reAgreed'}/>
			<div className={css.textBox}>
				<Scroller
					scrollMode="translate"
					focusableScrollbar
					className={css.scroller}
					direction={'vertical'}
					overscrollEffectOn={{
						arrowKey: false,
						drag: false,
						pageKey: false,
						track: false,
						wheel: false
					}}
				>
					{termsCondition}
				</Scroller>
			</div>
			<div className={css.bottomBtnLayer}>
				{panelState ==='withdraw' && btnNum !== 'btn2' &&
					<TButton className={css.nomalBtn} spotlightId={"agreeBtn"} onClick={onAgree}>{agreeBtnStr}</TButton>
				}
				{panelState === 'reAgreed' &&
					<TButton className={css.nomalBtn} spotlightId={"agreeBtn"} onClick={onReAgreed}>{$L("Agree")}</TButton>
				}
				{panelState === 'reAgreed' ?
					<TButton className={css.nomalBtn} spotlightId={"closeBtn"} onClick={onCalcel}>{$L("Cancel")}</TButton>
					: <TButton className={css.nomalBtn} spotlightId={"closeBtn"} onClick={onBack}>{$L("Go Back")}</TButton>
				}
			</div>
			{showConfirmPopup &&
				<TAlert message={alertMsg}
					button1text={panelState === 'reAgreed' ? $L('Cancel') : $L('OK')} button2text={panelState === 'reAgreed' ? $L('OK') : $L('Cancel')} onClick={onClickConfirmBtn} onClose={onCheckpopupCancel}/>
			}
			{exitPoPupOpen &&
				<TAlert message={$L("Thank you for using LG Fitness and hope to see you again. The application well close in 3 seconds.")}  />
			}
		</TPanel>
	);
};

export default TermsConditionsDetail;