import classNames from 'classnames';
import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Alert from '@enact/sandstone/Alert';
import Marquee from '@enact/sandstone/Marquee';
import ContentDetailInfo from '../../../components/ContentDetailInfo';
import TButton from '../../../components/TButton';
import MediaList from '../../../components/MediaList';
import * as CommonActions from '../../../actions/commonActions';
import SpotlightIds from '../../../data/SpotlightIds';
import css from './InfoPopup.module.less';
import {$L} from '../../../utils/common';
import * as Utils from '../../../utils/common';
import * as Config from '../../../data/Config';
import Spotlight from '@enact/spotlight';
import QRCode from "qrcode.react";

/**
 *
 * @param {type} "info", "log"
 * @returns
 */
const InfoPopup = ({open, type, onClose,onClickPromotionLink, promotionTitle ,onClickReplay, item, listTitle, contentsRelated, onListItemClick, homePageUrl, ...rest}) => {
	const dispatch = useDispatch();
	const [infoData, setInfoData] = useState({});
	const {accountId} = useSelector(state => state.accountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const workoutSummary = useSelector(state => state.workoutSummaryWeek);
	const goals = useSelector(state => state.goalsOfAccount);
	const goalAchievement = useSelector(state => state.goalAchievementWeek);

	useEffect(() => {
		let data={};
		if(type === 'log' && item){
			data = {contentType: item.contentType, playtime: item.playtime, calorie: item.calorie};
			data.title = $L('Content Summary');
			if(supportLogin && !accountId){
				data.description = $L("Log in to Track Your Activity.");
			}
			if(accountId){
				data.title = $L('My Activity Log');
				const summary = workoutSummary;
				if(!summary){
					data.playtime = 0;
					data.calorie = 0;
				}else{
					if(summary.timeSpent){
						let totalTime = 0;
						for(let i=0; i<summary.timeSpent.length; i++){
							totalTime+=summary.timeSpent[i];
						}
						data.playtime = totalTime;
					}
					data.calorie = summary.calorieBurned;
					if(promotionTitle) {
						data.description = $L("Well Done. {br}Click the button for more content");
					}else {
						if(summary.completedNumber <= 0 ){
							data.description = $L("Well Done. {br}Let's finish the workout next time.");
						}else if(summary.completedNumber <= 1 ){
							data.description = $L("Great! You just finished your {br}first workout this week!");
						}else if(summary.completedNumber <= 5 ){
							data.description = $L("Here’s your weekly workout {br}report. Keep up the good work!");
						}else if(summary.completedNumber <= 10 ){
							data.description = $L("Here’s your weekly workout {br}report. Great Job!");
						}else if(summary.completedNumber >= 11 ){
							data.description = $L("Here’s your weekly workout {br}report. Excellent work!");
						}
					}
					// goal
					if(goals[1] && goals[1].goalId){
						if(goalAchievement[goals[1].goalId] && goalAchievement[goals[1].goalId].newAchievement){
							data.description = goals[1].title==='goal1' ? $L("You did it! {br}You’ve achieved a Goal1!") : $L("You did it! {br}You’ve achieved a Goal2!");
						}
					}
					if(goals[0] && goals[0].goalId){
						if(goalAchievement[goals[0].goalId] && goalAchievement[goals[0].goalId].newAchievement){
							data.description = goals[0].title==='goal1' ? $L("You did it! {br}You’ve achieved a Goal1!") : $L("You did it! {br}You’ve achieved a Goal2!");
						}
					}
				}
			}
		}else{
			data = item;
		}
		setInfoData(data);
	},[item, type, workoutSummary, goalAchievement, goals, accountId, promotionTitle]);

	useEffect(() => {
		if(open){
			setTimeout(() => {
				Spotlight.focus('replayBtn');
				Spotlight.focus(SpotlightIds.LIST_PLAYER2);
			}, 1);
		}
    },[open]);

	const spotReplayBtn = useCallback((ev)=>{
		if(Spotlight.focus('replayBtn')){
			ev.stopPropagation();
		}
	},[]);

	const _onClose = useCallback((ev) => {
		if(onClose){
			onClose(ev);
		}
    }, [onClose]);

	const onClickLogin = useCallback(() => {
		dispatch(CommonActions.launchMembershipApp('Player'));
	}, [dispatch]);

	const renderQRImage = useCallback(() => {
		let qrValue = homePageUrl
		return (
			<div className={css.qrcodeContainer}>
				<QRCode className={css.qrcode}
					value={qrValue}
					renderAs="canvas"
					level={'L'}
					fgColor="#000000"
					bgColor="#FFFFFF"/>
				<div className={css.qrinfo}>{$L("Scan for more information")}</div>
			</div>
		);
	}, [homePageUrl]);

	return (
		<Alert {...rest}
			className={classNames(css.infopopup, type==='log'? css.log: null)}
			open={open}
			type={"overlay"}
			onClose={_onClose}>
			<div className={css.titlelayer}>
				<TButton className={css.backBtn} allowClickOnPreview type={"back"} onClick={onClose} onSpotlightDown={spotReplayBtn}/>
				<Marquee  className={css.title} marqueeOn="render">
					{infoData&&infoData.title}
				</Marquee>
			</div>
			{
				<div className={css.subtitle}>{infoData && Utils.convertNormalStr(infoData.description)}</div>
			}
			<ContentDetailInfo className={css.moreInfo} size={'video'}>{infoData}</ContentDetailInfo>
			{ type === 'log' &&
				<div className={css.buttonlayer}>
					{supportLogin && !accountId &&
						<TButton onClick={onClickLogin}>{$L('Login')} </TButton>
					}
					<TButton className={css.replayBtn} allowClickOnPreview spotlightId={'replayBtn'} onClick={onClickReplay}>{$L("Replay")} </TButton>
					{ promotionTitle &&
						<TButton withMarquee type={"promotionPopup"} onClick={onClickPromotionLink}>{promotionTitle}</TButton>
					}
				</div>
			}
			{contentsRelated && contentsRelated.length > 0 &&
			<div className={css.listlayer}>
				<MediaList title={listTitle} spotlightId={SpotlightIds.LIST_PLAYER2} viewList={contentsRelated}
					onListItemClick={onListItemClick}/>
			</div>
			}
			{homePageUrl && renderQRImage()}
		</Alert>
	);
};

export default InfoPopup;
