import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './MyWorkoutRoutineSettingsPanel.module.less';
import { $L } from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import * as CmsActions from '../../../actions/cmsActions';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton/TButton';
import { types } from '../../../actions/actionTypes';
import Item from '@enact/sandstone/Item';
import PlanAddContent from '../../../components/PlanItem/PlanAddContent';
import TBody from '../../../components/TPanel/TBody';

const Container = SpotlightContainerDecorator({leaveFor: {left:'', right:''}, enterTo: '', restrict: 'self-only'}, 'div');

const MyWorkoutRoutineSettingsPanel = ({panelInfo, hideChildren, ...rest }) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();

	const tempInfo = useSelector(state => state.tempPlanInfo);
	const panels = useSelector(state => state.panels);
	const workoutstyleTableSelected = useSelector(state => state.accountInfo.workoutStyleId);
	const workstylesList = useSelector(state => state.workstylesList);

	const [isFocus, setFocus] = useState([false, false, false, false, false]);
	const [currentFocusNum, setCurrentFocusNum] = useState(-1);
	const [previousFocusNum, setPreviousFocusNum] = useState(-1);
	const [planInfo, setPlanInfo] = useState();
	const [isReoderMode, setReorderMode] = useState(false);
	const [currentOrder, setCurrentOrder] = useState();
	const [params, setParams] = useState([]);
	const [isDisabledBtn, setDisabledBtn] = useState(true);

	const isEditMode = panelInfo.isEditMode;
	const planId = panelInfo.planId;
	const info = panelInfo.info;
	const headerTitle = isEditMode ? $L('My Workout Routine')+" > "+$L('Edit') : $L('My Workout Routine')+" > "+$L('Add');

	const defaultFocus = useCallback(() => {
		if (panelInfo.panelType && panelInfo.panelType === "onBack") {
			Spotlight.focus('contentContainer' + panelInfo.order);
			Spotlight.focus('contentChangeBtn' + panelInfo.order);
		} else {
			if (info && isEditMode && !info.contentChanged) {
				Spotlight.focus('contentChangeBtn0');
			} else if (info && info.length === 0 || !info) {
				setTimeout(() => {
					Spotlight.focus(`[data-spotlight-id="contentContainer0"]`);
				}, 0);
			}
		}
	}, [info, isEditMode, panelInfo]);

	useEffect(() => {
		if (!hideChildren) {
			defaultFocus();
		}
	}, [hideChildren]);

	//todo
	const title = useMemo(()=>{
		if(panelInfo && panelInfo.title){
			return panelInfo.title;
		}
		return $L({value: 'My Workout Routine', key: '_my_workout_routine'});
		// if(workstylesList && workoutstyleTableSelected>=1){
		// 	for(let i=0; i<workstylesList.length; i++){
		// 		if(workstylesList[i].workoutStyleId === workoutstyleTableSelected){
		// 			return workstylesList[i].title;
		// 		}
		// 	}
		// }
		return "";
	},[panelInfo]);

	// const title = useMemo(()=>{
	// 	if(panelInfo && panelInfo.title){
	// 		return panelInfo.title;
	// 	}
	// 	if(workstylesList && workoutstyleTableSelected>=1){
	// 		for(let i=0; i<workstylesList.length; i++){
	// 			if(workstylesList[i].workoutStyleId === workoutstyleTableSelected){
	// 				return workstylesList[i].title;
	// 			}
	// 		}
	// 	}
	// 	return "";
	// },[panelInfo, workstylesList, workoutstyleTableSelected]);

	/* button click function */
	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel('myworkoutroutinesettings'));
	}, [panelInfo, panels, dispatch]);

	const onTitleClick = useCallback(() => {
		dispatch(PanelActions.pushPanel('edittitle', { title, isEditMode, planId }));
	},[dispatch, title, isEditMode, planId]);

	const onSave = useCallback(() => {
		if (isEditMode) {
			if (planInfo.length === 1) {
				dispatch(CmsActions.deletePlanOfAccount(planId));
			} else {
				dispatch(CmsActions.updatePlanOfAccount(title, params, planId));
			}
		} else {
			dispatch(CmsActions.createPlanOfAccount(title, params));
		}
		dispatch(PanelActions.popPanel('myworkoutroutinesettings'));
	}, [dispatch, params, title, isEditMode, panelInfo, panels]);

	const onReset = useCallback(() => {
		dispatch({ type: types.RESET_TEMPORARY_PLAN });
		if (isEditMode) {
			setDisabledBtn(false);
		} else {
			setDisabledBtn(true);
		}
	}, [dispatch, isEditMode]);

	const updateInfoChanged = useCallback((value) => {
		let newPlanInfo = [];
		let focused = 0;
		tempInfo.plans.map((item, i) => {
			if (value.order) {
				if (value.order === i) {
					newPlanInfo.push({ ...item, ["info"]: value.items[0] });
					focused = value.order;
				} else {
					newPlanInfo.push({ ...item });
				}
			} else {
				if (value.items[i]) {
					newPlanInfo.push({ ...item, ["info"]: value.items[i] });
				} else {
					newPlanInfo.push({ ...item });
				}
			}
		});
		if (value.contentChanged) {
			setTimeout(() => {
				if (isEditMode) {
					Spotlight.focus('contentChangeBtn' + focused);
					Spotlight.focus('contentChangeBtn' + (focused + 1));
				} else {
					Spotlight.focus('contentContainer' + focused);
					Spotlight.focus('contentContainer' + (focused + 1));
				}
			}, 0);
		}
		dispatch({ type: types.SET_TEMPORARY_PLAN, payload: { plans: newPlanInfo } });
	}, [dispatch, tempInfo, isEditMode]);

	/* update info when added content and change content */
	useEffect(() => {
		if (info && info.length > 0 || info && Object.keys(info).length > 0) {
			updateInfoChanged(info);
		}
	}, [info]);

	/* setParams, button state setting   */
	useEffect(() => {
		let items = [];
		let _planInfo = [];
		_planInfo = tempInfo.plans.filter(item => item.info.length !== 0)
		if (_planInfo.length < 5) {
			_planInfo.push({ disabled: false, order: _planInfo.length, info: [] })
		}
		setPlanInfo(_planInfo);
		for (let i = 0; i < tempInfo.plans.length; i++) {
			if (tempInfo.plans[i].info.length !== 0) {
				const item = {
					contentId: tempInfo.plans[i].info.contentId,
					repeatCount: tempInfo.plans[i].info.repeatCount
				}
				items.push(item);
			}
		}
		if (items.length > 0) {
			setDisabledBtn(false);
		}
		setParams(items);
	}, [tempInfo]);

	/* onfocus setting when press direction key */
	const onKeyHandleContentContainer = useCallback(() => {
			Spotlight.focus('titleArea');
	}, []);

	const onKeyHandleButtonWrap = useCallback((ev) => {
		if (ev.key === 'ArrowUp') {
			defaultFocus();
			Spotlight.focus(previousFocus.current);
			// ev.stopPropagation();
		}
	}, [defaultFocus]);

	const onKeyHandleAddContent = useCallback((ev, isContent) => {
		if (!isContent) {
			previousFocus.current = Spotlight.getCurrent();
			Spotlight.focus('doneBtn');
		}
		if(isReoderMode){
			ev.stopPropagation();
		}
	},[isReoderMode]);

	const onKeyHandleRepeatBtn = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		if (ev.key === 'ArrowDown') {
			Spotlight.focus('doneBtn');
		}
	},[]);

	const onKeyHandReorderBtn = useCallback(() => {
		previousFocus.current = Spotlight.getCurrent();
	},[]);

	/* reorder Mode function */
	const onReorderMode = useCallback((bool, order) => {
		setReorderMode(bool);
		setCurrentOrder(order);
	},[]);

	useEffect(() => {
		Spotlight.focus('contentContainer'+currentOrder);
	}, [isReoderMode]);

	const reOrderContents = useCallback((order, targetOrder) => {
		const currentContent = tempInfo.plans[order];
		const targetContent = tempInfo.plans[targetOrder];
		let reOrderContent = [];

		if (targetContent.order !== currentContent.order) {
			tempInfo.plans.map((item, i) => {
				if (targetContent.order < currentContent.order) {
					if (currentContent.order === i) {
						reOrderContent.push({ ...item, ["order"]: targetContent.order + 1, ["info"]: targetContent.info });
					} else if (targetContent.order === i) {
						reOrderContent.push({ ...item, ["order"]: targetContent.order, ["info"]: currentContent.info });
					} else {
						reOrderContent.push({ ...item, ["order"]: targetContent.order < item.order && currentContent.order > item.order ? item.order + 1 : item.order });
					}
				} else if (targetContent.order > currentContent.order) {
					if (currentContent.order === i) {
						reOrderContent.push({ ...item, ["order"]: targetContent.order - 1, ["info"]: targetContent.info });
					} else if (targetContent.order === i) {
						reOrderContent.push({ ...item, ["order"]: targetContent.order, ["info"]: currentContent.info });
					} else {
						reOrderContent.push({ ...item, ["order"]: targetContent.order > item.order && currentContent.order < item.order ? item.order - 1 : item.order });
					}
				}
			});

			const sortReoderContent = reOrderContent.sort(function (a, b) {
				return a.order - b.order;
			}); // Sort by order number

			dispatch({ type: types.SET_TEMPORARY_PLAN, payload: { plans: sortReoderContent } });
			onReorderMode(true, targetContent.order);
		}
	}, [dispatch, onReorderMode, tempInfo]);

	/* state of list focus  */
	const onContentFocus = useCallback((order, bool) => {
		let currentFocus = [false, false, false, false, false];
		currentFocus[order] = bool;
		setPreviousFocusNum(currentFocusNum);
		setCurrentFocusNum(order);
		setFocus(currentFocus);
	}, [currentFocusNum]);

	const onContentBlur = useCallback(() => {
		setFocus([false, false, false, false, false]);
	},[]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={headerTitle} reoderMode={isReoderMode} onClick={onBack} />
			<TBody>
				<Item
					className={classNames(css.title, isReoderMode && css.reoderMode)}
					spotlightId={'titleArea'}
					spotlightDisabled={isReoderMode}
					centered
					onClick={onTitleClick}
				>
					{title}
				</Item>
				<Container className={css.contentContainer} >
					{planInfo && planInfo.map((item, index) =>
						<PlanAddContent
							key={index}
							type={"routine"}
							onKeyHandleContentContainer={onKeyHandleContentContainer}
							onKeyHandleAddContent={onKeyHandleAddContent}
							onReorderMode={onReorderMode}
							onKeyHandleRepeatBtn={onKeyHandleRepeatBtn}
							onKeyHandReorderBtn={onKeyHandReorderBtn}
							reOrderContents={reOrderContents}
							onContentFocus={onContentFocus}
							onContentBlur={onContentBlur}
							order={item.order}
							contentInfo={item}
							isFocus={isFocus[index]}
							title={title}
							isEditMode={isEditMode}
							planId={planId}
							isReoderMode={isReoderMode}
							currentOrder={currentOrder}
							previousFocusNum={previousFocusNum}
							currentFocusNum={currentFocusNum}
							contentContainer={'contentContainer' + item.order}
							contentChangeBtn={'contentChangeBtn' + index}
						/>
					)}

				</Container>
				<div className={css.buttonWrap} onKeyDown={onKeyHandleButtonWrap}>
					<TButton
						className={classNames(css.button)}
						disabled={isReoderMode ? true : isDisabledBtn}
						onClick={onReset}
					>
						{$L('Reset All')}
					</TButton>
					<TButton
						className={classNames(css.button)}
						spotlightId={'doneBtn'}
						disabled={isReoderMode ? true : isDisabledBtn}
						onClick={onSave}
					>
						{$L('Done')}
					</TButton>
				</div>
			</TBody>

		</TPanel>
	);
};

export default MyWorkoutRoutineSettingsPanel;