/**
 * TButton
 *
 * @module TButton
 */
import css from './TButton.module.less';
import classNames from 'classnames';
import React, {useCallback, useEffect} from 'react';
import Spottable from '@enact/spotlight/Spottable';
import Marquee from '@enact/sandstone/Marquee';
import * as Config from '../../data/Config';

const SpottableComponent = Spottable('div');
/**
 *
 * @param {type} normal, skipIntro, withArrow
 * @returns
 */
const TYPES=["normal", "skipIntro", "withArrow", "back", "backArrow","nextArrow", "interviewModifyBtn", "big"];

const TButton = ({type="normal",children, allowClickOnPreview=false, withMarquee=false, spotlightId, className, onClick, disabled, onDisabled, fillPer=100,...rest}) => {
	useEffect(() => {
		if(TYPES.indexOf(type) < 0 ){
			console.error('TButton type error');
		}
	}, [type]);

	const _onClick = useCallback((ev) => {
		if(disabled || (Config.ADMIN_PREVIEW_MODE&&!allowClickOnPreview)){
			ev.stopPropagation();
			return;
		}
		if (onClick) {
			onClick(ev, type);
		}
		ev.persist();
	}, [ onClick, disabled, allowClickOnPreview, type]);

	const fillPerStr = fillPer+"% 100%";
	const additionalClasses = [];
	let additionalStyle={};
	let spotlightDisabled = false;
	if(type === "skipIntro"){
		if(fillPer > 0) additionalClasses.push(css.fillPer);
		if(fillPer>=100) {
			additionalClasses.push(css.hide);
			spotlightDisabled = true;
			if(onDisabled){
				onDisabled();
			}
		}
		additionalStyle = fillPer>=0 ? {backgroundSize: fillPerStr} :null;
	}
	return (
		<SpottableComponent
			{...rest}
			style={additionalStyle}
			className={classNames(css.tButton, css[type], className ? className: null, additionalClasses, disabled ? css.disabled: null)}
			onClick={_onClick}
			data-webos-voice-intent={'Select'}
			data-webos-voice-label={children}
			spotlightDisabled={spotlightDisabled}
			spotlightId={spotlightId}
		>
			{children &&
				withMarquee ?
				<Marquee  className={css.marquee} marqueeOn={"render"}>
					{children}
				</Marquee>
				:
				<div className={css.text}>
					{children}
				</div>
			}
		</SpottableComponent>
	);
};

export default TButton;
