import DateFactory from 'ilib/lib/DateFactory';
import DateFmt from 'ilib/lib/DateFmt';
import * as Config from '../data/Config';
import StringReSourceKO from '../../resources/ko/strings.json';
import Enact_$L from '@enact/i18n/$L';

export const $L = (str) => {
	if (typeof window === 'object' && window.PalmSystem){
		return Enact_$L(str).replace(/{br}/g, '\n');
	}else{
		let resource = Config.DEBUG_WINDOW_COUNTRY === 'KR' ? StringReSourceKO : null;
		if(typeof window === 'object' && window.store && window.store.getState().localSettings.useQAServerURL === 'prd_us'){
			resource = null;
		}
		if(typeof str === 'object'){
			if(resource &&resource[str.key]){
				return resource[str.key].replace(/{br}/g, '\n');
			}else{
				str = str.value;
			}
		}else if(resource && resource[str]){
			return resource[str].replace(/{br}/g, '\n');
		}
	}
	return str && str.replace(/{br}/g, '\n');
};

export const getLanguageCode = (country) => {
	return Config.HOMEGYM_SUPPORT_COUNTRYS[country] ? Config.HOMEGYM_SUPPORT_COUNTRYS[country] : 'en';
};

/**
 * Generates a query string from the key/values of a JSON object
 * @param object The json object
 * @returns A value representing a URL compatible query string
 */
export const createQueryString = (object) => {
	const parts = [];
	for (const key of Object.getOwnPropertyNames(object)) {
		if(object[key]!==null && object[key] !== undefined && object[key] !== ""){
			parts.push(`${key}=${encodeURIComponent(object[key])}`);
		}
	}
	return parts.join('&');
};

export const createStringfyParams = (object) => {
	const parts ={};
	for (const key of Object.getOwnPropertyNames(object)) {
		if(object[key]!==null && object[key] !== undefined && object[key] !== ""){
			parts[key] = object[key].toString();
		}
	}
	return parts;
};
let localLaunchParams = {};
// let localLaunchParams = {
// 	intent:'PlayContent',intentParam: 'punch'
// };
export const getLaunchParams = () => {
	let params = {};

	if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
		try {
			params = JSON.parse(window.PalmSystem.launchParams);
			if (params['x-webos-app-container-launch'] === true) {
				params = params.details;
			}
		} catch (e) {
			params = {};
		}
		return params;
	}else{
		return localLaunchParams;
	}
};

export const clearLaunchParams = () => {
	console.log('common.clearLaunchParams');
	if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.launchParams) {
		window.PalmSystem.launchParams = '';
	}else{
		localLaunchParams = {};
	}
};

export const transDateText = (date) => {
	if (!date) {
		return '';
	}
	const year = date.slice(0, 4);
	const month  = date.slice(4, 6);
	const day  = date.slice(6, 8);

	try {
		const factory = DateFactory({
			type: 'gregorian',
			year: year,
			month: month,
			day: day
		});
		const fmt = new DateFmt({
			length: 'medium',
			type: 'date',
			date: 'dmy',
			useNative: false
		});
		return fmt.format(factory) || '';
	} catch (e) {
		return '';
	}
};

export const transSecToText = (sec) => {
	const hh = Math.floor(sec/3600);
	const mm = Math.floor(Math.round(sec-hh*3600)/60);
	const ss = Math.round(sec)%60;
	const strHH =  String(hh).length === 1 ? "0"+String(hh) : String(hh);
	const strMM =  String(mm).length === 1 ? "0"+String(mm) : String(mm);
	const strSS =  String(ss).length === 1 ? "0"+String(ss) : String(ss);
	return hh > 0 ? (strHH+":"+strMM+":"+strSS) : (strMM+":"+strSS);
};
export const getNearestTarget= (direction, node) => {
	const parentNode = node.parentNode;
	const children = parentNode.children;
	const currentRect = node.getBoundingClientRect();
	const currentCenter = {x: (currentRect.left+currentRect.right)/2, y: (currentRect.top+currentRect.bottom)/2};

	const minorGap = 50;
	let nearestNode = null;
	let minDistance = 1920;
	for (let child of children) {
		if(child !== node){
			const targetRect = child.getBoundingClientRect();
			const targetCenter = {x: (targetRect.left+targetRect.right)/2, y: (targetRect.top+targetRect.bottom)/2};
			if((direction==='down' && currentCenter.y + minorGap < targetCenter.y)
				|| (direction==='up' && currentCenter.y - minorGap > targetCenter.y)
				|| (direction==='left' && currentCenter.x - minorGap> targetCenter.x)
				|| (direction==='right' && currentCenter.x + minorGap < targetCenter.x)
				){
				if((direction === 'left' || direction==='right') && Math.abs(currentCenter.y - targetCenter.y) > minorGap ){
					continue;
				}
				const distance = Math.sqrt(Math.pow((targetCenter.y - currentCenter.y),2) + Math.pow((targetCenter.x - currentCenter.x),2));
				if(minDistance > distance){
					minDistance = distance;
					nearestNode = child;
				}
			}
		}
	}
	if(nearestNode){
		return nearestNode;
	}else{
		return null;
	}
  };

export const transTimeToText = (hour, minutes) => {
	if (hour < 10) {
			hour = '0' + hour;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	return hour+":"+ minutes;
};

export const durationText = (sec) => {
	const hh = Math.floor(sec/3600);
	const mm = Math.floor(Math.round(sec-hh*3600)/60);
	const ss = Math.round(sec)%60;
	let res = "";
	if(ss > 0){
		res = $L("{ss}s").replace('{ss}',ss);
	}
	if(mm >0){
		res = $L("{mm}m").replace('{mm}',mm)+" "+res;
	}
	if(hh >0){
		res = $L("{hh}h").replace('{hh}',hh)+" "+res;
	}
	return res.trimEnd();
};

//"0:00:10" --> 10
export const stringTimetoSec = (str)=> {
	let sec = 0;
	if(typeof(str) === 'string'){
		const sp = str.split(':');
		if(sp.length === 3){
			sec = Number(sp[0])*3600 + Number(sp[1])*60+Number(sp[2]);
		}else if(sp.length === 2){
			sec = Number(sp[0])*60+Number(sp[1]);
		}else if(sp.length ===1){
			sec = Number(sp[1]);
		}else{
			sec = Number(str);
		}
	}
	return sec;
};
let DomParser;
if (typeof window === 'object'){
	DomParser = new window.DOMParser();
}
export const replaceHtmlEntites = (s) => {
	if(!s){
		return s;
	}
	if (DomParser) {
		const finalResult = DomParser.parseFromString(s, "text/html");
		return finalResult.body.innerText;
	}else{
		return s;
	}
};
const strTime = (s) => {
	return String(s).length === 1 ? "0"+String(s) : String(s);
};
export const timeToISO8601DateStr = (date) => {
	return date.getFullYear()+'-'+strTime(date.getMonth()+1)+'-'+strTime(date.getDate());
};
// "2021-10-06T13:44:00"
export const timeToISO8601Str = (date) => {
	return date.getFullYear()+'-'+strTime(date.getMonth()+1)+'-'+strTime(date.getDate())+"T"+strTime(date.getHours())
	+":"+strTime(date.getMinutes())+":"+strTime(date.getSeconds());
};

export const readLocalStorage = (key, defaultValue) => {
	const value = typeof window === 'object' && window.localStorage.getItem(key);
	if(!value && defaultValue !== undefined){
		return defaultValue;
	}
	return value === 'undefined' ? null : JSON.parse(value);
};

export const writeLocalStorage = (key, value) => {
	if(typeof window === 'object'){
		window.localStorage.setItem(key, JSON.stringify(value));
	}
};
export const convertNormalStr = (str) => {
	if(!str)return str;
	let ret =  str.replace(/(\\n)/gm,"\n");
	ret = ret.replace(/(\\t)/gm,"\t");
	ret = ret.replace(/(\\")/gm,"\"");
	return ret;
};

export const windowClose = () => {
	if (typeof window === 'object' && window.PalmSystem) {
		window.close();
	}else if(typeof window === 'object'){
		window.location.reload();
	}
}

export const parseAdminPreviewData = () => {
	const data={};
	if (typeof window === 'object'){
		const query = window.location.search.substring(1);
		const vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			try {
				data[pair[0]] = JSON.parse(decodeURIComponent(pair[1]));
			}catch(err){
				data[pair[0]] = decodeURIComponent(pair[1]);
			}
		}
	}
	return data;
}
export const convertUniToUtf8 = (str) => {
	try{
		return unescape(encodeURIComponent(str));
	}catch{
		return str;
	}
}
export const convertUtf8ToUni = (str) => {
	try{
		return decodeURIComponent(escape(str));
	}catch{
		return str;
	}
}
export const jsonConcat = (o1, o2) => {
	for (let key in o2) {
		o1[key] = o2[key];
	}
	return o1;
}
export const scaleW = (value) => {
	if (typeof window === 'object'){
		return value * (window.innerWidth/1920);
	}
	return value;
}
export const scaleH = (value) => {
	if (typeof window === 'object'){
		return value * (window.innerHeight/1080);
	}
	return value;
}

export const convertLocalTimetoUTC = (date) => {
	if(date instanceof Date){
		return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	}
	return null;
}
export const convertUTCtoLocalTime = (date) => {
	if(date instanceof Date){
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	}
	return null;
}

export const cloneObject = (obj) => {
	const clone = {};
	for (let key in obj) {
		if(Array.isArray(obj[key])){
			clone[key] = [...obj[key]];
		} else if (typeof obj[key] == "object" && obj[key] != null) {
			clone[key] = cloneObject(obj[key]);
	  	} else {
			clone[key] = obj[key];
	  	}
	}
	return clone;
}

export const isEmailAddress = (str) => {
	const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
	return exptext.test(str);
}