import React from 'react';
import DayPicker from '@enact/sandstone/DayPicker';
import css from './TDayPicker.module.less';

const TDayPicker = ({...rest }) => {
    return (
        <DayPicker
            {...rest}
            className={css.dayPicker}
        />
    );
};

export default TDayPicker;