import classNames from 'classnames';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Panel} from '@enact/sandstone/Panels';
import {Cancelable} from '@enact/ui/Cancelable';
import css from './TPanel.module.less';

import RED_BG from '../../../assets/banner/red_bg.png';
import VIOLET_BG from '../../../assets/banner/violet_bg.png';

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const TPanel = ({className,children, ...rest}) => {
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const background = useSelector(state => state.background);

	const bgStyle = useMemo(() => {
		let style={};
		if(background.backgroundImageUrl){
			style = {backgroundImage: `url(${background.backgroundImageUrl})`};
		}else{
			style = deviceAccountInfo.language=== 'ko' ? {backgroundImage: `url(${RED_BG})`} : {backgroundImage: `url(${VIOLET_BG})`};
		}
		return style;
	}, [background, deviceAccountInfo]);

	delete rest.panelInfo;
	return (
		<CancelablePanel {...rest} style={bgStyle} className={classNames(css.tpanelmain,className)}>
			{children}
		</CancelablePanel>
	);
};

export default TPanel;
