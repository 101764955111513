/**
 * TItemList
 *
 * @module TItemList
 */
import React from 'react';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './TItemList.module.less';
import Item from '@enact/sandstone/Item';

const Container = SpotlightContainerDecorator({
	enterTo: 'default-element',
}, 'div');

const TItemList = ({type='searchRecents', children, spotlightId, onClickSearch, onClickDelete}) => {
	return (
		<Container className={css.resentSearchsContainer} >
				<div className={css.listItem}>
					<Item
						spotlightId={spotlightId}
						className={css.searchResult}
						onClick={onClickSearch}
					>
						{children}
					</Item>
					{ type !== 'autoSearchWord' &&
						<Item
							className={css.deletebutton}
							onClick={onClickDelete}
						>
							x
						</Item>
					}
				</div>
		</Container>

	);
};

export default TItemList;
