import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {$L} from '../../utils/common';
import css from './AlarmListItem.module.less';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import usePrevious from '../../utils/usePrevious';
import TContainer from '../TContainer/TContainer';
import Icon from '@enact/sandstone/Icon';

const AlarmListItem = ({ alarmTitle, alarmInfo, spotlightId}) => {
	const dispatch = useDispatch();
	const appStatus  = useSelector(state => state.appStatus);
	const [activated, setActivated] = useState(false);
	const [weekdays,setWeekdays] = useState([]);
	const [time, setTime] = useState();
	const [forceFocus, setForceFocus] = useState(false);
	const prevAlarmInfo = usePrevious(alarmInfo);

	useEffect(()=> {
		if(alarmInfo){
			setActivated(alarmInfo.activated);
			let time = new Date(2000, 1, 1, alarmInfo.time.substring(0, 2), alarmInfo.time.substring(3, 5));
			setTime(time.toLocaleTimeString(appStatus.country === 'KR' ? 'ko-KR' : 'en-US', { hour: '2-digit', minute: '2-digit' }))
			if (alarmInfo.weekdays.toString() === "SUN,MON,TUE,WED,THU,FRI,SAT") {
				setWeekdays(["Every Day"]);
			} else if (alarmInfo.weekdays.toString() === "SUN,SAT") {
				setWeekdays(["Every Weekend"]);
			} else if (alarmInfo.weekdays.toString() === "MON,TUE,WED,THU,FRI") {
				setWeekdays(["Every Weekday"]);
			} else {
				setWeekdays(alarmInfo.weekdays);
			}
		}
	}, [alarmInfo]);

	const onAlarmToggle = useCallback((ev) => {
		setActivated(ev.selected);
		if (prevAlarmInfo) {
			const h = parseInt(prevAlarmInfo.time.substring(0, 2));
			const m = parseInt(prevAlarmInfo.time.substring(3, 5));
			let t = new Date(2000, 1, 1, h, m);
			const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
			let days = [];
			for (let i = 0; i < alarmInfo.weekdays.length; i++) {
			  days.push(DAYS.indexOf(alarmInfo.weekdays[i]));
			}
			dispatch(CmsActions.updateAlarmOfAccount(prevAlarmInfo.alarmId, prevAlarmInfo.title, ev.selected, days, t));
		}
	},[dispatch,prevAlarmInfo, alarmInfo]);

	const onItemClick = useCallback(() => {
		if(alarmInfo) {
			dispatch(PanelActions.pushPanel('alarmsettings', {alarmTitle: alarmTitle, alarmInfo: alarmInfo}));
		} else {
			dispatch(PanelActions.pushPanel('alarmsettings', {alarmTitle: alarmTitle}));
		}
	}, [dispatch, alarmInfo, alarmTitle]);

	const setDays = useCallback(() => {
		let days = '';
		for (let i = 0; i < weekdays.length; i++) {
			if (i === weekdays.length - 1) {
				days = days + $L(weekdays[i]);
			} else {
				days = days + $L(weekdays[i]) + ",  ";
			}
		}
		return days;
	},[weekdays]);

	const onTContainerFocus = useCallback(() => {
		setForceFocus(true);
	},[]);

	const onTContainerBlur = useCallback(() => {
		setForceFocus(false);
	},[]);

	return (
		<TContainer
			type={"alarm"}
			title={alarmTitle === 'alarm1' ? $L('Alarm 1') : $L('Alarm 2')}
			spotlightId={spotlightId}
			isContentExist={alarmInfo}
			activated={activated}
			onClick={onItemClick}
			onToggle={onAlarmToggle}
			onFocus={onTContainerFocus}
			onBlur={onTContainerBlur}
			focus={forceFocus}
			switchDisabled={!alarmInfo}
		>
			{alarmInfo ?
				<div className={css.infoContainer}>
					<div className={css.time}>{time}</div>
					<div className={css.day}>{setDays()}</div>
					<div className={css.text}>{$L('Press OK to edit this alarm')}</div>
				</div>
				:
				<div className={css.infoContainer}>
						<div className={css.iconWrap}>
							<Icon className={css.icon} size={120}>plus</Icon>
						</div>
					<div className={css.addtext}>{$L('Set Another Alarm')}</div>
				</div>
			}
		</TContainer>
	);
};

export default AlarmListItem;