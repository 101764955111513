import classNames from 'classnames';
import React,{useMemo,} from "react";
import { useSelector } from "react-redux";
import css from './TPanel.module.less';
import TButton from '../TButton/TButton';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import LoginGuide from '../LoginGuide/LoginGuide';

const Container = SpotlightContainerDecorator({
	enterTo: 'first',
}, 'div');


const TBody = ({children, ...rest}) => {
  	const statusAll = {
		supportLogin : useSelector(state => state.supportLogin),
		webOSVersion : useSelector(state => state.appStatus.webOSVersion),
		accountInfo : useSelector(state => state.accountInfo),
		panels : useSelector(state => state.panels)
	}
	const {supportLogin, webOSVersion, accountInfo, panels} = statusAll;

	const showLoginGuide = useMemo(() =>  {
		if(accountInfo.accountId === "") {
			return true
		}else {
			return false
		}
	},[accountInfo]);

	const showFirmWareUpdateText = useMemo(() => {
		if(Number(webOSVersion) >= 5 && !supportLogin){
			return true;
		}
		return false;
	}, [webOSVersion, supportLogin]);


	return (
		<div>
			<Container spotlightDisabled={showLoginGuide}>
				{children}
			</Container>

			{showLoginGuide &&
				<LoginGuide
					firmwareType={showFirmWareUpdateText}
					accountId={accountInfo.accountId}
					panelName={panels[0].name}
					/>
			}
		</div>
	);

};

export default TBody;
