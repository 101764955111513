/**
 * WeeklyView
 *
 * @module WeeklyView
 */
import classNames from 'classnames';
import React from 'react';
import {shallowEqual} from 'react-redux';
import {$L} from '../../../utils/common';
import {Image} from '@enact/sandstone/Image';
import css from './GoalInfo.module.less';

const WeeklyView = ({goalData}) => {
	return (
		<div className={css.weeklyView}>
			{goalData.map((item, index) => (
				<div className={css.datalayer} key={index}>
					<Image className={classNames(css.iconImg)} src={item.icon}/>
					<div className={classNames(css.date, item.today ? css.today : null)}>{item.today ? $L("Today"): item.str}</div>
				</div>
			))}
		</div>
	);
};

export default React.memo(WeeklyView, shallowEqual);
