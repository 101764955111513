export const Types = {
	ALARMSETTING: 'AlarmSetting',
	NUDGE: 'Nudge',
	LOGINBUTTON: 'LoginButton',
	CAMERASETTING: 'CameraSetting',
	CAMERASHOWN: 'CameraShown',
	SEARCH_KEYWORD: 'SearchKeyword',
	LAUNCH_PROMOTION: 'LaunchPromotion',
	LAUNCH_APP: 'LaunchApp',
	UPDATE_CUSTOMIZEDPROGRAM_INFO: 'UpdateCustomizedProgram',
	BANNERBUTTON: 'bannerbutton'
};

export const Description = {
	AlarmSetting: 'AlarmType,Action,AlarmTitle,AlarmId,Days,Times,Active',
	Nudge: 'AlarmType,AlarmTitle,AlarmId,Message,Button',
	LoginButton: 'ClickedFrom',
	CameraSetting: 'From,Use,Size,Position',
	CameraShown: 'CameraShown',
	SearchKeyword: 'Keyword, Type',
	LaunchPromotion: 'Id, Target',
	LaunchApp: 'AppId',
	UpdateCustomizedProgram: 'Program, Level',
	bannerbutton: 'bannerLinkType, bannerLinkId, bannerLinkTitle'
};
