import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Spotlight from '@enact/spotlight';
import {on, off} from '@enact/core/dispatcher';
import ThemeDecorator from '@enact/sandstone/ThemeDecorator';
import {Job} from '@enact/core/util';
import platform from '@enact/core/platform';
import MainView from '../views/MainView';
import css from './App.module.less';

import {MAIN_INDEX} from '../actions/panelActions';
import * as CmsActions from '../actions/cmsActions';
import * as PanelActions from '../actions/panelActions';
import * as CommonActions from '../actions/commonActions';
import * as Config from '../data/Config';
import {getLaunchParams, parseAdminPreviewData} from '../utils/common';
import * as Utils from '../utils/common';
import * as ContentType from '../data/ContentType';
import appinfo from '../../webos-meta/appinfo.json';
import { types } from '../actions/actionTypes';

let cameraListRetryCount = 0;
const CAMERACHECK_MAX_COUNT = 3;

const updateCameraListJob = new Job((dispatch) => {
	if(Config.ADMIN_PREVIEW_MODE){
		return;
	}
	console.log('updateCameraListJob');
	if(typeof window !== 'undefined' && window.navigator.mediaDevices){
		window.navigator.mediaDevices.enumerateDevices()
		.then(function(devices) {
			let cameraDeviceName = "", cameraDetected = false;;
			for(let i=0; i<devices.length;i++){
				const device = devices[i];
				if(device.kind === 'videoinput'){
					console.log('ondevicechange camera...',device);
					cameraDeviceName = device.label;
					cameraDetected = true;
					break;
				}
			}
			dispatch(CommonActions.changeAppStatus({cameraDeviceName: cameraDeviceName, cameraDetected: cameraDetected}));
			console.log('updateCameraList cameraDetected', cameraDetected, cameraListRetryCount);
			cameraListRetryCount = 0;
		})
		.catch(function(err) {
			console.log('updateCameraList exception cameraListRetryCount', cameraListRetryCount);
			console.log('updateCameraList exception err',err);
			dispatch(CommonActions.changeAppStatus({cameraDeviceName: "", cameraDetected: false}));
			if(cameraListRetryCount < CAMERACHECK_MAX_COUNT){
				cameraListRetryCount++;
				updateCameraListJob.startAfter(cameraListRetryCount*500, dispatch);
			}
		});
	}
}, 500);

const setActiveAlarmDataJob = new Job((dispatch, alarmInfo, goalInfo) => {
	dispatch(CommonActions.setActiveAlarmData(alarmInfo, goalInfo));
}, 500);

// const getStyleBasedListsJob = new Job((dispatch) => {
// 	dispatch(CmsActions.getStyleBasedLists());
// }, 200);

const loginAccountJob = new Job((dispatch) => {
	dispatch(CmsActions.loginAccount());
}, 1000);

let foreGroundChangeTimer = null;

const AppBase = () => {
	const dispatch = useDispatch();
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo, shallowEqual);
	const cmsAccountId = useSelector(state => state.accountInfo.accountId, shallowEqual);
	const supportLogin = useSelector(state => state.supportLogin);
	const workoutStyleId = useSelector(state => state.accountInfo.workoutStyleId, shallowEqual);
	const alarmInfo = useSelector(state => state.alarmOfAccount);
	const goalInfo = useSelector(state => state.goalsOfAccount);
	const plansOfAccount = useSelector(state => state.plansOfAccount);
	const checkingAccountId = useSelector(state => state.appStatus.checkingAccountId);
	const deviceId = useSelector(state => state.appStatus.deviceId);
	const isAppForeground = useSelector(state => state.appStatus.isAppForeground);
	const [fontLoaded, setFontLoaded] = useState(false);
	const showTooltip = useSelector(state => state.appStatus.showTooltip);
	const showLoadingView = useSelector(state => state.showLoadingView.show);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible, shallowEqual);

	const initService = useCallback(async (haveyInit = true) => {
		console.log('<<<<<<<<<<<<<        appinfo      >>>>>>>>>>>> ',appinfo);
		if(haveyInit){
			dispatch(CommonActions.changeAppStatus({connectionFailed: false}));
			dispatch(CommonActions.initLoadingStatus());
			if(!fontLoaded && !Config.ADMIN_PREVIEW_MODE){
				dispatch(CommonActions.addLoadingStatus("fontLoaded"));
			}
			if (typeof window === "object" && window.PalmSystem && window.PalmSystem.cursor) {
				dispatch(CommonActions.changeAppStatus({ cursorVisible: window.PalmSystem.cursor.visibility }));
			}
			dispatch(CmsActions.initAccountInfo());
			dispatch(CommonActions.initDeviceAccountInfo());
			try {
				const promises = [
					new Promise((resolve) => (dispatch(CommonActions.getSystemSettings(resolve)))),
					new Promise((resolve) => (dispatch(CommonActions.getSystemInfo(resolve)))),
					new Promise((resolve) => (dispatch(CommonActions.getDeviceId(resolve)))),
				];
				await Promise.all(promises);
			} catch (e) {
				console.error("initService Error");
			}
			//get captionEnable
			dispatch(CommonActions.getSystemSettings2());
		}
		try {
			//get device account id
			const promises = [
				new Promise((resolve) => (dispatch(CommonActions.getHttpHeaderForServiceRequest(resolve)))),
				new Promise((resolve) => (dispatch(CommonActions.getDeviceAccountInfo(resolve))))
			];
			await Promise.all(promises);
		} catch (e) {
			console.error("initService2 Error");
		}

		if(haveyInit){
			setTimeout(() => {
				//this api check connectionfailed
				dispatch(CmsActions.getTermsModification());
				if(Config.SUPPORT_WORKOUTSTYLE){
					dispatch(CmsActions.getWorkStyleList());
				}
				dispatch(CmsActions.getBackground());
				dispatch(CmsActions.getCommonLists());
			}, 100);
		}else{
			dispatch(CmsActions.getActiveBanners());
		}
	}, [dispatch, fontLoaded]);

	const windowSizeChanged = useCallback(() => {
		const html = document.querySelector("html");
		const innerWidth = (typeof window !== 'undefined') && window.innerWidth;
		const innerHeight = (typeof window !== 'undefined') && window.innerHeight;
		html.style.fontSize = "24px";
		const scalew = innerWidth/1920;
		const scaleh = innerHeight/1080;
		const scale = scalew < scaleh ? scalew:scaleh;
		html.style.transform = `scale(${scale})`;
		html.style.width = "1920px";
		html.style.height = "1080px";
		const leftMargin = scale*1920 < innerWidth ? (innerWidth-scale*1920)/2 : 0;
		const topMargin = scale*1080 < innerHeight ? (innerHeight-scale*1080)/2 : 0;
		html.style.marginLeft=`${leftMargin}px`;
		html.style.marginTop=`${topMargin}px`;
		html.style.transformOrigin="left top";
	}, []);

	useEffect(() => {
		if(fontLoaded){
			dispatch(CommonActions.removeLoadingStatus("fontLoaded"));
		}
	}, [fontLoaded]);

	useEffect(() => {
		console.log('deviceAccountInfo changed...', deviceAccountInfo);
		if (deviceAccountInfo && deviceAccountInfo.accountId) {
			if(!supportLogin){
				dispatch(CommonActions.changeAppStatus({checkingAccountId: false}));
			}else{
				dispatch(CmsActions.getAccount(deviceAccountInfo.accountId));
			}
		}
	}, [deviceAccountInfo, dispatch]);

	useEffect(() => {
		console.log('cmsAccountId changed...', cmsAccountId);
		dispatch(CmsActions.getAccountBasedLists());
	}, [cmsAccountId, dispatch]);

	useEffect(() => {
		if(checkingAccountId === false){
			if (deviceId && !Config.ADMIN_PREVIEW_MODE) {
				dispatch(CmsActions.getTerms());
				loginAccountJob.start(dispatch);

				// TODO[CS] deviceid, accountid(cmsAccountId)로 last watched 확인 (도관님 API 사용)
				// dispatch(CommonActions.getRecentlyWatchedContent({ deviceId, accountId: cmsAccountId }));
			}
		}
	}, [checkingAccountId, dispatch]);

	useEffect(() => {
		console.log('workoutStyleId changed ', workoutStyleId, deviceAccountInfo);
		// if(Config.ADMIN_PREVIEW_MODE){
		// 	if(workoutStyleId > Config.UNKNOWN_WORKOUTSTYLEID){
		// 		getStyleBasedListsJob.start(dispatch);
		// 	}
		// } else if(deviceAccountInfo.language){
		// 	getStyleBasedListsJob.start(dispatch);
		// }
	}, [deviceAccountInfo, workoutStyleId, dispatch]);

	useEffect(() => {
		console.log('alarmInfo changed account ', deviceAccountInfo, goalInfo);
		if (deviceAccountInfo.accountId !== undefined){
			setActiveAlarmDataJob.start(dispatch, alarmInfo, goalInfo);
		}
	}, [deviceAccountInfo, alarmInfo, goalInfo, dispatch]);

	const handleExitApp = useCallback(() => {
		// TODO: 추가적인 close 함수 구현 (다른 앱 launch 등)
		loginAccountJob.stop();
		dispatch(CmsActions.logoutAccount());
		// CloseHandler.dispatchAll();
	// #IF_COMMERCIAL
	}, [dispatch]);

	const activateApp = useCallback(() => {
		if (typeof window === 'object' && window.PalmSystem) {
			window.PalmSystem.activate();
		}
	}, []);

	useEffect(() => {
		const launchParams = getLaunchParams();
		if((launchParams.panel === 'deepLink' || launchParams.intent === 'deepLink') && launchParams.planId && plansOfAccount.length> 0){
			for(let i=0;i<plansOfAccount.length;i++){
				if(plansOfAccount[i].planId === Number(launchParams.planId)){
					dispatch(CommonActions.handlePlayItem(plansOfAccount[i], null));
				}
			}
			Utils.clearLaunchParams();
		}
	}, [plansOfAccount]);

	// luna-send -n 1 -f luna://com.palm.applicationManager/launch '{ "id": "com.twin.app.homegym", "params":{"panel": "home", "ignoreInit": true}}'
	// action should be called if app is on foreground.
	// luna-send -n 1 -f luna://com.palm.applicationManager/launch '{ "id": "com.twin.app.homegym", "params":{"action": {"type":"pause10sec"}, "ignoreInit": true}}'
	// luna-send -n 1 -f luna://com.webos.applicationManager/launch '{"id":"com.twin.app.homegym","params":{"storeCaller":"search"},"replaceParams":{"deeplinkingParams":{"target":"$CONTENTID","value":"50"}},"autoInstallation":true,"checkUpdateOnLaunch":true,"reason":"searchResult"}'
	// luna-send -n 1 -f luna://com.webos.applicationManager/launch '{"id":"com.twin.app.homegym","params":{"storeCaller":"thinq"},"replaceParams":{"deeplinkingParams":{"target":"$CONTENTID","value":"50"}},"autoInstallation":true,"checkUpdateOnLaunch":true,"reason":"searchResult"}'

	const playFromIntent = useCallback((content)=> (success) => {
		if(success){
			dispatch(CommonActions.handlePlayItem(content, null));
		}
	}, [dispatch]);

	const handleLaunchEvent = useCallback((isRelaunch, adminPreviewData={}) => {
		const launchParams = getLaunchParams();
		console.log('getLaunchParams...', launchParams, isRelaunch);

		const localSettings = Utils.readLocalStorage('localSettings');
		dispatch(CommonActions.changeLocalSettings(localSettings));

		if(Config.ADMIN_PREVIEW_MODE){
			console.log('getLaunchParams',adminPreviewData);
			if(adminPreviewData.category || adminPreviewData.categoryItems){
				launchParams.panel = 'category';
			}
			if(adminPreviewData.terms){
				launchParams.panel = 'termsDetail';
			}
			if(adminPreviewData.workoutstyle || adminPreviewData.workoutstyleItems){
				launchParams.panel = 'workoutstyle';
			}
			if (adminPreviewData.termsModification) {
				launchParams.panel = 'termsModification';
			}
			if (adminPreviewData.noticeList) {
				launchParams.panel = 'noticeList';
			}
		}
		if(launchParams.intent === 'SearchContent' || launchParams.intent === 'PlayContent'){
			launchParams.panel = 'search';
		}
		// launchParams.contentTarget="93" //한국
		// launchParams.contentTarget="50" //미국
		if(launchParams.intent === 'deepLink' || launchParams.contentTarget){
			launchParams.panel = 'deepLink';
			if(launchParams.contentTarget){
				launchParams.contentId = launchParams.contentTarget;
			}
		}

		if(launchParams.panel){
			dispatch(PanelActions.resetPanels(true));
			switch(launchParams.panel){
				case 'home':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.HOME_VIEW));
					break;
				case 'category':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.CATEGORIES_VIEW));
					break;
				case 'dashboard':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.DASHBOARDS_VIEW));
					break;
			//승훈 추가
				case 'interview':
					dispatch(PanelActions.setMainPanelIndex(MAIN_INDEX.INTERVIEW_VIEW));
					break;
				case 'alarmlist':{
						dispatch(PanelActions.pushPanel('alarmlist'));
					}
					break;
				case 'goallist':{
					dispatch(PanelActions.pushPanel('goallist'));
					}
					break;
				//admin preview
				case 'termsDetail':
					dispatch(PanelActions.pushPanel('termsConditionsDetail'));
					break;
				case 'termsModification':
					dispatch(PanelActions.pushPanel('termsModification'));
					break;
				//admin preview
				case 'workoutstyle':
					dispatch(PanelActions.pushPanel('workoutstyle'));
					break;
				case 'search':
					dispatch({ type: types.GET_CONTENTS_KEYWORD_RESET });
					dispatch({ type: types.ADD_LOADING_SPINNER_STATUS, payload: "getResultOfLGFitness" });
					setTimeout(() => {
						dispatch(PanelActions.popPanel('search'));
						dispatch(PanelActions.pushPanel('search',{keyword: launchParams.intentParam ? launchParams.intentParam: ""}));
					}, isRelaunch ? 10: 2000);
					break;
				case 'deepLink':
					if(launchParams.storeCaller==='search'){
						dispatch(CommonActions.setPlayLaunchPath("integratedSearch"));
					}else if(launchParams.storeCaller === 'Nudge'){
						dispatch(CommonActions.setPlayLaunchPath("nudge"));
					}else{
						dispatch(CommonActions.setPlayLaunchPath("deepLink"));
					}
					dispatch(PanelActions.popPanel('player'));
					if(launchParams.planId && plansOfAccount && plansOfAccount.length >0){
						for(let i=0;i<plansOfAccount.length;i++){
							if(plansOfAccount[i].planId === Number(launchParams.planId)){
								dispatch(CommonActions.handlePlayItem(plansOfAccount[i]));
							}
						}
					}
					if(launchParams.contentId){
						setTimeout(() => {
							dispatch(CmsActions.getContentInfo(Number(launchParams.contentId), true,
								playFromIntent({contentId: Number(launchParams.contentId), contentType: ContentType.SINGLE})));
						}, isRelaunch ? 10: 2000);
					}
					break;
				case 'noticeList':
					dispatch(PanelActions.pushPanel('notice'));
					break;
			}
		}
		//todo remove this
		if(launchParams.action){
			dispatch(CommonActions.setLaunchAction(launchParams.action));
		}
		setTimeout(() => {
			initService(!isRelaunch);
		}, 100);

		clearTimeout(foreGroundChangeTimer);
		foreGroundChangeTimer = setTimeout(() => {
			console.log('visibility changed !!! ==> set to foreground');	// eslint-disable-line no-console
			dispatch(CommonActions.changeAppStatus({isAppForeground: true}));
		}, 1000);
		if (isRelaunch) {
			activateApp();
		}
		cameraListRetryCount = 0;
		updateCameraListJob.start(dispatch);
	}, [activateApp, dispatch, initService, plansOfAccount, playFromIntent]);

	const handleRelaunchEvent = useCallback(() => {
		console.log('handleRelaunchEvent');
		handleLaunchEvent(true);
	}, [handleLaunchEvent]);

	const visibilityChanged = useCallback(() => {
		console.log('visibility changed ', !document.hidden);
		if (document.hidden) {	// change to background
			console.log('visibility changed !!! ==> set to background');	// eslint-disable-line no-console
			clearTimeout(foreGroundChangeTimer);
			dispatch(CommonActions.changeAppStatus({isAppForeground: false, cameraDeviceName: "", cameraDetected: false}));
			updateCameraListJob.stop();
			cameraListRetryCount = CAMERACHECK_MAX_COUNT;
			loginAccountJob.stop();
			dispatch(CmsActions.logoutAccount());
		} else { 		// change to foreground
			// set foreground flag using delay time.
			loginAccountJob.start(dispatch);
			clearTimeout(foreGroundChangeTimer);
			foreGroundChangeTimer= setTimeout(() => {
				console.log('visibility changed !!! ==> set to foreground');	// eslint-disable-line no-console
				if(platform.platformName !== 'webos'){//for debug
					dispatch(CommonActions.changeAppStatus({isAppForeground: true, cursorVisible: true}));
				}else if (typeof window === 'object') {
					dispatch(CommonActions.changeAppStatus({isAppForeground: true, cursorVisible: window.cursorEvent && window.cursorEvent.visibility}));
				}
			}, 1000);
			cameraListRetryCount = 0;
			updateCameraListJob.start(dispatch);
			setTimeout(() => {
				initService(false);
			}, 100);
		}
	}, [dispatch, initService]);

	const ondevicechange = useCallback((event) => {
		console.log('ondevicechange....',event);
		if(isAppForeground){
			updateCameraListJob.startAfter(1500, dispatch);
		}
	}, [dispatch, isAppForeground]);

	const onUserAction = useCallback((wheel=false) => () => {
		if(showTooltip && !showLoadingView) {
			const current = Spotlight.getCurrent();
			let delay = 3000;
			if(current && current.getAttribute('data-webos-voice-intent') !== null && current.getAttribute('data-webos-voice-intent').indexOf('Select') >=0){
				delay = 0;
			}
			if(wheel) {
				delay = 0;
			}
			if(!cursorVisible && platform.touchscreen) {
				delay = 0;
			}
			setTimeout(() => {
				dispatch(CommonActions.changeAppStatus({showTooltip: false}));
			}, delay);
		}
	}, [dispatch, showTooltip, showLoadingView, cursorVisible]);

	useEffect(() => {
			document.fonts.ready.then(() => {
				setFontLoaded(true);
			});

		if(Config.ADMIN_PREVIEW_MODE){
			const d = parseAdminPreviewData();
			dispatch(CommonActions.updateAdminPreviewData(d));
			if(d.previewId !== "" && d.previewId >=0){
				dispatch(CmsActions.getAdminPreviewData(d.previewId, handleLaunchEvent));
				console.log('_adminPreViewData ',d);
			}else{
				handleLaunchEvent();
			}
		}else{
			handleLaunchEvent();
		}
		on('webOSRelaunch', handleRelaunchEvent);
		on('visibilitychange', visibilityChanged);

		if (typeof window === 'object') {
			if (window.PalmSystem && Object.prototype.hasOwnProperty.call(window.PalmSystem, 'onclose')) {
				window.PalmSystem.onclose = handleExitApp;
			}else{
				window.onclose = handleExitApp;
			}
			if(window.PalmSystem){
				dispatch(CommonActions.getConnectionStatus());
			}
			if(Config.ADMIN_PREVIEW_MODE){
				windowSizeChanged();
				window.addEventListener('resize', windowSizeChanged, false);
			}else if(window.navigator.mediaDevices){
				window.navigator.mediaDevices.addEventListener('devicechange', ondevicechange, false);
			}
		}
		return () => {
			off('webOSRelaunch', handleRelaunchEvent);
			off('visibilitychange', visibilityChanged);

			if (typeof window === 'object') {
				if(Config.ADMIN_PREVIEW_MODE){
					window.removeEventListener('resize', windowSizeChanged);
				}else if(window.navigator.mediaDevices){
					window.navigator.mediaDevices.removeEventListener('devicechange', ondevicechange);
				}
			}
			cameraListRetryCount = CAMERACHECK_MAX_COUNT;
			updateCameraListJob.stop();
			setActiveAlarmDataJob.stop();
		};
	}, [dispatch]);

	useEffect(() => {
		on('keydown', onUserAction());
		on('mousemove', onUserAction());
		on('wheel', onUserAction(true));
		return () => {
			off('keydown', onUserAction());
			off('mousemove', onUserAction());
			off('wheel', onUserAction());
		}
	},[onUserAction]);
	return     (
		<MainView className={css.app} initService={initService}/>
	);
};

const App = ThemeDecorator({noAutoFocus: true}, AppBase);

export default App;
export {
	App,
	AppBase
};
