import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { FixedPopupPanels, Panel } from '@enact/sandstone/FixedPopupPanels';
import Spotlight from '@enact/spotlight';
import css from './TimePopup.module.less';
import TAlert from '../TAlert/TAlert';
import TTimePicker from '../TTimePicker/TTimePicker';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as ContentType from '../../data/ContentType';
import * as PanelActions from '../../actions/panelActions';
import CheckBoxItem from '../../../node_modules/@enact/sandstone/CheckboxItem';
import { types } from '../../actions/actionTypes';
import TButton from '../TButton/TButton';

const TimePopup = ({ type, onlyEditTimeContentInfo, open, selectDays, previousFocus, onClosePopup,
	activated, goalId, title, isEditMode, selectedContent, day, preSetTime, ...rest }) => {

	const dispatch = useDispatch();
	const tempGoalInfo = useSelector(state => state.tempGoalInfo, shallowEqual);
	const contentInfos = useSelector(state => state.contentInfos, shallowEqual);
	const appStatus  = useSelector(state => state.appStatus);
	const refSetTime = useRef(new Date(2000, 1, 1, 0, 0));
	const [alarm, setAlarm] = useState({ hours: 0, minutes: 0 });
	const [defaultTime, setDefaultTime] = useState(new Date(2000, 1, 1, 0, 0));
	const [selectWorkoutDay, setSelectWorkoutDay] = useState(day);
	const [selectedCheckBox, setSelectedCheckBox] = useState(false);
	const [openTAlert, setOpenTAlert] = useState({open: false, target: ""});

	const contentTitle = useMemo(() => {
		return selectedContent && Utils.replaceHtmlEntites(Utils.convertUtf8ToUni(selectedContent.title));
	}, [selectedContent]);

	const contentInfo = useMemo(() => {
		return {contentChanged: true, day:selectWorkoutDay, alarm: alarm, items: selectedContent};
	}, [ alarm, selectedContent, selectWorkoutDay]);

	const onlyTimeEditInfo = useMemo(() => {
		return {
			day: onlyEditTimeContentInfo && onlyEditTimeContentInfo.info.day,
			alarm: alarm,
			items: onlyEditTimeContentInfo && onlyEditTimeContentInfo.info.items
		};
	}, [ alarm, onlyEditTimeContentInfo]);

	const onCancelTAlert = useCallback(() => {
		setSelectWorkoutDay(day);
		setSelectedCheckBox(false);
		setOpenTAlert({open: false});
	}, [day]);

	const onHide = useCallback((ev) => {
		console.log('onHide', ev);
	}, []);

	const onClose = useCallback((ev) => {
		console.log('onClose', ev);
		if (onClosePopup) {
			onClosePopup(ev);
		}
	}, [onClosePopup]);

	const getTime = useCallback((ev) => {
		refSetTime.current = ev.value;
	}, []);

	useEffect(() => {
		if (open && preSetTime) {
			setDefaultTime(preSetTime);
			refSetTime.current = preSetTime;
		}
	}, [open]);

	useEffect(() => {
		if (selectedContent && selectedContent.contentType === ContentType.PLAN) {
			const content = contentInfos[selectedContent.items[0].contentId];
			if(content && content.thumbnailImageUrl){
				selectedContent.items[0].thumbnailImageUrl = content.thumbnailImageUrl;
			}
		}
	}, [selectedContent, contentInfos]);

	const onCompleteSetting = useCallback((ev, alarm) => {
		if(type === 'onlyEditTime') {
			let newArr = [];
			onlyTimeEditInfo.alarm = alarm;
			tempGoalInfo.goals.map((item, i) => {
				if (onlyEditTimeContentInfo && onlyEditTimeContentInfo.info.day === i) {
					newArr.push({ ...item, ["info"]: onlyTimeEditInfo });
				} else {
					newArr.push({ ...item });
				}
			});
			dispatch({ type: types.SET_TEMPORARY_GOAL, payload: { selectDays: tempGoalInfo.selectDays, goals: newArr } });
			if (ev.type === 'click') {
				onClosePopup({ type: 'click' });
			} else {
				onClosePopup({ type : 'keyup'});
			}
		} else {
			onClosePopup();
			setOpenTAlert({ open: true });
		}
	}, [dispatch, type, onlyEditTimeContentInfo, onlyTimeEditInfo, onClosePopup, tempGoalInfo]);

	const onCloseTAlert = useCallback((ev) => {
		if(ev === 0) {
			dispatch(PanelActions.pushPanel('goalsettings', { isEditMode, goalId, activated, selectDays, title, info: contentInfo }));
			dispatch(PanelActions.popPanel('settingsMediaPicker'));
			setOpenTAlert({ open: false });
		} else {
			Spotlight.focus(previousFocus);
			onCancelTAlert();
		}
	}, [dispatch, onCancelTAlert, activated, goalId, isEditMode, previousFocus, selectDays, title, contentInfo]);

	const onSelectCheckBox = useCallback((ev) => {
		if (ev.selected) {
			setSelectWorkoutDay("everyDay");
		} else {
			setSelectWorkoutDay(day);
		}
		setSelectedCheckBox(ev.selected);
	}, [day]);

	const tAlertMessage = useCallback(() => {
		let time = new Date(2000, 1, 1, alarm.hours, alarm.minutes);
		time = time.toLocaleTimeString(appStatus.country === 'KR' ? 'ko-KR' : 'en-US', { hour: '2-digit', minute: '2-digit' });
		let message = $L("{content} has been added.{br}It will alarm at {time}");
		message = message.replace('{content}', contentTitle).replace('{time}', time);
		return (
			<>
				<div>{message}</div>
				<CheckBoxItem onToggle={onSelectCheckBox}>
					{$L("Apply this option for every selected day")}
				</CheckBoxItem>
			</>
		)
	}, [alarm, contentTitle, appStatus]);

	const onCompleteTimePicker = useCallback(() => {
		Spotlight.focus("button");
	}, []);

	const onClickDone = useCallback((ev) => {
		const _alarm = { hours: refSetTime.current.getHours(), minutes: refSetTime.current.getMinutes() };
		setAlarm({ hours: refSetTime.current.getHours(), minutes: refSetTime.current.getMinutes() });
		onCompleteSetting(ev, _alarm);
	}, [onCompleteSetting, refSetTime.current]);

	return (
		<>
			<FixedPopupPanels className={css.fixedpanel} position="right" open={open} onHide={onHide} onClose={onClose} >
				<Panel className={css.panel} {...rest}>
					<div className={css.text}>{$L('What time do you want to get a daily workout notification?')}</div>
					<div className={css.timepicker}>
						<TTimePicker
							id={"timepicker"}
							defaultValue={defaultTime}
							onComplete={onCompleteTimePicker}
							onChange={getTime}
						/>
						<div className={css.button}>
							<TButton onClick={onClickDone} spotlightId={"button"}>{$L("OK")}</TButton>
						</div>
					</div>
				</Panel>
			</FixedPopupPanels>
			{openTAlert.open &&
				<TAlert
					message={tAlertMessage()}
					selected={selectedCheckBox}
					button1text={$L('OK')}
					button2text={$L('Cancel')}
					onClose={onCancelTAlert}
					onClick={onCloseTAlert}
				/>
			}
		</>
	);
};

export default TimePopup;
