import classNames from 'classnames';
import React,{useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import css from './CategoryDetail.module.less';
import Image from '@enact/sandstone/Image';
import violet_bannerCoverSmall from '../../../assets/banner/violet_videocover_small.png';
import red_bannerCoverSmall from '../../../assets/banner/red_videocover_small.png';

const CategoryDetail = ({ className}) => {
	const viewList = useSelector(state => state.categoriesTable, shallowEqual);
	const background = useSelector(state => state.background);
	const categoriesTableSelected = useSelector(state => state.categoriesTableSelected);
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);

	const selectedCategory = useMemo(() => {
		let info = {};
		const selected = viewList.filter(item => item.categoryId === categoriesTableSelected);
		if(selected && selected.length > 0){
			info = selected[0];
		}
		return info;
	}, [viewList, categoriesTableSelected]);

	const unSelectedCategory = useMemo(() => {
		return viewList.filter(item => item.categoryId !== categoriesTableSelected);
	}, [viewList, categoriesTableSelected]);

	const previewMaskStyle = useMemo(() => {
		let style={};
		if(background.smallBannerCoverUrl){
			style = {backgroundImage: `url(${background.smallBannerCoverUrl})`}
		}else{
			style = deviceAccountInfo.language=== 'ko' ? {backgroundImage: `url(${red_bannerCoverSmall})`} : {backgroundImage: `url(${violet_bannerCoverSmall})`};
		}
		return style;
	}, [background, deviceAccountInfo]);

	if(!viewList || viewList.length <=0){
		return null;
	}

	return (
		<div className={classNames(className, css.header)}>
			<div className={classNames(css.categorybanner)}>
				<Image className={classNames(css.preview, css.poster)} src={selectedCategory.previewImageUrl}/>
				<div className={classNames(css.preview, css.previewMask)}  style={previewMaskStyle}/>
			</div>
			<Image className={css.icon} src={selectedCategory.largeImageUrl}/>
			<div className={css.textbox}>
				<div className={css.content}>
					<span className={css.title}>{selectedCategory.title}</span>
					<div className={css.unselectedCatGroup}>
						{unSelectedCategory.map(item =>
							<Image className={css.thumbs} key={item.categoryId} src={item.smallImageUrl}/>
						)}
					</div>
				</div>
				<div className={classNames(css.intro, deviceAccountInfo.language === 'en' ? css.en: null)}>
					{selectedCategory.intro}
				</div>
			</div>
		</div>
	);
};

export default CategoryDetail;
