/**
 * GoalButton
 *
 * @module GoalButton
 */
import classNames from 'classnames';
import React, {useMemo} from 'react';
import Button from '@enact/sandstone/Button';
import css from './GoalInfo.module.less';

const GoalButton = ({className,children,selected=false,disabled=false, ...rest}) => {
	const convClassName = useMemo(() => {
		return classNames(selected? css.selected:null, disabled? css.disabled: null, className);
	},[selected, disabled, className]);

	return (
		<Button {...rest} className={convClassName} selected={selected} disabled={disabled}>{children}</Button>
	);
};

export default GoalButton;
