/**
 * GoalInfo
 *
 * @module GoalInfo
 */

import classNames from 'classnames';
import {$L} from '../../../utils/common';
import React, {useState, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import css from './GoalInfo.module.less';
import * as Utils from '../../../utils/common';
import * as Config from '../../../data/Config';
import ChartTitle from '../ChartTitle/ChartTitle';
import WeeklyView from './WeeklyView';
import MonthlyView from './MonthlyView';
import YearlyView from './YearlyView';
import GoalButton from './GoalButton';
import * as TopLayer from '../TopLayer';
import SpotlightContainerDecorator from '@enact/spotlight/SpotlightContainerDecorator';
import Spotlight from '@enact/spotlight';
import SpotlightIds from '../../../data/SpotlightIds';

// 과거, 골설정X, 운동
import ICON_WORKOUT_CHECK from '../../../../assets/dashboard/Goal/1_workoutday_check.png';
// 과거, 골설정, 운동X
import ICON_NO_WORKOUT_UNCHECK from '../../../../assets/dashboard/Goal/2_goal_noworkoutday_uncheck.png';
// 과거, 오늘, 골설정, 운동
import ICON_GOAL_WORKOUT_CHECK from '../../../../assets/dashboard/Goal/3_goal_workoutday_check.png';
// 과거, 골설정X, 운동X
import ICON_NO_GOAL_SET_PAST from '../../../../assets/dashboard/Goal/4_nogoalsetday_past.png';
// 오늘, 미래, 골설정, 운동X
import ICON_GOAL_SET_FUTURE from '../../../../assets/dashboard/Goal/5_goalsetday_future.png';
// 미래, 골설정X
import ICON_NO_GOAL_SET_FUTURE from '../../../../assets/dashboard/Goal/6_nogoalsetday_future.png';


const Container = SpotlightContainerDecorator({
	enterTo: 'last-focused',
}, 'div');

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

// goal1, goal2 는 골 성취율만 보여주고
// all 은 완료한 운동만 보여준다.
const GoalInfo = ({className,workoutDate}) => {
	const [showingType, setShowingType] = useState("all"); //all, goal1, goal2
	const contentUsages = useSelector(state => state.contentUsages);
	const goals = useSelector(state => state.goalsOfAccount);
	const goalAchievement = useSelector(state => state.goalAchievement);
	const supportLogin = useSelector(state => state.supportLogin);

	const goalData = useMemo(() => {
		const ret={data: [], goalId1: -1, goalId2: -1, total: 0, totalAchieved: 0, goalSetDays: 0, monthlyAchieved:[], monthlyTotal:[], showingType: 'all'};
		if(workoutDate.mode === undefined || workoutDate.firstDate === undefined){
			return ret;
		}
		const date = new Date(workoutDate.firstDate.replace(/(-)/g,','));
		const today = new Date();

		const fullYear = date.getFullYear();
		const lastDayOfMonth = new Date(fullYear, date.getMonth()+1,0).getDate();

		ret.showingType = showingType;
		ret.total = workoutDate.mode === TopLayer.MODE_WEEKLY ? 7 : lastDayOfMonth;

		let goal1, goal2;
		for(let i=0; i<2; i++){
			if(goals[i]){
				if(goals[i].title === 'goal1'){
					ret.goalId1 = goals[i].goalId;
					goal1 = goals[i];
				}else{
					ret.goalId2 = goals[i].goalId;
					goal2 = goals[i];
				}
			}
		}

		if((ret.goalId1 < 0 && showingType === 'goal1')
			|| (ret.goalId2 < 0 && showingType === 'goal2')){
			setShowingType('all');
		}
		let targetGoal = null;
		if(showingType === 'goal1' && ret.goalId1 > 0){
			targetGoal = goal1;
		}
		if(showingType === 'goal2' && ret.goalId2 > 0){
			targetGoal = goal2;
		}

		if(workoutDate.mode === TopLayer.MODE_YEARLY){
			ret.total = 0;
			if(showingType === 'all'){
				for(let i=0; i<12;i++){
					const days = new Date(fullYear, i+1,0).getDate();
					ret.monthlyTotal[i] = days;
					ret.monthlyAchieved[i]=0;
					ret.total+=days;
				}
				let flagDate="", dayAchieved = false;;
				for(let i=0; i<contentUsages.length; i++){
					if(flagDate !== contentUsages[i].date){
						flagDate = contentUsages[i].date;
						dayAchieved = false;
					}
					if(!dayAchieved){
						if( contentUsages[i].calorie && (contentUsages[i].calorieBurned/contentUsages[i].calorie)>=Config.CALORIE_ACHIEVE_RATE
							|| contentUsages[i].calorie === null){ //youtube
							dayAchieved = true;
							const month = Number(contentUsages[i].date.split('-')[1]);
							ret.monthlyAchieved[month-1] +=1;
							ret.totalAchieved+=1;
						}
					}
				}
			}else{
				if(targetGoal){
					if(targetGoal && goalAchievement[targetGoal.goalId] && goalAchievement[targetGoal.goalId].months){
						for(let i=0; i<goalAchievement[targetGoal.goalId].months.length; i++){
							let monthAchievement = goalAchievement[targetGoal.goalId].months[i].achievements;
							let count = 0;
							for(count=0; monthAchievement!==0; count++) {
								monthAchievement&=(monthAchievement-1);
							}
							ret.totalAchieved+=count;
							ret.goalSetDays+=goalAchievement[targetGoal.goalId].months[i].expectedDays;
							ret.monthlyAchieved[i]=count;
							ret.monthlyTotal[i] = goalAchievement[targetGoal.goalId].months[i].expectedDays
						}
					}
				}
			}
		}else{ //week, month
			for(let i=0; i< ret.total;i++){
				if(i!==0){
					date.setDate(date.getDate()+1);
				}
				let isToday = false, isFuture = false, goalSet = false,	icon= i%2 ? ICON_NO_GOAL_SET_PAST:ICON_GOAL_WORKOUT_CHECK
					, achieved = false;

				if(showingType === 'all'){
					const ISODate = Utils.timeToISO8601DateStr(date);
					const filteredList = contentUsages.filter((elem) => (elem.date === ISODate));
					for(let j=0; j<filteredList.length; j++){
						if( filteredList[j].calorie && (filteredList[j].calorieBurned/filteredList[j].calorie)>=Config.CALORIE_ACHIEVE_RATE
							|| filteredList[j].calorie === null){ //youtube
							achieved = true;
							break;
						}
					}
				}else{
					if(targetGoal){
						for(let index in targetGoal.items){
							if( DAYS.indexOf(targetGoal.items[index].weekday) === date.getDay()){
								goalSet = true;
								break;
							}
						}
						if(goalAchievement[targetGoal.goalId]){
							if( goalAchievement[targetGoal.goalId].achievements & (1<<i)){
								achieved = true;
							}
						}
					}
				}

				if(date.toDateString() === today.toDateString()){
					isToday = true;
					icon=ICON_WORKOUT_CHECK;
				}else if( date > today){
					isFuture = true;
				}
				if(isFuture){
					icon = goalSet ? ICON_GOAL_SET_FUTURE: ICON_NO_GOAL_SET_FUTURE;
				}else if(isToday){
					if(goalSet){
						icon = achieved ? ICON_GOAL_WORKOUT_CHECK: ICON_GOAL_SET_FUTURE;
					}else{
						icon = achieved ? ICON_WORKOUT_CHECK: ICON_NO_GOAL_SET_FUTURE;
					}
				}else{
					if(goalSet){
						icon = achieved ? ICON_GOAL_WORKOUT_CHECK: ICON_NO_WORKOUT_UNCHECK;
					}else{
						icon = achieved ? ICON_WORKOUT_CHECK: ICON_NO_GOAL_SET_PAST;
					}
				}
				ret.data.push({str: date.getDate(), today: isToday, icon: icon});
				if(achieved){
					ret.totalAchieved +=1;
				}
				if(goalSet){
					ret.goalSetDays +=1;
				}
			}
		}
		return ret;
	}, [goals, workoutDate, goalAchievement, showingType, contentUsages]);

	const onClick = useCallback((type) => () => {
		setShowingType(type);
	}, []);

	const keyDownToScroll = useCallback((ev) => {
		const dashboardScroll = SpotlightIds.DASHBOARD_SCROLLER
		const scrollbar = document.querySelector(`[data-spotlight-id="${dashboardScroll}"]`).childNodes[1].childNodes[0].childNodes[0];
		if(ev.key === "ArrowDown") {
			return Spotlight.focus(scrollbar);
		};
     },[]);


	const WEEKDAYS = [$L('Sun'), $L('Mon'), $L('Tue'), $L('Wed'), $L('Thu'), $L('Fri'), $L('Sat')];

	return (
		<div className={className} >
			<ChartTitle>{$L('Workout Completed Days')}</ChartTitle>
			{	supportLogin &&
				<Container className={css.buttonlayer} spotlightId={SpotlightIds.DASHBOARD_GOAL_BTN_GROUP} >
					{/* todo: selectable button by homegym style  */}
					<GoalButton size="small" selected={showingType==='all'} className={css.button} onClick={onClick('all')} onKeyDown={keyDownToScroll}>{$L("ALL")}</GoalButton>
					<GoalButton size="small" selected={showingType==='goal1'} disabled={goalData.goalId1<0} className={css.button} onClick={onClick('goal1')} onKeyDown={keyDownToScroll}>{$L("Goal1")}</GoalButton>
					<GoalButton size="small" selected={showingType==='goal2'} disabled={goalData.goalId2<0} className={css.button} onClick={onClick('goal2')} onKeyDown={keyDownToScroll}>{$L("Goal2")}</GoalButton>
				</Container>
			}
			<div className={css.chart}>
				<div className={css.top}>
					{workoutDate.mode === TopLayer.MODE_YEARLY ?
						<div className={css.year_top_text}>{$L("Achievement")}</div>
					:
						<>
							{WEEKDAYS.map((item, index) => (
									<div key={index} className={css.weekday}>{item}</div>
								))}
						</>
					}
				</div>
				<div className={css.body}>
					{workoutDate && workoutDate.mode === TopLayer.MODE_WEEKLY &&
						<WeeklyView firstDate={workoutDate.firstDate} goalData={goalData.data}/>
					}
					{workoutDate && workoutDate.mode === TopLayer.MODE_MONTHLY &&
						<MonthlyView firstDate={workoutDate.firstDate} goalData={goalData.data}/>
					}
					{workoutDate && workoutDate.mode === TopLayer.MODE_YEARLY &&
						<YearlyView firstDate={workoutDate.firstDate} goalData={goalData}/>
					}
				</div>
				<div className={css.bottom}>
					{showingType === 'all' &&
					<div className={css.item}>
						<div className={css.title}>{$L("Workout Days")}</div>
						<div className={css.datalayer}>
							<div className={css.data}>
								{goalData.totalAchieved}
							</div>
							<div className={css.data2}>
								{"/"}
							</div>
							<div className={css.data}>
								{goalData.total}
							</div>
						</div>
					</div>
					}
					{showingType !== 'all' &&
					<div className={css.item}>
						<div className={css.title}>{$L("Goal Achievement Rate")}</div>
						<div className={classNames(css.datalayer,css.archiveRate)}>
							<div className={css.data}>
								{goalData.goalSetDays >0 ? Math.round((goalData.totalAchieved/goalData.goalSetDays)*100): 0}
							</div>
							<div className={css.data2}>
								{"%"}
							</div>
						</div>
					</div>
					}
					{showingType !== 'all' &&
					<div className={css.item}>
						<div className={css.title}>{$L("Goal Achieved Days")}</div>
						<div className={classNames(css.datalayer,css.archiveDays)}>
							<div className={css.data}>
								{goalData.totalAchieved}
							</div>
							<div className={css.data2}>
								{"/"}
							</div>
							<div className={css.data}>
								{goalData.goalSetDays}
							</div>
						</div>
					</div>
					}
				</div>
			</div>
		</div>
	);
};

export default React.memo(GoalInfo, shallowEqual);

