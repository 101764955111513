/**
 * TToast
 *
 * @module TToast
 */
import classNames from 'classnames';
import React, { useEffect } from 'react';
import css from './TToast.module.less';
import Alert from '@enact/sandstone/Alert/Alert';
import { useDispatch } from 'react-redux';
import * as commonActions from '../../actions/commonActions';

const TToast = ({ children, ...rest }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		setTimeout(() => {
			dispatch(commonActions.changeAppStatus({toast: false}));
		}, 5000);
	})
	 return (
		 <Alert
			className={classNames(css.ttoast)}
			open
			type="overlay"
			spotlightRestrict="none"
			scrimType="none"
		>
			{children}
		</Alert>
	 );
 };

 export default TToast;
