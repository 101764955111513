/**
 * ContentDetailInfo
 *
 * @module ContentDetailInfo
 */

import classNames from 'classnames';
import Marquee from '@enact/sandstone/Marquee';
import React, {useState, useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import * as ContentType from '../../data/ContentType';
import css from './ContentDetailInfo.module.less';

/*
** size : medium, small
*/
const ContentDetailInfo = ({className, children, size}) => {
	const [infos, setInfos] = useState([]);
	const supportPIPCamera  = useSelector(state => state.supportPIPCamera);

	useEffect(() => {
		const infoarray=[];
		if(children && (children.contentType === ContentType.SINGLE || children.contentType === ContentType.PLAN || children.contentType === ContentType.SERIES)){
			// iconTime
			// if(typeof children.workoutTime !== 'undefined'){
			// 	infoarray.push({iconName: 'iconDuration', infoText: Utils.transSecToText(children.workoutTime)});
			// }
			if(children.playtime){
				infoarray.push({iconName: 'iconDuration', infoText: Utils.transSecToText(children.playtime)});
			}

			if(children.calorie){
				infoarray.push({iconName: 'iconCalory', infoText: children.calorie+" kcal"});
			}
			//todo icon
			if(children.exerciseType){
				infoarray.push({iconName: 'iconCardio', infoText: children.exerciseType});
			}
			//todo icon
			if(children.difficulty){
				infoarray.push({iconName: 'iconDifficulty', infoText: children.difficulty});
			}

			if(children.workoutArea){
				infoarray.push({iconName: 'iconFullbody', infoText: children.workoutArea});
			}
			if(children.categoryList && children.categoryList.length > 0){ //todo firstCategoryId
				infoarray.push({iconName: 'iconStretching', infoText: children.categoryList[0].title});
			}
			if(children.equipmentList && children.equipmentList.length>0){
				infoarray.push({iconName: 'iconMat', infoText: children.equipmentList.toString()});
			}
			if(children.useCamera && supportPIPCamera){
				infoarray.push({iconName: 'iconAvailable', infoText: $L("Available")});
			}
		}
		setInfos(infoarray);
	}, [children]);

	return (
		<div className={classNames(className, css[size])}>
			{infos.map((item, index) => (
					<div className={css.infoItem} key={index}>
						<div className={classNames(css.infoIcon, css[item.iconName])}/>
						<Marquee  className={css.infotext} marqueeOn={"render"}>
							{item.infoText}
						</Marquee>
					</div>
				))}
		</div>
	);
};

export default React.memo(ContentDetailInfo, shallowEqual);
