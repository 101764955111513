import {HOME_GROUP_IDS} from './Constants';

const SpotlightIds = {
	MAIN_PANEL: 'mainpanel',
	BACK_BUTTON: 'backButton',
	BANNER_PLAYBTN: 'bannerPlayerButton',
	BANNER_ADDFAVBTN: 'bannerAddFavButton',
	MAIN_SETTINGSBTN: 'settingsButton',
	MAIN_SEARCHBTN: 'searchButton',
	MAIN_EXITBTN: 'exitButton',
	MAIN_LOGINBTN: 'loginButton',
	MAIN_LOGOUTBTN: 'logoutButton',
	MAIN_CATEGORY_BTN: 'mainCategoryButton',
	MAIN_HOME_BTN: 'mainHomeButton',
	MAIN_DASHBOARD_BTN: 'mainDashboardButton',

	HOME_SCROLLER: 'home_scroller',
	HOME_BANNER: 'home_banner',
	LIST_CATEGORY: 'list_category',
	LIST_CATEGORY_TABLE: "list_category_table",
	LIST_WORKOUTSTYLE_TABLE: "list_workoutstyle_table",

	//static list ------->
	LIST_TODAYTOP: HOME_GROUP_IDS.HOME_TODAY_TOP,
	LIST_RECENTLY_WATCHED: HOME_GROUP_IDS.HOME_RECENTLY_WATHCHED,
	LIST_RECOMMANDED: HOME_GROUP_IDS.HOME_RECOMMEND,
	LIST_MYFAVORITE: HOME_GROUP_IDS.HOME_MY_FAVORITE,
	LIST_MYWORKOUTROUTINE: HOME_GROUP_IDS.HOME_MY_TRAINING_PLAN,
	LIST_RECENTLY_ADDED: HOME_GROUP_IDS.HOME_RECENTLY_ADDED,
	LIST_YOUTUBE: HOME_GROUP_IDS.HOME_YOUTUBE,
	LIST_CUSTOMIZED_WORKOUT: HOME_GROUP_IDS.CUSTOMIZED_WORKOUT,
	LIST_THIRD_PARTY: HOME_GROUP_IDS.HOME_THIRD_PARTY,
	//static list <-------

	LIST_YOUTUBE_CHANNELS: "HOME_YOUTUBE_CHANNELS",
	LIST_SEARCH: "list_search",
	LIST_MORE: 'list_more',
	LIST_TAG: 'list_tag',
	LIST_DUMMY: 'list_dummy',

	PLAYER_SKIPINTRO: 'skipintro',
	PLAYER_TITLE_LAYER: 'playerTitleLayer',
	PLAYER_SLIDER: 'playerslider',
	LIST_PLAYER: "list_player",
	LIST_PLAYER2: "list_player2",
	PROMOTION: 'promotion',

	RECENT_SEARCH: "recent_search",
	LOGIN_GUIDE_BUTTON: "LOGIN_GUIDE_BUTTON",
	DASHBOARD_SCROLLER: "DASHBOARD_SCROLLER",
	DASHBOARD_GOAL_BTN_GROUP: "DASHBOARD_GOAL_BTN_GROUP",
	//승훈 추가
	INTERVIEW_VIEW: 'interview',
	INTERVIEW_PANEL: 'interviewpanel',

};

export default SpotlightIds;
