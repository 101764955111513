import classNames from 'classnames';
import React, { useState, useCallback, useReducer, useMemo, useRef, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Spotlight from '@enact/spotlight';
import { Job } from '@enact/core/util';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import * as CommonActions from '../../actions/commonActions';
import { $L, cloneObject } from '../../utils/common';

import * as CP875Table from '../../data/CP875Table';
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/TPanel/THeader';
import TButton from '../../components/TButton/TButton';
import TPopup from '../../components/TPopup/TPopup';
import BasicPage from './pages/BasicPage';
import RadioPage from './pages/RadioPage';
import OneMinuitTest from './pages/OneMinuitTest';
import SubmitPage from './pages/SubmitPage';

import css from './InterviewView.module.less';
import TBody from '../../components/TPanel/TBody';

/**
 *
 * @param panelInfo {type: 'singletone' or undefined}
 * @returns
 */
const Interview = ({panelInfo}) => {
    const dispatch = useDispatch();
    const customizedUserInfo = useSelector(state => state.customizedUserInfo);
    const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const [userInfo, setUserInfo] = useState(customizedUserInfo);

    const [path, setPath] = useState([]);
    const [aniDirection, setAniDirection] = useState(0); // -1, 0, 1
    const [lastAniDirection, setLastAniDirection] = useState(0);
    const accountInfo = useSelector(state => state.accountInfo);

    useEffect(()=>{
        if(panelInfo.id){
            const cur = cloneObject(CP875Table.getSequence()[panelInfo.id]);
            if(panelInfo.selected >=0){
                cur.selected = panelInfo.selected;
            }
            setPath([cur]);
        }else{
            setPath([cloneObject(CP875Table.getSequence().basic)]);
        }
        if(panelInfo.type !== 'singletone' && !panelInfo.id){
            setShowInterviewStartPopup(true);
        }
    },[panelInfo]);

    const restartInterview = useCallback(() => {
        if(panelInfo.id){
            setPath([cloneObject(CP875Table.getSequence()[panelInfo.id])]);
        }else{
            setPath([cloneObject(CP875Table.getSequence().basic)]);
        }
    }, []);

    const prevPageInfo = useMemo(() => {
        if(path[path.length-2]){
            return path[path.length-2];
        }
        return {};
    }, [path]);
    const currentPageInfo = useMemo(() => {
        if(path[path.length-1]){
            return path[path.length-1];
        }
        return {};
    }, [path]);

	const onBack = useCallback((ev) => {
        if (ev) {
            ev.stopPropagation();
		}
        dispatch(PanelActions.popPanel('interview'));
	}, [dispatch]);
    const onClickStartAlertBtn = useCallback((ev) => {
        setShowInterviewStartPopup(false);
        dispatch(CommonActions.changeLocalSettings({ initialCustomizedProgramGuideShown: true }));
		Spotlight.focus('pageMain');//focus
		if (ev === 1) {
            dispatch(PanelActions.popPanel('interview'));
        }
    }, [dispatch]);

    const previous = useCallback(()=>{
            setAniDirection(-1);
            setLastAniDirection(-1);
    },[currentPageInfo, path]);

    const next = useCallback(()=>{
        if(currentPageInfo.nextId){
            setPath([...path, cloneObject(CP875Table.getSequence()[currentPageInfo.nextId])]);
            setAniDirection(1);
            setLastAniDirection(1);
        }
    },[currentPageInfo, path]);

    const onBasicInfoChanged = useCallback((info)=>{
        currentPageInfo.userInfo = info;
        if(info.gender !==0 && info.tall !== 0 && info.weight !== 0 && info.age !== 0){
            currentPageInfo.nextId = 'Q1';
        }else{
            currentPageInfo.nextId = null;
        }
        setUserInfo(Object.assign({}, userInfo, info));
    },[currentPageInfo, userInfo]);

    const onRadioSelected = useCallback((index, item)=>{
        currentPageInfo.selected = index;
        if(item){
            const newData={};
            if(item.nextId){
                currentPageInfo.nextId = item.nextId;
                forceUpdate();
            }
            if(item.goal){
                newData.goal = item.goal;
            }
            if(item.plan){
                newData.plan = item.plan;
            }
            if(item.program){
                newData.program = item.program;
            }
            if(item.level){
                newData.level = item.level;
            }
            if(Object.keys(newData).length > 0){
                setUserInfo(Object.assign({}, userInfo, newData));
            }
        }
    },[userInfo, currentPageInfo]);

    const makePrevPage = useCallback((pageInfo)=>{
        let slideClass = null;
        if(aniDirection > 0){
            slideClass = css.slideOut;
        }else if(aniDirection < 0){
            slideClass = css.slideInFromLeft;
        }
        const props = {
            isReady: false,
            className: classNames(css.prevPage, slideClass ? slideClass: null),
            pageInfo: pageInfo
        };
        if(aniDirection !==0){
            if(pageInfo.type === 'basic'){
                return <BasicPage {...props}/>
            }else if(pageInfo.type === 'radio'){
                return <RadioPage {...props}/>
            }else if(pageInfo.type === 'selftest'){
                return <OneMinuitTest {...props}/>
            }else if(pageInfo.type === 'complete'){
                return <SubmitPage {...props}/>
            }
        }
        return null;
    },[aniDirection]);

    const handleOnAnimationEnd = useCallback((direction)=> {
        if(direction === -1){
            setPath([...path.slice(0, (path.length - 1))])
        }
        setAniDirection(0)
    },[path])

    const makePage = useCallback((pageInfo)=>{
        let slideClass = null;
        if(aniDirection > 0){
            slideClass = css.slideInFromRight;
        }else if(aniDirection < 0){
            slideClass = css.slideOutToRight;
        }
        pageInfo.userInfo = userInfo;
        const props = {
            isReady: aniDirection===0,
            direction: lastAniDirection,
            className: slideClass ? slideClass: null,
            pageInfo: pageInfo,
            spotlightId: 'pageMain',
            onAnimationEnd: handleOnAnimationEnd
        };
        if(pageInfo.type === 'basic'){
            return <BasicPage {...props} popupBlurEffect={showInterviewStartPopup} onChanged={onBasicInfoChanged}/>
        }else if(pageInfo.type === 'radio'){
            return <RadioPage {...props} onSelected={onRadioSelected}/>
        }else if(pageInfo.type === 'selftest'){
            return <OneMinuitTest {...props} next={next}/>
        }else if(pageInfo.type === 'complete'){
            return <SubmitPage {...props} restartInterview={restartInterview} modifyMode={!!panelInfo.id}/>
        }
        return null;
    },[onRadioSelected, next, restartInterview, aniDirection, userInfo, showInterviewStartPopup, lastAniDirection]);

    const onSingleDone= useCallback(()=>{
        dispatch(CmsActions.updateCustomizedUserInfo(userInfo));
        dispatch(PanelActions.popPanel('interview'));
    },[userInfo]);

    //console.log('yhcho interviewView plan, program, level: ', selectedPlan, selectedProgram, selectedLevel);
    return (
        <TPanel spotlightId={'interview'} handleCancel={onBack} className={css.panel}>
            <THeader className={css.header} onClick={onBack}/>
            <TBody>
                {makePrevPage(prevPageInfo)}
                {makePage(currentPageInfo)}
                {panelInfo.type !== 'singletone' && path.length > 1 && aniDirection === 0 && currentPageInfo.type !== 'complete' &&
                    <TButton className={css.prevBtn} type={"backArrow"} disabled={path.length<=1} onClick={previous}>
                            {$L("Back")}
                    </TButton>
                }
                {panelInfo.type !== 'singletone' && currentPageInfo.type !== 'complete' &&  aniDirection === 0 &&
                    <TButton className={css.nextBtn} type={"nextArrow"} disabled={!(currentPageInfo.nextId)} onClick={next}>
                        {$L({value: 'Next', key: '__Next'})}
                    </TButton>
                }
                {panelInfo.type !== 'singletone' && showInterviewStartPopup && accountInfo.accountId !== "" &&(
                    <TPopup
                        type={'textAlignLeft'}
                        title={$L("Fill out a questionnaire{br}for custom workout plan!")}
                        message={$L("Find your routines with a fitness questionnaire!{br}28 days custom workout plans await you.")}
                        button1text={$L('Start')}
                        button2text={$L('Skip')}
                        onClick={onClickStartAlertBtn}
                    />
                )}
                {panelInfo.type === 'singletone' &&
                    <div className={css.singletoneButtonlayer}>
                        <TButton onClick={onSingleDone}>{$L("Done")}</TButton>
                        <TButton onClick={onBack}>{$L("Cancel")} </TButton>
                    </div>
                }
            </TBody>
        </TPanel>
    );
};

export default Interview;
