/**
 * DashboardsView
 *
 * @module DashboardsView
 */
import css from './DashboardsView.module.less';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {Image} from '@enact/sandstone/Image';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import SpotlightIds from '../../data/SpotlightIds';
import TopLayer from './TopLayer';
import MyInfo from './MyInfo';
import GoalInfo from './GoalInfo';
import CircleChart from './CircleChart';
import TimeStatistics from './TimeStatistics';
import History from './History';
import TButton from '../../components/TButton';
import * as CommonActions from '../../actions/commonActions';
import { useDispatch, useSelector } from 'react-redux';
import * as CmsActions from '../../actions/cmsActions'
import * as Config from '../../data/Config';
import {$L} from '../../utils/common';
import LoginGuide from '../../components/LoginGuide/LoginGuide';
import TScroller from '../../components/TScroller/TScroller';
//todo preload on MainView
import PREPARE_IMAGE_KO from '../../../assets/dashboard/dashboard_prepare_ko.png';
import PREPARE_IMAGE_EN from '../../../assets/dashboard/dashboard_prepare_en.png';

const Container = SpotlightContainerDecorator({
	enterTo: 'default-element',
}, 'div');

const DashboardsView = () => {
	const dispatch = useDispatch();
	const accountInfo = useSelector(state => state.accountInfo);
	const contentUsages = useSelector(state => state.contentUsages);
	const goals = useSelector(state => state.goalsOfAccount);
	const supportLogin = useSelector(state => state.supportLogin);
	const webOSVersion = useSelector(state => state.appStatus.webOSVersion);
	const [workoutDate, setWorkoutDate] = useState({});

	useEffect(() => {
		Spotlight.focus(SpotlightIds.MAIN_DASHBOARD_BTN);
		Spotlight.focus("dashboard_login");
	}, []);

	const onTopDataChanged = useCallback((ev) => {
		console.log('onTopDataChanged ', ev);
		setWorkoutDate(ev)
	}, []);

	useEffect(() => {
		if(workoutDate.firstDate && workoutDate.previousDate){
			dispatch(CmsActions.getWorkoutSummary(workoutDate.firstDate, workoutDate.mode));
			dispatch(CmsActions.getWorkoutSummary(workoutDate.previousDate, workoutDate.mode));
			if(goals){
				if(goals[0] && goals[0].goalId){
					dispatch(CmsActions.getGoalAchievement(workoutDate.firstDate, workoutDate.mode, goals[0].goalId));
				}
				if(goals[1] && goals[1].goalId){
					dispatch(CmsActions.getGoalAchievement(workoutDate.firstDate, workoutDate.mode, goals[1].goalId));
				}
			}
		}
		if(workoutDate.firstDate && workoutDate.lastDate){
			dispatch(CmsActions.getContentUsagesOfAccount(workoutDate.firstDate, workoutDate.lastDate));
		}

	},[workoutDate, goals, dispatch]);

	const showLoginGuide = useMemo(() =>  {
		if(accountInfo.accountId === "") {
			return true
		}else {
			return false
		}
	},[accountInfo]);

	const showFirmWareUpdateText = useMemo(() => {
		if(Number(webOSVersion) >= 5 && !supportLogin){
			return true;
		}
		return false;
	}, [webOSVersion, supportLogin]);


	return (
			<>
			<Container className={css.bodyarea} spotlightDisabled={!accountInfo.accountId}>
				<div className={css.toplayer}>
					<TopLayer onDataChanged={onTopDataChanged}/>
				</div>
				<TScroller
					className={css.scroller}
					spotlightId={SpotlightIds.DASHBOARD_SCROLLER}
				>
					<MyInfo className={css.chartLayer} workoutDate={workoutDate}/>
					<GoalInfo className={css.chartLayer}  workoutDate={workoutDate} />
					<CircleChart className={css.chartLayer} workoutDate={workoutDate}/>
					<TimeStatistics className={css.chartLayer} workoutDate={workoutDate} />
					<History className={css.historyLayer} contentUsages={contentUsages}/>
				</TScroller>
			</Container>

			{showLoginGuide &&
				<LoginGuide
					firmwareType={showFirmWareUpdateText}
					accountId={accountInfo.accountId}
				/>
			}
			</>
		);
};

export default DashboardsView;
