import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {Panel} from '@enact/sandstone/Panels';
import Button from '@enact/sandstone/Button';
import {useDispatch, useSelector} from 'react-redux';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import {MediaControls} from '../../components/MediaPlayer';
import {VoiceControlDecorator} from '@enact/webos/speech';
import Skinnable from '@enact/sandstone/Skinnable';
import {Cancelable} from '@enact/ui/Cancelable';
import SpotlightIds from '../../data/SpotlightIds';
import {Job} from '@enact/core/util';
import MediaList from '../../components/MediaList';
import PIPCamera from '../../components/PIPCamera';
import TButton from '../../components/TButton';
import {Image} from '@enact/sandstone/Image';
import {VideoPlayer} from '../../components/VideoPlayer';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import * as CommonActions from '../../actions/commonActions';
import * as CmsActions from '../../actions/cmsActions';
import * as Config from '../../data/Config';
import * as LogTypes from '../../data/LogTypes';
import InfoPopup from './InfoPopup';
import CameraSettingPopup from './CameraSettingPopup';
import * as PanelActions from '../../actions/panelActions';

import subtitleVTT from '../../../assets/mock/video.vtt';
import css from './PlayerPanel.module.less';
import Spotlight from '@enact/spotlight';
import TSwitchBox from '../../components/TSwitchItem/TSwitchItem'
import VideoPlayerSubtitle from '../../components/VideoPlayerSubtitle/VideoPlayerSubtitle';

const Container = SpotlightContainerDecorator({leaveFor: {down:'', up:'', left:'', right:''}, enterTo: '', restrict: 'self-only'}, 'div');
const CancelableDiv = Cancelable({modal: true, onCancel: 'handleCancel'}, Skinnable(Container));
const VoiceMain = VoiceControlDecorator(CancelableDiv);

const testJob = new Job((voiceEventCallback) => {
	const pd = function(){console.log('preventDefault');};
	// ["skipIntro", "forward", "backward", "move", "play","pause","stop"]
	// const detail = {type: "action", intent: "ControlMedia", offset:"30", control:"forward"};
	// const detail = {type: "action", intent: "ControlMedia", offset:"30", control:"backward"};
	// const detail = {type: "action", control:"next"};
	// const detail = {type: "action", control:"previous"};
	// const detail = {type: "action", intent: "ControlMedia", offset:"120", control:"play"};
	// const detail = {type: "action", intent: "ControlMedia", control:"skipIntro"};
	const detail = {type: "action", intent: "ControlMedia", control:"move", time:{"Minutes":"0","Seconds":"0","Hour":"0"}};
	voiceEventCallback({detail: detail, preventDefault: pd});
},10000);

let playerInterval = null;
let endedTimer = null;

const unableToPlay = new Job((callback) => {
	callback();
},5000);

const PlayerPanel = ({hideChildren, ...props}) => {
	const dispatch = useDispatch();
	const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const {accountId}  = useSelector(state => state.accountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const playListInfo  = useSelector(state => state.playListInfo);
	const webOSVersion  = useSelector(state => state.appStatus.webOSVersion);
	const contentInfos = useSelector(state => state.contentInfos);
	const launchAction = useSelector(state => state.launchAction);
	const videoPreviewThumbs = useSelector(state => state.videoPreviewThumbs);
	const appStatus = useSelector(state => state.appStatus);
	const localSettings = useSelector(state => state.localSettings);
	const supportPIPCamera  = useSelector(state => state.supportPIPCamera);
	const [cameraStatus, setCameraStatus] = useState({activated: true, size: 'small', position: 'rt'});
	const [subtitle, setSubtitle] = useState('');
	const [homePageUrl, setHomePageUrl] = useState('');

	const [playingIndex, setPlayingIndex] = useState(-1);
	const [showingInfoPopup, setShowingInfoPopup] = useState(false);
	const [showingPlayLog, setShowingPlayLog] = useState(false);
	const [showingCameraSetting, setShowingCameraSetting] = useState(false);
	const [thumbnailUnavailable, setThumbnailUnavailable] = useState(true);
	const [repeatCount, setRepeatCount] = useState(0);
	const [previewThumb, setPreviewThumb] = useState('');
	const videoPlayer = useRef(null);
	const _knobSec = useRef(null);
	const updatedContentInfo = useSelector(state => state.updatedContentInfo, (newState) => {
		if(playListInfo){
			let matched = false;
			if(playListInfo.playList){
				for(let j=0; j<playListInfo.playList.length; j++){
					if(playListInfo.playList[j].contentId === newState.contentId){
						Utils.jsonConcat(playListInfo.playList[j], newState);
						matched = true;
					}
				}
				if(matched){
					return false;
				}
			}
		}
		return true;//it's same item. Will not update.
	});

	//validWorkoutTime : played time except introTime
	const playRecord = useRef({contentId: null, planId: null, introTime: 0, workoutTime: 0, validWorkoutTime: 0, calorieBurned: 0, totalCalorie: 0, lastPositionSec: 0, timeSpent: 0, totalTime: 0, introSkipped: false, reachedEnd: false});

	const getSummaryInfo = useCallback(()=> {
		dispatch(CmsActions.getThisWeekSummaryInfo());
	}, [dispatch]);

	const updatePlayLog = useCallback((callback) => {
		const record = playRecord.current;
		if (record.contentId) {
			if(record.timeSpent > 0 && record.totalTime > 0){
				const per = record.validWorkoutTime/(record.totalTime-record.introTime);
				record.calorieBurned = Math.round(record.totalCalorie*(per));
				record.endTime = Utils.timeToISO8601Str(new Date());
				dispatch(CmsActions.updatePlayLog(record, callback));
			}else{
				console.log('PlayerPanel updatePlayLog ignore.....', record.timeSpent, record.totalTime);
			}
		}
		record.workoutTime = 0;
		record.validWorkoutTime = 0;
		record.timeSpent = 0;
		record.introSkipped = false;
		record.reachedEnd = false;
	}, [dispatch]);

	const onReadSubtitle = useCallback(() => {
		const player = document.querySelector("video");
		let tracks = player.textTracks[0];
		setSubtitle("");
		if (tracks) {
			tracks.oncuechange = function (e) {
				let cue = tracks.activeCues[0];
				if (cue) {
					setSubtitle(cue.text);
				}else{
					setSubtitle("");
				}
			}
		}
	},[]);

	const currentItem = useMemo(() => {
		let itemInfo = (playListInfo && playingIndex >= 0) ? contentInfos[playListInfo.playList[playingIndex].contentId]: {};
		if(itemInfo && contentsMyFavorites && contentsMyFavorites.length > 0){
			const id = itemInfo.contentId;
			itemInfo.isFavorite = false;
			for(let index in contentsMyFavorites){
				if( id === contentsMyFavorites[index].contentId){
					itemInfo.isFavorite = true;
					break;
				}
			}
		}
		if(!itemInfo) itemInfo = {};
		return itemInfo;
	}, [playListInfo, contentInfos, playingIndex, contentsMyFavorites, updatedContentInfo]);

	const updatePlayRecordTime = useCallback(() => {
		if(playRecord && playRecord.current.contentId){
			if(videoPlayer){
				if(!videoPlayer.current.state.sourceUnavailable && !videoPlayer.current.state.paused){
					if(videoPlayer.current.state.currentTime > playRecord.current.introTime){
						playRecord.current.validWorkoutTime+=1;
					}
					playRecord.current.workoutTime+=1;
					playRecord.current.lastPositionSec = Math.round(videoPlayer.current.state.currentTime);
					if(playRecord.current.timeSpent === 0){
						playRecord.current.startTime = Utils.timeToISO8601Str(new Date());
						playRecord.current.timeSpent+=1;
					}
				}
				if(playRecord.current.timeSpent !== 0){
					playRecord.current.timeSpent+=1;
				}
			}
		}
	}, []);
	useEffect(() => {
		setCameraStatus({
			activated: localSettings.cameraActivated,
			size: localSettings.cameraSize,
			position: localSettings.cameraPosition
		});
		playerInterval = setInterval(updatePlayRecordTime, 1000);
		getSummaryInfo();
		return () => {
			updatePlayLog();
			clearInterval(playerInterval);
			playerInterval = null;
		}
	},[localSettings]);

	useEffect(() => {
		if(videoPreviewThumbs["thumb"+_knobSec.current]){
			setPreviewThumb(videoPreviewThumbs["thumb"+_knobSec.current]);
			setThumbnailUnavailable(false);
		}
	}, [videoPreviewThumbs]);

	useEffect(() => {
		if(!showingInfoPopup && !showingPlayLog && !showingCameraSetting){
			if(appStatus.isAppForeground && videoPlayer.current && !videoPlayer.current.state.sourceUnavailable && videoPlayer.current.state.paused){
				console.log('PlayerPanel resume....');
				videoPlayer.current.play();
			}
		}
		if(appStatus.isAppForeground && !playerInterval){
			playerInterval = setInterval(updatePlayRecordTime, 1000);
		}else if(!appStatus.isAppForeground){
			clearInterval(playerInterval);
			playerInterval = null;
			if(videoPlayer.current && !videoPlayer.current.state.paused){
				videoPlayer.current.pause();
			}
		}
	}, [appStatus.isAppForeground, showingInfoPopup, showingPlayLog, showingCameraSetting]);

	const delayedPlayJob = new Job(useCallback(() => {
		console.log('delayedPlay');
		if(videoPlayer.current){
			videoPlayer.current.play();
		}
	},[]), 10000);

	useEffect(() => {
		console.log('launchAction changed',launchAction);
		if(launchAction.type === 'pause10sec'){
			if(!videoPlayer.current.paused){
				videoPlayer.current.pause();
			}
			delayedPlayJob.start();
			dispatch(CommonActions.clearLaunchAction());
		}
	}, [dispatch, launchAction]);

	useEffect(() => {
		if(!hideChildren){
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.PLAYER_TITLE_LAYER);
				Spotlight.focus("videoPlayer_mediaControls");
				Spotlight.focus(SpotlightIds.PLAYER_SKIPINTRO);
			}, 100);
		}
	}, [hideChildren]);

	const onClickBack = useCallback((ev) => {
		console.log('onClickBack ev...', ev);
		dispatch(PanelActions.popPanel());
	}, [dispatch]);

	const SpotToSlider = useCallback((ev) => {
		if(Spotlight.focus(SpotlightIds.PLAYER_SLIDER)){
			ev.stopPropagation();
		}
	}, []);
	const onClickFav = useCallback((ev) => {
		console.log('onClickFav ev...', ev);
		if(currentItem.contentId){
			dispatch(CmsActions.addOrRemoveFavorites( currentItem.contentId));
		}
	}, [dispatch, currentItem]);
	const onClickInfo = useCallback((ev) => {
		console.log('onClickInfo ev...', ev);
		if( videoPlayer && videoPlayer.current){
			videoPlayer.current.doAutoClose();
			videoPlayer.current.pause();
		}
		setShowingInfoPopup(true);
	}, []);
	const onClickCameraSettings = useCallback((ev) => {
		console.log('onClickCameraSettings ev...', ev);
		if( videoPlayer && videoPlayer.current){
			videoPlayer.current.doAutoClose();
			videoPlayer.current.pause();
		}
		setShowingCameraSetting(true);
	}, []);
	const onClickSkipIntro = useCallback((ev, type) => {
		if(type === 'skipIntro'){
			if( videoPlayer && videoPlayer.current && currentItem && currentItem.introTime > 0 ){
				videoPlayer.current.seek(currentItem.introTime);
				if(playRecord){
					playRecord.current.introSkipped = true;
				}
			}
		}else{
			console.log('onClickSkipIntro ',currentItem);
			if(currentItem.promotionLinkUrl){
				const splitedValue = currentItem.promotionLinkUrl.split('/');
				const valid = splitedValue.length === 2;
				if(valid){
					dispatch(CommonActions.launchPromotionApp(splitedValue[0], splitedValue[1]));
				}
			}
		}
	}, [dispatch, currentItem]);
	const onIntroDisabled = useCallback(() => {
		console.log('onIntroDisabled');
		setTimeout(() => {
			const current = Spotlight.getCurrent();
			if(!current || current.getAttribute('data-spotlight-id') === SpotlightIds.PLAYER_SKIPINTRO ){
				Spotlight.focus("videoPlayer_mediaControls");
			}
		}, 100);
	}, []);
	const onCloseInfo = useCallback((ev) => {
		console.log('onCloseInfo ev...', ev, ev.type);
		if(showingPlayLog){
			dispatch(PanelActions.popPanel('player'));
			ev.stopPropagation();
			return;
		}
		setShowingInfoPopup(false);
		if( videoPlayer && videoPlayer.current){
			videoPlayer.current.showControls();
		}
	}, [showingPlayLog, dispatch]);
	const onCloseCameraSetting = useCallback((changed)=>(ev) => {
		console.log('onCloseCameraSetting changed...', changed);
		setShowingCameraSetting(false);
		// if( videoPlayer && videoPlayer.current){
		// 	videoPlayer.current.showControls();
		// }
		if(changed){
			setCameraStatus({
				activated: localSettings.cameraActivated,
				size: localSettings.cameraSize,
				position: localSettings.cameraPosition
			});
		}
	}, [localSettings]);
	const initializePlayer = useCallback(() => {
		_knobSec.current = 0;
		delayThumbJob.stop();
		delayedPlayJob.stop();
		setPreviewThumb('');
		if (typeof window === 'object' && window.PalmSystem) {
			dispatch(CommonActions.cancelGetVideoThumbnail());
		}
		dispatch(CommonActions.clearVideoThumbnails());
		setShowingPlayLog(false);
		setShowingCameraSetting(false);
		updatePlayLog();
		setRepeatCount(0);
		if(videoPlayer){
			videoPlayer.current.pause();
			videoPlayer.current.seek(0);
			videoPlayer.current.play();
			videoPlayer.current.showControls();
		}
	},[dispatch, updatePlayLog]);

	const onClickReplay = useCallback(() => {
		if(accountId){
			getSummaryInfo();
		}
		dispatch(CommonActions.setPlayLaunchPath("replay"));
		initializePlayer();
		if(playListInfo.planId){
			setPlayingIndex(0);
		}
	}, [playListInfo, getSummaryInfo, accountId, dispatch, initializePlayer]);

	useEffect(() => {
		if(videoPlayer.current){
			videoPlayer.current.doAutoClose();
		}
		if(playListInfo){
			setPlayingIndex(playListInfo.playIndex);
		}
	}, [playListInfo]);
	useEffect(() => {
		initializePlayer();
		if(currentItem){
			if(playRecord){
				playRecord.current.contentId = currentItem.contentId;
				playRecord.current.totalCalorie = currentItem.calorie ? currentItem.calorie: 0;
				playRecord.current.introTime = currentItem.introTime ? currentItem.introTime: 0;
				playRecord.current.totalTime = currentItem.playtime ? currentItem.playtime: 0;
				playRecord.current.introSkipped = false;
			}
		}else if(playRecord){
			playRecord.current.contentId = null;
		}
		if(playRecord){
			if(playListInfo.planId){
				playRecord.current.planId = playListInfo.planId;
			}else{
				playRecord.current.planId = null;
			}
		}
	}, [currentItem, initializePlayer]);

	// useEffect(() => {
	// 	testJob.start(handleCustomVoiceEvent);
	// }, [contentsRelated]);

	const showPlayLog = useCallback((ev) => {
		console.log('showPlayLog', ev);
		if( videoPlayer && videoPlayer.current){
			videoPlayer.current.doAutoClose();
			videoPlayer.current.pause();
		}
		setShowingPlayLog(true);
	},[]);

	const onControlsAvailable = useCallback(({available}) => {
		let position = localSettings.cameraPosition;
		if(available){
			if(position === 'lb') position = 'lt';
			if(position === 'rb') position = 'rt';
			setCameraStatus({ ...cameraStatus, position: position });
		} else {
			setCameraStatus({...cameraStatus, position: position});
		}
	},[cameraStatus, localSettings]);

	//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
	const mediainfoHandler = useCallback((ev) => {
		const type = ev.type;
		if(type !== 'timeupdate'){
			console.log('mediainfoHandler.....',type);
		}
		switch(type){
			case 'loadeddata': {
				onReadSubtitle();
				//do nothing
			}break;
			// case 'loadedmetadata':{

			// }break;
			case 'durationchange':{
				console.log('mediainfoHandler duration',ev.currentTarget.duration);
			}break;
			// case 'timeupdate':{
			// 	//todo intro time.
			// 	const per = Math.round((ev.currentTarget.currentTime/INTRO_TIME)*100);
			// 	setIntroPer(per);
			// }break;
			case 'ended':{
				playRecord.current.reachedEnd=true;
				clearTimeout(endedTimer);
				endedTimer = setTimeout(() => {
					//refresh summaryInfo with playlog
					updatePlayLog(getSummaryInfo);
					if(playListInfo.hasRelated){
						showPlayLog();
					}else{
						let index = playingIndex+1, doRepeat = false;;
						if(playListInfo.playList[playingIndex] && playListInfo.playList[playingIndex].repeatCount){
							if( playListInfo.playList[playingIndex].repeatCount > repeatCount+1){
								doRepeat = true;
							}
						}
						if(doRepeat){
							dispatch(CommonActions.setPlayLaunchPath("autoNext"));
							setRepeatCount(repeatCount+1);
							if(videoPlayer){
								videoPlayer.current.pause();
								videoPlayer.current.seek(0);
								videoPlayer.current.play();
								videoPlayer.current.showControls();
							}
						}else if(index < playListInfo.playList.length){
							dispatch(CommonActions.setPlayLaunchPath("autoNext"));
							setPlayingIndex(index);
							initializePlayer();
						}else{
							showPlayLog();
						}
					}
				}, 300);
			}break;
		}
	}, [playListInfo, playingIndex, repeatCount, showPlayLog, updatePlayLog, initializePlayer, getSummaryInfo, onReadSubtitle, dispatch]);
	const getPlayer = useCallback((ref) => {
		videoPlayer.current = ref;
		console.log('getPlayer ',ref);
		// videoPlayer.current.play();
	}, []);

	const backKeyHandler = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		// if (!currentItem) {
		// 	return false;
		// }
		dispatch(PanelActions.popPanel());
	},[dispatch]);

	const onJumpForward = useCallback((ev) => {
		console.log('onJumpForward....',ev);
		if (!currentItem.contentId) {
			return false;
		}
		if(playListInfo.playList.length > 1){
			videoPlayer.current.pause();
			let index = playingIndex+1;
			if(index < playListInfo.playList.length){
				dispatch(CommonActions.setPlayLaunchPath("forward"));
				initializePlayer();
				setPlayingIndex(index);
			}else{
				showPlayLog();
			}
		}
		ev.preventDefault();
	},[playListInfo, playingIndex, currentItem, showPlayLog, initializePlayer, dispatch]);

	const onJumpBackward = useCallback((ev) => {
		console.log('onJumpBackward....',ev);
		if (!currentItem.contentId) {
			return false;
		}
		if(playListInfo.playList.length >1){
			videoPlayer.current.pause();
		}
		let index = playingIndex-1;
		index %= playListInfo.playList.length;
		dispatch(CommonActions.setPlayLaunchPath("backward"));
		initializePlayer();
		setPlayingIndex(index);
		ev.preventDefault();
	},[playListInfo, playingIndex, currentItem, initializePlayer, dispatch]);

	const backButton = useCallback(() => {
		return (<TButton allowClickOnPreview spotlightId={SpotlightIds.BACK_BUTTON} type={"back"} onClick={onClickBack} onSpotlightDown={SpotToSlider}/>);
	},[onClickBack, SpotToSlider]);

	const getPreviewThumb = useCallback((sourceUri, time) => {
		console.log('getPreviewThumb: ' + time );	// eslint-disable-line no-console
		dispatch(CommonActions.cancelGetVideoThumbnail());
		dispatch(CommonActions.getVideoThumbnail(sourceUri, time));
	},[dispatch]);

	const requestThumb = useCallback((sourceUri, time) => {
		if (typeof window === 'object' && !window.PalmSystem) {
			return;
		}
		getPreviewThumb(sourceUri, time);
	},[getPreviewThumb]);

	const delayThumbJob = new Job(requestThumb);

	const onScrub = useCallback((ev) => {
		if (_knobSec.current !== ev.seconds) {
			_knobSec.current = ev.seconds;
			if(videoPreviewThumbs["thumb"+_knobSec.current]){
				setPreviewThumb(videoPreviewThumbs["thumb"+_knobSec.current]);
				setThumbnailUnavailable(false);
			}else{
				setThumbnailUnavailable(true);
				//thumbVideo url 256X144 size
				if(currentItem.playUrl){
					const thumbVideoUrl = currentItem.playUrl.replace('.m3u8','.mp4');
					delayThumbJob.startAfter(50, thumbVideoUrl, _knobSec.current);
				}
			}
		}
	},[_knobSec, currentItem, setThumbnailUnavailable, videoPreviewThumbs, setPreviewThumb]);

	const isActive = useMemo(() => {
		return playListInfo && currentItem.playUrl;
	}, [ playListInfo, currentItem]);

	const toastUnableToPlay = useCallback(()=> {
		dispatch(CommonActions.alertToast($L("An unknown error has occured.")));
		onClickBack();
	},[dispatch, onClickBack]);

	useEffect(() => {
		if(!isActive){
			unableToPlay.start(toastUnableToPlay);
		}else{
			unableToPlay.stop();
		}
	},[isActive]);

	const onPlay = useCallback((ev) => {
		console.log('PlayerPanel onPlay', ev);
	},[]);

	const onPause = useCallback((ev) => {
		console.log('PlayerPanel onPause', ev);
	},[]);

	const handleCustomVoiceEvent = useCallback((ev) => {
		const direction = ev.detail.control;
		console.log('handleCustomVoiceEvent', JSON.stringify(ev.detail), playingIndex);

		if (!currentItem.contentId || !videoPlayer.current || showingInfoPopup || showingPlayLog || showingCameraSetting) {
			ev.preventDefault();
			console.log('handleCustomVoiceEvent return without action', currentItem);
			return false;
		}
		switch (direction) {
			case 'skipIntro':{
				if( currentItem.introTime > 0 && videoPlayer.current.state.currentTime < currentItem.introTime){
					videoPlayer.current.seek(currentItem.introTime);
				}
				ev.preventDefault();
			} break;
			case 'forward':{
				videoPlayer.current.seek(videoPlayer.current.state.currentTime + Number(ev.detail.offset));
				ev.preventDefault();
			} break;
			case 'backward':{
				videoPlayer.current.seek(videoPlayer.current.state.currentTime - Number(ev.detail.offset));
				ev.preventDefault();
			} break;
			case 'move':{
				let offset = ev.detail.offset;
				if(ev.detail.time){
					offset = Number(ev.detail.time.Hour)*3600+Number(ev.detail.time.Minutes)*60+Number(ev.detail.time.Seconds);
				}
				videoPlayer.current.seek(offset);
				ev.preventDefault();
			} break;
			case 'next': {
				onJumpForward(ev);
			} break;
			case 'previous': {
				onJumpBackward(ev);
			} break;
		}
	},[currentItem, playingIndex, showingInfoPopup, showingPlayLog, showingCameraSetting, onJumpForward, onJumpBackward]);

	const onListItemClick = useCallback((item, index, force) => {
		if(!force && playingIndex === index){
			return;
		}
		if(playListInfo.playList[index] && playListInfo.playList[index].contentId === item.contentId){
			dispatch(CommonActions.setPlayLaunchPath(SpotlightIds.LIST_PLAYER));
			initializePlayer();
			setPlayingIndex(index);
		}
	}, [playListInfo, playingIndex, initializePlayer, dispatch]);

	const onLogListItemClick = useCallback((item, index)=>{
		getSummaryInfo();
		onListItemClick(item, index, true);
	}, [onListItemClick, getSummaryInfo]);

	const bottomcompnent = useCallback(() => {
		if(showingPlayLog || showingInfoPopup || showingCameraSetting){
			return null;
		}
		if(playListInfo.playList.length > 0){
			return (<MediaList noLimit title={playListInfo.listTitle} spotlightId={SpotlightIds.LIST_PLAYER}
				viewList={playListInfo.playList} onListItemClick={onListItemClick} playingIndex={playingIndex}/>);
		}
		return null;
	}, [showingPlayLog, showingInfoPopup, showingCameraSetting, playListInfo, onListItemClick, playingIndex]);

	const showPIPCamera = useMemo(() => {
		if(!appStatus.isAppForeground || !supportPIPCamera){
			return false;
		}
		return cameraStatus.activated && !showingInfoPopup && !showingPlayLog && !showingCameraSetting && appStatus.cameraDetected;
	}, [appStatus, cameraStatus, showingInfoPopup, showingPlayLog, showingCameraSetting, supportPIPCamera]);

	const promotionTitle = useMemo(()=>{
		let title = "", message = "";
		if(currentItem.contentId && currentItem.promotionLinkUrl){
			const valid = currentItem.promotionLinkUrl.split('/').length === 2;
			if(valid){
				if(currentItem.promotionName){
					title = currentItem.promotionName;
				}else if(currentItem.tagList && currentItem.tagList.length > 0){
					title = currentItem.tagList[0].title;
				}
			}else{
				console.error('promotionLinkUrl is not valid ', currentItem.promotionLinkUrl);
			}
		}
		if(title){
			message = $L("Watch on {title}");
			message = message.replace('{title}', title);
		}
		return message;
	}, [currentItem]);
	const poster = (showingPlayLog&& currentItem.contentId) ? currentItem.postImageUrl  ? currentItem.postImageUrl: currentItem.thumbnailImageUrl: "";
	const voiceIntent = 'PlayListControl ControlMedia:{"control": ["skipIntro", "forward", "backward", "move"]}';

	const cameraSettingsButton = useCallback(() => {
		if (supportPIPCamera) {
			return (
				<TButton className={css.cameraSettingsButtonContainer} onClick={onClickCameraSettings} onSpotlightDown={SpotToSlider}>
					{$L("Camera Settings")}
				</TButton>
			)
		}
	}, [supportPIPCamera, onClickCameraSettings, SpotToSlider]);


	useEffect(()=> {
		if(currentItem.homePageUrl) {
			setHomePageUrl(currentItem.homePageUrl)
		}
	},[currentItem])

	delete props.panelInfo;
	return (
		<Panel {...props}>
			<VoiceMain className={isActive ? css.videoMain : classNames(css.videoMain, css.hide)} data-webos-voice-intent={isActive ?voiceIntent:null} handleCancel={backKeyHandler} onVoice={handleCustomVoiceEvent}>
			<VideoPlayer
				setApiProvider={getPlayer}
				autoCloseTimeout={7000}
				disabled={undefined}
				feedbackHideDelay={3000}
				initialJumpDelay={400}
				jumpDelay={200}
				miniFeedbackHideDelay={2000}
				onScrub={onScrub}
				onPlay={onPlay}
				onPause={onPause}
				thumbnailSrc={previewThumb}
				thumbnailUnavailable={thumbnailUnavailable}
				title={currentItem.title}
				titleHideDelay={4000}
				onEnded={mediainfoHandler}
				onLoadedData={mediainfoHandler}
				onLoadedMetadata={mediainfoHandler}
				onDurationChange={mediainfoHandler}
				onControlsAvailable={onControlsAvailable}
				onTimeUpdate={mediainfoHandler}
				noAutoPlay={false}
				onJumpForward={onJumpForward}
				onJumpBackward= {onJumpBackward}
				backButton={backButton()}
				introTime={currentItem.introTime > 0 ? currentItem.introTime: 0}
				promotionTitle={promotionTitle}
				onClickSkipIntro={onClickSkipIntro}
				onIntroDisabled={onIntroDisabled}
				jumpBy={10}
				spotlightDisabled={showingInfoPopup||showingPlayLog||showingCameraSetting}
				no5WayJump={showingPlayLog||showingInfoPopup||showingCameraSetting}
				cameraSettingsButton={cameraSettingsButton()}
			>
				<source
					src={isActive ? (webOSVersion==='local' ? currentItem.playUrl.replace('.m3u8','.mp4') : currentItem.playUrl): ""}
					type={webOSVersion==='local' ? "video/mp4": "application/mpegurl"}
				/>
				{/* "https://d28ymb5cwyyibz.cloudfront.net/uploads/8aad0bcf53a140f1b848a2dd23559401.vtt" */}
				{ (Config.DEBUG_VIDEO_SUBTITLE_TEST || currentItem.subtitlePath) && appStatus.captionEnable &&
					<track kind="subtitles" src={Config.DEBUG_VIDEO_SUBTITLE_TEST ? subtitleVTT : currentItem.subtitlePath} default/>
				}
				<MediaControls
					actionGuideAriaLabel={$L("Scroll down or press the Down key")}
					actionGuideLabel={$L("Scroll down or press the Down key")}
					jumpBackwardIcon="jumpbackward"
					jumpBackwardDisabled={playingIndex<=0}
					jumpForwardIcon="jumpforward"
					jumpForwardDisabled={playingIndex+1 >= playListInfo.playList.length}
					moreActionDisabled={undefined}
					noJumpButtons={undefined}
					pauseIcon="pause"
					playIcon="play"
					playPauseButtonDisabled={undefined}
					rateChangeDisabled
				>
					{/*!isHidePlaylist && */
						<bottomComponents>
							<div>
								{bottomcompnent()}
							</div>
						</bottomComponents>
					}
					{supportLogin && accountId &&
						<Button
							icon={currentItem && currentItem.isFavorite ? "star" : "starhollow"}
							size="small"
							onClick={onClickFav}
						/>
					}
					<Button
						icon="info"
						size="small"
						onClick={onClickInfo}
					/>
				</MediaControls>
			</VideoPlayer>
			{ isActive && showPIPCamera &&
				<PIPCamera size={cameraStatus.size} position={cameraStatus.position}/>
			}
			<VideoPlayerSubtitle subtitle={subtitle} />
			<Image className={classNames(css.playLogBg, !showingPlayLog ? css.hide: null)} src={poster}/>
			{webOSVersion==='local' && !showingInfoPopup && !showingPlayLog && !showingCameraSetting &&
				<div className={css.localGuide}>{$L('Will be played high quality on TV.')}</div>
			}
			{(showingInfoPopup||showingPlayLog) &&
			<InfoPopup open type={showingPlayLog? "log":"info"} onClose={onCloseInfo} item={currentItem}
				contentsRelated={showingPlayLog ? playListInfo.playList: null} onClickReplay={onClickReplay}
				listTitle={playListInfo.listTitle} onListItemClick={onLogListItemClick} onClickPromotionLink={onClickSkipIntro}
				promotionTitle={promotionTitle} homePageUrl={homePageUrl}
				/>
			}
			{showingCameraSetting &&
				<CameraSettingPopup open onClose={onCloseCameraSetting(false)} onClickDone={onCloseCameraSetting(true)}/>
			}
			</VoiceMain>
		</Panel>
	);
};

export default PlayerPanel;