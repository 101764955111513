import React, {useMemo} from 'react';
import classNames from 'classnames';
import css from './PageTitle.module.less';

const PageTitle = ({children, hasIcon = true, className }) => {
    const icontitle = useMemo(()=>{
        return hasIcon ? `<span/>${children}` : children;
    },[children, hasIcon]);

    return (
        <div className={classNames(css.titleMain, className)}>
            {hasIcon &&
                <div className={css.icon}/>
            }
            <div className={css.title} dangerouslySetInnerHTML={{ __html: icontitle }}></div>
        </div>
    );
};

export default PageTitle;