import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Cancelable from '@enact/ui/Cancelable';
import {Panel} from '@enact/sandstone/Panels';
import {Job} from '@enact/core/util';
import * as CmsActions from '../../actions/cmsActions';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import css from './LoadingPanel.module.less';

const FIRST_DELAY = 250;
const MIN_SHOWING_TIME= 750;
const MAX_SHOWING_TIME= 20000;
const DUMMY_DELAY= 500;
const HIDING_TIME = 500;

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

let minShowingTimeJob, hidingJob, maxShowingTimeJob, animationReadyJob;

const LoadingPanel = ({...rest}) => {
	const dispatch = useDispatch();
	const showLoadingView = useSelector(state => state.showLoadingView.show);
	const [readyToHide, setReadyToHide] = useState(false);
	const [readyToAni, setReadyToAni] = useState(false);
	const [showingStatus, setShowingStatus] = useState({showing: true, hiding: false});
	const accountId = useSelector(state => state.accountInfo.accountId);
	const isAppForeground = useSelector(state => state.appStatus.isAppForeground);
	const goals = useSelector(state => state.goalsOfAccount);

	useEffect(() => {
		if(accountId && isAppForeground){
			dispatch(CmsActions.getThisWeekSummaryInfo());
		}
	},[accountId, isAppForeground, goals, dispatch]);

	useEffect(() => {
		return () => {
			if(minShowingTimeJob)minShowingTimeJob.stop();
			if(hidingJob)hidingJob.stop();
			if(maxShowingTimeJob)maxShowingTimeJob.stop();
			if(animationReadyJob)animationReadyJob.stop();
			Utils.clearLaunchParams();
		};
	}, []);

	useEffect(() => {
		if(readyToHide && !showLoadingView){
			setTimeout(() => {
				setShowingStatus({showing: false, hiding: true});
			}, DUMMY_DELAY);
		}
	}, [readyToHide, showLoadingView]);
	useEffect(() => {
		if(!showingStatus.showing){
			if(showingStatus.hiding){
				if(!hidingJob){
					hidingJob = new Job(() => {
						setShowingStatus({showing: false, hiding: false});
					}, HIDING_TIME);
				}
				hidingJob.start();
			}else{
				setReadyToHide(false);
				setReadyToAni(false);
				minShowingTimeJob.stop();
				hidingJob.stop();
				maxShowingTimeJob.stop();
				dispatch(CmsActions.getNotice());
			}
		}
	}, [showingStatus, dispatch]);
	useEffect(() => {
		if(showLoadingView){
			if(!minShowingTimeJob){
				minShowingTimeJob = new Job(() => {
					setReadyToHide(true);
				}, Config.ADMIN_PREVIEW_MODE? 0: MIN_SHOWING_TIME);
			}
			if(!maxShowingTimeJob){
				maxShowingTimeJob = new Job(() => {
					setShowingStatus({showing: false, hiding: true});
					// dispatch(CommonActions.changeAppStatus({connectionFailed: true}));
				}, MAX_SHOWING_TIME);
			}
			if(!animationReadyJob){
				animationReadyJob = new Job(() => {
					setReadyToAni(true);
				}, FIRST_DELAY);
			}
			minShowingTimeJob.start();
			maxShowingTimeJob.start();
			animationReadyJob.start();
			setShowingStatus({showing: true, hiding: false});
		}
	}, [showLoadingView, dispatch]);

	const handleCancel = useCallback((ev) => {
		ev.stopPropagation();
	}, []);

	delete rest.panelInfo;
	const hidingStyle = showingStatus.hiding ? {"transition": "opacity "+HIDING_TIME+"ms ease", "opacity": 0}:{};

	if(showingStatus.showing || showingStatus.hiding){
		return (
			<CancelablePanel {...rest} className={css.panel} style={hidingStyle} handleCancel={handleCancel}>
				<div className={readyToAni ? css.splash : css.nomal}/>
				<div className={css.textImg} />
				<div className={css.text1}>text</div>
				<div className={css.text2}>text</div>
				<div className={css.text3}>text</div>
				<div className={css.text4}>text</div>
			</CancelablePanel>
		);
	}else{
		return null;
	}
};

export default LoadingPanel;
