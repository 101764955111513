/**
 * TInputField
 *
 * @module TInputField
 */
import React from 'react';
 import { InputField } from '@enact/sandstone/Input';
import classNames from 'classnames';
import css from './TInputField.module.less';

const TInputField = ({className, invalid=false, ...rest}) => {
	return (
		<InputField
			className={classNames(css.tInputField, invalid? css.invalid: null,className)}
			{...rest}
		/>
	);
};

export default TInputField;
