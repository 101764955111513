/**
 * BannerSummary
 *
 * @module BannerSummary
 */
import css from './BannerSummary.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import * as CommonActions from '../../actions/commonActions';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';

const SpottableComponent = Spottable('div');


let goalRollingInterval;
const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const BannerSummary = ({className, hideButton, onSpotlightUp, onSpotlightDown}) => {
	const dispatch = useDispatch();
	const workoutSummary = useSelector(state => state.workoutSummaryWeek);
	const accountId = useSelector(state => state.accountInfo.accountId);
	const extended = useSelector(state => state.appStatus.extendHomeBannerSummary);
	const goals = useSelector(state => state.goalsOfAccount);
	const goalAchievement = useSelector(state => state.goalAchievementWeek);
	const refShowingGoal = useRef('goal1Data');
	const [, updateState] = useState();

	const rolling = useCallback(() => {
		if(refShowingGoal.current === 'goal1Data'){
			refShowingGoal.current = 'goal2Data';
		}else{
			refShowingGoal.current = 'goal1Data';
		}
		updateState({});
	}, []);

	useEffect(() => {
		goalRollingInterval = setInterval(rolling, 5000);
		return () => {
			clearInterval(goalRollingInterval);
			goalRollingInterval = null;
		};
	}, [rolling]);

	const _onClick = useCallback(() => {
		if(!hideButton){
			dispatch(CommonActions.changeAppStatus({extendHomeBannerSummary: !extended}));
		}
	}, [dispatch, extended, hideButton]);

	const statusList = useMemo(() => {
		let emptyText = "";
		let goalId1=null, goalId2=null, goal1Achieved=0, goal2Achieved=0, goal1Total=0, goal2Total=0,
			totalTime=0, goal1Per = 0, goal2Per = 0, goal1EmptyText="", goal2EmptyText="";
		const total=[
			{title: $L("Workouts Completed"), value: [0], value2: [$L("time")]},
			{title: $L("Total Workout Time"), value: ["0", "0"], value2: [$L({value: 'h', key: '__h'}),$L({value: 'm', key: '__m'})]}
		];
		const firstDateOfThisWeek = new Date();
		firstDateOfThisWeek.setDate(firstDateOfThisWeek.getDate()-firstDateOfThisWeek.getDay());
		const summary = workoutSummary;
		if(accountId && summary){
			total[0].value = summary.completedNumber ? [summary.completedNumber]:[0];
			if(summary.timeSpent){
				for(let i=0; i<summary.timeSpent.length; i++){
					totalTime+=summary.timeSpent[i];
				}
				const h = Math.floor(totalTime/3600);
				const m = Math.floor((totalTime-h*3600)/60);
				total[1].value = [h,m];
			}
		}
		let goal1, goal2;
		for(let i=0; i<2; i++){
			if(goals[i]){
				if(goals[i].title === 'goal1'){
					goalId1 = goals[i].goalId;
					goal1 = goals[i];
				}else{
					goalId2 = goals[i].goalId;
					goal2 = goals[i];
				}
			}
		}

		const date = new Date(firstDateOfThisWeek);
		for(let i=0; i< 7;i++){ //week
			if(i!==0){
				date.setDate(date.getDate()+1);
			}
			if(goalId1 && goal1){
				for(let index in goal1.items){
					if( DAYS.indexOf(goal1.items[index].weekday) === date.getDay()){
						goal1Total++;
						break;
					}
				}
				if(goalAchievement[goalId1]){
					if( goalAchievement[goalId1].achievements & (1<<i)){
						goal1Achieved++;
					}
				}
			}
			if(goalId2 && goal2){
				for(let index in goal2.items){
					if( DAYS.indexOf(goal2.items[index].weekday) === date.getDay()){
						goal2Total++;
						break;
					}
				}
				if(goalAchievement[goalId2]){
					if( goalAchievement[goalId2].achievements & (1<<i)){
						goal2Achieved++;
					}
				}
			}
		}
		if(goal1Total > 0){
			goal1Per = Math.round((goal1Achieved/goal1Total)*100);
		}
		if(goal2Total > 0){
			goal2Per = Math.round((goal2Achieved/goal2Total)*100);
		}
		if(!accountId){
			emptyText = $L("Log in to view your{br}workout summary.");
		}else if(totalTime <=0){
			emptyText = $L("It’s your first{br}time this week.{br}{br}Keep up{br}the good work!");
			if(goalId1 || goalId2){
				emptyText = $L("It’s your first step{br}to achieving a goal.{br}{br}Keep up{br}the good work!");
			}
		}
		if(goal1Achieved===0 && goal2Achieved===0){
			goal1EmptyText = goal2EmptyText = $L("It’s your first step{br}to achieving a goal.{br}Keep up{br}the good work!");
		}else if(goal1Achieved===0){
			goal1EmptyText = $L("It seems like your{br}Goal2 is winning.{br}Keep up{br}the good work!");
		}else if(goal2Achieved===0){
			goal2EmptyText = $L("It seems like your{br}Goal1 is winning.{br}Keep up{br}the good work!");
		}
		const goal1Data = {title: $L('Goal1'), total: goal1Total, achieved: goal1Achieved, per: goal1Per, emptyText: goal1EmptyText};
		const goal2Data = {title: $L('Goal2'), total: goal2Total, achieved: goal2Achieved, per: goal2Per, emptyText: goal2EmptyText};
		return {total, emptyText, goalId1, goalId2, goal1Data, goal2Data};
	}, [workoutSummary, accountId, goals, goalAchievement]);

	if(statusList){
		if(!statusList.goalId2){
			refShowingGoal.current = 'goal1Data';
		}else if(!statusList.goalId1){
			refShowingGoal.current = 'goal2Data';
		}
	}

	return (
		<div className={classNames(className, css.Container, extended ? css.extended : null, hideButton? css.hideButton: null)}>
			<div className={css.infoArea}>
				{statusList.emptyText &&
					<div className={css.emptyInfo}>
						{statusList.emptyText}
					</div>
				}
				{!statusList.emptyText && !statusList.goalId1 && !statusList.goalId2 &&
					<div className={css.totalInfo}>
						{statusList.total.map((item, index) => (
							<div key={index} className={css.textBlock}>
								{index !== 3 && item.value.map((t, i) => (
									<React.Fragment key={i}>
										<div className={css.value}>{item.value[i]}</div>
										<div className={css.value2}>{item.value2[i]}</div>
									</React.Fragment>
								))}
								<div className={css.title} >{item.title}</div>
							</div>
						))}
					</div>
				}
				{!statusList.emptyText && (statusList.goalId1 || statusList.goalId2 ) &&
					<div className={css.goalInfo}>
						<div className={css.goalTitle}>{statusList[refShowingGoal.current].title}</div>
						<div className={css.goalTitleBar}/>
						<div className={css.goalBody}>
							{ statusList[refShowingGoal.current].emptyText ?
								<div className={css.emptyInfo}>
									{statusList[refShowingGoal.current].emptyText}
								</div>
								:
								<React.Fragment>
									<div className={css.goalDataLayer}>
										<div>{statusList[refShowingGoal.current].per}</div>
										<div>%</div>
									</div>
									<div className={css.goalSubTitle}>{$L("Goal Achievement Rate")}</div>
									<div className={classNames(css.goalDataLayer, css.achieve)}>
										<div>{statusList[refShowingGoal.current].achieved}</div>
										<div>/</div>
										<div>{statusList[refShowingGoal.current].total}</div>
									</div>
									<div className={css.goalSubTitle}>{$L("Goal Achieved Days")}</div>
								</React.Fragment>
							}
							{ statusList.goalId1 && statusList.goalId2 &&
								<div className={css.dots}>
									<div className={classNames(css.dot, refShowingGoal.current === 'goal1Data' ? css.active: null)}/>
									<div className={classNames(css.dot, refShowingGoal.current === 'goal2Data' ? css.active: null)}/>
								</div>
							}
						</div>
						<div className={css.goalTitleBar}/>
						<div className={css.goalTotalLayer}>
							{statusList.total[1].value.map((t, i) => (
								<React.Fragment key={i}>
									<div className={css.value}>{statusList.total[1].value[i]}</div>
									<div className={css.value2}>{statusList.total[1].value2[i]}</div>
								</React.Fragment>
							))}
						</div>
						<div className={css.goalSubTitle}>{$L("Total Workout Time")}</div>
					</div>
				}
			</div>
			<SpottableComponent className={css.button}
				spotlightId='bannersummaryBtn'
				data-webos-voice-intent={hideButton? null:'Select'}
				data-webos-voice-label={hideButton? null: $L("This Week's Summary")}
				onClick={_onClick}
				onSpotlightUp={onSpotlightUp}
				onSpotlightDown={onSpotlightDown}>
				<div>{$L("This Week's Summary")}</div>
			</SpottableComponent>
		</div>
	);
};

export default BannerSummary;
