/**
 * TableItem
 *
 * @module TableItem
 */
import css from './TableItem.module.less';
import classNames from 'classnames';
import React, {useState, useCallback, useMemo} from 'react';
import Spottable from '@enact/spotlight/Spottable';
import {Image} from '@enact/sandstone/Image';
import SpotlightIds from '../../data/SpotlightIds';

import dummyFocused from '../../../assets/list/BodyShaper_focused.png';
import dummyNormal from '../../../assets/list/BodyShaper_on.png';
import dummySelected from '../../../assets/list/BodyShaper_on_check.png';

const SpottableComponent = Spottable('div');
const TableItem = ({isOnTop, tooMany, onSelect, spotlightId, title, selectedId, ...rest}) => {
	const [pressed] = useState(false);
	const [isfocused, setIsFocused] = useState(false);

	const itemId = useMemo(() => {
		if( spotlightId === SpotlightIds.LIST_CATEGORY_TABLE){
			return rest.categoryId;
		}else{
			return rest.workoutStyleId
		}
	}, [spotlightId, rest]);
	const images = useMemo(() => {
		const data={};
		if( spotlightId === SpotlightIds.LIST_CATEGORY_TABLE){
			data.focusedImageUrl = rest.mediumImageUrl;
			data.normalImageUrl = rest.smallImageUrl;
			data.selectedImageUrl = rest.largeImageUrl;
		}else{
			if(!rest.normalImageUrl){
				data.focusedImageUrl = dummyFocused;
				data.normalImageUrl = dummyNormal;
				data.selectedImageUrl = dummySelected;
			}else{
				data.focusedImageUrl = rest.focusedImageUrl;
				data.normalImageUrl = rest.normalImageUrl;
				data.selectedImageUrl = rest.selectedImageUrl;
			}
		}
		return data;
	}, [spotlightId, rest]);
	const onClick = useCallback((ev) => {
		console.log('onClick ev...', ev, rest['data-index']);
		// setPressed(true);
		// new Job(() => {
		// 	setPressed(false);
		// 	console.log("rest........",rest);
		// 	if (onSelect) {
		// 		onSelect(itemId);
		// 	}
		// }, 200).start();
		if (onSelect) {
			onSelect(itemId);
		}
	}, [rest, onSelect, itemId]);
	const onFocus = useCallback(() => {
		setIsFocused(true);
	}, []);
	const onBlur = useCallback(() => {
		setIsFocused(false);
	}, []);
	return (
		<SpottableComponent key={rest['data-index']} spotlightId={spotlightId + '_' + rest['data-index']}
			data-webos-voice-intent={isOnTop?"Select":null}
			data-webos-voice-label={isOnTop?title: null}
			className={classNames(css.buttonContainer, selectedId === itemId ? css.selected: null, pressed ? css.pressed: null, tooMany ? css.tooMany: null, spotlightId === SpotlightIds.LIST_WORKOUTSTYLE_TABLE ? css.big: null)} onClick={onClick}
			onFocus={onFocus}
			onBlur={onBlur}>
			<Image src={isfocused ? images.focusedImageUrl: selectedId === itemId ? images.selectedImageUrl: images.normalImageUrl} className={css.image}/>
			<div className={css.name}>{title}</div>
		</SpottableComponent>
	);
};

export default TableItem;
