import React, {useCallback, useEffect, useState} from 'react';
import Button from '@enact/sandstone/Button';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import {$L} from '../../../utils/common';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton';
import TPopup from '../../../components/TPopup/TPopup';
import TPlayerFeedBackBtn from '../../../components/TPlayerFeedBackBtn';
import * as PanelActions from '../../../actions/panelActions';
import * as CommonActions from '../../../actions/commonActions';

const ServiceTestPanel = props => {
	const dispatch = useDispatch();
	const serviceTestResult = useSelector(state => state.serviceTestResult);
	const [showPopup, setShowPopup] = useState(false);
	useEffect(() => {
		if(!props.hideChildren){
            Spotlight.focus('testpanel');
        }
	}, [props]);
	const onBack = useCallback((ev) => {
		console.log('onClick ev...', ev);
		dispatch(PanelActions.popPanel());
	}, [dispatch]);

	const onClickToastTest = useCallback((ev) => {
		console.log('toastTest ev...', ev);
		dispatch(CommonActions.toastTest());
	}, [dispatch]);
	const onClickTButton = useCallback((ev) => {
		console.log('onClickTButton ev...', ev);
	}, []);
	const onClickAction = useCallback((type)=>(ev) => {
		console.log('onClickAction ev...', ev);
		if(type === 'interview'){
			dispatch(PanelActions.pushPanel('interview'));
		}else if(type === 'interviewModify'){
			dispatch(PanelActions.pushPanel('interviewModify'));
		}else if(type === 'popup'){
			setShowPopup(true);
		}
	}, [dispatch]);
	const onPopupAction = useCallback(()=>{
		setShowPopup(false);
	},[]);
	return (
		<TPanel {...props} spotlightId={'testpanel'}>
			<THeader title={'Service Test Panel...'} onClick={onBack}/>
			<Button size="small" onClick={onBack}>
				{'go back'}
			</Button>
			<Button size="small" onClick={onClickToastTest}>
				{'ServiceTest CreateToast'}
			</Button>
			<TButton type={"skipIntro"} fillPer={50} onClick={onClickTButton}>{"SkipIntro"}</TButton>
			<TButton spotlightId={"btn"} onClick={onClickTButton}>{"TButton"}</TButton>
			<TButton type={"withArrow"} onClick={onClickTButton}>{"TButton"}</TButton>
			<TButton type={"back"} onClick={onClickTButton}/>
			<TPlayerFeedBackBtn >{'10 sec'}</TPlayerFeedBackBtn>
			<TPlayerFeedBackBtn type={'right'}>{'10 sec'}</TPlayerFeedBackBtn>
			<div>{" result : "+serviceTestResult}</div>
			<TButton onClick={onClickAction('interview')}>{"InterView"}</TButton>
			<TButton onClick={onClickAction('interviewModify')}>{"InterView Modify"}</TButton>
			<TButton onClick={onClickAction('popup')}>{"POPUP"}</TButton>
			{showPopup &&
				<TPopup title={$L("The program schedule has ended.")} message={$L("Would you like to maintain{br}a <b>{program}</b> program?")} button1text={$L("Keep")} button2text={$L("Change")} onClick={onPopupAction} />
			}
		</TPanel>
	);
};

export default ServiceTestPanel;
