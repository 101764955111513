/**
 * TPlayerFeedBackBtn
 *
 * @module TPlayerFeedBackBtn
 */
import css from './TPlayerFeedBackBtn.module.less';
import classNames from 'classnames';
import React, {useCallback, useEffect} from 'react';
import Spottable from '@enact/spotlight/Spottable';

const SpottableComponent = Spottable('div');
/**
 *
 * @param {type} left, right
 * @returns
 */
const TYPES=["left", "right"];

const TPlayerFeedBackBtn = ({type="left",children, spotlightId, className, onClick, disabled, ...rest}) => {
	useEffect(() => {
		if(TYPES.indexOf(type) < 0 ){
			console.error('TButton type error');
		}
	}, [type]);

	const _onClick = useCallback((ev) => {
		if(disabled){
			ev.stopPropagation();
			return;
		}
		if (onClick) {
			onClick(ev);
		}
		ev.persist();
	}, [ onClick, disabled]);

	const _onKeyDown =  useCallback((ev) => {
		if(ev.key === 'ArrowLeft' && type==="left"){
			_onClick(ev);
		}else if(ev.key === 'ArrowRight' && type==="right"){
			_onClick(ev);
		}
	}, [type, _onClick]);

	return (
		<SpottableComponent
			{...rest}
			className={classNames(css.tButton, type === 'right' ? css.right: null, className ? className: null, disabled ? css.disabled: null)}
			onClick={_onClick}
			onKeyDown={_onKeyDown}
			// data-webos-voice-intent={'Select'}
			// data-webos-voice-label={children}
			spotlightDisabled={disabled}
			spotlightId={spotlightId}
		>
			<div className={css.skipicon}/>
			<div className={css.text}>
				{children}
			</div>
		</SpottableComponent>
	);
};

export default TPlayerFeedBackBtn;
