/**
 * HomeView
 *
 * @module HomeView
 */
import classNames from 'classnames';
import Scroller from '@enact/sandstone/Scroller';

import platform from '@enact/core/platform';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Job} from '@enact/core/util';
import Spotlight from '@enact/spotlight';
import {on, off} from '@enact/core/dispatcher';
import * as CommonActions from '../../actions/commonActions';
import * as PanelActions from '../../actions/panelActions';
import usePrevious from '../../utils/usePrevious';
import HomeBanner from '../../components/HomeBanner';
import MediaList from '../../components/MediaList';
import VerticalScrollGuide from '../../components/VerticalScrollGuide';
import * as Config from '../../data/Config';

import {$L} from '../../utils/common';
import SpotlightIds from '../../data/SpotlightIds';
import css from './HomeView.module.less';

const calculateVisibleListJob = new Job((func) => {
	func();
},2000);

const HomeView = ({isOnTop}) => {
	const dispatch = useDispatch();
	const adminPreviewData = useSelector(state => state.adminPreviewData, shallowEqual);
	const homeGroup = useSelector(state => state.homeGroup, shallowEqual);
	const homeGroup2 = useSelector(state => state.homeGroup2, shallowEqual);
	const homeGroupLists = useSelector(state => state.homeGroupLists, shallowEqual);
	const homeBannerSize = useSelector(state => state.appStatus.homeBannerSize, shallowEqual);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible, shallowEqual);
	const {accountId, workoutStyleId}  = useSelector(state => state.accountInfo, shallowEqual);
	const supportLogin = useSelector(state => state.supportLogin);
	const contentsTops = useSelector(state => state.contentsTops, shallowEqual);
	const contentsRecommended = useSelector(state => state.contentsRecommended, shallowEqual);
	const contentsRecentlyWatched = useSelector(state => state.contentsRecentlyWatched, shallowEqual);
	const contentsCustomizedWorkout = useSelector(state => state.contentsCustomizedWorkout, shallowEqual);
	const plansOfAccount = useSelector(state => state.plansOfAccount, shallowEqual);
	const contentsMyFavorites  = useSelector(state => state.contentsMyFavorites, shallowEqual);
	const contentsRecentlyAdded = useSelector(state => state.contentsRecentlyAdded, shallowEqual);
	const orderedTagList = useSelector(state => state.orderedTagList, shallowEqual);
	const contentsOfTag  = useSelector(state => state.contentsOfTag, shallowEqual);
	const contentsYoutube = useSelector(state => state.contentsYoutube, shallowEqual);
	const contentsYoutubeChannels = useSelector(state => state.contentsYoutubeChannels, shallowEqual);
	const showLoadingView = useSelector(state => state.showLoadingView.show, shallowEqual);
	const panels = useSelector(state => state.panels, shallowEqual);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [visibleList, setVisibleList] = useState([]);
	const scrollTo = useRef(null);
	const prevPanels = usePrevious(panels);
	const currentBannerSize = useRef('medium');
	const scrollTop = useRef(0);
	const isOnTopRef = useRef(isOnTop);
	const debugKey = useRef([]);

	useEffect(() => {
		isOnTopRef.current = isOnTop;
	}, [isOnTop]);

	useEffect(() => {
		setBigBanner();
		on('wheel', handleWheel);
		on('keydown', handleKeydown);
		Spotlight.focus(SpotlightIds.MAIN_HOME_BTN);
		Spotlight.focus(SpotlightIds.BANNER_PLAYBTN);
		return () => {
			off('wheel', handleWheel);
			off('keydown', handleKeydown);
			if(calculateVisibleListJob) calculateVisibleListJob.stop();
		};
	}, []);

	useEffect(() => {
		if(Config.ADMIN_PREVIEW_MODE && (adminPreviewData.content || adminPreviewData.contents)){
			setMediumBannerForPreview();
		}
	}, [adminPreviewData]);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		const listArray = [];
		if(scroller){
			for(let index in scroller.children[0].children){
				if(!isNaN(index)){
					const list = scroller.children[0].children[index];
					if(list.offsetTop >= scrollTop.current && (list.offsetTop+list.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
						listArray.push(list.getAttribute('data-spotlight-id'));
					}
				}
			}
		}
		setVisibleList(listArray);
	}, []);

	useEffect(() => {
		currentBannerSize.current = homeBannerSize;
		if( scrollTo && scrollTo.current && (homeBannerSize==='big' || homeBannerSize==='medium')){
			scrollTo.current({align: 'top', animate: false});
		}
		calculateVisibleListJob.start(calculateVisibleList);
	}, [homeBannerSize]);

	const onListFocus = useCallback((ev) => {
		const listSpotlightId = ev.currentTarget.getAttribute('data-spotlight-id');
		if(!Spotlight.getPointerMode()){
			try{
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				const firstList = scroller.children[0].children[0];
				if(firstList && firstList.getAttribute){
					if(listSpotlightId === firstList.getAttribute('data-spotlight-id')){ //first list
						const itemSize = firstList.getAttribute('itemsize');
						if(itemSize === 'plan' || itemSize === 'big'){
							dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
						}else{
							dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
						}
					}else{
						dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
					}
				}
			}catch(e){
				console.warn('HomeView onListFocus warning',e);
			}
		}
	}, [dispatch]);
	const setMediumBannerForPreview = useCallback(() => {
		dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
	}, [dispatch]);
	const setBigBanner = useCallback(() => {
		dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
		dispatch(CommonActions.clearFocusedBannerItem());
		if(!cursorVisible){
			Spotlight.focus(SpotlightIds.BANNER_PLAYBTN);
		}
	}, [dispatch, cursorVisible]);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, []);

	const onScrollStart = useCallback((ev) => {
		calculateVisibleListJob.stop();
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		scroller.style.setProperty("pointer-events", "none");
	}, []);

	//not working with hook
	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		scroller.style.setProperty("pointer-events", "all");
		if(scrollTop.current > 0 && scrollTop.current + scroller.offsetHeight + 300/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		calculateVisibleListJob.start(calculateVisibleList);
	}, [calculateVisibleList]);

	const moveBottom = useCallback((wheel = false, forceTop=false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
			let listArray = scroller.children[0].children;
			if(!wheel){
				const currentItem = Spotlight.getCurrent();
				const nextItem = currentItem && currentItem.nextElementSibling;
				if(currentItem && nextItem && nextItem !== listArray[listArray.length-1]){ //check dummy
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					Spotlight.focus(nextItem);
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						let bigSize = false;
						//contain big size item
						if(currentItem.offsetHeight + nextItem.offsetHeight > scroller.offsetHeight){
							bigSize = true;
						}
						const targetOffset = (forceTop||bigSize) ? nextItem.offsetTop: currentItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for (let i = 0; i < listArray.length; i++){
				const nextItem = listArray[i].nextElementSibling;
				if(scrollTop.current < listArray[i].offsetTop){
					//contain big size item
					if (nextItem && listArray[i].getBoundingClientRect().height < nextItem.getBoundingClientRect().height) {
						const targetOffset = nextItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
					if(i < (listArray.length-2)){ //ignore dummy, lastitem
						Spotlight.focus(listArray[i]);
						scrollTop.current = listArray[i].offsetTop;
						scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
					}else if(i === (listArray.length-2)){ //last item
						let prevItem = listArray[i].previousElementSibling;
						if(prevItem && (prevItem.getAttribute('itemsize') === 'plan' || prevItem.getAttribute('itemsize') === 'big')){
							Spotlight.focus(listArray[i]);
							scrollTop.current = listArray[i].offsetTop;
							scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
						}
					}
					return true;
				}
			}
		}
	}, []);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
			let listArray = scroller.children[0].children;
			if(!wheel){
				const currentItem = Spotlight.getCurrent();
				let nextItem = currentItem && currentItem.previousElementSibling;
				if(currentItem && nextItem){
					try{
						Spotlight.focus(nextItem);
					}catch(e){
						console.warn('HomeView moveTop -----',e);
					}
					if(nextItem.offsetTop === scrollTop.current){ //contain
						return true;
					}else{
						//contain big size item
						if (currentItem.getBoundingClientRect().height > nextItem.getBoundingClientRect().height) {
							const backup = nextItem;
							nextItem = nextItem.previousElementSibling;
							if(!nextItem){
								scrollTop.current = backup.offsetTop;
								scrollTo.current({position:{y: backup.offsetTop}, animate: true});
								Spotlight.focus(backup);
								return true;
							}
						}
						scrollTop.current = nextItem.offsetTop;
						scrollTo.current({position:{y: nextItem.offsetTop}, animate: true});
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if(listArray[i].offsetTop < scrollTop.current){
					//contain big size item
					let nextItem = listArray[i].nextElementSibling;
					if (i === 0) {
						if(currentBannerSize.current === 'small' &&	listArray[i] && listArray[i].getAttribute &&
							(listArray[i].getAttribute('itemsize') === 'plan' || listArray[i].getAttribute('itemsize') === 'big')){
							//do nothing
						}else{
							dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
						}
					}
					if (i !== 0 && nextItem && (listArray[i].getBoundingClientRect().height < nextItem.getBoundingClientRect().height)) {
						nextItem = listArray[i].previousElementSibling;
						const targetOffset = nextItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y:listArray[i].offsetTop},animate: true});
					Spotlight.focus(listArray[i]);
					return true;
				}
			}
		}
	}, [dispatch]);

	const onClickTopGuide = useCallback((ev, bannerSize) => {
		if(!isOnTopRef.current){
			return;
		}
		if(!bannerSize){
			bannerSize = currentBannerSize.current;
		}
		if(bannerSize === 'medium'){
			setBigBanner();
		}else if(bannerSize === 'small'){
			if(scrollTop.current === 0){
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				const firstList = scroller.children[0].children[0];
				if(firstList && firstList.getAttribute){
					const itemSize = firstList.getAttribute('itemsize');
					if(itemSize === 'plan' || itemSize === 'big'){
						setBigBanner();
					}else{
						dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
						dispatch(CommonActions.clearFocusedBannerItem());
					}
				}else{
					dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
					dispatch(CommonActions.clearFocusedBannerItem());
				}
			}
			moveTop(true);
		}
	}, [ moveTop, dispatch, setBigBanner]);
	const onClickBottomGuide = useCallback((ev, bannerSize) => {
		if(!isOnTopRef.current){
			return;
		}
		if(!bannerSize){
			bannerSize = currentBannerSize.current;
		}
		if(bannerSize === 'big'){
			dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
			Spotlight.focus(SpotlightIds.LIST_TODAYTOP);
			try{
				const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
				const firstList = scroller.children[0].children[0];
				if(firstList && firstList.getAttribute){
					Spotlight.focus(firstList.getAttribute('data-spotlight-id'));
					const itemSize = firstList.getAttribute('itemsize');
					if(itemSize === 'plan' || itemSize === 'big'){
						dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
					}
				}
			}catch(e){
				console.warn('onClickBottomGuide warning',e);
			}
		}else if(bannerSize === 'medium'){
			dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
			moveBottom(true, true);
		}else{
			moveBottom(true);
		}
	}, [moveBottom, dispatch]);

	const onKeyDown = useCallback((ev) => {
		const isDown = (ev.key === 'ArrowDown'|| ev.key === 'PageDown');
		const isUp = (ev.key === 'ArrowUp'|| ev.key === 'PageUp');
		if(currentBannerSize.current === 'medium' && isDown){
			return moveBottom(false, true);
		// }else if(currentBannerSize.current === 'medium' && isUp){
		// 	dispatch(CommonActions.changeAppStatus({homeBannerSize: 'big'}));
		// 	return false;
		}else if(ev.key === 'ArrowDown'){
			return moveBottom(false);
		}else if(currentBannerSize.current === 'small' && isUp){
			return moveTop(false);
		}else if(currentBannerSize.current === 'medium' && isUp){
			dispatch(CommonActions.clearFocusedBannerItem());
			setTimeout(() => {
				if(!Spotlight.focus("bannerBtn")){
					Spotlight.focus("bannersummaryBtn")
				}
			}, 100);
			ev.stopPropagation();
			ev.preventDefault();
		}
		return false;
	}, [dispatch, moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		if(prevPanels && prevPanels.length > 0){
			return;
		}
		if(ev.deltaY < 0){
			onClickTopGuide(null, currentBannerSize.current);
		}else{
			onClickBottomGuide(null, currentBannerSize.current);
		}
	}, [prevPanels, onClickTopGuide, onClickBottomGuide]);

	const handleKeydown = useCallback((ev) => {
		if(prevPanels && prevPanels.length > 0){
			return;
		}
		if(ev && (ev.key >= 0 && ev.key <=9)){
			if(debugKey.current.length >= Config.DEBUG_KEY.length){
				debugKey.current.shift();
			}
			debugKey.current.push(String(ev.key));
			if(debugKey.current.join('') === Config.DEBUG_KEY){
				dispatch(PanelActions.pushPanel('debugpanel'));
			}
			if(debugKey.current.join('') === Config.TESTPANEL_KEY){
				dispatch(PanelActions.pushPanel('servicetest'));
			}
		}
		if(ev.key === 'PageDown' || ev.key === 'PageUp'){
			ev.stopPropagation();
			ev.preventDefault();
		}
		if(ev.key === 'PageUp'){
			onClickTopGuide(null, currentBannerSize.current);
		}else if(ev.key === 'PageDown'){
			onClickBottomGuide(null, currentBannerSize.current);
		}
	}, [prevPanels, onClickTopGuide, onClickBottomGuide, onKeyDown, dispatch]);

	const supportFavBtn = useMemo(() => {
		if(supportLogin && accountId){
			return true;
		}
		return false;
	}, [supportLogin, accountId]);

	const renderList = useCallback(({title, spotlightId, viewList, supportFavBtn, ...rest}) => {
		const vList = (visibleList.indexOf(spotlightId) >= 0 && isOnTop && panels.length <=0 ) ? visibleList: [];
		if(viewList && viewList.length >0){
			return (<MediaList {...rest} visibleList={vList} title={title} supportFavBtn={supportFavBtn} spotlightId={spotlightId} viewList={viewList}
					onKeyDown={onKeyDown} onListFocus={onListFocus}/>);
		}
		return null;
	}, [isOnTop, panels, onKeyDown, onListFocus, visibleList]);

	const deleteNoElementKeys = useCallback(rest=>{
		delete rest.openStatus;
		delete rest.openFrom;
		delete rest.updatedAt;
		delete rest.cpContentsApi;
		delete rest.cpContentsAuthKey;
		delete rest.cpContentsSubtitle;
		return rest
	}, []);

	const renderList2 = useCallback(({title, id, homeGroupType, ...rest}, key) => {
		const viewList = homeGroupLists[id] ? homeGroupLists[id] : [];
		let _supportFavBtn = supportFavBtn;
		let spotlightId = id;
		let logHint = "";
		let noLimit = false;
		if(homeGroupType === 'TAG'){
			spotlightId = SpotlightIds.LIST_TAG+"_"+id;
			logHint = SpotlightIds.LIST_TAG+"_"+title;
		}
		const vList = (visibleList.indexOf(spotlightId) >= 0 && isOnTop && panels.length <=0 ) ? visibleList: [];
		if(!supportFavBtn && id === SpotlightIds.LIST_MYFAVORITE){
			return null;
		}
		if(id === SpotlightIds.LIST_MYWORKOUTROUTINE
			|| id === SpotlightIds.LIST_THIRD_PARTY
			|| rest.cpContentsApi ){
			_supportFavBtn = false;
		}
		if(id === SpotlightIds.LIST_CUSTOMIZED_WORKOUT){
			noLimit = true;
		}
		if(viewList && viewList.length >0){
			rest = deleteNoElementKeys(rest)
			if (spotlightId == SpotlightIds.LIST_YOUTUBE){
				rest["contentsYoutube"] = contentsYoutube;
			}
			return (<MediaList {...rest} homeGroupId={id} visibleList={vList} title={title} supportFavBtn={_supportFavBtn} spotlightId={spotlightId} viewList={viewList}
					onKeyDown={onKeyDown} onListFocus={onListFocus} key={key} noLimit={noLimit} logHint={logHint}/>);
		}
		return null;
	}, [isOnTop, panels, onKeyDown, onListFocus, visibleList, homeGroupLists, supportFavBtn, contentsYoutube, deleteNoElementKeys]);

	const onScrollTop = useCallback(()=>{
		scrollTo.current({ position: { x: 0, y: 0 }, animate: true });
		setBigBanner();
	},[setBigBanner]);

	const onScrollShelf = useCallback((id, tag=false)=>{
		const scroller = document.querySelector(`[data-spotlight-id="${SpotlightIds.HOME_SCROLLER}"]`);
		let listArray = scroller.children[0].children;
		let _top, _focus;
		if(tag) {
			id = SpotlightIds.LIST_TAG+"_"+id;
		}
		for (let i = 0; i < listArray.length; i++) {
			if(listArray[i].dataset.spotlightId === id) {
				_top = listArray[i].offsetTop;
				_focus = listArray[i].getAttribute('data-spotlight-id');
				break;
			}
		}
		if(_top > 0) {
			dispatch(CommonActions.changeAppStatus({homeBannerSize: 'small'}));
			scrollTo.current({position:{y: _top}, animate: true});
		}
		Spotlight.focus(_focus);
	},[dispatch]);

	return (
		<div className={css.bodyarea}>
			<HomeBanner isOnTop={isOnTop} spotlightId={SpotlightIds.HOME_BANNER} onScrollTop={onScrollTop} onScrollShelf={onScrollShelf}/>
			{!showLoadingView &&
				<Scroller
					cbScrollTo={getScrollTo}
					className={classNames(css.scroller, css[homeBannerSize])}
					spotlightId={SpotlightIds.HOME_SCROLLER}
					direction="vertical"
					horizontalScrollbar="hidden"
					verticalScrollbar="hidden"
					scrollMode="translate"
					noScrollByWheel
					noScrollByDrag
					onScrollStart={onScrollStart}
					onScrollStop={onScrollStop}
				>
					{
						homeGroup2.map((item, idx) => {
							return renderList2(item, idx);
						})
					}
					<div className={css.dummy}/>
				</Scroller>
			}
			{homeBannerSize !== 'medium' && (cursorVisible || platform.touchscreen) &&
				<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css[homeBannerSize], css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
			}
			{ (cursorVisible || platform.touchscreen)  && !scrollOnBottom &&
				<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
			}
		</div>
	);
};
export default React.memo(HomeView, shallowEqual);
