import classNames from 'classnames';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { $L } from '../../../utils/common';

import * as PanelActions from '../../../actions/panelActions';
import * as CmsActions from '../../../actions/cmsActions';
import TButton from '../../../components/TButton/TButton';
import TPopup from '../../../components/TPopup/TPopup';
import css from './SubmitPage.module.less';
import Spotlight from '@enact/spotlight';

import diet from  '../../../../assets/interview/diet_submit.png'
import strength from  '../../../../assets/interview/strength_submit.png'
import health from  '../../../../assets/interview/health_submit.png'
import posture from  '../../../../assets/interview/posture_submit.png'

import { getProgramNames, getGoals } from "../../../data/CP875Table"

const SubmitCard = ({userInfo}) => {
    const ICON = [diet, strength, health, posture]

    return(
        <div className={css.card}>
            <div className={css.cardLeft}>
                <img className={css.cardImage} src={ICON[userInfo.goal-1]}/>
            </div>
            <div className={css.cardRight}>
                <div className={css.cardItem}>
                    <div className={css.cardItemTitle}>{$L("Goal")}</div>
                    <div className={css.cardItemContent}>{getGoals()[userInfo.goal]}</div>
                </div>
                <div className={css.cardItem}>
                    <div className={css.cardItemTitle}>{$L("Workout Program")}</div>
                    <div className={css.cardItemContent}>{getProgramNames()[userInfo.program]}</div>
                </div>
                <div className={css.cardItem}>
                    <div className={css.cardItemTitle}>{$L("Difficulty")}</div>
                    <div className={css.cardItemContent}>{$L("Level {num}").replace('{num}', userInfo.level)}</div>
                </div>
            </div>
        </div>
    )
}

const SubmitPage = ({ pageInfo, restartInterview, isReady, className, modifyMode, onAnimationEnd, direction }) => {
    const dispatch = useDispatch();
    const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);

    useEffect(() => {
        if(isReady){
            Spotlight.focus('btnFinish');
            dispatch(CmsActions.updateCustomizedUserInfo(pageInfo.userInfo));
        }
    }, [isReady]);

    const onMainPage = useCallback(() => {
        dispatch(PanelActions.popPanel('interview'));
    }, []);
    const onAlarmListPage = useCallback(() => {
        //data send
        dispatch(PanelActions.popPanel('interview'));
        dispatch(PanelActions.pushPanel('alarmlist'));
    }, []);

    const onClickQuitBtn = useCallback(() => {
        if(modifyMode){
            onMainPage();
        }else{
            setShowInterviewStartPopup(true);
        }
    },[modifyMode, onMainPage])
    const onClickBtns = useCallback((ev) => {
        setShowInterviewStartPopup(false);
        if(ev == 0){
            return onAlarmListPage();
        }
        if(ev === 1 && onMainPage){
            return onMainPage();
        }
    }, [onMainPage, onAlarmListPage]);

    const _onAnimationEnd = useCallback(() => {
        if(onAnimationEnd){
            onAnimationEnd(direction)
        }
    },[onAnimationEnd, direction])

    return (
        <>
            <div className={classNames(css.main, className ? className: null, showInterviewStartPopup ? css.blur: null)} onAnimationEnd={_onAnimationEnd}>
                <div className={css.pageTitle}>{$L(pageInfo.title)}</div>
                <div className={css.subTitle}>{$L("Start working out with a customized workout program!")}</div>
                <SubmitCard userInfo={pageInfo.userInfo}/>
                <div className={css['submit-btn-container']}>
                    <TButton type="normal" spotlightId={'btnFinish'} onClick={onClickQuitBtn}>{$L("Quit")}</TButton>
                    <TButton type="normal" onClick={restartInterview}>{$L("Start again")}</TButton>
                </div>
            </div>
            {showInterviewStartPopup && <TPopup message={$L("Do you want to setting an alarm for workout routines?")} button1text={$L('Go Settings')} button2text={$L('Skip')} onClick={onClickBtns} />}
        </>
    );
};
export default SubmitPage;
