import LS2Request from './LS2Request';
import * as Config from '../data/Config';

//W6.0
let httpHeaderHandler = null;
export const getHttpHeaderForServiceRequest = (webOSVersion, language, {onSuccess, onFailure, onComplete}) => {
	const virtualPermission = (typeof window === 'object') ? window.store.getState().localSettings.virtualPermission: false;
	if(webOSVersion === 'local' || virtualPermission){
		if(Config.SUPPORT_LOGIN|| Config.USE_DUMMY){
			if(language === 'ko'){
				onSuccess({
					"X-User-Number": Config.DEBUG_WINDOW_ACCOUNTID_KR
				});
			}else{
				onSuccess({
					"X-User-Number": Config.DEBUG_WINDOW_ACCOUNTID_US
				});
			}
		}else{
			onFailure();
		}
		onComplete();
	}else {
		const isOverWebOS9 = Number(webOSVersion) >= 9;

		if (isOverWebOS9 && httpHeaderHandler) {
			httpHeaderHandler.cancel();
		  }
		  httpHeaderHandler = new LS2Request().send({
			service: 'luna://com.webos.service.sdx',
			method: 'getHttpHeaderForServiceRequest',
			subscribe: isOverWebOS9 ? true : false,
			parameters:{},
			onSuccess,
			onFailure,
			onComplete
		});
		return httpHeaderHandler;
	}
}

let deviceAccountInfo = null;
export const getDeviceAccountInfo = (webOSVersion, language, {onSuccess, onFailure, onComplete}) => {
	const virtualPermission = (typeof window === 'object') ? window.store.getState().localSettings.virtualPermission : false;
	if(webOSVersion === 'local'|| virtualPermission){
		if(Config.SUPPORT_LOGIN|| Config.USE_DUMMY){
			onSuccess({
				"id": Config.DEBUG_WINDOW_ACCOUNT_DISPLAYNAME, //6.0
				"UserID": (language === 'ko') ? Config.DEBUG_WINDOW_ACCOUNTID_KR : Config.DEBUG_WINDOW_ACCOUNTID_US, //5.0
				"DisplayUserID": Config.DEBUG_WINDOW_ACCOUNT_DISPLAYNAME, //5.0
				"returnValue": true
			});
		}else{
			onFailure();
		}
		onComplete();
	}else if(Number(webOSVersion) < 6){
		return new LS2Request().send({
			service: 'luna://com.webos.service.membership',
			method: 'getValue',
			subscribe: false,
			parameters:{"key":"/users/fitness"},
			onSuccess,
			onFailure,
			onComplete
		});
	}else{ //over W6.0
		const isOverWebOS9 = Number(webOSVersion) >= 9;

		if (isOverWebOS9 && deviceAccountInfo) {
			deviceAccountInfo.cancel();
		}
		deviceAccountInfo = new LS2Request().send({
			service: 'luna://com.webos.service.accountmanager',
			method: 'getLoginID',
			subscribe: isOverWebOS9 ? true : false,
			parameters:{"serviceName": "LGE"},
			onSuccess,
			onFailure,
			onComplete
		});

		return deviceAccountInfo;
	}
};
export const getSystemSettings = (parameters, {onSuccess, onFailure, onComplete}) => {
	const virtualPermission = (typeof window === 'object') ? window.store.getState().localSettings.virtualPermission: false;
	if (typeof window === 'object' && window.PalmSystem && !virtualPermission) {
		return new LS2Request().send({
			service: 'luna://com.webos.settingsservice',
			method: 'getSystemSettings',
			subscribe: true,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		const res = {
			"settings": {
				"smartServiceCountryCode2": Config.DEBUG_WINDOW_COUNTRY,
				"captionEnable": true
			},
			"returnValue": true
		};
		onSuccess(res);
		onComplete(res);
	}
};
export const getSystemInfo = (parameters, {onSuccess, onFailure, onComplete}) => {
	if (typeof window === 'object' && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.tv.systemproperty',
			method: 'getSystemInfo',
			subscribe: false,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		onSuccess({returnValue: true, sdkVersion: 'local'});
		onComplete();
	}
};
export const getDeviceId = (parameters, {onSuccess, onFailure, onComplete}) => {
	if (typeof window === 'object' && window.PalmSystem) {
		return new LS2Request().send({
			service: 'luna://com.webos.service.sm',
			method: 'deviceid/getIDs',
			subscribe: false,
			parameters: parameters,
			onSuccess,
			onFailure,
			onComplete
		});
	}else{
		onSuccess({returnValue: true, idList: [{idValue: Config.DEBUG_WINDOW_DEVICEID, idtype:"LGUDID"}]});
		onComplete();
	}
};
