import React, { useCallback, useEffect  , useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import platform from '@enact/webos/platform';
import css from './DebugPanel.module.less';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import * as Config from '../../../data/Config';
import * as PanelActions from '../../../actions/panelActions';
import * as CommonActions from '../../../actions/commonActions';

import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import TRadioItem from '../../../components/TRadioItem/TRadioItem';
import {$L} from '../../../utils/common';
import * as Utils from '../../../utils/common';
import appinfo from '../../../../webos-meta/appinfo.json';

const DebugPanel = props => {
	const dispatch = useDispatch();
	const appStatus = useSelector(state => state.appStatus);
	const localSettings = useSelector(state => state.localSettings);
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const accountInfo = useSelector(state => state.accountInfo);

	useEffect(() => {
		if(!props.hideChildren){
      		Spotlight.focus('debugpanel');
        }
	}, [props]);

	useEffect(() => {
		dispatch(CommonActions.getHomegymServiceInfo());
	}, [dispatch]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel('debugpanel'));
	}, [dispatch]);

	const infos = useMemo(() => {
		let v = [];
		v.push({title: 'Version(App, Service)', value: appinfo.version+"("+appStatus.homeServiceVersion+")"});
		v.push({title: 'WEBOS', value: appStatus.webOSVersion+"("+appStatus.webOSVersionReal+")"});
		v.push({title: 'platformCode', value: appStatus.otaId});
		v.push({title: 'DeviceId', value: appStatus.deviceId});
		v.push({title: 'EMPAccount(account,lang)', value: deviceAccountInfo.accountId +","+ deviceAccountInfo.language});
		v.push({title: 'CMS(account,workStyle)', value: accountInfo.accountId +","+ accountInfo.workoutStyleId});
		v.push({title: 'CMS_URL', value: Config.GYMURL(deviceAccountInfo.language,"", localSettings.useQAServerURL)});
		v.push({title: 'CameraDetected', value: String(appStatus.cameraDetected)});
		v.push({title: 'CameraName', value: appStatus.cameraDeviceName});
		v.push({title: 'CameraPermissionRequired', value: String(appStatus.cameraPermissionRequired)});
		v.push({title: ' ', value: ' '});
		return v;
	}, [appStatus, deviceAccountInfo, accountInfo]);


	const onToggleTestServer = useCallback((type) => (ev) => {
		// Utils.writeLocalStorage("Prd", !localSettings.useQAServerURL);
		dispatch(CommonActions.alertToast("Test Server:  "+ type));
		dispatch(CommonActions.changeLocalSettings({ useQAServerURL: type }))

		if(typeof window === 'object'){
			window.location.reload();
		}

	},[]);

	const switches = useMemo(() => {
		let v = [];

		v.push({title: 'Force Use PIP Camera(reboot)', value: localSettings.useForceCamera, func:(ev) => {
			dispatch(CommonActions.alertToast("Force Use PIP Camera "+!localSettings.useForceCamera));
			dispatch(CommonActions.changeLocalSettings({ useForceCamera: !localSettings.useForceCamera}));

			if(typeof window === 'object'){
				window.location.reload();
			}
		}});

		v.push({title: 'ActiveServiceDebug', value: appStatus.activeServiceDebug, func:(ev) => {
			dispatch(CommonActions.activeServiceDebug());
		}});

		//paulkim puah virtualPermission
		v.push({title: 'Virtual Permission(reboot)', value: localSettings.virtualPermission, func:(ev) => {
			dispatch(CommonActions.alertToast("DebugVirtualPermission "+!localSettings.virtualPermission));
			dispatch(CommonActions.changeLocalSettings({ virtualPermission: !localSettings.virtualPermission}));

			if(typeof window === 'object'){
				window.location.reload();
			}
		}});

		return v;
	}, [appStatus, deviceAccountInfo, accountInfo, localSettings]);

	return (
		<TPanel {...props} spotlightId={'debugpanel'} handleCancel={onBack}>
			<THeader title={appinfo.id} onClick={onBack}/>
			<div className={css.panel}>
				{infos.map((item, index) =>
					{return(
						<div className={css.titleArea} key={index}>
							<div className={css.titleBox}>
								<div className={css.text}>{item.title}</div>
							</div>
							<div className={css.textArea}>
								<div className={css.text}>{item.value}</div>
							</div>
						</div>
					)
					}
				)}

				<div className={css.titleArea} >
							<div className={css.switchs}>
								<span className={css.switchTitle}>{'Test Server(reboot)'}</span>

								<TRadioItem
									selected={localSettings.useQAServerURL==='prd'}
									onToggle={onToggleTestServer('prd')}
									>
									{$L("PRD")}
								</TRadioItem>

								<TRadioItem
									selected={localSettings.useQAServerURL === 'qa'}
									onToggle={onToggleTestServer('qa')}
									>
									{$L("QA")}
								</TRadioItem>

								<TRadioItem
									selected={localSettings.useQAServerURL === 'dev'}
									onToggle={onToggleTestServer('dev')}
									>
									{$L("DEV")}
								</TRadioItem>
								<TRadioItem
									selected={localSettings.useQAServerURL === 'twin'}
									onToggle={onToggleTestServer('twin')}
									>
									{$L("TWIN")}
								</TRadioItem>
								<TRadioItem
									selected={localSettings.useQAServerURL === 'prd_us'}
									onToggle={onToggleTestServer('prd_us')}
									>
									{$L("PRD_US(web)")}
								</TRadioItem>
							</div>
						</div>

				{switches.map((item, index) =>
					{return(
						<div className={css.titleArea} key={index}>
							<div className={css.switchs}>
								<span className={css.switchTitle}>{item.title}</span>
								<TSwitchItem selected={item.value} onToggle={item.func} className={css.switch}>{item.value ? $L('On') : $L('Off')}</TSwitchItem>
							</div>
						</div>
					)
					}
				)}
				{!platform.twinModified &&
					<div className={css.warning} >{"Patch is not applied...please rebuild source"}</div>
				}
			</div>
		</TPanel>
	);
};

export default DebugPanel;
