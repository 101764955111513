/**
 * Tooltip
 *
 * @module Tooltip
 */
import React from 'react';
import css from './Tooltip.module.less';
import {$L} from '../../utils/common';

const Tooltip = ({msg}) => {
	return (
		<div className={css.tooltipContainer}>
			<div className={css.tooltipWrap}>
				<div className={css.text}>
					{msg}
				</div>
			</div>
		</div>
	);
};

export default Tooltip;
