import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
// import Panels from '@enact/sandstone/Panels';
import {Panels} from '@enact/sandstone/Panels';
import platform from '@enact/core/platform';
import Spotlight from '@enact/spotlight';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Repeater from '@enact/ui/Repeater';
import PreloadImage from '../../components/PreloadImage';
import css from './MainView.module.less';
import LoadingPanel from '../LoadingPanel';
import MainPanel from '../MainPanel';
import PlayerPanel from '../PlayerPanel';
import MoreListPanel from '../MoreListPanel';
import MoreFormedListPanel from '../MoreFormedListPanel';
import DebugPanel from '../Settings/DebugPanel';
import GoalSettingsPanel from '../Settings/GoalSettingsPanel';
import WorkoutStylePanel from '../Settings/WorkoutStylePanel';
import AlarmSettingsPanel from '../Settings/AlarmSettingsPanel/AlarmSettingsPanel';
import AlarmListPanel from '../Settings/AlarmSettingsPanel/AlarmListPanel';
import ServiceTestPanel from '../Settings/ServiceTestPanel/ServiceTestPanel';
import * as PanelActions from '../../actions/panelActions';
import * as CommonActions from '../../actions/commonActions';
import * as CmsActions from '../../actions/cmsActions';
import * as Config from '../../data/Config';
import GoalListPanel from '../Settings/GoalSettingsPanel/GoalListPanel';
import SettingsMediaPicker from '../Settings/SettingsMediaPicker';
import CameraSettingPanel from '../Settings/CameraSettingPanel';

import SearchView from '../SearchView/SearchView';
import TermsConditions from '../TermsConditions/TermsConditions';
import TermsConditionsDetail from '../TermsConditions/TermsConditionsDetail';
import TAlert from '../../components/TAlert';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import * as CP875Table from '../../data/CP875Table';
import SpotlightIds from '../../data/SpotlightIds';
import MyWorkoutRoutineSettingsPanel from '../Settings/MyTrainingPlan/MyWorkoutRoutineSettingsPanel';
import MyWorkoutRoutineListPanel from '../Settings/MyTrainingPlan/MyWorkoutRoutineListPanel';
import EditTitlePanel from '../Settings/MyTrainingPlan/EditTitlePanel';

// preloadImages
import RED_BG from '../../../assets/banner/red_bg.png';
import VIOLET_BG from '../../../assets/banner/violet_bg.png';
import violet_bannerCoverBig from '../../../assets/banner/violet_videocover_big.png';
import violet_bannerCoverMedium from '../../../assets/banner/violet_videocover_medium.png';
import violet_bannerCoverSmall from '../../../assets/banner/violet_videocover_small.png';
import red_bannerCoverBig from '../../../assets/banner/red_videocover_big.png';
import red_bannerCoverMedium from '../../../assets/banner/red_videocover_medium.png';
import red_bannerCoverSmall from '../../../assets/banner/red_videocover_small.png';
import PREPARE_IMAGE_KO from '../../../assets/dashboard/dashboard_prepare_ko.png';
import PREPARE_IMAGE_EN from '../../../assets/dashboard/dashboard_prepare_en.png';
import TermsModification from '../TermsConditions/TermsModification';
import TToast from '../../components/TToast/TToast';
import TPopup from '../../components/TPopup/TPopup';
import NoticePanel from '../Settings/NoticePanel/NoticePanel';

//승훈 추가
import InterviewView from '../InterviewView/InterviewView';
import InterviewModifyView from '../InterviewView/InterviewModifyView';

const PanelMap = {
	loading: LoadingPanel,
	workoutstyle: WorkoutStylePanel,
	main: MainPanel,
	goalsettings: GoalSettingsPanel, //goal setting
	goallist: GoalListPanel, //goal setting
	settingsMediaPicker: SettingsMediaPicker,
	alarmsettings: AlarmSettingsPanel, //alarm setting
	alarmlist: AlarmListPanel, //alarm setting
	player: PlayerPanel,
	servicetest: ServiceTestPanel,
	search : SearchView,
	termsConditions : TermsConditions,
	termsConditionsDetail: TermsConditionsDetail,
	termsModification: TermsModification,
	morelist: MoreListPanel,
	moreformedlist: MoreFormedListPanel,
	cameraSetting: CameraSettingPanel,
	myworkoutroutinesettings: MyWorkoutRoutineSettingsPanel,
	myworkoutroutinelist: MyWorkoutRoutineListPanel,
	edittitle: EditTitlePanel,
	debugpanel: DebugPanel,
	notice: NoticePanel,
	interview: InterviewView,
	interviewModify: InterviewModifyView
};

const preloadImages = [
	RED_BG,
	VIOLET_BG,
	violet_bannerCoverBig,
	violet_bannerCoverMedium,
	violet_bannerCoverSmall,
	red_bannerCoverBig,
	red_bannerCoverMedium,
	red_bannerCoverSmall,
	PREPARE_IMAGE_KO,
	PREPARE_IMAGE_EN
];

const MainView = ({initService,...props}) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();
	const panels = useSelector(state => state.panels, shallowEqual);
	const launchAction = useSelector(state => state.launchAction, shallowEqual);
	const appStatus = useSelector(state => state.appStatus, shallowEqual);
	const isInternetConnected = useSelector(state => state.appStatus.isInternetConnected, shallowEqual);
	const connectionFailed = useSelector(state => state.appStatus.connectionFailed, shallowEqual);
	const customScheduleFinished = useSelector(state => state.appStatus.customScheduleFinished, shallowEqual);
	const showLoadingView = useSelector(state => state.showLoadingView.show, shallowEqual);
	const [showNetworkError, setShowNetworkError] = useState(!isInternetConnected);
	const [showConnectionError, setShowConnectionError] = useState(connectionFailed);
	const [showScheduleEndedPopup, setShowScheduleEndedPopup] = useState(customScheduleFinished);
	const customizedUserInfo = useSelector(state => state.customizedUserInfo);

	const isOnTop = useMemo(() => {
		return (!showNetworkError && !showConnectionError && panels.length <= 0 && !showLoadingView);
	}, [showNetworkError, showConnectionError, showLoadingView, panels]);

	const noAnimation = useMemo(() => {
		if(!panels || !panels[panels.length - 1]){
			return true;
		}
		if(panels[panels.length - 1].name === 'loading' || panels[panels.length - 1].name === 'termsConditions' || panels[panels.length - 1].name === 'player'){
			return true;
		}
		return false;
	}, [panels]);

	const cursorStateChange = useCallback((ev) => {
		dispatch(CommonActions.changeAppStatus({cursorVisible: ev.visibility || ev.detail.visibility}));
	}, [dispatch]);

	useEffect(() => {
		document.addEventListener('cursorStateChange', cursorStateChange, false);
		if(platform.platformName !== 'webos'){//for debug
			dispatch(CommonActions.changeAppStatus({cursorVisible: true}));
		}else if (typeof window === 'object' && window.PalmSystem && window.PalmSystem.cursor) {
			dispatch(CommonActions.changeAppStatus({cursorVisible: window.PalmSystem.cursor.visibility}));
		}
		return () => {
			document.removeEventListener('cursorStateChange', cursorStateChange);
		};
	}, []);

	useEffect(() => {
		if (!isInternetConnected) {
			previousFocus.current = Spotlight.getCurrent();
			setShowNetworkError(true);
			if (panels.length !== 0) {
				dispatch(PanelActions.resetPanels());
			}
		}else if(connectionFailed){
			previousFocus.current = Spotlight.getCurrent();
			setShowConnectionError(true);
			if (panels.length !== 0) {
				dispatch(PanelActions.resetPanels());
			}
		}else if(customScheduleFinished){
			previousFocus.current = Spotlight.getCurrent();
			setShowScheduleEndedPopup(true);
		}
	}, [isInternetConnected, connectionFailed, customScheduleFinished]);

	useEffect(() => {
		console.log('launchAction changed',launchAction);
		const panel = panels[panels.length-1];
		// only for player
		if(launchAction.type === "pause10sec" && (!panel || panel.name !== 'player')){
			dispatch(CommonActions.clearLaunchAction());
		}
	}, [dispatch, launchAction]);

	const onClickNetworkError = useCallback((ev) => {
		if (ev === 0) {
			Spotlight.focus(SpotlightIds.MAIN_PANEL);
			Spotlight.focus(previousFocus.current);
			if(initService){
				initService();
			}
			setShowNetworkError(false);
			setShowConnectionError(false);
			dispatch(CommonActions.changeAppStatus({connectionFailed: false}));
		}else{
			Utils.windowClose();
		}
	}, [dispatch, initService]);

	const onClickNoticeAlert = useCallback((ev) => {
		if (ev === 0) {
			dispatch(CmsActions.getNoticeList());
			Utils.writeLocalStorage('noticeId', appStatus.noticeId);
			dispatch(CommonActions.changeAppStatus({ noticeAlert: false }));
			dispatch(PanelActions.pushPanel('notice', { onNoticeClick : true}));
		} else {
			Utils.writeLocalStorage('noticeId', appStatus.noticeId);
			dispatch(CommonActions.changeAppStatus({ noticeAlert: false }));
			Spotlight.focus(SpotlightIds.MAIN_PANEL);
			Spotlight.focus(previousFocus.current);
		}
	}, [dispatch, appStatus]);

	const onSchedulePopupAction = useCallback((ev) => {
		const now = Utils.timeToISO8601Str(new Date()); // 설문일자
		dispatch(CommonActions.changeLocalSettings({ customizedProgramInfoCreatedAt: now }));
		setShowScheduleEndedPopup(false);
		Spotlight.focus(previousFocus.current);
		setTimeout(() => {
			dispatch(CmsActions.getCustomizedWorkOutSchedule());
			if (ev === 1){  //change
				dispatch(PanelActions.pushPanel('interviewModify'));
			}
		}, 10);
	}, [dispatch]);

	const popupStatus = useMemo(()=>{
		const ret = {
			networkError: {show: false},
			connectionError: {show: false},
			noticeAlert: {show: false},
			scheduleEnded: {show: false, message: ""}
		};
		if(showNetworkError){
			ret.networkError.show = true;
		}else if(showConnectionError){
			ret.connectionError.show = true;
		}else if(appStatus.noticeAlert && panels.length === 0){
			ret.noticeAlert.show = true;
		}else if(showScheduleEndedPopup  && panels.length === 0 && appStatus.homeGroupHasCustomizedWorkout){
			ret.scheduleEnded.show = true;
			ret.scheduleEnded.message = $L("Would you like to maintain{br}a <b>{program}</b> program?").replace('{program}', CP875Table.getProgramNames()[customizedUserInfo.program]);
		}
		return ret;
	},[showNetworkError, showConnectionError, showScheduleEndedPopup, appStatus, panels, customizedUserInfo]);

	return (
		<div {...props}>
			{panels.length > 0 &&
				<Panels {...props} noCloseButton index={(panels.length - 1)} noAnimation={noAnimation}>
					{
						panels.map(item => {
							const Component= PanelMap[item.name];
							return <Component key={item.name} panelInfo={item.panelInfo} />;
						})
					}
				</Panels>
			}
			<MainPanel isOnTop={isOnTop}/>
			<Repeater className={css.repeater} childComponent={PreloadImage} >{preloadImages}</Repeater>
			{popupStatus.networkError.show &&
				<TAlert message={$L("Failed to connect to LG Fitness. Please check your device’s network connection.")}
					button1text={isInternetConnected ? $L("Retry"): ""} button2text={$L("Exit")} onClick={onClickNetworkError}/>
			}
			{popupStatus.connectionError.show &&
				<TAlert message={$L("An unknown error has occured.")}
				button1text={$L("Retry")} button2text={$L("Exit")} onClick={onClickNetworkError}/>
			}
			{appStatus.toast &&
				<TToast children={appStatus.toastText} />
			}
			{popupStatus.noticeAlert.show &&
				<TAlert message={appStatus.noticeAlertText} noticeAlert={true} button1text={$L("OK")} button2text={$L("Close")} onClick={onClickNoticeAlert}/>
			}
			{popupStatus.scheduleEnded.show &&
				<TPopup title={$L("The program schedule has ended.")} message={popupStatus.scheduleEnded.message} button1text={$L("Keep")} button2text={$L("Change")} onClick={onSchedulePopupAction} />
			}
			<LoadingPanel/>
		</div>
	);
};

export default MainView;
