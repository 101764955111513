import React from 'react';
import Image from '@enact/sandstone/Image';
import PropTypes from 'prop-types';

import css from './PreloadImage.module.less';

const PreloadImage = ({children}) => (<Image src={children} className={css.visibleHidden} />);

PreloadImage.propTypes = {
	children: PropTypes.string.isRequired
};

export default PreloadImage;
