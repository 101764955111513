import Spotlight from '@enact/spotlight';
import { useDispatch } from 'react-redux';
import { InputField } from '@enact/sandstone/Input';
import React, { useCallback, useEffect, useState } from 'react';
import css from './EditTitlePanel.module.less';
import THeader from '../../../components/TPanel/THeader';
import TPanel from '../../../components/TPanel/TPanel';
import { $L } from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import TButton from '../../../components/TButton/TButton';

const EditTitlePanel = ({ panelInfo, hideChildren, ...rest }) => {
	const dispatch = useDispatch();
	const [changedTitle, setChangedTitle] = useState();

	const defaultTitle = panelInfo.title;
	const headerTitle = $L('My Workout Routine') + " > " + $L('Edit Title');

	useEffect(() => {
		if (!hideChildren) {
			Spotlight.focus('InputField');
		}
	}, [hideChildren]);

	useEffect(() => {
		setChangedTitle(panelInfo.title);
	}, [panelInfo]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { isEditMode: panelInfo.isEditMode, planId: panelInfo.planId, title: defaultTitle }));
		dispatch(PanelActions.popPanel('edittitle'));
	}, [dispatch, defaultTitle, panelInfo]);

	const onChangeTitle = useCallback((ev) => {
		setChangedTitle(ev.value);
	}, []);

	const onHandleInputField = useCallback((ev) => {
		if (ev.key === 'Enter') {
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { isEditMode: panelInfo.isEditMode, planId: panelInfo.planId, title: changedTitle }));
			dispatch(PanelActions.popPanel('edittitle'));
		}
	}, [dispatch, changedTitle, panelInfo]);

	const onDone = useCallback(() => {
		onHandleInputField({ key: 'Enter' });
	}, [onHandleInputField]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={headerTitle} onClick={onBack}/>
			<InputField
				className={css.InputField}
				spotlightId={'InputField'}
				value={changedTitle}
				autoFocus
				dismissOnEnter
				onKeyDown={onHandleInputField}
				onChange={onChangeTitle} />
			<div className={css.button} >
				<TButton onClick={onDone}>{$L("Done")}</TButton>
			</div>
		</TPanel>
	);
};

export default EditTitlePanel;