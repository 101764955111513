/**
 * Custom Image
 *
 * @module CustomImage
 */
import React, {useCallback, useEffect, useState, useRef} from 'react';
import classNames from 'classnames';
import {Image} from '@enact/sandstone/Image';
import {Job} from '@enact/core/util';
import css from './CustomImage.module.less';

const CustomImage = ({src, className, delay=100, hide, onClickBanner, onImageLoaded}) => {
	const [imageLoaded, setImageLoaded] = useState(false);
	const [renderComplete, setRenderComplete] = useState(false);

	const showImageJob = useRef(new Job((setImageLoaded) => {
		setImageLoaded(true);
	}, delay));

	useEffect(() => {
		setTimeout(() => {
			setRenderComplete(true);
		}, 100);
	}, []);

	useEffect(() => {
		if(onImageLoaded && imageLoaded) {
			onImageLoaded(true);
		}
		return()=>{
			if(onImageLoaded){
				onImageLoaded(false);
			}
		}
	}, [imageLoaded]);

	useEffect(() => {
		setImageLoaded(false);
		showImageJob.current.stop();
	}, [src]);

	const onLoad = useCallback(() => {
		showImageJob.current.start(setImageLoaded);
	}, []);

	const onError = useCallback(() => {
	}, []);

	const onClick = useCallback(() => {
		if(renderComplete){
			onClickBanner();
		}
	}, [renderComplete, onClickBanner]);

	if(!src){
		return null;
	}
	return (
		<div className={css.imgWrap} onClick={onClick}>
			<Image className={classNames(css.customimage, className, (!imageLoaded || hide) ? css.hidden: null)} src={src} onLoad={onLoad} onError={onError}/>
		</div>
	);
};

export default CustomImage;
