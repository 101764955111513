import React, { useState, useCallback, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import css from './GoalListItem.module.less';
import GoalContentList from './GoalContentList';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import TContainer from '../TContainer/TContainer';
import { types } from '../../actions/actionTypes';
import * as Utils from '../../utils/common';
import {$L} from '../../utils/common';
import Icon from '@enact/sandstone/Icon';

const GoalListItem = ({ title, info, isEditMode, spotlightId, ...rest }) => {
	const dispatch = useDispatch();

	const [activated, setActivated] = useState(false);
	const [goalInfos, setGoalInfos] = useState([]);
	const [forceFocus, setForceFocus] = useState(false);

	const goalId = info && info.length !== 0 ? info[0].goalId : '';

	useEffect(() => {
		let DAYS = [0, 1, 2, 3, 4, 5, 6];
		let goalInfoList = [];
		if (info && info.length !== 7) {
			info.map(item => {
				const index = DAYS.indexOf((item.day));
				if (index > -1) {
					DAYS.splice(index, 1);
				}
			});
		} else {
			DAYS = [];
		}
		if (info && info.length > 0) {
			setActivated(info[0].alarmActivated);
		} else {
			setActivated(false);
		}

		if (info && info.length > 0) {
			if (info.length !== 7) {
				//empty days
				DAYS.map(item => {
					let noGoal = {
						disabled: true,
						alarm: { hours: "0", minutes: "0" },
						day: item,
						title: info && info[0].title,
						goalId: info && info[0].goalId
					}
					goalInfoList.push(noGoal);

				});
				info.map(item => {
					goalInfoList.push(item);
				});
				goalInfoList.sort((a, b) => {
					if (a.day > b.day) {
						return 1;
					}
					if (a.day < b.day) {
						return -1;
					}
					return 0;
				});
			} else if(info){
				info.map(item => {
					goalInfoList.push(item);
				});
			}
			//check validation
			if(goalInfoList.length > 7){
				console.warn('GoalListItem goalInfoList is invalid. length ',goalInfoList.length);
				const checkedDays = [];
				const validList = [];
				for(let i=0; i<goalInfoList.length; i++){
					if(checkedDays.indexOf(goalInfoList[i].day) < 0){
						validList.push(goalInfoList[i]);
						checkedDays.push(goalInfoList[i].day);
					}
				}
				goalInfoList = validList;
			}
			setGoalInfos(goalInfoList);
		}
	}, [info]);

	const onSwitchToggle = useCallback((ev) => {
		setActivated(ev.selected);
		let goalValues = [];
		if (info) {
			const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
			for (let i = 0; i < info.length; i++) {
				const goal = {
					title: title,
					alarmActivated: ev.selected,
					items: {
						weekday: DAYS[info[i].day],
						time: Utils.transTimeToText(info[i].alarm.hours, info[i].alarm.minutes)
					},
					alarms: {
						activated: info[i].disabled,
						time: Utils.transTimeToText(info[i].alarm.hours, info[i].alarm.minutes)
					}
				}
				goalValues.push(goal);
				if ("contentId" in info[i].items) {
					goal.items.contentId = info[i].items.contentId
				} else {
					goal.items.planId = info[i].items.planId
				}
			}
		}
		dispatch(CmsActions.updateGoalOfAccount(goalValues, goalId, ev.selected));
	}, [dispatch, goalId, info]);

	const onItemClick = useCallback(() => {
		dispatch(PanelActions.pushPanel('goalsettings', {isEditMode: isEditMode, title, info, goalId, activated }));
	}, [dispatch, isEditMode, title, info, goalId, activated]);

	const onTContainerFocus = useCallback(() => {
		setForceFocus(true);
	},[]);

	const onTContainerBlur = useCallback(() => {
		setForceFocus(false);
	},[]);

	return (
		<TContainer
			{...rest}
			onClick={onItemClick}
			isContentExist={info && info.length !== 0}
			title={title === "goal1" ? $L('Goal 1') : $L('Goal 2')}
			spotlightId={spotlightId}
			activated={activated}
			onToggle={onSwitchToggle}
			onFocus={onTContainerFocus}
			onBlur={onTContainerBlur}
			focus={forceFocus}
			switchDisabled={info && info.length ===0}
		>
			{info && info.length !==0 ?
				<div className={css.infoContainer}>
					<div className={css.goalList}>
						{goalInfos && goalInfos.map(item =>
							<GoalContentList key={item.day} forceFocus={forceFocus} contentInfo={item} />
						)}
					</div>
					<div className={css.editText}>{$L('Press Ok to edit this Goal')}</div>
				</div>
				:
				<div className={css.infoContainer}>
						<div className={css.iconWrap}>
							<Icon className={css.icon} size={160}>plus</Icon>
						</div>
					<div className={css.addText}>{$L('Set Another Goal')}</div>
				</div>
			}
		</TContainer>
	);
};

export default GoalListItem;