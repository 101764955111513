export const types = {
  SHOW_HIDE_LOADING_SPINNER: 'SHOW_HIDE_LOADING_SPINNER',

  // Panel Actions
  PUSH_PANEL: 'PUSH_PANEL',
  UPDATE_PANEL: 'UPDATE_PANEL',
  SET_PANEL_RESULT: 'SET_PANEL_RESULT',
  CLEAR_PANEL_RESULT: 'CLEAR_PANEL_RESULT',
  POP_PANEL: 'POP_PANEL',
  RESET_PANELS: 'RESET_PANELS',
  SET_MAINPANEL_INDEX: 'SET_MAINPANEL_INDEX',
  SET_MAINPANEL_CURRENTSPOT: 'SET_MAINPANEL_CURRENTSPOT',

  INIT_LOADING_STATUS: 'INIT_LOADING_STATUS',
  ADD_LOADING_STATUS: 'ADD_LOADING_STATUS',
  ADD_LOADING_SPINNER_STATUS: 'ADD_LOADING_SPINNER_STATUS',
  REMOVE_LOADING_STATUS: 'REMOVE_LOADING_STATUS',
  REMOVE_LOADING_SPINNER_STATUS: 'REMOVE_LOADING_SPINNER_STATUS',
  DEFAULT_LOADING_SPINNER_STATUS: 'DEFAULT_LOADING_SPINNER_STATUS',

  // Cms Actions
  GET_WORKSTYLES_LIST: 'GET_WORKSTYLES_LIST',
  GET_BACKGROUND: 'GET_BACKGROUND',
  GET_ACCOUNT_INFO: 'GET_ACCOUNT_INFO',
  GET_CATEGORIES_TABLE: 'GET_CATEGORIES_TABLE',
  GET_ACTIVE_BANNER: 'GET_ACTIVE_BANNER',
  GET_CATEGORIES_CONTENTS: 'GET_CATEGORIES_CONTENTS',
  GET_CONTENTS_CATEGORY: 'GET_CONTENTS_CATEGORY',
  GET_HOME_GROUP: 'GET_HOME_GROUP',
  GET_HOME_GROUP2: 'GET_HOME_GROUP2',
  GET_CONTENTS_TOPS: 'GET_CONTENTS_TOPS',
  GET_CONTENTS_RECOMMENDED: 'GET_CONTENTS_RECOMMENDED',
  GET_CONTENTS_RECENTLY_WATCHED: 'GET_CONTENTS_RECENTLY_WATCHED',
  GET_CONTENTS_RECENTLY_ADDED: 'GET_CONTENTS_RECENTLY_ADDED',
  GET_CONTENTS_MY_FAVORITES: 'GET_CONTENTS_MY_FAVORITES',
  GET_ORDERED_TAG_LIST: 'GET_ORDERED_TAG_LIST',
  GET_CONTENTS_TAG: 'GET_CONTENTS_TAG',
  GET_CONTENT_INFO: 'GET_CONTENT_INFO',
  GET_CONTENTS_RELATED: 'GET_CONTENTS_RELATED',
  CLEAR_CONTENTS_RELATED: 'CLEAR_CONTENTS_RELATED',
  GET_GOALS_OF_ACCOUNT: 'GET_GOALS_OF_ACCOUNT',
  GET_GOAL_OF_ACCOUNT: 'GET_GOAL_OF_ACCOUNT',
  GET_YOUTUBE_VIDEOS: 'GET_YOUTUBE_VIDEOS',
  GET_YOUTUBE_CHANNELS: 'GET_YOUTUBE_CHANNELS',
  GET_YOUTUBE_SEARCH: 'GET_YOUTUBE_SEARCH',
  GET_YOUTUBE_SEARCH_RESET: 'GET_YOUTUBE_SEARCH_RESET',
  UPDATE_YOUTUBE_VIDEOINFO: 'UPDATE_YOUTUBE_VIDEOINFO',
  GET_CONTENTS_KEYWORD: 'GET_CONTENTS_KEYWORD',
  GET_CONTENTS_KEYWORD_RESET: 'GET_CONTENTS_KEYWORD_RESET',
  GET_YOUTUBE_CHANNELIDS: 'GET_YOUTUBE_CHANNELIDS',
  GET_ALARM_OF_ACCOUNT: 'GET_ALARM_OF_ACCOUNT',
  CLEAR_ALARM_AND_GOALS: 'CLEAR_ALARM_AND_GOALS',
  GET_WORKOUT_SUMMARY: 'GET_WORKOUT_SUMMARY',
  GET_WORKOUT_SUMMARY_WEEK: 'GET_WORKOUT_SUMMARY_WEEK',
  GET_GOAL_ACHIEVEMENT: 'GET_GOAL_ACHIEVEMENT',
  GET_GOAL_ACHIEVEMENT_WEEK: 'GET_GOAL_ACHIEVEMENT_WEEK',
  GET_CONTENT_USAGES: 'GET_CONTENT_USAGES',
  SELECT_CONTENT_INFO: 'SELECT_CONTENT_INFO',
  GET_PLANS_OF_ACCOUNT: 'GET_PLANS_OF_ACCOUNT',
  GET_TERMS_MODIFICATION: 'GET_TERMS_MODIFICATION',
  GET_NOTICE_LIST: 'GET_NOTICE_LIST',
  GET_CUSTOMIZED_WORKOUT_SCHEDULE: 'GET_CUSTOMIZED_WORKOUT_SCHEDULE',
  GET_FLOATING_MESSAGE_LIST: 'GET_FLOATING_MESSAGE_LIST',

  // list Actions
  GET_CATEGORIES_TABLE_SELECTED: 'GET_CATEGORIES_TABLE_SELECTED',
  GET_WORKOUTSTYLE_TABLE_SELECTED: 'GET_WORKOUTSTYLE_TABLE_SELECTED',
  GET_FOCUSED_BANNER_ITEM: 'GET_FOCUSED_BANNER_ITEM',
  GET_PLAYER_INFO: 'GET_PLAYER_INFO',

  // Common Actions
  GET_DEVICE_ACCOUNT_INFO: 'GET_DEVICE_ACCOUNT_INFO',
  GET_TERMS_STATUS: 'GET_TERMS_STATUS',
  GET_TERMS_DOC: 'GET_TERMS_DOC',
  GET_HTTP_HEADER: 'GET_HTTP_HEADER',

  //todo test
  // homegym service test
  HOMEGYM_SERVICE_TEST: 'HOMEGYM_SERVICE_TEST',

  // Common Actions types
  CHANGE_APP_STATUS: 'CHANGE_APP_STATUS',
	CHANGE_LOCAL_SETTINGS: 'CHANGE_LOCAL_SETTINGS',
  UPDATE_ADMIN_PREVIEW_DATA: 'UPDATE_ADMIN_PREVIEW_DATA',
  RECEIVE_GET_EFFECTIVE_BROADCAST_TIME: 'RECEIVE_GET_EFFECTIVE_BROADCAST_TIME',
  SET_LAUNCHING_MODE: 'SET_LAUNCHING_MODE',

  // device Actions
  GET_LIST_DEVICES: 'GET_LIST_DEVICES',
  GET_DEVICE_INFORMATION_UDNS: 'GET_DEVICE_INFORMATION_UDNS',
  DEVICE_CHANGED: 'DEVICE_CHANGED',

  // video actions
  GET_VIDEO_PROGRESS_THUMBNAIL: 'GET_VIDEO_PROGRESS_THUMBNAIL',
  CLEAR_VIDEO_PROGRESS_THUMBNAIL: 'CLEAR_VIDEO_PROGRESS_THUMBNAIL',

  // temp action
  SET_TEMPORARY_GOAL: 'SET_TEMPORARY_GOAL',
  RESET_TEMPORARY_GOAL: 'RESET_TEMPORARY_GOAL',
  SET_TEMPORARY_PLAN: 'SET_TEMPORARY_PLAN',
  RESET_TEMPORARY_PLAN: 'RESET_TEMPORARY_PLAN',

  // launch action
  GET_LAUNCH_ACTION: 'GET_LAUNCH_ACTION',
  CLEAR_LAUNCH_ACTION: 'CLEAR_LAUNCH_ACTION',

  GET_CUSTOMIZED_USERINFO: 'GET_CUSTOMIZED_USERINFO',
  GET_CONTENTS_THIRDPARTY_APPS: 'GET_CONTENTS_THIRDPARTY_APPS'
}
