import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import css from './WorkoutStylePanel.module.less';
import * as PanelActions from '../../../actions/panelActions';
import TPanel from '../../../components/TPanel';
import TableList from '../../../components/TableList';
import {$L} from '../../../utils/common';
import SpotlightIds from '../../../data/SpotlightIds';
import Spotlight from '@enact/spotlight';
import THeader from '../../../components/TPanel/THeader';

const WorkoutStylePanel = props => {
	const dispatch = useDispatch();
	const accountInfo = useSelector(state => state.accountInfo);
	useEffect(() => {
        if(!props.hideChildren){
			Spotlight.focus(SpotlightIds.LIST_WORKOUTSTYLE_TABLE);
        }
	}, [props.hideChildren]);
	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if(accountInfo.accountId){
			dispatch(PanelActions.popPanel('workoutstyle'));
		}
	}, [dispatch, accountInfo]);
	return (
		<TPanel {...props} handleCancel={onBack} className={css.panel}>
			{accountInfo.accountId &&
				<THeader onClick={onBack}/>
			}
			<div className={css.verticalCenter}>
				{!accountInfo.accountId ?
					<div className={css.title}>{$L('Choose your workout preferred workout')}</div>
					:
					<div className={css.title}>{$L('Change your workout preferred workout')}</div>
				}
				<TableList isOnTop className={css.styleList} spotlightId={SpotlightIds.LIST_WORKOUTSTYLE_TABLE} />
			</div>
		</TPanel>
	);
};

export default WorkoutStylePanel;