import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Scroller from '@enact/sandstone/Scroller';
import Spotlight from '@enact/spotlight';
import THeader from '../../../components/TPanel/THeader';
import TPanel from '../../../components/TPanel/TPanel';
import css from './NoticePanel.module.less';
import { $L } from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import classNames from 'classnames';
import * as Utils from '../../../utils/common';
import NoticeItem from './Notice/NoticeItem';
import NoticeAlert from './Notice/NoticeAlert';

const NoticePanel = ({ hideChildren, panelInfo, ...rest }) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();
	const noticeList = useSelector(state => state.noticeList, shallowEqual);
	const [TAlertOpen, setTAlertOpen] = useState(false);
	const [title, setTitle] = useState();
	const [notice, setNotice] = useState();
	const [dateStr, setDateStr] = useState();
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [lastIndex, setLastIndex] = useState(false);

	useEffect(() => {
		if (!hideChildren) {
			Spotlight.focus("backButton");
			Spotlight.focus("noticeList");
			if (noticeList.length !==0 && panelInfo.onNoticeClick) {
				onClickList(0);
			}
		}
	}, [hideChildren, noticeList]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel('notice'));
	}, [dispatch]);

	const setPopupInfo = useCallback((selectIndex) => {
		setTitle(noticeList[selectIndex].title);
		setNotice(noticeList[selectIndex].notice);
		const openDay = Utils.convertUTCtoLocalTime(new Date(noticeList[selectIndex].openFrom));
		setDateStr(openDay.toLocaleDateString("default", { year: 'numeric', month: 'short', day: 'numeric' }));
		setCurrentIndex(selectIndex);
	},[noticeList]);

	const onClickList = useCallback((ev) => {
		setPopupInfo(ev);
		setTAlertOpen(true);
		setLastIndex(ev === noticeList.length - 1 ? true : false);
		previousFocus.current = Spotlight.getCurrent();
	});

	const onCloseTAlert = useCallback(() => {
		setTAlertOpen(false);
		setLastIndex(false);
		Spotlight.focus(previousFocus.current);
	});

	const onClickTAlert = useCallback((ev) => {
		if (ev === 0) {
			onCloseTAlert();
		} else if (ev === 1) {
			if (currentIndex > 0) {
				setPopupInfo(currentIndex - 1);
				setLastIndex(false);
			}
		} else if (ev === 2) {
			if (currentIndex < noticeList.length - 1) {
				setPopupInfo(currentIndex + 1);
				if (currentIndex === noticeList.length - 2) {
					setLastIndex(true);
				}
			}
		}
	}, [currentIndex, setPopupInfo]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={$L('Notice')} onClick={onBack} />
			<Scroller
				scrollMode="translate"
				className={css.scroller}
				direction={'vertical'}
				overscrollEffectOn={{
					arrowKey: false,
					drag: false,
					pageKey: false,
					track: false,
					wheel: false
				}}
			>
			<div className={classNames(css.list)}>
			{noticeList.map((item, index) => {
				return <NoticeItem onClick={onClickList} key={index} noticeData={item} index={index}/>
			})}
			{noticeList.length <=0 &&
				<div className={css.empty_comment}>{$L('You will see Notice here.')}</div>
			}
			</div>
			</Scroller>
			{TAlertOpen &&
				<NoticeAlert title={title} message={notice} date={dateStr} currentIndex={currentIndex} lastIndex={lastIndex} button1text={$L("OK")} onClick={onClickTAlert}/>
			}
		</TPanel>
	);
};

export default NoticePanel;