/**
 * TRadioItem
 *
 * @module TRadioItem
 */
import classNames from 'classnames';
import React from 'react';
import css from './TRadioItem.module.less';
import RadioItem from '@enact/sandstone/RadioItem';

 const TRadioItem = ({className, children, selected, ...rest}) => {
     return (
         <RadioItem
                 className={classNames(className, css.tradio, selected ? css.selected : null)}
                 {...rest}
             >{children}
         </RadioItem>
     );
 };

 export default TRadioItem;
