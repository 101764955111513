import React, {useState, useCallback, useEffect  , useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import css from './CameraSettingPanel.module.less';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton/TButton';
import * as PanelActions from '../../../actions/panelActions';
import * as CmsActions from '../../../actions/cmsActions';
import * as CommonActions from '../../../actions/commonActions';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import {$L} from '../../../utils/common';
import * as LogTypes from '../../../data/LogTypes';
import CheckBoxItem from '@enact/sandstone/CheckboxItem';

const CameraSettingPanel = props => {
	const dispatch = useDispatch();
	const [activated, setActivated] = useState(true);
	const localSettings = useSelector(state => state.localSettings);
	const cameraDeviceName = useSelector(state => state.appStatus.cameraDeviceName);
	const cameraDetected = useSelector(state => state.appStatus.cameraDetected);
	const [size, setSize] = useState('small');
	const [position, setPosition] = useState('rt');
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);

	useEffect(() => {
		if(!props.hideChildren && localSettings){
      Spotlight.focus('camerasettingpanel');
			Spotlight.focus('alarm');
			setActivated(localSettings.cameraActivated);
			setSize(localSettings.cameraSize);
			setPosition(localSettings.cameraPosition);
		}
	}, [props, localSettings]);
	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel('cameraSetting'));
	}, [dispatch]);

	const onState = useCallback((ev) => {
		setActivated(ev.selected);
		setIsBtnDisabled(false);
	},[]);

	const onSelecSize = useCallback((_size) => (ev) => {
		if(ev.selected){
			setSize(_size);
			setIsBtnDisabled(false);
		}
	},[]);
	const onSelecPosition = useCallback((_position) => (ev) => {
		if(ev.selected){
			setPosition(_position);
			setIsBtnDisabled(false);
		}
	},[]);

	const onDone = useCallback(() => {
		dispatch(CommonActions.changeLocalSettings({ cameraActivated: activated, cameraSize: size, cameraPosition: position }))
		dispatch(CmsActions.appLog(LogTypes.Types.CAMERASETTING,"Settings",activated,size,position));
		dispatch(PanelActions.popPanel('cameraSetting'));
	  }, [dispatch, activated, size, position]);

	const connectionText = useMemo(() => {
		let text=$L('Not Connected');
		if(cameraDeviceName){
			text = $L('{Model Name} Connected.').replace('{Model Name}', cameraDeviceName);
		}
		else if(cameraDetected){
			text=$L('Connected');
		}
		return text;
	}, [cameraDeviceName, cameraDetected]);

	return (
		<TPanel {...props} spotlightId={'camerasettingpanel'} handleCancel={onBack}>
			<THeader title={$L('Camera')} onClick={onBack}/>
			<div className={css.panel}>
				<div className={css.titleArea}>
					<div className={css.titleBox}>
						<div className={css.text}>{$L('Connection')}</div>
					</div>
					<div className={css.textArea}>
						<div className={css.text}>{connectionText}</div>
					</div>
				</div>

				<div className={css.titleArea}>
					<div className={css.alarm}>
						<span className={css.alarmTitle}>{$L('Camera Access')}</span>
						<TSwitchItem spotlightId={'alarm'} data-webos-voice-label={$L('Camera')} selected={activated} onToggle={onState} className={css.alarmSwitch}>{activated ? $L('On') : $L('Off')}</TSwitchItem>
					</div>
				</div>
				<div className={css.cmArea}>
					<div className={css.ScreenTitle}>
						<div className={css.text}>{$L('Screen Size')}</div>
					</div>
					<div className={css.cameraArea}>
						<div className={css.cmBox}>
							<CheckBoxItem selected={size==='big'} className={css.checkBox} onToggle={onSelecSize('big')}>
								{$L("Big Screen")}
							</CheckBoxItem>
							<div className={css.subboxSizeBig}/>
						</div>
						<div className={css.cmBox}>
							<CheckBoxItem selected={size==='medium'} className={css.checkBox} onToggle={onSelecSize('medium')}>
								{$L("Medium Screen")}
							</CheckBoxItem>
							<div className={css.subboxSizeMedium}/>
						</div>
						<div className={css.cmBox}>
							<CheckBoxItem selected={size==='small'} className={css.checkBox} onToggle={onSelecSize('small')}>
								{$L("Small Screen")}
							</CheckBoxItem>
							<div className={css.subboxSizeSmall}/>
						</div>
					</div>
				</div>
				<div className={css.cmArea}>
					<div className={css.ScreenTitle}>
						<div className={css.text}>{$L('Position')}</div>
					</div>
					<div className={css.cameraArea}>
						<div className={css.cmBox}>
							<CheckBoxItem selected={position==='lt'} className={css.checkBox} onToggle={onSelecPosition('lt')}>
								{$L("Upper Left")}
							</CheckBoxItem>
							<div className={css.subboxTopLeft}/>
						</div>
						<div className={css.cmBox}>
							<CheckBoxItem selected={position==='rt'} className={css.checkBox} onToggle={onSelecPosition('rt')}>
								{$L("Upper Right")}
							</CheckBoxItem>
							<div className={css.subboxTopRight}/>
						</div>
						<div className={css.cmBox}>
							<CheckBoxItem selected={position==='lb'} className={css.checkBox} onToggle={onSelecPosition('lb')}>
								{$L("Lower Left")}
							</CheckBoxItem>
							<div className={css.subboxBottomLeft}/>
						</div>
						<div className={css.cmBox}>
							<CheckBoxItem selected={position==='rb'} className={css.checkBox} onToggle={onSelecPosition('rb')}>
								{$L("Lower Right")}
							</CheckBoxItem>
							<div className={css.subboxBottomRight}/>
						</div>
					</div>
				</div>
				<div className={css.buttonContainer} >
					<TButton className={css.doneBtn} disabled={isBtnDisabled} spotlightId={'btn'} onClick={onDone}>{$L('Done')}</TButton>
				</div>
			</div>
		</TPanel>
	);
};

export default CameraSettingPanel;
