import LS2Request from './LS2Request';

const ls2instances = {};

export const LS2RequestSingleton = {
	instance: function (skey) {
		ls2instances[skey] = ls2instances[skey] || new LS2Request();
		return ls2instances[skey];
	},
	deleteInstance: function (skey) {
		ls2instances[skey] = null;
	}
};

export default LS2RequestSingleton;
