import TimePicker from '@enact/sandstone/TimePicker';
import css from './TTimePicker.module.less';
import React from 'react';
const TTimePicker = ({...rest}) => {
    return (
        <TimePicker
            {...rest}
            className={css.timePicker}
        />
    );
};

export default TTimePicker;