import LS2Request from './LS2Request';
import LS2RequestSingleton from './LS2RequestSingleton';

import { $L } from '../utils/common';
import appinfo from '../../webos-meta/appinfo.json';

const LUNA_HOMEGYM_SERVICE="luna://com.twin.app.homegym.service";

export const launchMembershipAppNew = (webOSVersion, {onSuccess, onFailure, onComplete}) => {
	if(webOSVersion === 'local'){
		console.log('launchMembershipApp'); //do nothing
	}else {
		return new LS2Request().send({
			service: 'luna://com.webos.applicationManager',
			method: 'launchDefaultApp',
			subscribe: false,
			parameters:{"category":"MembershipApp", params: {query:"login", id: appinfo.id, reLaunch: "Y"}},
			onSuccess,
			onFailure,
			onComplete
		});
	}
};
export const launchMembershipApp = (webOSVersion, {onSuccess, onFailure, onComplete}) => {
	if(webOSVersion === 'local'){
		console.log('launchMembershipApp'); //do nothing
	}else {
		return new LS2Request().send({
			service: 'luna://com.webos.applicationManager',
			method: 'launch',
			subscribe: false,
			parameters:{id:"com.webos.app.membership", params: {query:"login", id: appinfo.id, reLaunch: "Y"}},
			onSuccess,
			onFailure,
			onComplete
		});
	}
};
export const getConnectionStatus = ({onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.connectionmanager',
		method: 'getStatus',
		subscribe: true,
		parameters:{},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const launchPromotionApp = (id, target, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.applicationManager',
		method: 'launch',
		subscribe: false,
		parameters:{checkUpdateOnLaunch: true, autoInstallation: true, id: id, params: {contentTarget: target, handledBy: "app"}},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const launchYoutubeApp = (type, id, lunchAppId, {onSuccess, onFailure, onComplete}) => {
	const target= type === "youtube#channel" ? "c="+id : "v="+id;
	return new LS2Request().send({
		service: 'luna://com.webos.applicationManager',
		method: 'launch',
		subscribe: false,
		parameters:{checkUpdateOnLaunch: true, autoInstallation: true, id: lunchAppId, params: {contentTarget: target, handledBy: "app"}},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const launchApp = (appid, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.applicationManager',
		method: 'launch',
		subscribe: false,
		parameters:{checkUpdateOnLaunch: true, autoInstallation: true, id: appid, params: {handledBy: "app"}},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const cancelGetVideoThumbnail = ({onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.tnm',
		method: 'cancel',
		subscribe: false,
		parameters: {
			type: 'all'
		},
		onSuccess,
		onFailure,
		onComplete
	});
};
export const getVideoThumbnail = (sourceUri, time, {onSuccess, onFailure, onComplete}) => {
	return new LS2Request().send({
		service: 'luna://com.webos.service.tnm',
		method: 'getThumbnail',
		subscribe: false,
		parameters: {
			sourceUri: sourceUri,
			mediaType: 'video',
			targetWidth: 250,
			targetHeight: 141,
			requestId: 'thumb' + time,
			scaleType: 'crop',
			priority: 1,
			times: [time],
			count: 1
		},
		onSuccess,
		onFailure,
		onComplete
	});
};
export const getHomegymServiceInfo = (parameters, {onSuccess, onFailure, onComplete}) => {
	let r = LS2RequestSingleton.instance('homegymService');
	return r.send({
		service: LUNA_HOMEGYM_SERVICE,
		method: 'getServiceInfo',
		subscribe: false,
		parameters:parameters,
		onSuccess,
		onFailure,
		onComplete
	});

};

export const activeServiceDebug = (parameters, {onSuccess, onFailure, onComplete}) => {
	let r = LS2RequestSingleton.instance('homegymService');
	return r.send({
		service: LUNA_HOMEGYM_SERVICE,
		method: 'activeServiceDebug',
		subscribe: false,
		parameters:parameters,
		onSuccess,
		onFailure,
		onComplete
	});

};

export const toastTest = (parameters, {onSuccess, onFailure, onComplete}) => {
	console.log('lunasend toastTest');
	return new LS2Request().send({
		service: 'palm://com.webos.notification',
		method: 'createToast',
		subscribe: false,
		parameters:{message: "test toast "+new Date().toTimeString()},
		onSuccess,
		onFailure,
		onComplete
	});

};

export const alertToast = (parameters, {onSuccess, onFailure, onComplete}) => {
	console.log('lunasend alertToast');
	return new LS2Request().send({
		service: 'palm://com.webos.notification',
		method: 'createToast',
		subscribe: false,
		parameters:{message: parameters},
		onSuccess,
		onFailure,
		onComplete
	});
};

export const setActiveAlarmData = (parameters, {onSuccess, onFailure, onComplete}) => {
	let r = LS2RequestSingleton.instance('homegymService');
	return r.send({
		service: LUNA_HOMEGYM_SERVICE,
		method: 'setActiveAlarmData',
		subscribe: false,
		parameters: parameters,
		onSuccess,
		onFailure,
		onComplete
	});
};

export const updateQCard = (parameters, { onSuccess, onFailure, onComplete }) => {
  const lunaParameters = {
    launchPointId: `${appinfo.id}_default`,
    qCardTitle: $L('Best At-Home Workout'),
  };

  if (parameters !== undefined && typeof parameters === 'object') {
    lunaParameters.qCardTitle = parameters.title;
    lunaParameters.qCardRemoteIcon = parameters.thumbnailImageUrl;
  }

  // local
  if (!window || !window.PalmSystem) {
    onSuccess(lunaParameters);
    return;
  }

  const iconPath = window.location.pathname;
  const qCardIcon = iconPath.replace(/[^\/]*$/, 'icon.png');
  lunaParameters.qCardIcon = qCardIcon;

  return new LS2Request().send({
    service: 'luna://com.webos.service.homelaunchpoints',
    method: 'updateQCard',
    parameters: lunaParameters,
    onSuccess,
    onFailure,
    onComplete,
  });
};