import Spotlight from '@enact/spotlight';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import css from './MyWorkoutRoutineListPanel.module.less';
import THeader from '../../../components/TPanel/THeader';
import TPanel from '../../../components/TPanel/TPanel';
import { $L } from '../../../utils/common';
import * as ContentType from '../../../data/ContentType';
import * as PanelActions from '../../../actions/panelActions';
import SpotlightIds from '../../../data/SpotlightIds';
import MediaList from '../../../components/MediaList/MediaList';
import { types } from '../../../actions/actionTypes';
import TBody from '../../../components/TPanel/TBody';

const MyWorkoutRoutineListPanel = ({hideChildren, panelInfo, ...rest}) => {
	const dispatch = useDispatch();
	const planListInfo = useSelector(state => state.plansOfAccount);
	const [restoreIndex, setRestoreIndex] = useState(panelInfo.restoreIndex);

	useEffect(() => {
		if (!hideChildren) {
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.LIST_MYWORKOUTROUTINE);
			}, 100);
		}
		setTimeout(() => {
			setRestoreIndex(-1);
		}, 500);
	}, [hideChildren]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel('myworkoutroutinelist'));
	}, [dispatch]);

	const onItemClick = useCallback((ev) => {
		dispatch({ type: types.RESET_TEMPORARY_PLAN });
		if (ev.contentType === ContentType.PLAN) {
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { info: ev, title: ev.title, planId: ev.planId, isEditMode: true, spotlightId: SpotlightIds.LIST_MYWORKOUTROUTINE}));
		} else {
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { info: [], isEditMode: false, spotlightId: SpotlightIds.LIST_MYWORKOUTROUTINE }));
		}
	}, [dispatch]);

	const onFocusedIndexChanged = useCallback((id, index) => {
		dispatch(PanelActions.updatePanel('myworkoutroutinelist', {restoreIndex: index}));
	}, [dispatch]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={$L('My Workout Routine')} onClick={onBack} />
			<div className={css.PlanListContainer}>
				<MediaList spotlightId={SpotlightIds.LIST_MYWORKOUTROUTINE} restoreIndex={restoreIndex} onFocusedIndexChanged={onFocusedIndexChanged} viewList={planListInfo} selectMode itemSize={'plan'} onListItemClick={onItemClick} />
			</div>
		</TPanel>
	);
};

export default MyWorkoutRoutineListPanel;