/**
 * TableList
 *
 * @module TableList
 */
import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import Group from '@enact/ui/Group';
import TableItem from './TableItem';
import SpotlightIds from '../../data/SpotlightIds';
import {handleCategoriTableSelected, handleWorkoutStyleTableSelected} from '../../actions/cmsActions';

import css from './TableList.module.less';

const GroupContainer = SpotlightContainerDecorator({preserveId: true, continue5WayHold: true, enterTo:'last-focused'}, Group);

const TableList = ({isOnTop, spotlightId, pickerMode=false, pickerSelectedId, pickerOnSelect, className, ...rest}) => {
	const dispatch = useDispatch();
	const viewList = useSelector(state => {
		if(pickerMode){
			return state.categoriesTablePicker;
		}
		return spotlightId === SpotlightIds.LIST_CATEGORY_TABLE ? state.categoriesTable : state.workstylesList;
	}, shallowEqual);
	const categoriesTableSelected = useSelector(state => state.categoriesTableSelected);
	const workoutstyleTableSelected = useSelector(state => state.accountInfo.workoutStyleId);

	const selectedId = useMemo(() => {
		if(pickerMode){
			return pickerSelectedId;
		}
		return (spotlightId === SpotlightIds.LIST_CATEGORY_TABLE)  ? categoriesTableSelected: workoutstyleTableSelected
	}, [pickerMode, pickerSelectedId, spotlightId, categoriesTableSelected, workoutstyleTableSelected]);

	const onSelect = useCallback((evt) => {
		if(pickerMode && pickerOnSelect){
			pickerOnSelect(evt);
		}else if (spotlightId === SpotlightIds.LIST_CATEGORY_TABLE) {
			dispatch(handleCategoriTableSelected(evt));
		} else {
			dispatch(handleWorkoutStyleTableSelected(evt));
		}
	}, [dispatch, pickerMode, spotlightId, pickerOnSelect]);
	return (
		<GroupContainer
				{...rest}
				spotlightId={spotlightId}
				className={classNames(className, css.listMain, css.hCenterAlign, spotlightId === SpotlightIds.LIST_WORKOUTSTYLE_TABLE ? css.big: null)}
				childComponent={TableItem}
				itemProps={{tooMany: viewList && viewList.length > 8, onSelect: onSelect, isOnTop: isOnTop, spotlightId, selectedId}}
				select="radio"
				selectedProp="selected"
			>
				{
					viewList
				}
		</GroupContainer>
	);
};

export default TableList;
