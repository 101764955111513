/**
 * TopButton
 *
 * @module TopButton
 */

import React, {useState, useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import {setContainerLastFocusedElement} from '@enact/spotlight/src/container';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import Group from '@enact/ui/Group';
import {Job} from '@enact/core/util';
import TopButton from './TopButton';
import SpotlightIds from '../../../data/SpotlightIds';
import * as Utils from '../../../utils/common';
import * as Config from '../../../data/Config';
import * as PanelActions from '../../../actions/panelActions';
import * as CommonActions from '../../../actions/commonActions';

import css from './TopLayer.module.less';

const MainContainer = SpotlightContainerDecorator({enterTo: 'last-focused', defaultElement: [`.${css.topgroup}`],preserveId: true}, 'div');
const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');
const GroupContainer = SpotlightContainerDecorator({preserveId: true, enterTo:'last-focused'}, Group);

const extendChangeJob = new Job((func, value) => {
	func(value);
},100);

const TopLayer = ({isOnTop=true, mainIndex, onOpenSettingPopup, extended}) => {
	const dispatch = useDispatch();
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const appStatus = useSelector(state => state.appStatus);
	const localSettings = useSelector(state => state.localSettings);
	const [isHover, setHover] = useState(false);
	const [isFocused, setFocused] = useState(false);
	const [showExtendedView, setShowExtendedView] = useState(false);

	useEffect(() => {
		let value = false;
		if(extended || isHover || isFocused || Config.ADMIN_PREVIEW_MODE){
			value=true;
		}
		extendChangeJob.start(setShowExtendedView, value);
	}, [extended, isHover, isFocused]);

	useEffect(() => {
		const rightButtonLayer = document.querySelector(`[data-spotlight-id="topRightButtonLayer"]`);
		if(rightButtonLayer && rightButtonLayer.children && rightButtonLayer.children[0]){
			setContainerLastFocusedElement(rightButtonLayer.children[0], ['topRightButtonLayer']);
		}
	}, [supportLogin]);

	const topButtonClicked = useCallback((name, index) => {
		console.log('topButtonClicked ev...', name, index);
		dispatch(PanelActions.setMainPanelIndex(index));
	}, [dispatch]);
	const onClickLogin = useCallback(() => {
		if (typeof window === 'object' && window.PalmSystem) {
			dispatch(CommonActions.launchMembershipApp('TopIcon'));
		}else{
			dispatch(CommonActions.accountError(true));
		}
	}, [dispatch]);
	const onClickExit = useCallback(() => {
		console.log('onClickExit');
		Utils.windowClose();
	}, []);

	const onMouseOver = useCallback(() => {
		setHover(true);
	}, [setHover]);

	const onMouseOut = useCallback(() => {
		setHover(false);
	}, [setHover]);

	const onFocus = useCallback(() => {
		setFocused(true);
	}, [setFocused]);

	const onBlur = useCallback(() => {
		setFocused(false);
	}, [setFocused]);

	const onFocusTopButtonLayer = useCallback(() => {
		setContainerLastFocusedElement(null, ['topRightButtonLayer']);
	}, []);

	const topButtons = [
		{key: 'Categories', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_CATEGORY_BTN, onSelect: topButtonClicked},
		{key: 'Home', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_HOME_BTN, onSelect: topButtonClicked}
	];
	// if( Config.SUPPORT_LOGIN ){
		topButtons.push(
			{key: 'Dashboards', isOnTop:isOnTop, extended: showExtendedView, spotlightId: SpotlightIds.MAIN_DASHBOARD_BTN, onSelect: topButtonClicked}
		);
	// }
	const onClickSearch = useCallback(() => {
		dispatch(PanelActions.pushPanel("search", {"init": true }))
	},[dispatch])

	const debugString = useMemo(() => {
		let str = "";
		if(localSettings.useQAServerURL === 'qa'){
			str = "QA";
		}
		if(localSettings.useQAServerURL === 'dev'){
			str = "DEV";
		}
		if(localSettings.useQAServerURL === 'twin'){
			str = "TWIN";
		}
		if(localSettings.useForceCamera){
			str += str ? "/Cam": "Cam";
		}
		if(localSettings.virtualPermission){
			str += str ? "/Virtual": "Virtual";
		}
		if(Config.ADMIN_PREVIEW_MODE){
			str = "";
		}
		return str;
	}, [appStatus, localSettings]);

	return (
		<MainContainer className={css['top-title-layer']}  onMouseOver={onMouseOver} onMouseOut={onMouseOut}
			onFocus={onFocus} onBlur={onBlur}>
			<Container className={css.globalbutton} spotlightId="topRightButtonLayer">
				{ supportLogin && deviceAccountInfo.accountId &&
					<TopButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_LOGOUTBTN} onSelect={onClickLogin}/> //<div className={css.account}>{deviceAccountInfo.accountId}</div>
				}
				{ supportLogin && !deviceAccountInfo.accountId &&
					<TopButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_LOGINBTN} onSelect={onClickLogin}/>   //기존 방식 <Button size="small" spotlightId={SpotlightIds.MAIN_LOGINBTN} onClick={onClickLogin}>{$L('Login')}</Button>
				}
				<TopButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_SETTINGSBTN} onSelect={onOpenSettingPopup}/>
				{ supportLogin &&
					<TopButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_SEARCHBTN} onSelect={onClickSearch}/>
				}
				<TopButton isOnTop={isOnTop} spotlightId={SpotlightIds.MAIN_EXITBTN} onSelect={onClickExit}/>
			</Container>
			<GroupContainer
				spotlightId="topButtonLayer"
				onFocus={onFocusTopButtonLayer}
				className={classNames(css.topgroup, showExtendedView ? css.extended: null)}
				childComponent={TopButton}
				itemProps={{inline: false}}
				select="radio"
				selectedProp="selected"
				selected={mainIndex}
			>
				{topButtons}
			</GroupContainer>
			<div className={classNames(css.logo, !showExtendedView ? css.hide: null)}/>
			{debugString &&
				<div className={css.isQAServer}>{debugString}</div>
			}
		</MainContainer>
	);
};

export default TopLayer;
