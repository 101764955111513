import {Panel} from '@enact/sandstone/Panels';
import React, {useCallback, useEffect} from 'react';
import css from './LoadingSpinner.module.less'
import Cancelable from '@enact/ui/Cancelable';
import { useDispatch, useSelector } from 'react-redux';
import { Job } from '@enact/core/util';
import { types } from '../../actions/actionTypes';

const CancelablePanel = Cancelable({modal: true, onCancel: 'handleCancel'}, Panel);

const MAX_SHOWING_TIME= 5000;

const LoadingSpinner = ({ ...rest }) => {
	const dispatch = useDispatch();
	const showLoading = useSelector(state => state.showLoadingSpinner.show);
	const handleCancel = useCallback((ev) => {
		ev.stopPropagation();
	}, []);

	let maxShowingTimeJob;

	useEffect(() => {
		return () => {
			if(maxShowingTimeJob)maxShowingTimeJob.stop();
		};
	}, []);

	useEffect(() => {
		if (showLoading) {
			if (!maxShowingTimeJob) {
				maxShowingTimeJob = new Job(() => {
					dispatch({ type: types.DEFAULT_LOADING_SPINNER_STATUS });
				}, MAX_SHOWING_TIME);
			}
		}
		maxShowingTimeJob.start();
		console.log("getYoutubeResult spinner", maxShowingTimeJob)
	}, [showLoading, dispatch])

	return (
		<CancelablePanel {...rest} className={css.panel} handleCancel={handleCancel}>
			<div className={css.spinner} />
		</CancelablePanel>
	);
};

export default LoadingSpinner;