/**
* TimeStatistics
*
* @module TimeStatistics
*/
import classNames from 'classnames';
import React, {useMemo} from 'react';
import css from './TimeStatistics.module.less';
import {$L} from '../../../utils/common';
import ChartTitle from '../ChartTitle/ChartTitle';
import {useSelector, shallowEqual} from 'react-redux';
import * as Utils from '../../../utils/common';

const MODE_WEEKLY = 0;
const MODE_MONTHLY = 1;
const MODE_YEARLY = 2;
const COMMENT = [
	{//week
		less: "On average, you’re working out{br}less this week than last week.",
		same: "On average, you’re working out about{br}the same amount of time as last week.",
		more: "On average, you’re working out{br}more this week than last week."
	},
	{//month
		less: "On average, you're working out{br}less this month than last month.",
		same: "On average, you’re working out about{br}the same amount of time as last month.",
		more: "On average, you’re working out{br}more this month than last month."
	},
	{//year
		less: "On average, you're working out{br}less this year than last year.",
		same: "On average, you're working out about{br}the same amount of time as last year.",
		more: "On average, you're working out{br}more this year than last year."
	}
]
const weekly = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthly = [7, 14, 21, 28];
const yearly = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
const yearly_ko = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

const LINE_TOP = Utils.scaleH(329);
const BAR_TOP = Utils.scaleH(309);

const TimeStatistics = ({ className, workoutDate}) => {
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const workoutSummary = useSelector(state => state.workoutSummary);

	const parsedData = useMemo(() => {
		const summary = workoutSummary[workoutDate.firstDate];
		const summaryPrevious = workoutSummary[workoutDate.previousDate];
		const data={workoutTimeList:[], dayTitles: [], prevAverage:0,average:0, averageStr: 0+$L({value: 'm', key: '__m'}), comment: "", avgHeight: 0};
		if(!workoutDate || Object.keys(workoutDate).length <=0){
			return data;
		}
		data.dayTitles = workoutDate.mode === MODE_MONTHLY ? monthly : workoutDate.mode === MODE_WEEKLY ? weekly : deviceAccountInfo.language === 'ko' ? yearly_ko : yearly;
		data.mode = workoutDate.mode;
		const fDate = new Date(workoutDate.firstDate.replace(/(-)/g,','));
		if(workoutDate.mode === MODE_WEEKLY){
			const lDate = new Date(workoutDate.lastDate.replace(/(-)/g,','));
			const weekFirstDayStr= String(fDate.getMonth()+1)+'/'+fDate.getDate();
			const weekLastDayStr = String(lDate.getMonth()+1)+'/'+lDate.getDate();
			data.dayStr = weekFirstDayStr + " ~ " + weekLastDayStr;
		}else if(workoutDate.mode === MODE_MONTHLY){
			data.dayStr = fDate.toLocaleDateString("default", { year: 'numeric', month: 'long' });
		}else{
			data.dayStr = fDate.toLocaleDateString("default", { year: 'numeric' });
		}

		if(summary && summary.timeSpent){
			let totalTime = 0, validcount=0, maxTime=0, timeList=[];
			for(let i=0; i<summary.timeSpent.length; i++){
				if(summary.timeSpent[i] > 0){
					totalTime+=summary.timeSpent[i];
					validcount++;
				}
				if (maxTime < summary.timeSpent[i]) {
					maxTime = summary.timeSpent[i];
				}
				timeList.push(summary.timeSpent[i]);
				if(workoutDate.mode === MODE_WEEKLY && i >=6){
					break;
				}else if(workoutDate.mode === MODE_YEARLY && i >=11){
					break;
				}
			}
			if(validcount>0){
				data.average = Math.round((totalTime/validcount));
			}
			for(let i=0; i<timeList.length; i++){
				if(maxTime > 0){
					timeList[i] = Utils.scaleH(Math.round((timeList[i]/maxTime) * 100 * 1.8));
				}
			}
			data.workoutTimeList = timeList;
			data.avgHeight = (timeList.length !==0 && validcount > 0) ? Math.round(timeList.reduce((a, b) => (a + b)) / validcount): 0;
			const h = Math.floor(data.average/3600);
			const m = Math.floor((data.average-h*3600)/60);
			data.averageStr = h>0 ? h+$L({value: 'h', key: '__h'})+" "+m+$L({value: 'm', key: '__m'}) : m+$L({value: 'm', key: '__m'});
		}
		if(summaryPrevious && summaryPrevious.timeSpent){
			let totalTime = 0, validcount=0;
			for(let i=0; i<summaryPrevious.timeSpent.length; i++){
				if(summaryPrevious.timeSpent[i] > 0){
					totalTime+=summaryPrevious.timeSpent[i];
					validcount++;
				}
			}
			if(validcount>0){
				data.prevAverage = Math.round((totalTime/validcount));
			}
		}
		if (data.prevAverage * 1.1 < data.average) {
			data.comment = COMMENT[workoutDate.mode].more;
		} else if (data.prevAverage * 0.9 > data.average) {
			data.comment = COMMENT[workoutDate.mode].less;
		} else {
			data.comment = COMMENT[workoutDate.mode].same;
		}
		return data;
	}, [workoutSummary, deviceAccountInfo, workoutDate]);

	return (
		<div className={className}>
			<ChartTitle>{$L('Workout Time Statistics')}</ChartTitle>
			<div className={css.chart}>
				<div className={css.date}>{parsedData.dayStr}</div>
				<div className={css.avg_info}>
					<span className={css.title}>{$L("Average")}</span>
					<span className={css.time}>{parsedData.averageStr}</span>
					<span className={css.text_day}>{parsedData.mode === MODE_YEARLY ? '/ '+$L("mth"): '/ '+$L("day")}</span>
				</div>
				<div className={classNames(css.comment)}>{$L(parsedData.comment)}</div>
				<div className={css.listContainer}>
				{
					parsedData.dayTitles.map((item, index) =>
						<div key={index} className={classNames(parsedData.mode === MODE_MONTHLY ? css.monthly : parsedData.mode === MODE_WEEKLY ? css.weekly : css.yearly)}>
							{parsedData.mode === MODE_MONTHLY ? item :$L(item)}
						</div>
					)
				}
				<div className={css.lineContainer}>
				{
					parsedData.dayTitles.map((item, index) =>
						<div key={index} className={classNames(parsedData.mode === MODE_MONTHLY ? css.line_month : parsedData.mode === MODE_WEEKLY ? css.hide : css.line_year)}/>)
				}
				</div>
				</div>
				<div className={css.text_zero}>0</div>
				<div className={css.barContainer}>
				{ parsedData.workoutTimeList &&
					parsedData.workoutTimeList.map((item, index) => <div key={index} className={classNames(parsedData.mode === MODE_MONTHLY ? css.bar_monthly : parsedData.mode === MODE_YEARLY ? css.bar_yearly : css.bar_weekly)} style={{ "height": item + "px" }} />)
				}
				</div>
				{parsedData.avgHeight > 0 &&
					<div className={css.avg_line} style={{ "top": (LINE_TOP - parsedData.avgHeight) + "px" }}/>
				}
				{parsedData.avgHeight > 0 &&
					<div className={css.avg_text} style={{ "top": (BAR_TOP - parsedData.avgHeight) + "px" }}>{$L('Average Time')}</div>
				}
			</div>
		</div>
	);
};

export default React.memo(TimeStatistics, shallowEqual);