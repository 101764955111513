import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { InputField } from '@enact/sandstone/Input';
import Spotlight from '@enact/spotlight';
import Scroller from '@enact/sandstone/Scroller';
import { Job } from '@enact/core/util';
import platform from '@enact/core/platform';
import {on, off} from '@enact/core/dispatcher';
import css from './SearchView.module.less';
import * as PanelActions from '../../actions/panelActions';
import * as CmsActions from '../../actions/cmsActions';
import * as CommonActions from '../../actions/commonActions';
import TPanel from '../../components/TPanel';
import TButton from '../../components/TButton/TButton';
import {$L} from '../../utils/common';
import * as Utils from '../../utils/common';
import MediaList from '../../components/MediaList/MediaList';
import { types } from '../../actions/actionTypes';
import ContentDetailInfo from '../../components/ContentDetailInfo/ContentDetailInfo';
import * as Config from '../../data/Config';
import * as ContentType from '../../data/ContentType';
import VerticalScrollGuide from '../../components/VerticalScrollGuide/VerticalScrollGuide';
import TRadioItem from '../../components/TRadioItem/TRadioItem';
import TItemList from '../../components/TItemList/TItemList';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import SpotlightIds from '../../data/SpotlightIds';
import LoadingSpinner from './LoadingSpinner';

const Container = SpotlightContainerDecorator({ leaveFor: { left: '', right: '', down: SpotlightIds.LIST_SEARCH }, enterTo: '', spotlightRestrict: 'self-only' }, 'div');

const launchSearchJob = new Job((func, ev) => {
	func(ev);
}, 1000);
const calculateVisibleListJob = new Job((func) => {
	func();
}, 2000);

const SearchView = ({ hideChildren, panelInfo}) => {
	const dispatch = useDispatch();

	const fitnessContent = useSelector(state => state.contentsOfKeyword);
	const youtubeContent = useSelector(state => state.YoutubeSearchResult);
	const plansOfAccount = useSelector(state => state.plansOfAccount);
	const focusedBannerItem = useSelector(state => state.focusedBannerItem);
	const contentInfos = useSelector(state => state.contentInfos);
	const updatedContentInfo = useSelector(state => state.updatedContentInfo);
	const {accountId} = useSelector(state => state.accountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const showLoading = useSelector(state => state.showLoadingSpinner.show);
	const panels = useSelector(state => state.panels);
	const localRecentSearch = useSelector(state => state.localSettings.recentSearch, shallowEqual);

	const [keyword, setKeyword] = useState(panelInfo.keyword);
	const recentSearches = useRef([]);
	const [selectedMode, setSelectedMode] = useState({ LGFitness: true, YouTube: false });
	const [integratedSearchResult, setIntegratedSearchResult] = useState();
	const [myWorkoutRoutine, setMyWorkoutRoutine] = useState();
	const [contentFocus, setContentFocus] = useState();
	const [isInputFocus, setInputFocus] = useState(false);
	const [isTypingWord, setTypingWord] = useState(false);
	const [searchWord] = useState([]);
	// const [searchList, setSearchList] = useState([]);
	const [searchExecution, setSearchExecution] = useState(false);
	const [, updateState] = useState();

	const supportFavBtn = useMemo(() => {
		if(supportLogin && accountId){
			return true;
		}
		return false;
	}, [supportLogin, accountId]);

	/*scroll 변수 */
	const SCROLLER_ID = "detail_scroller";
	const MAX_VISIBLE_ITEM = 10;

	const scrollTo = useRef(null);
	const scrollTop = useRef(0);

	const [visibleList, setVisibleList] = useState([]);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [scrollOnTop, setScrollOnTop] = useState(true);
	const [restoreIndex, setRestoreIndex] = useState(panelInfo.restoreIndex); //media focus

	const cursorVisible = useSelector(state => state.appStatus.cursorVisible);

	useEffect(() => {
		if (plansOfAccount.length > 0 && plansOfAccount[plansOfAccount.length - 1].contentType === ContentType.ADD_PLAN) {
			setMyWorkoutRoutine(plansOfAccount.slice(0, plansOfAccount.length - 1));
		} else {
			setMyWorkoutRoutine(plansOfAccount);
		}
	}, [plansOfAccount]);

	const playFromIntent = useCallback((content)=> (success) => {
		if(success){
			dispatch(CommonActions.setPlayLaunchPath("voiceIntent"));
			dispatch(CommonActions.handlePlayItem(content, null));
		}
	}, [dispatch]);

	useEffect(() => {
		//voice intent
		const launchParams = Utils.getLaunchParams();
		if(!hideChildren && launchParams.intent === 'PlayContent'){
			if (fitnessContent.data && fitnessContent.data.length > 0) {
				dispatch(CmsActions.getContentInfo(fitnessContent.data[0].contentId, true
					,playFromIntent({contentId:fitnessContent.data[0].contentId, contentType: ContentType.SINGLE})));
				Utils.clearLaunchParams();
			}
		}
		calculateVisibleListJob.start(calculateVisibleList);
	}, [youtubeContent, fitnessContent]);

	const delayFocus = new Job(() => {
		Spotlight.focus(SpotlightIds.LIST_SEARCH);
	}, 100);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if (panels.length > 1) {
			dispatch(PanelActions.popPanel('search'));
			dispatch(PanelActions.popPanel('player'));
		} else {
			dispatch(PanelActions.popPanel('search'));
		}
	}, [dispatch, panelInfo, panels]);

	const addRecentSearch = useCallback((_keyword) => {
		let value = [];
		if(recentSearches){
			recentSearches.current.forEach(item => {
				value.push(item);
			});
		}
		if (_keyword.trim().length !== 0) {
			value.splice(0, 0, _keyword.trim());
			const set = new Set(value);
			let removedSameKeyword = [...set];
			removedSameKeyword = removedSameKeyword.length > 5 ? removedSameKeyword.slice(0, 5) : removedSameKeyword
			recentSearches.current = removedSameKeyword;
			dispatch(CommonActions.changeLocalSettings({ recentSearch: removedSameKeyword }))
		}
	}, []);

	const onHandleInput = useCallback((ev) => {
		if (keyword !== undefined) {
			if (ev.key === 'Enter') {
				const checkedKeyword = keyword.trim();
				setKeyword(checkedKeyword);
				setSearchExecution(true);
				if (ev.byLaunchParam) {
					dispatch(CmsActions.searchLog(checkedKeyword, "LGFitness"));
					dispatch(CommonActions.getResultOfLGFitness(checkedKeyword, ev.byLaunchParam));
				} else {
					if (selectedMode.LGFitness) {
						dispatch(CmsActions.searchLog(checkedKeyword, "LGFitness"));
					} else {
						dispatch(CmsActions.searchLog(checkedKeyword, "Youtube"));
					}
						dispatch(CmsActions.getYoutubeResult(checkedKeyword, false));
						dispatch(CommonActions.getResultOfLGFitness(checkedKeyword, false));
					}
					addRecentSearch(checkedKeyword);
					if(!ev.byLaunchParam){
						Utils.clearLaunchParams();
					}
				dispatch(PanelActions.updatePanel('search', {keyword: checkedKeyword}));
			}
		}
  }, [keyword, dispatch, addRecentSearch, selectedMode]);

	const onHandlebackBtn = useCallback((ev) => {
		if (ev.key === 'ArrowRight') {
			Spotlight.focus("inputBox");
			ev.stopPropagation();
			ev.preventDefault();
		}
	}, []);

	const onSelectedYoutube = useCallback(() => {
		setSelectedMode({ LGFitness: false, YouTube: true });
		if (keyword !== youtubeContent.keyword) {
			if (keyword !== undefined && keyword.trim().length !== 0 && keyword !== "") {
				const checkedKeyword = keyword.trim();
				setKeyword(checkedKeyword);
				setSearchExecution(true);
				dispatch(CmsActions.searchLog(checkedKeyword, "Youtube"));
				dispatch(CmsActions.getYoutubeResult(checkedKeyword, false));
				return;
			}
		}
		if (keyword && keyword.length > 0) {
		dispatch(CmsActions.searchLog(keyword, "Youtube"));
		}
	}, [dispatch, keyword, youtubeContent]);

	const onSelectedLGFitness = useCallback(() => {
		setSelectedMode({ LGFitness: true, YouTube: false });
		if (keyword !== fitnessContent.keyword) {
			if (keyword !== undefined && keyword.trim().length !== 0 && keyword !== "") {
				const checkedKeyword = keyword.trim();
				setKeyword(checkedKeyword);
				setSearchExecution(true);
				dispatch(CmsActions.searchLog(checkedKeyword, "LGFitness"));
				dispatch(CommonActions.getResultOfLGFitness(checkedKeyword, false));
				return;
			}
		}
		if (keyword && keyword.length > 0) {
		dispatch(CmsActions.searchLog(keyword, "LGFitness"));
		}
  }, [dispatch, keyword, fitnessContent]);

	const onKeyWordChange = useCallback((ev) => {
		setKeyword(ev.value);
		const value = ev.value !== "";
		// setSearchWord(searchList.filter(item => item.toLowerCase().indexOf(ev.value) !== -1).slice(0, 5));
		if (value) {
			setTypingWord(true);
		} else {
			setTypingWord(false);
		}
	}, []);
	// }, [searchList]);

	const onClickRecentSearch = useCallback((item) => () => {
		setKeyword(item.item);
		addRecentSearch(item.item);
		setInputFocus(false);
		setSearchExecution(true);
		if (selectedMode.LGFitness) {
	  dispatch(CmsActions.searchLog(item.item, "LGFitness"));
			dispatch(CommonActions.getResultOfLGFitness(item.item, false));
		} else {
	  dispatch(CmsActions.searchLog(item.item, "Youtube"));
			dispatch(CmsActions.getYoutubeResult(item.item, false));
		}
		dispatch(PanelActions.updatePanel('search', {keyword: item.item}));
		Spotlight.focus("inputBox");
	}, [dispatch, selectedMode, addRecentSearch]);

	useEffect(() => {
		if (selectedMode.YouTube) {
			if (youtubeContent && youtubeContent.data.length !== 0) {
				setInputFocus(false);
			}
		} else if (selectedMode.LGFitness) {
			if (fitnessContent && fitnessContent.data.length !== 0) {
				setInputFocus(false);
			}
		}
		delayFocus.start();
	},[fitnessContent, youtubeContent, integratedSearchResult, myWorkoutRoutine]);

	const onDelete = useCallback((item) => () => {
		if(recentSearches){
			recentSearches.current = recentSearches.current.filter(items => items !== item.item);
		}
		if(recentSearches.current.length === 0) {
			Spotlight.focus('inputBox');
		}
		dispatch(CommonActions.changeLocalSettings({ recentSearch: recentSearches.current }))

		updateState({})
	}, [recentSearches]);

	const spotlightMedia = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			if (isInputFocus) {
				if (isTypingWord) {
					if (searchWord && searchWord.length === 0) {
						Spotlight.focus(SpotlightIds.LIST_SEARCH);
					} else {
						Spotlight.focus("searchListContainer");
						ev.preventDefault();
						ev.stopPropagation();
					}
				} else {
					if (recentSearches && recentSearches.length === 0) {
						Spotlight.focus(SpotlightIds.LIST_SEARCH);
					} else {
						Spotlight.focus("searchListContainer");
						ev.preventDefault();
						ev.stopPropagation();
					}
				}
			} else {
				Spotlight.focus(SpotlightIds.LIST_SEARCH);
			}
		}
	}, [isInputFocus, isTypingWord, searchWord, recentSearches]);

	const bannerContent = useMemo(() => {
		let content = null
		if (focusedBannerItem !== null) {
			content = focusedBannerItem;
		}
			// choose first content if it is series content
		if(content){
			const c = contentInfos[content.contentId];
			if(c){
				content = {...content, ...c};
			}
			if(updatedContentInfo.contentId === content.contentId){
				content = {...content, ...updatedContentInfo};
			}
		}
		return { content };
	}, [focusedBannerItem, contentInfos, updatedContentInfo]);

	/* scroll function*/

	const contentsList = useMemo(() => {
		if (selectedMode.LGFitness) {
			return fitnessContent.data;
		} else {
			return youtubeContent.data;
		}
	}, [fitnessContent, youtubeContent, selectedMode]);

	useEffect(() => {
		if (scrollTo && scrollTo.current) {
			scrollTo.current({ position: { x: 0, y: 0 }, animate: true });
		}
	}, [contentsList]);

	useEffect(() => {
		Spotlight.focus(SpotlightIds.LIST_SEARCH);
		on('wheel', handleWheel);
		calculateVisibleListJob.start(calculateVisibleList);
		return () => {
			off('wheel', handleWheel);
			if(calculateVisibleListJob){
				calculateVisibleListJob.stop();
			}
		};
	}, []);

  useEffect(() => {
    if(recentSearches && localRecentSearch){
      recentSearches.current = localRecentSearch;
    }
  }, [localRecentSearch])

	useEffect(() => {
		// searchWordList();
		if (!hideChildren) {
			if (panelInfo.init) {
				dispatch({ type: types.GET_YOUTUBE_SEARCH_RESET });
				dispatch({ type: types.GET_CONTENTS_KEYWORD_RESET });
				Spotlight.focus("inputBox");
				panelInfo.init = false;
			}
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.LIST_SEARCH);
				if(panelInfo.scrollTop && scrollTo.current){
					scrollTo.current({position:{y: panelInfo.scrollTop}, animate: true});
				}
			}, 100);
			setTimeout(() => {
				setRestoreIndex(-1);
			}, 500);

			if (panelInfo && panelInfo.keyword) {
				Spotlight.focus("backBtn");
				const launchParams = Utils.getLaunchParams();
				let byLaunchParam = false;
				if(launchParams.intent === 'PlayContent' || launchParams.intent === 'SearchContent'){
					byLaunchParam = true;
				}
				if (byLaunchParam) {
					launchSearchJob.start(onHandleInput, { key: 'Enter', byLaunchParam: byLaunchParam });
				}
			}
		}
	}, [hideChildren]);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
		const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_SEARCH}"]`);
		let listArray = listView && listView.childNodes[0].childNodes;
		const itemList = [];
		for(let index in listArray){
			if(!isNaN(index)){
				const item = listArray[index];
				if(item.offsetTop >= scrollTop.current && (item.offsetTop+item.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
					itemList.push(item.getAttribute('data-spotlight-id'));
				}
			}
		}
		setVisibleList(itemList);
	}, []);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, [scrollTo]);

	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
		if (scrollTop.current + scroller.offsetHeight + 270 /*dummy*/ > scroller.childNodes[0].scrollHeight + 30) {
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		if(scrollTop.current <= 0){
			setScrollOnTop(true);
		}else{
			setScrollOnTop(false);
		}
		calculateVisibleList();
		dispatch(PanelActions.updatePanel('search', {scrollTop: scrollTop.current}));
	}, [scrollTop, setScrollOnBottom, setScrollOnTop, calculateVisibleList, dispatch]);

	const moveBottom = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
			const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_SEARCH}"]`);
			let listArray = listView.childNodes[0].childNodes;
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('down', currentItem);
				if(currentItem && nextItem){
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						const targetOffset = currentItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for (let i = 0; i < listArray.length; i++){
				if(scrollTop.current < listArray[i].offsetTop){
					//check last line
					const lastLine = Math.ceil(listArray.length/5); // 5 items per line
					if(lastLine > 2 && Math.ceil((i+1)/5) < lastLine){
						scrollTop.current = listArray[i].offsetTop;
						scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
					}
					return true;
				}
			}
		}
	}, []);

	const moveTop = useCallback((wheel = false) => {
			if(scrollTo.current !== null){
				const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
				const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_SEARCH}"]`);
					let listArray = [];
					scroller.childNodes[0].childNodes.forEach(element => {
					listArray.push(element)
			});
			listView.childNodes[0].childNodes.forEach(element => {
				listArray.push(element)
			});
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('up', currentItem);
				if(currentItem && nextItem){
					if(nextItem.offsetTop === scrollTop.current){ //contain
						return true;
					}else{
						if(nextItem.offsetTop < scrollTop.current){
							scrollTop.current = nextItem.offsetTop;
							scrollTo.current({position:{y: nextItem.offsetTop}, animate: true});
						}
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if(listArray[i].offsetTop < scrollTop.current){
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y:listArray[i].offsetTop},animate: true});
					return true;
				}
			}
		}
	}, []);

	const onClickTopGuide = useCallback(() => {
		moveTop(true);
	}, [moveTop]);

	const onClickBottomGuide = useCallback(() => {
		moveBottom(true);
	}, [moveBottom]);

	const onKeyDown = useCallback((ev) => {
		if(ev.key === 'ArrowDown' ||ev.key === 'PageDown'){
			return moveBottom(false);
		}else if(ev.key === 'ArrowUp' || ev.key === 'PageUp'){
			return moveTop(false);
		}
		return false;
	}, [moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		console.log('onWheel ',ev);
		if(ev.deltaY < 0){
			onClickTopGuide();
		}else{
			onClickBottomGuide();
		}
	}, [onClickTopGuide, onClickBottomGuide]);

	const onFocusedIndexChanged = useCallback((id, index) => {
		dispatch(PanelActions.updatePanel('search', {restoreIndex: index}));
	}, [dispatch]);

	const searchMediaListTitle = useCallback(() => {
		if (selectedMode.LGFitness) {
			if (fitnessContent.data.length !== 0) {
				return ("LG Fitness");
			}
		} else if (selectedMode.YouTube) {
			if (youtubeContent.data.length !== 0) {
				return ("YouTube");
			}
		}
	}, [selectedMode, fitnessContent, youtubeContent])

	const showSearchContentsList = useCallback(() => {
		if (selectedMode.LGFitness) {
			if (fitnessContent.data.length !== 0) {
				return (
					<>
					<MediaList
						visibleList={visibleList}
						orientation={'vertical'}
						restoreIndex={restoreIndex}
						spotlightId={SpotlightIds.LIST_SEARCH}
						onKeyDown={onKeyDown}
						viewList={fitnessContent.data}
						supportFavBtn={supportFavBtn}
						onFocusedIndexChanged={onFocusedIndexChanged}
					/>
					<div className={css.dummy}/>
					</>
				);
			} else {
				if (searchExecution && !showLoading) {
					return (
						<div className={css.noneResult}>{$L("No results for this keyword.")}</div>
					)
				}
			}
		} else if (selectedMode.YouTube) {
			if (youtubeContent.data.length !== 0) {
				return (
					<>
					<MediaList
						visibleList={visibleList}
						orientation={'vertical'}
						spotlightId={SpotlightIds.LIST_SEARCH}
						onKeyDown={onKeyDown}
						viewList={youtubeContent.data}
						supportFavBtn={supportFavBtn}
					/>
					<div className={css.dummy}/>
					</>
				);
			} else {
				if (searchExecution && !showLoading) {
					return (
						<div className={css.noneResult}>{$L("No results for this keyword.")}</div>
					)
				}
			}
		}
	}, [selectedMode, supportFavBtn, visibleList, restoreIndex, fitnessContent, youtubeContent, searchExecution, showLoading, onFocusedIndexChanged, onKeyDown]);

	const onContentFocus = useCallback(() => {
		setContentFocus(true);
		setInputFocus(false);
	},[]);

	const onContentBlur = useCallback(() => {
		setContentFocus(false);
	},[]);

	const onFocusInputField = useCallback(() => {
		setInputFocus(true);
		if (keyword !== "" && keyword !==undefined) {
			setTypingWord(true);
		} else {
			setTypingWord(false);
		}
	}, [keyword]);

	return (
		<TPanel className={css.panel} handleCancel={onBack}>
			<div className={css.headContainer} >
				<TButton type={"back"} spotlightId={"backBtn"} onClick={onBack} className={classNames(css.backBtn)} onKeyDown={onHandlebackBtn}/>
				<InputField
					className={css.inputBox}
					spotlightId={"inputBox"}
					autoFocus
					value={keyword}
					dismissOnEnter
					onFocus={onFocusInputField}
					onChange={onKeyWordChange}
					onKeyDown={onHandleInput}
					placeholder={$L("Search for trainers, workout programs, and whatever else you want")} />
			</div>
			<div className={classNames(css.toggleContainer) }>
				<TRadioItem
					onKeyDown={spotlightMedia}
					selected={selectedMode.LGFitness}
					onToggle={onSelectedLGFitness}
				>
					{$L("LG Fitness")}
				</TRadioItem>
				<TRadioItem
					onKeyDown={spotlightMedia}
					selected={selectedMode.YouTube}
					onToggle={onSelectedYoutube}
				>
					{"YouTube"}
				</TRadioItem>
			</div>
			<Container className={css.searchContainer} spotlightId={"searchListContainer"}>
				{isInputFocus && !isTypingWord &&
					recentSearches && recentSearches.current.map((item,index) =>
						<TItemList
							key={index}
							index={index}
							spotlightId={"recentSearchs"+index}
							onClickSearch={onClickRecentSearch({ item })}
							onClickDelete={onDelete({ item })}
						>
							{item}
						</TItemList>)
				}
				{isInputFocus && isTypingWord &&
					searchWord.map((item, index) =>
						<TItemList
							key={index}
							index={index}
							spotlightId={"recentSearchs"+index}
							onClickSearch={onClickRecentSearch({ item })}
							type={'autoSearchWord'}
						>
							{item}
						</TItemList>
					)}
			</Container>
			{selectedMode.LGFitness && contentsList && contentsList.length !== 0 && contentFocus &&
				<ContentDetailInfo className={css.moreInfo} size={"small"}>{bannerContent.content}</ContentDetailInfo>
			}
			{contentsList && contentsList.length !== 0 &&
				<div className={classNames(css.mediaListTitleWrap)}>
					<div className={css.listIcon}/>
					<div className={classNames(css.mediaListTitle)}>
						{searchMediaListTitle()}
					</div>
				</div>
			}
			<Scroller
				className={classNames(css.medialistlayer)}
				cbScrollTo={getScrollTo}
				spotlightId={SCROLLER_ID}
				direction="vertical"
				horizontalScrollbar="hidden"
				verticalScrollbar="hidden"
				scrollMode="translate"
				noScrollByWheel
				noScrollByDrag
				onScrollStop={onScrollStop}
			>
				<div className={classNames(css.contentContainer)} onFocus={onContentFocus} onBlur={onContentBlur}>
					{showSearchContentsList()}
				</div>
			</Scroller>
			{(cursorVisible || platform.touchscreen) && contentsList && contentsList.length > MAX_VISIBLE_ITEM && !scrollOnTop &&
				<VerticalScrollGuide
					type={"top"}
					className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide : null)}
					onClick={onClickTopGuide}
				/>
			}
			{(cursorVisible || platform.touchscreen) && contentsList && contentsList.length > MAX_VISIBLE_ITEM && !scrollOnBottom &&
				<VerticalScrollGuide
					type={"bottom"}
					className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide : null)}
					onClick={onClickBottomGuide}
				/>
			}
			{showLoading &&
				<LoadingSpinner />
			}
		</TPanel>
	);
};

export default SearchView;