import {types} from './actionTypes';
import {Job} from '@enact/core/util';

export const DELAY = {
	NORMAL: 500,
	PANEL_ANIM: 1200
};
/**
 * View index of MainPanel
 */
export const MAIN_INDEX = {
	CATEGORIES_VIEW: 0,
	HOME_VIEW: 1,
	DASHBOARDS_VIEW: 2
};
const hideSpinner = (dispatch) => {
	dispatch({type: types.SHOW_HIDE_LOADING_SPINNER, showing: false});
};

let hideSpinnerJob = new Job(hideSpinner, 500);

export const showHideLoadingSpinner = (bShow, delay = DELAY.NORMAL) => (dispatch, getState) => {
	console.log('showHideLoadingSpinner ' + bShow + ' delay ' + delay);
	if (bShow === true ) {
		hideSpinnerJob.stop();
		dispatch({type: types.SHOW_HIDE_LOADING_SPINNER, showing: true});
	} else {
		hideSpinnerJob.timeout = delay;
		hideSpinnerJob.start(dispatch, getState);
	}
};
const delayedPanel = (dispatch, obj) => {
	dispatch(obj);
};

let delayedPanelJob = new Job(delayedPanel, 200);

export const pushPanel = (name, panelInfo={}) => (dispatch) => {
	console.log('pushPanel', name, panelInfo);
	dispatch({type: types.PUSH_PANEL, payload:{name: name, panelInfo: panelInfo}});
	// const state = getState();
	// if (state.dataFetched) {
	// 	settingsThrottleJob.throttle('pushRouter', payload, dispatch);
	// }
};
export const updatePanel = (name, panelInfo={}) => (dispatch) => {
	dispatch({type: types.UPDATE_PANEL, payload:{name: name, panelInfo: panelInfo}});
};

export const popPanel = (payload) => (dispatch) => {
	// dispatch({type: types.SHOW_HIDE_LOADING_SPINNER, showing: true});
	dispatch({type: types.POP_PANEL, payload});
	// delayedPanelJob.start(dispatch, {type: types.POP_PANEL});
};

export const resetPanels = (withOutTop = false) => (dispatch) => {
	dispatch({type: types.RESET_PANELS, payload: {withOutTop: withOutTop}});
};

export const popPanelByIndex = (index) => (dispatch) => {
	dispatch({type: types.SHOW_HIDE_LOADING_SPINNER, showing: true});
	delayedPanelJob.start(dispatch, {type: types.POP_PANEL_BY_INDEX, index});
};

export const setMainPanelIndex = payload => (dispatch) => {
	dispatch({type: types.SET_MAINPANEL_INDEX, payload});
};
export const setMainPanelCurrentSpot = payload => (dispatch, getState) => {
	const panels = getState().panels;
	console.log('setMainPanelCurrentSpot', payload, panels);
	// if (panels[panels.length - 1] === 'main') {
	// 	dispatch({type: types.SET_MAINPANEL_CURRENTSPOT, payload});
	// }
};

export const popInterviewInputRadio = (currentPath) => (dispatch) =>{
	if(currentPath !== 'basic-v1-0' && currentPath !== 'basic-v1-1'){
		dispatch({type: types.POP_INTERVIEW_INPUT_RADIO , currentPath});
	}
}
