import axios from 'axios';
import * as Config from '../data/Config';
import * as Utils from '../utils/common';
import * as CommonActions from './commonActions';

const AUTHORIZATION = {headers: {Authorization: Config.CMS_AUTHORIZATION}};
/**
 * type: 'get', 'put', 'post', 'delete'
 */

 export const TAxios = async (dispatch, getState, type, baseUrl, params={}, onSuccess, onFail) => {
	if (typeof window === 'object') {
		const urlparams = Utils.createQueryString(params);
		const {useQAServerURL} = getState().localSettings;
		const deviceAccountInfo = getState().deviceAccountInfo;
		let u = baseUrl;
		if(Array.isArray(baseUrl)){
			u = baseUrl[0];
		}
		let url = `${Config.GYMURL(deviceAccountInfo.language, u, useQAServerURL)}`;
		if(Array.isArray(baseUrl)){
			for(let i=1; i<baseUrl.length; i++){
				url += '/'+baseUrl[i];
			}
		}
		switch(type){
			case 'get':
				url +=  urlparams ? `?${urlparams}`: '';
				break;
		}
		if(useQAServerURL !== 'prd'){
			console.log('TAxios ', type+": " +url, params);
		}
		let axiosIns = null;
		switch(type){
			case 'get':
				axiosIns = axios[type](url, AUTHORIZATION);
				break;
			case 'put':
			case 'post':
				axiosIns = axios[type](url, params, AUTHORIZATION);
				break;
			case 'delete':{
				const auth = Object.assign({}, AUTHORIZATION, {data: params});
				axiosIns = axios[type](url, auth);
				break;
			}
		}
		if(axiosIns){
			axiosIns.then((res)=>{
				if(useQAServerURL !== 'prd'){
					console.log('TAxios response', url, res);
				}
				if(onSuccess){
					onSuccess(res);
				}
			}).catch((res)=>{
				const error = res && res.toJSON ? res.toJSON() : {};
				console.error('TAxios ', url, error);
				if(error.message === 'Network Error'){
					dispatch(CommonActions.changeAppStatus({connectionFailed: true}));
				}
				// else if(userId && error.message && error.message.indexOf('400')>=0){

				// }else if(userId && error.message && error.message.indexOf('401')>=0){

				// }else if(userId && error.message && error.message.indexOf('403')>=0){

				// }else{
				// 	//todo
				// }
				if(onFail){
					onFail(res);
				}
			});
		}else{
			console.warn('TAxios invalid case type ', type);
		}
	}
};