import React, { useState, useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './GoalSettingsPanel.module.less';
import { $L } from '../../../utils/common';
import SpotlightIds from "../../../data/SpotlightIds";
import * as Utils from '../../../utils/common';
import * as PanelActions from '../../../actions/panelActions';
import * as CmsActions from '../../../actions/cmsActions';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import GoalAddContent from '../../../components/GoalItem/GoalAddContent';
import TButton from '../../../components/TButton/TButton';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import { types } from '../../../actions/actionTypes';
import TDayPicker from '../../../components/TDayPicker/TDayPicker';
import LoginGuide from '../../../components/LoginGuide';
import TBody from '../../../components/TPanel/TBody';

const Container = SpotlightContainerDecorator({leaveFor: {left:'', right:''}, enterTo: '', restrict: 'self-only'}, 'div');

const GoalSettingsPanel = ({ panelInfo, hideChildren, ...rest }) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();

	const title = panelInfo.title;
	const info = panelInfo.info;
	const goalId = panelInfo.goalId;
	const isEditMode = panelInfo.isEditMode;
	const headerTitle = isEditMode ? $L('Goal Settings')+" > "+$L('Edit') : $L('Goal Settings')+" > "+$L('Add');

	const tempGoalInfo = useSelector(state => state.tempGoalInfo, shallowEqual);
	const panels = useSelector(state => state.panels, shallowEqual);

	const [isFocus, setFocus] = useState([false, false, false, false, false, false, false]);
	const [currentFocusNum, setCurrentFocusNum] = useState(-1);
	const [previousFocusNum, setPreviousFocusNum] = useState(-1);
	const [activated, setActivated] = useState(true);
	const [resetButtonState, setResetButtonState] = useState(true);
	const [doneButtonState, setDoneButtonState] = useState(true);
	const [initialization, setInitialization] = useState(false);
	const [selectDays, setSelectDays] = useState([0, 1, 2, 3, 4, 5, 6]);

	useEffect(() => {
		if (!hideChildren) {
			defaultFocus();
		}
		if (info && info.length > 0 || info && Object.keys(info).length > 0) {
			updateInfoChanged(info);
		}
		if (isEditMode) {
			setActivated(panelInfo && panelInfo.activated);
		} else {
			setActivated(true);
		}
		if(panelInfo.selectDays) {
			setSelectDays(panelInfo.selectDays);
		}
	}, [hideChildren]);

	const defaultFocus = useCallback(() => {
		if (panelInfo.panelType && panelInfo.panelType === "onBack") {
			Spotlight.focus('contentContainer' + panelInfo.day);
			Spotlight.focus('contentChangeBtn' + panelInfo.day);
		} else {
			if (isEditMode) {
				for (let i = 6; i >= 0; i--) {
					if (info && !info.contentChanged) {
						if (panelInfo.activated) {
							Spotlight.focus('contentContainer0');
							Spotlight.focus('contentChangeBtn' + i);
						} else {
							Spotlight.focus('switchBtn');
						}
					} else {
						Spotlight.focus(previousFocus.current);
					}
				}
			} else {
				Spotlight.focus('contentContainer0');
			}
		}
	}, [isEditMode, info, panelInfo]);

	/* button state setting */
	useEffect(() => {
		let checkValue = []
		if (selectDays !== null) {
			for (let i = 0; i < selectDays.length; i++) {
				if (Object.keys(tempGoalInfo.goals[selectDays[i]].info).length > 1) {
					checkValue.push(true);
				} else {
					checkValue.push(false);
				}
			}
			if (checkValue.includes(true)) {
				setDoneButtonState(false);
				setResetButtonState(false);
			} else {
				setDoneButtonState(true);
				if (selectDays.length === 7) {
					setResetButtonState(true);
				} else {
					setResetButtonState(false);
				}
			}
		}

		if (selectDays === null) {
			if (isEditMode) {
				setDoneButtonState(false);
			} else {
				setDoneButtonState(true);
			}
		}

		if (initialization) {
			if (isEditMode) {
				setDoneButtonState(false);
			} else {
				setDoneButtonState(true);
			}
		}

		if (!activated) {
			setResetButtonState(false)
		}
	}, [selectDays, tempGoalInfo, isEditMode, initialization, activated]);

	const createParms = useCallback((state) => {
		let goalInfos = [];
		let goal = {};
		const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

		for (let i = 0; i < tempGoalInfo.goals.length; i++) {
			if ((activated ? !tempGoalInfo.goals[i].disabled : true) && Object.keys(tempGoalInfo.goals[i].info).length > 1) {
				goal = {
					title: title,
					alarmActivated: activated,
					items: {
						weekday: tempGoalInfo.goals[i].info.day === "everyDay" ? DAYS[i] : DAYS[tempGoalInfo.goals[i].info.day],
						time: Utils.transTimeToText(tempGoalInfo.goals[i].info.alarm.hours, tempGoalInfo.goals[i].info.alarm.minutes)
					},
					alarms: {
						activated: activated ? !tempGoalInfo.goals[i].disabled : activated,
						time: Utils.transTimeToText(tempGoalInfo.goals[i].info.alarm.hours, tempGoalInfo.goals[i].info.alarm.minutes)
					}
				}
				if ("planId" in tempGoalInfo.goals[i].info.items) {
					goal.items.planId = tempGoalInfo.goals[i].info.items.planId;
				} else {
					goal.items.contentId = tempGoalInfo.goals[i].info.items.contentId;
				}
				goalInfos.push(goal);
			}
		}
		if (state === "update") {
			if (goalInfos.length !== 0) {
				dispatch(CmsActions.updateGoalOfAccount(goalInfos, goalId, activated));
			} else {
				dispatch(CmsActions.deleteGoalOfAccount(goalId));
			}
		} else if (state === "create") {
			dispatch(CmsActions.createGoalOfAccount(goalInfos));
		}
		dispatch(PanelActions.pushPanel('goallist', { spotId: title }));
		dispatch(PanelActions.popPanel('goalsettings'));
	}, [dispatch, title, goalId, activated, tempGoalInfo]);

	/* content List state setting(disabled) */
	const updateDisabledChanged = useCallback((selected) => {
		let setContent = [];
		for (let index = 0; index < 7; index++) {
			tempGoalInfo.goals.map((item, i) => {
				if (index === i) {
					setContent.push({ ...item, disabled: !(selected && selected.includes(i)), info: item.info });
				}
			});
		}
		dispatch({ type: types.SET_TEMPORARY_GOAL, payload: { selectDays: selected, goals: setContent } });
	}, [dispatch, tempGoalInfo]);

	/* update info when added content and change content */
	const updateInfoChanged = useCallback((value) => {
		let goalInfo = [];
		let settingsDay = [];
		let focused = 0;
		tempGoalInfo.goals.map(item => {
			if (value.contentChanged) {
				if (value.day === 'everyDay') {
					if (item.disabled) {
						goalInfo.push({ ...item, ["info"]: { day: item.info.day } });
					} else {
						goalInfo.push({ ...item, ["info"]: { ...value, day: item.info.day } });
						settingsDay = panelInfo.selectDays;
					}
				} else if (value.day === item.info.day) {
					goalInfo.push({ ...item, ["info"]: value });
					settingsDay = panelInfo.selectDays;
					focused = value.day;
				} else {
					goalInfo.push({ ...item });
				}
			} else {
				if (value.map(i => i.day === item.info.day)) {
					const content = value.filter(i => i.day === item.info.day);
					goalInfo.push({ ...item, ["disabled"]: content[0] ? content[0].disabled : true, ["info"]: content[0] ? content[0] : {day:item.info.day} });
					settingsDay.push(content[0] && content[0].day);
				}
			}
		});
		/* next conetent onfocus after current content setting */
		if (isEditMode) {
			for (let i = 7; i > focused; i--) {
				if(panelInfo.selectDays && panelInfo.selectDays[panelInfo.selectDays.length-1] <= focused) {
					Spotlight.focus('contentChangeBtn' + focused);
				} else {
					Spotlight.focus('contentChangeBtn' + i);
				}
			}
		} else {
			Spotlight.focus('contentContainer' + focused);
			Spotlight.focus('contentContainer' + (focused+1));
		}
		dispatch({ type: types.SET_TEMPORARY_GOAL, payload: { selectDays, goals: goalInfo} });
		setSelectDays(settingsDay.filter(item => typeof (item) === "number")); //setting content day

		/* change button state */
		setDoneButtonState(false);
		setResetButtonState(false);
	}, [dispatch, tempGoalInfo, selectDays, panelInfo, isEditMode]);

	/* button and toggle click function */
	const onSelectDay = useCallback((ev) => {
		updateDisabledChanged(ev.selected);
		setSelectDays(ev.selected);
	}, [updateDisabledChanged]);

	const onToggleSwitch = useCallback((ev) => {
		setActivated(ev.selected);
		if (ev.selected) {
			updateDisabledChanged(selectDays);
		} else {
			updateDisabledChanged([]);
		}
	}, [updateDisabledChanged, selectDays]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if (panels && panels.length > 1) {
			dispatch(PanelActions.pushPanel('goallist', { spotId: title }));
			dispatch(PanelActions.popPanel('goalsettings'));
		} else {
			dispatch(PanelActions.popPanel('goalsettings'));
		}
	}, [dispatch, panels, title]);

	const onSave = useCallback(() => {
		if (isEditMode) {
			createParms("update");
		} else {
			createParms("create");
		}
	}, [createParms, isEditMode]);

	const onReset = useCallback(() => {
		dispatch({ type: types.RESET_TEMPORARY_GOAL });
		setSelectDays([0, 1, 2, 3, 4, 5, 6]);
		setActivated(true);
		setInitialization(true);
		setResetButtonState(true);
	}, [dispatch, updateInfoChanged]);

	/* onfocus setting when press direction key */
	const onKeyHandleAlarm = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			Spotlight.focus('[data-webos-voice-intent="SelectCheckItem"]');
			ev.stopPropagation();
		}
	}, []);

	const onKeyHandleTButton = useCallback((ev) => {
		if (ev.key === 'ArrowUp') {
			defaultFocus();
			Spotlight.focus(previousFocus.current);
			ev.stopPropagation();
		}
	}, []);

	const onKeyHandleContent = useCallback((isContent) => {
		if (!isContent) {
			previousFocus.current = Spotlight.getCurrent();
			Spotlight.focus('doneBtn');
		}
	},[]);

	const onKeyHandleDayPicker = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			previousFocus.current = Spotlight.getCurrent();
		}
	},[]);

	const onKeyHandleAlarmTButton = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		if (ev.key === 'ArrowDown') {
			Spotlight.focus('doneBtn');
		}
	},[]);

	const onKeyHandleTHeader = useCallback((ev) => {
		if (ev.key === "ArrowDown") {
			Spotlight.focus('switchBtn');
			Spotlight.focus(SpotlightIds.LOGIN_GUIDE_BUTTON);
			ev.stopPropagation();
		}
	},[]);

	/* contentList focus setting */
	const onGoalContentFocus = useCallback((order, bool) => {
		let currentFocus = [false, false, false, false, false, false, false];
		currentFocus[order] = bool;
		setPreviousFocusNum(currentFocusNum);
		setCurrentFocusNum(order);
		setFocus(currentFocus);
	}, [currentFocusNum]);

	const onGoalContentBlur = useCallback(() => {
		setFocus([false, false, false, false, false, false, false]);
	},[]);

	return (
		<TPanel {...rest} className={css.panel} handleCancel={onBack}>
			<THeader title={headerTitle} onClick={onBack} onKeyDown={onKeyHandleTHeader}/>
			<TBody>
			<div className={css.title}>
				<h2>{title === 'goal1' ? $L('Goal 1') : $L('Goal 2')}</h2>
			</div>
			<div className={css.alarm}>
				<span className={css.alarmTitle}>{$L('Alarm')}</span>
				<TSwitchItem
					className={css.alarmSwitch}
					data-webos-voice-label={$L('Alarm')}
					selected={activated}
					spotlightId={"switchBtn"}
					onToggle={onToggleSwitch}
					onKeyDown={onKeyHandleAlarm}
				>
					{activated ? $L('On') : $L('Off')}
				</TSwitchItem>
			</div>
			<div className={css.repeat}>
				<span className={css.repeatTitle}>{$L('Repeat')}</span>
				<div className={css.dayPicker} onKeyDown={onKeyHandleDayPicker}>
					<TDayPicker selected={selectDays} onSelect={onSelectDay} disabled={!activated}/>
				</div>
			</div>
			<Container className={css.goalitem} >
				{tempGoalInfo.goals.map((item, index) =>
					<GoalAddContent
						key={index}
						type={'goal'}
						onKeyHandleContent={onKeyHandleContent}
						onKeyHandleAlarmTButton={onKeyHandleAlarmTButton}
						onGoalContentFocus={onGoalContentFocus}
						onGoalContentBlur={onGoalContentBlur}
						contentInfo={item}
						order={index}
						isEditMode={isEditMode}
						title={title}
						goalId={goalId}
						activated={activated}
						selectDays={selectDays}
						initialization={initialization}
						isFocus={isFocus[index]}
						previousFocusNum={previousFocusNum}
						currentFocusNum={currentFocusNum}
						contentContainer={'contentContainer' + index}
						contentChangeBtn={'contentChangeBtn' + index}
					/>
				)}
			</Container>
			<div onKeyDown={onKeyHandleTButton} className={css.buttonContainer} >
				<TButton
					className={classNames(css.button)}
					disabled={resetButtonState}
					onClick={onReset}
				>
					{$L('Reset All')}
				</TButton>
				<TButton
					className={classNames(css.button)}
					disabled={doneButtonState}
					onClick={onSave}
					spotlightId={'doneBtn'}
				>
					{$L('Done')}
				</TButton>
			</div>
			</TBody>
		</TPanel>

	);
};

export default GoalSettingsPanel;