import {types} from './actionTypes';
import axios from 'axios';
import {Job} from '@enact/core/util';
import * as Config from '../data/Config';
import * as LogTypes from '../data/LogTypes';
import * as ContentType from '../data/ContentType';
import * as CP875Table from '../data/CP875Table';
import {HOME_GROUP_IDS} from '../data/Constants';
import * as Utils from '../utils/common';
import {$L} from '../utils/common';
import * as PanelActions from './panelActions';
import * as CommonActions from './commonActions';
import * as CmsActions from './cmsActions';
import {TAxios} from './TAxios';

import dummyContents from '../../assets/mock/dummyContents.json';
import dummyCategoryTable from '../../assets/mock/dummyCategoryTable.json';
import dummyWorkStyleTable from '../../assets/mock/dummyWorkStyleTable.json';
import dummyOrderedTagList from '../../assets/mock/dummyOrderedTagList.json';
import dummyGoalPlan from '../../assets/mock/dummyGoalPlan.json'
import dummyContentInfo from '../../assets/mock/dummyContentInfo.json';
import dummyActiveBanners from '../../assets/mock/dummyActiveBanners.json';
import dummyYoutubeContents from '../../assets/mock/dummyYoutubeContents.json';
import dummyYoutubeContentsKo from '../../assets/mock/dummyYoutubeContentsKo.json';
import dummyTermsModificationKR from '../../assets/mock/dummyTermsModificationKR.json';
import dummyTermsModificationEN from '../../assets/mock/dummyTermsModificationEN.json';
import dummyNotice from '../../assets/mock/dummyNotice.json';
import dummyFloatingMessage from '../../assets/mock/dummyFloatingMessage.json';

const AUTHORIZATION = {headers: {Authorization: Config.CMS_AUTHORIZATION}};
const dummyChannelds=['UCsLF0qPTpkYKq81HsjgzhwQ', 'UCvGEK5_U-kLgO6-AMDPeTUQ', 'UCX32D3gKXENrhOXdZjWWtMA'];
const dummyChanneldsKo=['UCkTRKCuFrRiXQm_gMG1uDvg', 'UCOj011ViWHIfYIGUf4kvgWQ', 'UCWljWTLRN-CdvmZ3KChy5TQ', 'UCXH1-gZuuOPHvwBUrAvTpvw', 'UCsv1hWoDQNBkTnAJOQacXbw', 'UCc8cQzOKUEHtSshDtS1srzQ'];

export const appLog = (type, value0, value1, value2, value3, value4, value5, value6, value7, value8, value9) => (dispatch, getState) => {
	if(Config.ADMIN_PREVIEW_MODE){
		return;
	}
	const {country, webOSVersion, otaId, deviceId} = getState().appStatus;
	const time = Utils.timeToISO8601Str(new Date());
	let params={
		country, webOs:webOSVersion, platformCode: otaId, deviceId,
		type, description: LogTypes.Description[type], value0, value1, value2, value3, value4, value5, value6, value7, value8, value9: time};

	TAxios(dispatch, getState, "post", Config.APPLOG, params);
};

export const initAccountInfo = () => (dispatch, getState) => {
	dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: '', workoutStyleId: Config.UNKNOWN_WORKOUTSTYLEID}});
}

export const getWorkStyleList = () => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.workoutstyle){
		//ignore unknown
		const styles = adminPreviewData.workoutstyle;
		for(let i=0;i<styles.length;i++){
			styles[i].key = i;
		}
		dispatch({type: types.GET_WORKSTYLES_LIST, payload: styles});
		return;
	}
	const query = {
		language: deviceAccountInfo.language
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyWorkStyleTable;
		}
		if (response && response.status === 200) {
			//ignore unknown
			const styles = response.data.data.filter((elem) => (elem.workoutStyleId !== Config.UNKNOWN_WORKOUTSTYLEID));
			for(let i=0;i<styles.length;i++){
				styles[i].key = i;
			}
			dispatch({type: types.GET_WORKSTYLES_LIST, payload: styles});
		}
	};
	TAxios(dispatch, getState, "get", Config.WORKSTYLES, query, onSuccess);
};
export const getBackground = () => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.background){
		dispatch({type: types.GET_BACKGROUND, payload: adminPreviewData.background});
		return;
	}
	const onSuccess = (response) => {
		if (response && response.status === 200) {
			if(response.data.data && response.data.data[0]){
				dispatch({type: types.GET_BACKGROUND, payload: response.data.data[0]});
			}
		}
	};
	TAxios(dispatch, getState, "get", Config.BACKGROUND, {}, onSuccess);
};
export const getAccount = (accountId) => (dispatch, getState) => {
	let resAccountId = '', resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
	const supportLogin = getState().supportLogin;
	const supportLoginWithDeviceId = getState().supportLoginWithDeviceId;

	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			resAccountId = response.data.data.accountId;
			resWorkoutStyleId = response.data.data.workoutStyleId;
		} else {
			resAccountId = '';
			resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
		}
		if(!Config.SUPPORT_WORKOUTSTYLE){
			resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
		}
		dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: resAccountId, workoutStyleId: resWorkoutStyleId}});
		if (!resAccountId || resWorkoutStyleId <= Config.UNKNOWN_WORKOUTSTYLEID) {
			if(Config.SUPPORT_WORKOUTSTYLE){
				dispatch(PanelActions.pushPanel('workoutstyle'));
			}else if(!resAccountId){
				dispatch(createAccount(2));
			}else{
				dispatch(updateAccount(resWorkoutStyleId));
			}
		}else {
			dispatch(updateAccount(resWorkoutStyleId));
		}
		dispatch({type: types.CHANGE_APP_STATUS, status: {checkingAccountId: false}});
	};
	const onFail = () => {
		dispatch({type: types.CHANGE_APP_STATUS, status: {checkingAccountId: false}});
		if(supportLoginWithDeviceId || supportLogin || Config.USE_DUMMY){
			if(Config.SUPPORT_WORKOUTSTYLE){
				dispatch(PanelActions.pushPanel('workoutstyle'));
			}else{
				dispatch(createAccount(2));
			}
		}
	};
	TAxios(dispatch, getState, "get", [Config.ACCOUNT,accountId], {}, onSuccess, onFail);
};

export const createAccount = (workoutStyleId) => (dispatch, getState) => {
	let resAccountId = '', resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const supportLoginWithDeviceId = getState().supportLoginWithDeviceId;
	const deviceId = getState().appStatus.deviceId;

	const params = {
		accountId: deviceAccountInfo.accountId,
		workoutStyleId: workoutStyleId,
		language: deviceAccountInfo.language,
		timezoneOffset: new Date().getTimezoneOffset()*(-1)
	};
	if(!params.accountId && supportLoginWithDeviceId){
		params.accountId = deviceId;
	}

	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			resAccountId = response.data.data.accountId;
			resWorkoutStyleId = response.data.data.workoutStyleId;
		} else {
			resAccountId = '';
			resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
		}
		if(!Config.SUPPORT_WORKOUTSTYLE){
			resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
		}
		dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: resAccountId, workoutStyleId: resWorkoutStyleId}});
	};
	const onFail = () => {
		if(Config.SUPPORT_WORKOUTSTYLE){
			dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: resAccountId, workoutStyleId: resWorkoutStyleId}});
		}
	};
	TAxios(dispatch, getState, "post", Config.ACCOUNT, params, onSuccess, onFail);
};
export const updateAccount = (workoutStyleId) => (dispatch, getState) => {
	let resAccountId = '', resWorkoutStyleId = Config.UNKNOWN_WORKOUTSTYLEID;
	const accountInfo = getState().accountInfo;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const params = {
		workoutStyleId: workoutStyleId,
		language: deviceAccountInfo.language,
		timezoneOffset: new Date().getTimezoneOffset()*(-1)
	};
	const onSuccess = (response) => {
		if(!Config.SUPPORT_WORKOUTSTYLE){
			//do nothing
			return;
		}
		if (response && response.data.message === 'OK') {
			resAccountId = response.data.data.accountId;
			resWorkoutStyleId = response.data.data.workoutStyleId;
		} else {
			resAccountId = accountInfo.accountId;
			resWorkoutStyleId = accountInfo.workoutStyleId;
		}
		dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: resAccountId, workoutStyleId: resWorkoutStyleId}});
	};
	const onFail = () => {
		if(!Config.SUPPORT_WORKOUTSTYLE){
			//do nothing
			return;
		}
		dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: resAccountId, workoutStyleId: resWorkoutStyleId}});
	};
	TAxios(dispatch, getState, "put", [Config.ACCOUNT,accountInfo.accountId], params, onSuccess, onFail);
};

const getContentInfoQueue = [];
export const getContentInfo = (contentId, immediately, callback) => (dispatch, getState) => {
	if(!contentId){
		return;
	}
	if(immediately){
		dispatch(doGetContentInfo(contentId, callback));
	}else{
		if(callback){
			console.warn('getContentInfo has callback but not immediately called');
		}
		getContentInfoQueue.push(contentId);
		if(getContentInfoQueue.length === 1){
			getContentInfoJob.start(dispatch);
		}
	}
};
const doGetContentInfoQueue = (dispatch) => {
	if(getContentInfoQueue.length > 0){
		const contentId = getContentInfoQueue.pop();
		dispatch(doGetContentInfo(contentId));
	}
}
const doGetContentInfo = (contentId, callback)  => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.content){
		const content = adminPreviewData.content;
		dispatch({type: types.GET_CONTENT_INFO, payload: {key: contentId, content : content}});
		return;
	}
	const accountInfo = getState().accountInfo;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const contentInfo = getState().contentInfos[contentId];
	if(contentInfo){
		console.log('getContentInfo exist....ignore',contentInfo);
		if(callback){
			callback(true);
		}
		getContentInfoJob.startAfter(0, dispatch);
		return;
	}
	const query = {
		accountId: accountInfo.accountId,
		language: deviceAccountInfo.language
	};
	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			if(response.data.data.title){
				response.data.data.title = Utils.convertUtf8ToUni(response.data.data.title);
			}
			dispatch({type: types.GET_CONTENT_INFO, payload: {key: contentId, content : response.data.data}});
			if(response.data.data.contentType === ContentType.SERIES){
				console.log('it is series contents.......');
				for ( let index in response.data.data.clipList) {
					const content = response.data.data.clipList[index];
					dispatch(getContentInfo(content.contentId, false));
				}
			}
			if(callback){
				callback(true);
			}
		}
		getContentInfoJob.start(dispatch);
	};
	const onFail = () => {
		if(callback){
			callback(false);
		}
		getContentInfoJob.start(dispatch);
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENTINFO,contentId], query, onSuccess, onFail);
};

let getContentInfoJob = new Job(doGetContentInfoQueue, 100);

const registYoutubeQueue = [];
export const registYoutubeVideo = (item, immediately, callback) => (dispatch, getState) => {
	if(!item){
		return;
	}
	if(immediately){
		dispatch(doRegistYoutubeVideo(item, callback));
	}else{
		if(callback){
			console.warn('registYoutubeVideo has callback but not immediately called');
		}
		registYoutubeQueue.push(item);
		if(registYoutubeQueue.length === 1){
			registYoutubeJob.start(dispatch);
		}
	}
};
const doRegistYoutubeQueue = (dispatch) => {
	if(registYoutubeQueue.length > 0){
		const item = registYoutubeQueue.pop();
		dispatch(doRegistYoutubeVideo(item));
	}
}
const doRegistYoutubeVideo = (item, callback) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	if(!isNaN(item.contentId)){ //ignore number
		if(callback){
			callback({});
		}
		registYoutubeJob.startAfter(0, dispatch);
		return;
	}
	const params = {
		youtubeId: item.contentId,
		title: Utils.convertUniToUtf8(item.title),
		thumbnailImageUrl: item.thumbnailImageUrl,
		language: deviceAccountInfo.language,
		update: false
	};
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch({type: types.UPDATE_YOUTUBE_VIDEOINFO, payload: response.data});
			const newParams = {...params};
			newParams.contentId=response.data.contentId;
			newParams.youtubeId=response.data.youtubeId;
			newParams.contentType=ContentType.YOUTUBE_VIDEO;
			newParams.title = Utils.convertUtf8ToUni(newParams.title);
			dispatch({type: types.GET_CONTENT_INFO, payload: {key: newParams.contentId, content : newParams}});
			if(callback){
				callback(newParams);
			}
		}
		registYoutubeJob.start(dispatch);
	};
	const onFail = () => {
		if(callback){
			callback({});
		}
		registYoutubeJob.start(dispatch);
	};
	TAxios(dispatch, getState, "post", Config.REGIST_CONTENTS_YOUTUBE, params, onSuccess, onFail);
};

let registYoutubeJob = new Job(doRegistYoutubeQueue, 100);

export const registCpContent = (item, callback) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	if(!isNaN(item.contentId)){ //ignore number
		if(callback){
			callback({});
		}
		return;
	}
	const params = {
		cpContentId: item.cpContentId,
		title: Utils.convertUniToUtf8(item.title),
		badgeIconUrl: item.badgeIconUrl,
		language: deviceAccountInfo.language,
		update: false
	};
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			const newParams = {...params};
			item.contentId = response.data.contentId;
			newParams.contentId=response.data.contentId;
			newParams.cpContentId=response.data.cpContentId;
			newParams.contentType=ContentType.CP_CONTENT;
			newParams.title = Utils.convertUtf8ToUni(newParams.title);
			newParams.description = item.description;
			dispatch({type: types.GET_CONTENT_INFO, payload: {key: newParams.contentId, content : newParams}});
			if(callback){
				callback(newParams);
			}
		}
	};
	const onFail = () => {
		if(callback){
			callback({});
		}
	};
	TAxios(dispatch, getState, "post", Config.REGIST_CONTENTS_CPCONTENT, params, onSuccess, onFail);
};

export const getAlarmsOfAccount = () => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			const checkedTitle = [];
			let alarm1;
			let alarm2;
			for (let i = 0; i < response.data.data.length; i++) {
				if (checkedTitle.indexOf(response.data.data[i].title) < 0) {
					checkedTitle.push(response.data.data[i].title);
					if (response.data.data[i].title === 'alarm1') {
						alarm1 = response.data.data[i];
					}
					if (response.data.data[i].title === 'alarm2') {
						alarm2 = response.data.data[i];
					}
				}
			};
			dispatch({ type:types.GET_ALARM_OF_ACCOUNT, payload: {alarm1, alarm2}});
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_ALARMS,accountId], {}, onSuccess);
};
const getHomeGroup = () => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.homeGroup){
		dispatch({type: types.GET_HOME_GROUP, payload: adminPreviewData.homeGroup});
		return;
	}
	dispatch(CommonActions.addLoadingStatus("getHomeGroup"));

	const onSuccess = (response) => {
		let matched = [];
		const now = Utils.convertLocalTimetoUTC(new Date());
		for ( let index=0; index<response.data.data.length; index++) {
			const menu = response.data.data[index];
			if(now < new Date(menu.openFrom) || menu.openStatus !== 'OPEN'){
				continue;
			}else if(menu.homeMenuId){
				matched.push(menu.homeMenuId);
			}
		}
		dispatch({type: types.GET_HOME_GROUP, payload: matched});
		dispatch(CommonActions.removeLoadingStatus("getHomeGroup"));
	};
	const onFail = () => {
		dispatch(CommonActions.removeLoadingStatus("getHomeGroup"));
	};
	TAxios(dispatch, getState, "get", Config.GET_HOME_GROUP, {}, onSuccess, onFail);
};
const getHomeGroup2 = () => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		language: deviceAccountInfo.language
	};
	dispatch(CommonActions.addLoadingStatus("getHomeGroup2"));

	const onSuccess = (response) => {
		let matched = [];
		let homeGroupHasRoutine = false;
		let homeGroupHasCustomizedWorkout = false;
		for ( let index=0; index<response.data.data.length; index++) {
			const menu = response.data.data[index];
			if(menu.openStatus === 'OPEN'){
				matched.push(menu);
				if(menu.homeGroupType === 'TAG'){
					if(menu.cpContentsApi){
						dispatch(getCPContents({tagId: menu.id, ...menu}));
					}else{
						dispatch(getContentsOfTag({tagId: menu.id}));
					}
				}else if(menu.id === HOME_GROUP_IDS.HOME_TODAY_TOP){
					dispatch(getContentsTops());
				}else if(menu.id === HOME_GROUP_IDS.HOME_RECENTLY_ADDED){
					dispatch(getContentsRecentlyAdded());
				}else if(menu.id === HOME_GROUP_IDS.HOME_YOUTUBE){
					dispatch(getYoutubeVideos());
				}else if(menu.id === HOME_GROUP_IDS.HOME_MY_TRAINING_PLAN){
					homeGroupHasRoutine = true;
				}
				// will be called in getAccountBasedLists
				else if(menu.id === HOME_GROUP_IDS.CUSTOMIZED_WORKOUT){
					homeGroupHasCustomizedWorkout = true;
					if(Config.ADMIN_PREVIEW_MODE){
						dispatch(getCustomizedWorkOutSchedule());
					}
				}
				else if(menu.id === HOME_GROUP_IDS.HOME_THIRD_PARTY){
					dispatch(getThirdPartyApps());
				}
			}
		}
		if(!homeGroupHasCustomizedWorkout){
			dispatch(PanelActions.popPanel('interview'));
		}
		dispatch(CommonActions.changeAppStatus({homeGroupHasRoutine, homeGroupHasCustomizedWorkout}));
		dispatch({type: types.GET_HOME_GROUP2, payload: matched});
		dispatch(CommonActions.removeLoadingStatus("getHomeGroup2"));
	};
	const onFail = () => {
		dispatch(CommonActions.removeLoadingStatus("getHomeGroup2"));
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.homeGroup){
		onSuccess({data:{data: adminPreviewData.homeGroup}});
		return;
	}
	TAxios(dispatch, getState, "get", Config.GET_HOME_GROUP2, query, onSuccess, onFail);
};
const getCategoriesTable = () => (dispatch, getState) => {
	const categoriesTableSelected = getState().categoriesTableSelected;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.category){
		dispatch({type: types.GET_CATEGORIES_TABLE, payload: adminPreviewData.category});
		dispatch({type: types.GET_CATEGORIES_TABLE_SELECTED, payload: adminPreviewData.category[0].categoryId });
		return;
	}
	dispatch(CommonActions.addLoadingStatus("getCategoriesTable"));
	const query = {
		language: deviceAccountInfo.language
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyCategoryTable;
		}
		if (response && response.data.message === 'OK') {
			for(let i=0;i<response.data.data.length;i++){
				response.data.data[i].key = i;
			}
			dispatch({type: types.GET_CATEGORIES_TABLE, payload: response.data.data});
			if(categoriesTableSelected < 0){
				dispatch({type: types.GET_CATEGORIES_TABLE_SELECTED, payload: response.data.data[0].categoryId });
			}
		}
		dispatch(CommonActions.removeLoadingStatus("getCategoriesTable"));
	};
	const onFail = () => {
		dispatch(CommonActions.removeLoadingStatus("getCategoriesTable"));
		if (typeof window === 'object' && window.PalmSystem) {
			dispatch(CommonActions.changeAppStatus({connectionFailed: true}));
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_ACTIVE_CATEGORIES, query, onSuccess, onFail);
};
export const getActiveBanners = () => (dispatch, getState) => {
	dispatch(CommonActions.addLoadingStatus("getActiveBanners"));
	const adminPreviewData = getState().adminPreviewData;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		language: deviceAccountInfo.language
	};

  /** API 2.0 */
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
		response.data = dummyActiveBanners;
		}
		if (response && response.data.data) {
			let activeBanner =null;
			if(response.data.data.length < 0){
				console.error('No matched content');
				dispatch(CommonActions.removeLoadingStatus("getActiveBanners"));
			}else{
				activeBanner = response.data.data[Math.floor(Math.random() * response.data.data.length)];
				if(activeBanner.bannerLinkType && activeBanner.bannerLinkType != 'NONE'){
					activeBanner.bannerLinkTitle = Utils.convertNormalStr(activeBanner.bannerLinkTitle);
				}
				if(activeBanner.bannerLinkType === 'CONTENT'){
					dispatch(getContentInfo(Number(activeBanner.bannerLinkId), true, ()=>{
						dispatch({type: types.GET_ACTIVE_BANNER, payload: activeBanner});
					}));
				}else{
					dispatch({type: types.GET_ACTIVE_BANNER, payload: activeBanner});
				}
				dispatch(CommonActions.removeLoadingStatus("getActiveBanners"));
			}
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch({type: types.GET_ACTIVE_BANNER, payload: dummyActiveBanners.data[0].content});
		}
		dispatch(CommonActions.removeLoadingStatus("getActiveBanners"));
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.activeBanner){
		onSuccess({data:{data: adminPreviewData.activeBanner}});
		return;
	}
	TAxios(dispatch, getState, "get", Config.GET_ACTIVE_BANNERS, query, onSuccess, onFail);
};
let getContentsOfCategoryKey=null;
export const getContentsOfCategory = (categoryId, accountId, page=0, key) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.categoryItems && adminPreviewData.categoryItems[categoryId]){
		dispatch({type: types.GET_CONTENTS_CATEGORY, payload: adminPreviewData.categoryItems[categoryId], categoryId});
		for(let i=0; i< adminPreviewData.categoryItems[categoryId].length; i++){
			dispatch(getContentInfo(adminPreviewData.categoryItems[categoryId][i].contentId, true));
		}
		return;
	}
	const query = {
		accountId: accountId,
		language: deviceAccountInfo.language,
		size: Config.CMS_SIZE_LIMIT,
		page: page
	};
	let currentKey = key;
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			if(page === 0){
				getContentsOfCategoryKey = new Date();
				currentKey = getContentsOfCategoryKey
				dispatch({type: types.GET_CONTENTS_CATEGORY, payload: response.data.data, categoryId});
			}else if(getContentsOfCategoryKey === currentKey){
				dispatch({type: types.GET_CONTENTS_CATEGORY, payload: response.data.data, categoryId, append: true});
			}
			if(getContentsOfCategoryKey === currentKey && response.data.totalElements > (page+1)*Config.CMS_SIZE_LIMIT){
				dispatch(getContentsOfCategory(categoryId, accountId, page+1, currentKey));
			}
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch({type: types.GET_CONTENTS_CATEGORY, payload:dummyContents.data, categoryId});
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENTS_OF_CATEGORY,categoryId], query, onSuccess, onFail);
};
const getRelatedContentWithTag = async (contentId, accountInfo, deviceAccountInfo, useQAServerURL) => {
	try {
		const query = {
			accountId: accountInfo.accountId,
			language: deviceAccountInfo.language
		};
		const url = `${Config.GYMURL(deviceAccountInfo.language, Config.GET_RELATEDCONTENT_TAG, useQAServerURL)}/${contentId}?${Utils.createQueryString(query)}`;
		if(useQAServerURL !== 'prd'){
			console.log('getRelatedContentWithTag url...', url);
		}
		const response = await axios.get(url, AUTHORIZATION);
		if(useQAServerURL !== 'prd'){
			console.log('getRelatedContentWithTag response....', response);
		}
		if (response && response.data.message === 'OK') {
			return response.data.data;
		}
		return [];
	} catch (err) {
		console.error('getRelatedContentWithTag ', err);
		return [];
	}
};
const getRelatedContentWithCategory = async (contentId, accountInfo, deviceAccountInfo, useQAServerURL) => {
	try {
		const query = {
			accountId: accountInfo.accountId,
			language: deviceAccountInfo.language
		};
		const url = `${Config.GYMURL(deviceAccountInfo.language, Config.GET_RELATEDCONTENT_CATEGORY, useQAServerURL)}/${contentId}?${Utils.createQueryString(query)}`;
		if(useQAServerURL !== 'prd'){
			console.log('getRelatedContentWithCategory url...', url);
		}
		const response = await axios.get(url, AUTHORIZATION);
		if(useQAServerURL !== 'prd'){
			console.log('getRelatedContentWithCategory response....', response);
		}
		if (response && response.data.message === 'OK') {
			return response.data.data;
		}
		return [];
	} catch (err) {
		console.error('getRelatedContentWithCategory ', err);
		return [];
	}
};
export const getRelatedContents = async (contentId, accountInfo, deviceAccountInfo, useQAServerURL) => {
	try {
		const relatedWithTagList = await getRelatedContentWithTag(contentId, accountInfo, deviceAccountInfo, useQAServerURL);
		const relatedWithCategoryList = await getRelatedContentWithCategory(contentId, accountInfo, deviceAccountInfo, useQAServerURL);
		let relatedWithTagAndCategoryList = [];
		if(relatedWithTagList.length > relatedWithCategoryList.length){
			relatedWithTagAndCategoryList = [...relatedWithTagList,...relatedWithCategoryList]
		}else{
			relatedWithTagAndCategoryList = [...relatedWithCategoryList,...relatedWithTagList]
		}
		//remove duplicate item
		relatedWithTagAndCategoryList = [...new Set(relatedWithTagAndCategoryList.map(JSON.stringify))].map(JSON.parse);

		if(Config.USE_DUMMY){
			relatedWithTagAndCategoryList = dummyContents;
		}
		return relatedWithTagAndCategoryList;
	} catch (err) {
		return [];
	}
};

const getContentsTops = (accountId) => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.content){
		const content = adminPreviewData.content;
		dispatch({type: types.GET_CONTENTS_TOPS, payload:[content]});
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.contents){
		dispatch({type: types.GET_CONTENTS_TOPS, payload: adminPreviewData.contents});
		return;
	}
	dispatch(CommonActions.addLoadingStatus("getContentsTops"));
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		accountId: accountId,
		language: deviceAccountInfo.language,
		size: 10
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			dispatch({type: types.GET_CONTENTS_TOPS, payload: response.data.data});
		}
		dispatch(CommonActions.removeLoadingStatus("getContentsTops"));
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch({type: types.GET_CONTENTS_TOPS, payload: dummyContents.data});
		}
		dispatch(CommonActions.removeLoadingStatus("getContentsTops"));
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_TOPS, query, onSuccess, onFail);
};

export const getContentsRecommended = (workoutStyleId) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.recommended){
		dispatch({type: types.GET_CONTENTS_RECOMMENDED, payload: adminPreviewData.recommended});
		for(let i=0; i< adminPreviewData.recommended.length; i++){
			dispatch(getContentInfo(adminPreviewData.recommended[i].contentId, true));
		}
		return;
	}
	const query = {
		workoutStyleId: workoutStyleId,
		language: deviceAccountInfo.language
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			if(response.data.data.length > 0){
				dispatch({type: types.GET_CONTENTS_RECOMMENDED, payload: response.data.data});
				for(let i=0; i< response.data.data.length; i++){ //for postimageurl
					dispatch(getContentInfo(response.data.data[i].contentId, false));
				}
			}
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch({type: types.GET_CONTENTS_RECOMMENDED, payload: dummyContents.data});
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_RECOMMENDED, query, onSuccess, onFail);
};
const getContentsRecentlyAdded = (accountId) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		accountId: accountId,
		language: deviceAccountInfo.language,
		size: Config.HLIST_HOME_MAX
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			dispatch({type: types.GET_CONTENTS_RECENTLY_ADDED, payload: response.data.data});
		}
	};
	const onFail = () => {
		if(Config.USE_DUMMY){
			dispatch({type: types.GET_CONTENTS_RECENTLY_ADDED, payload: dummyContents.data});
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_RECENTLY_ADDED, query, onSuccess, onFail);
};

let getYoutubeVideosKey=null;
const getYoutubeVideos = (page=0, key) => (dispatch, getState) => {
	let youtubeContents;
	const adminPreviewData = getState().adminPreviewData;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		language: deviceAccountInfo.language,
		key : Config.YOUTUBE_AUTHORIZATION,
		page: page
	};
	if(Config.USE_YOUTUBE_DUMMY) {
		if(deviceAccountInfo.language === 'ko'){
			youtubeContents = dummyYoutubeContentsKo;
		}else{
			youtubeContents = dummyYoutubeContents;
		}
		dispatch({type: types.GET_YOUTUBE_VIDEOS, payload: youtubeContents});
		return;
	}
	let currentKey = key;
	const onSuccess = (response) => {
		let youtubeChannels = []
		for(let i=0; i< response.data.data.length; i++){
			if(response.data.data[i].contentType === 'youtube#channel'){
				youtubeChannels = youtubeChannels.concat(response.data.data[i])
			}
		}
		if(page === 0){
			getYoutubeVideosKey = new Date();
			currentKey = getYoutubeVideosKey;
			dispatch({type: types.GET_YOUTUBE_CHANNELS, payload: youtubeChannels})
			dispatch({type: types.GET_YOUTUBE_VIDEOS, payload: response.data.data});
		}else if(getYoutubeVideosKey === currentKey){
			dispatch({type: types.GET_YOUTUBE_CHANNELS, payload: youtubeChannels, append: true})
			dispatch({type: types.GET_YOUTUBE_VIDEOS, payload: response.data.data, append: true});
		}
		if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.youtube){
			//do nothing
		}else if(getYoutubeVideosKey === currentKey && response.data.totalElements > (page+1)*Config.CMS_SIZE_LIMIT){
			dispatch(getYoutubeVideos(page+1, currentKey));
		}
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.youtube){
		onSuccess({data:{data: adminPreviewData.youtube}, totalElements: adminPreviewData.youtube.length});
		return;
	}
	TAxios(dispatch, getState, "get", Config.GET_YOUTUBE_VIDEOS, query, onSuccess);
};

const getContentsRecentlyWatched = () => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const query = {
		size: Config.HLIST_HOME_MAX
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			dispatch({type: types.GET_CONTENTS_RECENTLY_WATCHED, payload: response.data.data});
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENTS_RECENTLYWATCHED,accountId], query, onSuccess);
}

let getContentsOfFavoritesKey=null;
const getContentsOfFavorites = (page=0, key) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const query = {
		size: Config.CMS_SIZE_LIMIT,
		page: page
	};
	let currentKey = key;
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			if(page === 0){
				getContentsOfFavoritesKey = new Date();
				currentKey = getContentsOfFavoritesKey;
				dispatch({type: types.GET_CONTENTS_MY_FAVORITES, payload: response.data.data});
			}else if(getContentsOfFavoritesKey === currentKey){
				dispatch({type: types.GET_CONTENTS_MY_FAVORITES, payload: response.data.data, append: true});
			}
			if(getContentsOfFavoritesKey === currentKey && response.data.totalElements > (page+1)*Config.CMS_SIZE_LIMIT){
				dispatch(getContentsOfFavorites(page+1, currentKey));
			}
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENTS_FAVORITE,accountId], query, onSuccess);
};
const getContentsOfTagQueue = [];
const doGetContentsOfTagQueue = (dispatch) => {
	if(getContentsOfTagQueue.length > 0){
		const data = getContentsOfTagQueue.pop();
		if(data.cpContentsApi){
			dispatch(getCPContents(data));
		}else{
			dispatch(getContentsOfTag(data));
		}
	}
};
const getContentsOfTagJob = new Job(doGetContentsOfTagQueue, 10);

const getCPContents = (data) => (dispatch, getState) => {
	const {webOSVersion} = getState().appStatus;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	const contentsOfTag = getState().contentsOfTag;

	const query = {
		language: deviceAccountInfo.language
	};
	if(!(data && data.tagId >= 0)){
		return;
	}
	const tagId = data.tagId;

	if(contentsOfTag[tagId]){
		console.log('getCPContents exist....ignore',tagId);
		getContentsOfTagJob.startAfter(0, dispatch);
		return;
	}
	const url = data.cpContentsApi;
	console.log('getCPContents url...', url, data);
	const authorization = {headers: {Authorization: data.cpContentsAuthKey}};
	axios.get(url, authorization)
	.then((response) => {
		console.log('getCPContents response....', response);
		if (response && response.data.message === 'OK') {
			const appId = response.data.appId;
			const resOs = response.data.webOsVersion;
			const badgeIconUrl = response.data.badgeIconUrl;
			if(Number(resOs) != NaN && Number(resOs) > Number(webOSVersion)){
				console.warn('getCPContents not supported version', Number(resOs), appId);
				getContentsOfTagJob.start(dispatch);
				return;
			}
			const items = [];
			for(let i=0; i< response.data.data.length; i++){
				const item = {};
				item.cpContentId = appId+'/'+response.data.data[i].contentId;
				item.contentId = item.cpContentId;
				item.title = Utils.convertUtf8ToUni(response.data.data[i].title);
				item.contentType = ContentType.CP_CONTENT;
				item.description = data.cpContentsSubtitle;
				item.badgeIconUrl = badgeIconUrl;
				item.postImageUrl = response.data.data[i].postImageUrl;
				item.thumbnailImageUrl = response.data.data[i].thumbnailImageUrl;
				items.push(item);
			}
			dispatch({type: types.GET_CONTENTS_TAG, payload: {[tagId]: items}});
		}
		getContentsOfTagJob.start(dispatch);
	})
	.catch((err) => {
		console.error('getCPContents ', err);
		getContentsOfTagJob.start(dispatch);
	});
};

const getContentsOfTag = (data) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	const contentsOfTag = getState().contentsOfTag;

	const query = {
		language: deviceAccountInfo.language
	};
	if(!(data && data.tagId >= 0)){
		return;
	}
	const tagId = data.tagId;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.contents){
		dispatch({type: types.GET_CONTENTS_TAG, payload: {[tagId]: adminPreviewData.contents}});
		for(let i=0; i< adminPreviewData.contents.length; i++){
			dispatch(getContentInfo(adminPreviewData.contents[i].contentId, true));
		}
		return;
	}
	if(contentsOfTag[tagId]){
		console.log('getContentsOfTag exist....ignore',tagId);
		getContentsOfTagJob.startAfter(0, dispatch);
		return;
	}
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			dispatch({type: types.GET_CONTENTS_TAG, payload: {[tagId]: response.data.data}});
		}
		getContentsOfTagJob.start(dispatch);
	};
	const onFail = () => {
		getContentsOfTagJob.start(dispatch);
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENTS_OF_TAG,tagId], query, onSuccess, onFail);
};

export const getOrderedTagList = (workoutStyleId) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const adminPreviewData = getState().adminPreviewData;
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.workoutstyleItems && adminPreviewData.workoutstyleItems[workoutStyleId]){
		const tagList = adminPreviewData.workoutstyleItems[workoutStyleId];
		dispatch({type: types.GET_ORDERED_TAG_LIST, payload: {[workoutStyleId]:tagList}});
		if(tagList.length > 0){
			for(let i= tagList.length-1; i>=0; i--){
				if(tagList[i]){
					getContentsOfTagQueue.push(tagList[i]);
				}
			}
			getContentsOfTagJob.start(dispatch);
		}
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.tagList){
		const styleId = workoutStyleId <=0 ? Config.UNKNOWN_WORKOUTSTYLEID: workoutStyleId;
		const tagList = adminPreviewData.tagList;
		dispatch({type: types.GET_ORDERED_TAG_LIST, payload: {[styleId]:adminPreviewData.tagList}});
		if(tagList.length > 0){
			for(let i= tagList.length-1; i>=0; i--){
				if(tagList[i]){
					getContentsOfTagQueue.push(tagList[i]);
				}
			}
			getContentsOfTagJob.start(dispatch);
		}
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.tagItems){
		const tagList= [{tagId: 1, position:0, ...adminPreviewData.tagItems}];
		dispatch({type: types.GET_ORDERED_TAG_LIST, payload: {[workoutStyleId]:tagList}});
		getContentsOfTagQueue.push(tagList[0]);
		getContentsOfTagJob.start(dispatch);
		return;
	}
	const query = {
		workoutStyleId: workoutStyleId,
		language: deviceAccountInfo.language
	};
	const onSuccess = (response) => {
		if(Config.USE_DUMMY){
			response.data = dummyOrderedTagList;
		}
		if (response && response.data.message === 'OK') {
			if(Config.CPCONTENTS_TEST){
				response.data.data.length +=1;
				response.data.data.push({
					tagId: 1000,
					position: response.data.data.length,
					cpContentsApi: "https://api.1mhomedance.com/api/getLgFitnessRecommendContents",
					//256이상임 db는 512로 설계
					cpContentsAuthKey: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIwYzc0ZWE2ZDNjZDViOWIyN2QxZWUyYzdkYjFjYjE5MCIsImp0aSI6IjRkMjNjYjUxLTgyZmUtNGRmYy05ODQ0LTViODY0NTcwNGNmNiIsInJvbGUiOiJST0xFX1NFUlZFUiIsImlkIjowLCJleHAiOjE5NjA2OTU2MTgsImlhdCI6MTY0NTE2MjgxOH0.1vZVqBLz5NlgdnCBlNFG60CSV9I-qmCfIiB4bDUUCyU",
					cpContentsSubtitle: "CP추천 콘텐츠를 테스트 확인해보세요.",
					title: "CP Contents Dummy Test"
				});
				console.log('getOrderedTagList CPCONTENTS_TEST response....', response, workoutStyleId);
			}

			dispatch({type: types.GET_ORDERED_TAG_LIST, payload: {[workoutStyleId]:response.data.data}});
			if(response.data.data.length > 0){
				for(let i= response.data.data.length-1; i>=0; i--){
					if(response.data.data[i]){
						getContentsOfTagQueue.push(response.data.data[i]);
					}
				}
				getContentsOfTagJob.start(dispatch);
			}
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_ORDEREDTAGLIST, query, onSuccess);
};

export const getGoalsOfAccount = () => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			const checkedTitle = [];
			const validList = [];
			for (let i = 0; i < response.data.data.length; i++) {
				if (checkedTitle.indexOf(response.data.data[i].title) < 0
				&& response.data.data[i].items && response.data.data[i].items.length > 0) {
					checkedTitle.push(response.data.data[i].title);
					validList.push(response.data.data[i]);
				}
			};
			dispatch({ type: types.GET_GOALS_OF_ACCOUNT, payload: validList });
			for (let i = 0; i < validList.length; i++) {
				for (let j = 0; j < validList[i].items.length; j++) {
					if(validList[i].items[j].contentId){
						dispatch(getContentInfo(validList[i].items[j].contentId, false));
					}
				}
			}
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_GOALS_OF_ACCOUNT,accountId], {}, onSuccess);
};

export const createGoalOfAccount = (value) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	let items = [];
	let alarms = [];
	for (let i = 0; i < value.length; i++) {
		items.push(value[i].items);
		alarms.push(value[i].alarms);
	}
	const params = {
		title: value[0].title,
		alarmActivated: value[0].alarmActivated,
		items: items,
		alarms: alarms
	}

	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			console.log('data...', response.data.data);
			const day=[], time=[];
			for(let i=0; i<items.length; i++){
				day.push(items[i].weekday);
				time.push(items[i].time);
			}
			dispatch(appLog(LogTypes.Types.ALARMSETTING, 'goal', 'CREATE', params.title, response.data.data.goalId, day, time, params.alarmActivated ));
		}
		dispatch(getGoalsOfAccount());
	};
	TAxios(dispatch, getState, "post", [Config.GET_GOALS_OF_ACCOUNT,accountId], params, onSuccess);
};

/*
 list log out states
*/
export const getCommonLists = () => async (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	dispatch(CommonActions.addLoadingStatus("getCommonLists"));
	if (Config.ADMIN_PREVIEW_MODE && adminPreviewData.termsModification) {
		dispatch(getTermsModification());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if (Config.ADMIN_PREVIEW_MODE && adminPreviewData.terms) {
		dispatch(getTermsDoc());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.homeGroup){
		dispatch(getActiveBanners());
		dispatch(getHomeGroup2());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}

	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.content){
		dispatch(getActiveBanners());
		dispatch(getContentsTops());
		dispatch({type: types.GET_HOME_GROUP2, payload: [{id: HOME_GROUP_IDS.HOME_TODAY_TOP, title: $L("Today's Top 10")}]});
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.contents){
		dispatch(getActiveBanners());
		dispatch(getContentsTops());
		dispatch({type: types.GET_HOME_GROUP2, payload: [{id: HOME_GROUP_IDS.HOME_TODAY_TOP, title: $L("Today's Top 10")}]});
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && (adminPreviewData.category || adminPreviewData.categoryItems)){
		dispatch(getCategoriesTable());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && (adminPreviewData.workoutstyle || adminPreviewData.workoutstyleItems)){
		dispatch(getActiveBanners());
		// if(adminPreviewData.recommended){
		// 	dispatch(getContentsRecommended());
		// }
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.recommended){
		dispatch(getActiveBanners());
		// dispatch(getContentsRecommended());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	// 안씀.
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.tagList){
		dispatch(getActiveBanners());
		// dispatch(getOrderedTagList(Config.UNKNOWN_WORKOUTSTYLEID));
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.tagItems){
		dispatch(getActiveBanners());
		// dispatch(getOrderedTagList(Config.UNKNOWN_WORKOUTSTYLEID));
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.noticeList){
		dispatch(getNoticeList());
		dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
		return;
	}
	// dispatch(getHomeGroup());
	dispatch(getHomeGroup2());
	dispatch(getCategoriesTable());
	dispatch(getActiveBanners());
	dispatch(getFloatingMessages());
	dispatch(CommonActions.removeLoadingStatus("getCommonLists"));
	// if(Config.ADMIN_PREVIEW_MODE){
	// 	dispatch(getStyleBasedLists());
	// }
}
/*
	receive all tables list
	Category, Tag
*/
// export const getStyleBasedLists = () => async (dispatch, getState) => {
// 	const {workoutStyleId} = getState().accountInfo;
// 	if(Config.SUPPORT_WORKOUTSTYLE){
// 		dispatch(getContentsRecommended(workoutStyleId));
// 		// dispatch(getOrderedTagList(workoutStyleId));
// 	}else{
// 		dispatch(getContentsRecommended(Config.UNKNOWN_WORKOUTSTYLEID));
// 		// dispatch(getOrderedTagList(Config.UNKNOWN_WORKOUTSTYLEID));
// 	}
// };

export const getAccountBasedLists = () => async (dispatch, getState) => {
	const {accountId} = getState().accountInfo;
	const supportLogin = getState().supportLogin;
	if(supportLogin && accountId){
		dispatch(getContentsRecentlyWatched());
		dispatch(getContentsOfFavorites());
		dispatch(getAlarmsOfAccount());
		dispatch(getGoalsOfAccount());
		dispatch(getPlansOfAccount());
		dispatch(getCustomizedWorkOutOfAccountInfo());
	}else{
		dispatch({ type: types.CLEAR_ALARM_AND_GOALS});
	}
};


export const handleCategoriTableSelected = (listIndex) => (dispatch, getState) => {
	dispatch({type: types.GET_CATEGORIES_TABLE_SELECTED, payload: listIndex});
};

export const handleWorkoutStyleTableSelected = (workoutStyleId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	if(Config.ADMIN_PREVIEW_MODE){
		dispatch({type: types.GET_ACCOUNT_INFO, payload: {workoutStyleId: workoutStyleId}});
		// if(workoutStyleId === Config.UNKNOWN_WORKOUTSTYLEID){
		// 	dispatch(CmsActions.getOrderedTagList(workoutStyleId));
		// }
		dispatch(PanelActions.popPanel('workoutstyle'));
		return;
	}
	if (!accountInfo.accountId) { // create
		dispatch(createAccount(workoutStyleId));
		dispatch(PanelActions.popPanel('workoutstyle'));
	} else { // update
		dispatch(updateAccount(workoutStyleId));
	}
	// dispatch(PanelActions.popPanel('workoutstyle'));
};

export const updateGoalOfAccount = (value, goalId, selected) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	let items = [];
	let alarms = [];
	for (let i = 0; i < value.length; i++) {
		items.push(value[i].items);
		alarms.push(value[i].alarms);
	}
	const params = {
		title: value[0].title,
		alarmActivated: selected,
		items: items,
		alarms: alarms
	}
	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			const day=[], time=[];
			for(let i=0; i<items.length; i++){
				day.push(items[i].weekday);
				time.push(items[i].time);
			}
			dispatch(appLog(LogTypes.Types.ALARMSETTING, 'goal', 'UPDATE', params.title, goalId, day, time, params.alarmActivated ));
		}
		dispatch(getGoalsOfAccount());
	};
	TAxios(dispatch, getState, "put", [Config.GET_GOALS_OF_ACCOUNT,accountId,goalId], params, onSuccess);
};

export const deleteGoalOfAccount = (goalId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const goalsOfAccount = getState().goalsOfAccount;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			let title = "";
			for (let i = 0; i < goalsOfAccount.length; i++) {
				if(goalsOfAccount[i].goalId === goalId){
					title = goalsOfAccount[i].title;
				}
			}
			dispatch(appLog(LogTypes.Types.ALARMSETTING, 'goal', 'DELETE', title, goalId));
		}
		dispatch(getGoalsOfAccount());
	};
	TAxios(dispatch, getState, "delete", [Config.GET_GOALS_OF_ACCOUNT,accountId,goalId], {}, onSuccess);
};

export const getGoalOfAccount = (goalId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch({ type: types.GET_GOAL_OF_ACCOUNT, payload: response.data.data });

			for (let i = 0; i < response.data.data.length; i++) {
				for (let j = 0; j < response.data.data[i].items.length; j++) {
					dispatch(getContentInfo(response.data.data[i].items[j].contentId, false));
				}
			}
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_GOALS_OF_ACCOUNT,accountId,goalId], {}, onSuccess);
};

let prevSearchLogKey = { Youtube: '', LGFitness: '' };
export const searchLog = (keyword, type) => (dispatch) => {
  //keyword, 'Youtube" or "LGFitness"
  if (prevSearchLogKey[type] !== keyword) {
    dispatch(appLog(LogTypes.Types.SEARCH_KEYWORD, keyword, type));
  }
  prevSearchLogKey[type] = keyword;
};
export const getYoutubeResult = (keyword, Loding) => async (dispatch, getState) => {
	const params = { params : {
		part : 'snippet',
		q: keyword,
		maxResults: 15,
		type : "video",
		key : Config.YOUTUBE_AUTHORIZATION
	}};
	if (!Loding) {
		dispatch({ type: types.ADD_LOADING_SPINNER_STATUS, payload: "getYoutubeResult" });
	}
	try {
		const url = `${Config.GET_YOUTUBE_SEARCH_VIDEOS_URL}`;
		console.log('getYoutubeResult url...', url);
		const response = await axios.get(url, params);
		console.log('getYoutubeResult resoponse....', response);
			let youtubeContents =[]
			for (let i = 0; i < response.data.items.length; i++) {
				const item = response.data.items[i];
				const content = {};
				content.contentType = item.id.kind; //youtube#channel, youtube#video
				content.title = item.snippet.title;
				content.thumbnailImageUrl = item.snippet.thumbnails.medium.url;
				content.contentId = item.id.channelId ? item.id.channelId : item.id.videoId;
				content.youtubeId = content.contentId;
				youtubeContents.push(content);
			}
		dispatch({ type: types.GET_YOUTUBE_SEARCH, payload: { keyword: keyword, data: youtubeContents } })
		if (!Loding) {
			dispatch({type: types.REMOVE_LOADING_SPINNER_STATUS, payload: "getYoutubeResult"});
		}

	} catch (err) {
		console.error('getYoutubeResult ', err);
		if (!Loding) {
			dispatch({type: types.REMOVE_LOADING_SPINNER_STATUS, payload: "getYoutubeResult"});
		}

	}
};

export const getContentsOfKeyword = (keyword) => (dispatch, getState) => {
	const deviceAccountInfo = getState().deviceAccountInfo;
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const query = {
		accountId: accountId,
		language: deviceAccountInfo.language,
		keyword: keyword
	};
	const onSuccess = (response) => {
		if (Config.USE_DUMMY) {
			response.data = dummyContents;
		}
		if (response && response.data.message === 'OK') {
			dispatch({ type: types.GET_CONTENTS_KEYWORD, payload: response.data.data });
		}
	};
	const onFail = () => {
		if (Config.USE_DUMMY) {
			dispatch({ type: types.GET_CONTENTS_KEYWORD, payload: dummyContents.data });
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_CONTENTS_OF_KEYWORD, query, onSuccess, onFail);
};
// data = {contentId, launchPath, timeSpent, calorieBurned, lastPositionSec, planId, introSkipped, reachedEnd, startTime, endTime}
export const updatePlayLog = (data, callback) => (dispatch, getState) => {
	const {accountId, workoutStyleId} = getState().accountInfo;
	const launchPath = getState().appStatus.playerLaunchPath;
	const supportLogin = getState().supportLogin;
	const {country, webOSVersion, otaId, deviceId} = getState().appStatus;
	if (Config.ADMIN_PREVIEW_MODE){
		if(callback){
			callback();
		}
		return;
	}
	let params = {
		country, webOs:webOSVersion, platformCode: otaId, deviceId, accountId, launchPath, workoutStyleId,
		...data
	};
	if(!params.startTime){
		params.startTime = Utils.timeToISO8601Str(new Date());
	}
	if(!params.endTime){
		params.endTime = Utils.timeToISO8601Str(new Date());
	}
	params = Utils.createStringfyParams(params);
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			if(supportLogin && accountId){
				dispatch(getContentsRecentlyWatched(accountId));
			}
		}
		if(callback){
			callback();
		}
	};
	const onFail = () => {
		if(callback){
			callback();
		}
	};
	TAxios(dispatch, getState, "post", [Config.PLAYLOG,data.contentId,'ex'], params, onSuccess, onFail);
};
const createTerms = () => (dispatch, getState) => {
	const deviceId = getState().appStatus.deviceId;
	const webOSVersion = getState().appStatus.webOSVersion;
	const params = {
		webOs: webOSVersion
	};
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch(PanelActions.pushPanel('termsConditions'));
		}
	};
	TAxios(dispatch, getState, "post", [Config.TERMSCONDITION,deviceId], params, onSuccess);
};

const doGetTermsDoc = async (dispatch, getState, onlyVersion) => {
	const {useQAServerURL} = getState().localSettings;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const deviceId = getState().appStatus.deviceId;
	const adminPreviewData = getState().adminPreviewData;
	if (Config.ADMIN_PREVIEW_MODE && adminPreviewData.terms) {
		dispatch({ type: types.GET_TERMS_DOC, payload: adminPreviewData.terms });
		if (adminPreviewData.terms.accepted1 === false) {
			dispatch(PanelActions.popPanel('termsConditionsDetail'));
			dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Terms & Conditions", btnNum: "btn0", panelState: "reAgreed", updateTerms: 1 }));
			return;
		} else if (adminPreviewData.terms.accepted2 === false) {
			dispatch(PanelActions.popPanel('termsConditionsDetail'));
			dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Privacy Policy", btnNum: "btn1", panelState: "reAgreed", updateTerms: 1 }));
			return;
		} else if (adminPreviewData.terms.accepted === false) {
			dispatch(PanelActions.popPanel('termsConditionsDetail'));
			dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "개인정보 처리방침", btnNum: "btn1", panelState: "reAgreed", updateTerms: 1 }));
			return;
		}
	}
	try {
		const query = {
			versionOnly: onlyVersion ? "true" : "",
			deviceId: deviceId
		}
		const url = `${Config.GYMURL(deviceAccountInfo.language, Config.GET_TERMSCONDITION_DOCS, useQAServerURL)}/?${Utils.createQueryString(query)}`;
		console.log('doGetTermsDoc url...', url);
		const response = await axios.get(url, AUTHORIZATION)
		console.log('getTermsDoc .... onlyVersion ', onlyVersion, " response ",response);
		if (response && response.data.message === "OK") {
			if(onlyVersion){
				return response.data.data;
			}else{
				dispatch({ type: types.GET_TERMS_DOC, payload: {...response.data.data} });
			}
		}
	} catch (err) {
		console.error('getTermsDoc', err.response);
		return null;
	}
};
export const getTermsDoc = () => async (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if (Config.ADMIN_PREVIEW_MODE && adminPreviewData.terms) {
		doGetTermsDoc(dispatch, getState, false);
		return;
	}
	doGetTermsDoc(dispatch, getState, false);
}

export const updateTerms = (newVersion, accepted1, accepted2, accepted) => (dispatch, getState) => {
	const {deviceId, webOSVersion} = getState().appStatus;
	const getTermsStatus = getState().getTermsStatus;
	const params = {
		webOs: webOSVersion,
		version: newVersion ? newVersion : getTermsStatus.version,
		accepted1: accepted1 ? accepted1 : getTermsStatus.accepted1,
		accepted2: accepted2 ? accepted2 : getTermsStatus.accepted2,
		accepted: accepted ? accepted : getTermsStatus.accepted
	};
	Utils.writeLocalStorage("termsAccepted", params.accepted);
	Utils.writeLocalStorage("termsAccepted1", params.accepted1);
	Utils.writeLocalStorage("termsAccepted2", params.accepted2);
	TAxios(dispatch, getState, "put", [Config.TERMSCONDITION, deviceId], params);
};

export const getTerms = () => async (dispatch, getState) => {
	const {deviceId} = getState().appStatus;
	const termsDocInfo = await doGetTermsDoc(dispatch, getState, true);
	if(!termsDocInfo){
		return;
	}
	const newVersion = termsDocInfo.version;
	const updateTerms1 = termsDocInfo.accepted1;
	const updateTerms2 = termsDocInfo.accepted2;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			//for instop
			const version = response.data.data.version;
			let accepted1 = Utils.readLocalStorage('termsAccepted1') && response.data.data.accepted1;
			let accepted2 = Utils.readLocalStorage('termsAccepted2') && response.data.data.accepted2;
			let accepted = Utils.readLocalStorage('termsAccepted') && response.data.data.accepted;
			if (Config.DEBUG_SHOW_TERMS) {
				dispatch(PanelActions.pushPanel('termsConditions'));
				dispatch({ type: types.GET_TERMS_STATUS, payload: { version: newVersion, accepted1, accepted2, accepted } });
			} else if (newVersion && version === newVersion) {
				dispatch({ type: types.GET_TERMS_STATUS, payload: { version: newVersion, accepted1, accepted2, accepted } });
				if (accepted1 && accepted2 && accepted) {
					dispatch(updateTerms(newVersion, accepted1, accepted2));
				} else {
					dispatch(PanelActions.pushPanel('termsConditions'));
				}
			} else if (newVersion && version !== newVersion) {
				if (!accepted) {
					dispatch(PanelActions.pushPanel('termsConditions'));
				} else {
					dispatch(getTermsDoc());
					if (!updateTerms1 && updateTerms2) {
						accepted1 = false;
						dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Terms & Conditions", btnNum: "btn0", panelState: "reAgreed", updateTerms: 1}));
					} else if (!updateTerms2 && updateTerms1) {
						accepted2 = false;
						dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Privacy Policy", btnNum: "btn1", panelState: "reAgreed", updateTerms: 1 }));
					} else if (!updateTerms1 && !updateTerms2) {
						accepted1 = false;
						accepted2 = false;
						dispatch(PanelActions.pushPanel('termsConditionsDetail', { title: "Terms & Conditions", btnNum: "btn0", panelState: "reAgreed", updateTerms: 2 }));
					}
				}
				dispatch({ type: types.GET_TERMS_STATUS, payload: { version: newVersion, accepted1, accepted2, accepted } });
			}
		} else {
			dispatch({ type: types.GET_TERMS_STATUS, payload: {version:newVersion, accepted1:false, accepted2:false, accepted:false} });
			dispatch(createTerms());
		}
	};
	const onFail = (err) => {
		//terms not found
		if(err.response && err.response.status === 404){
			dispatch({ type: types.GET_TERMS_STATUS, payload: {version:newVersion, accepted1:false, accepted2:false, accepted:false} });
			dispatch(createTerms());
		}
	};
	TAxios(dispatch, getState, "get", [Config.TERMSCONDITION, deviceId], {}, onSuccess, onFail);
};

export const createAlarmOfAccount = (alarmTitle, activated, weekday, Time) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	console.log('createAlarmOfAccount', accountId)
	let day = [];
	const DAYS=["SUN","MON","TUE", "WED", "THU", "FRI", "SAT"];
	for (let i = 0; i < weekday.length; i++) {
		day.push(DAYS[weekday[i]]);
	};
	const params = {
			title : alarmTitle,
			activated: activated,
			weekdays: day,
			time: Utils.transTimeToText(Time.getHours(), Time.getMinutes())
	};
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch(appLog(LogTypes.Types.ALARMSETTING, 'alarm', 'CREATE', alarmTitle, response.data.data.alarmId, day, params.time, activated ));
		}
		dispatch(getAlarmsOfAccount());
	};
	TAxios(dispatch, getState, "post", [Config.GET_ALARMS, accountId], params, onSuccess);
};

export const updateAlarmOfAccount = (alarmId, alarmTitle , activated, weekday, Time) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	console.log('updateAlarmOfAccount', accountId)
	let day = [];
	const DAYS=["SUN","MON","TUE", "WED", "THU", "FRI", "SAT"];
	for (let i = 0; i < weekday.length; i++) {
		day.push(DAYS[weekday[i]]);
	};
	const params = {
			alarmId: alarmId,
			title : alarmTitle,
			activated: activated,
			weekdays: day,
			time: Utils.transTimeToText(Time.getHours(), Time.getMinutes())
	};
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch({ type: types.GET_ALARM_OF_ACCOUNT, payload: {[alarmTitle]:params} });
			dispatch(appLog(LogTypes.Types.ALARMSETTING, 'alarm', 'UPDATE', alarmTitle, alarmId, day, params.time, activated ));
		}
		dispatch(getAlarmsOfAccount());
	};
	TAxios(dispatch, getState, "put", [Config.GET_ALARMS, accountId, alarmId], params, onSuccess);
};

export const deleteAlarmOfAccount = (alarmId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = () => {
		dispatch(getAlarmsOfAccount());
	};
	TAxios(dispatch, getState, "delete", [Config.GET_ALARMS, accountId, alarmId], {}, onSuccess);
};

export const getAlarmOfAccount = (goalId, alarmTitle) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			dispatch({ type:types.GET_ALARM_OF_ACCOUNT, payload: response.data.data });
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_ALARMS, accountId, goalId], {}, onSuccess);
};

let loginBaseTime= new Date();
//CMS Server log 용 login
export const loginAccount = () => (dispatch, getState) => {
	if(Config.ADMIN_PREVIEW_MODE){
		return;
	}
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const {deviceId, webOSVersion} = getState().appStatus;
	const query = {
		accountId : accountId,
		deviceId: deviceId,
		regionTime: Utils.timeToISO8601Str(new Date()),
		webOs: webOSVersion
	};
	if (Config.ADMIN_PREVIEW_MODE){
		return;
	}
	loginBaseTime= new Date();
	TAxios(dispatch, getState, "get", Config.LOGIN, query);
};
export const logoutAccount = () => (dispatch, getState) => {
	if(Config.ADMIN_PREVIEW_MODE){
		return;
	}
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const {deviceId, webOSVersion} = getState().appStatus;
	const timeSpent = Math.round((new Date() - loginBaseTime)/1000);
	loginBaseTime= new Date();
	const query = {
		accountId : accountId,
		deviceId: deviceId,
		regionTime: Utils.timeToISO8601Str(new Date()),
		webOs: webOSVersion,
		timeSpent: timeSpent
	};
	if (Config.ADMIN_PREVIEW_MODE){
		return;
	}
	TAxios(dispatch, getState, "get", Config.LOGOUT, query);
};

/*
mode 0 : week
mode 1 : month
mode 2 : year
mode 3 : this week
*/
export const getWorkoutSummary = (date, mode) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	if(!accountId){
		return;
	}
	let endUrl = Config.GET_WEEKLY_WORKOUT_SUMMARY;
	if(mode === 1){
		endUrl = Config.GET_MONTHLY_WORKOUT_SUMMARY;
	}else if(mode === 2){
		endUrl = Config.GET_YEARLY_WORKOUT_SUMMARY;
	}
	const onSuccess = (response) => {
		if(response.data && response.data.data){
			if(mode === 3){
				dispatch({ type: types.GET_WORKOUT_SUMMARY_WEEK, payload: response.data.data});
			}else{
				dispatch({ type: types.GET_WORKOUT_SUMMARY, payload: {[response.data.data.start]: response.data.data}});
			}
		}
	};
	TAxios(dispatch, getState, "get", [endUrl, accountId, date], {}, onSuccess);
};

/*
mode 0 : week
mode 1 : month
mode 2 : year
mode 3 : this week
*/
export const getGoalAchievement = (date, mode, goalId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const goalAchievement = getState().goalAchievementWeek;
	const accountId = accountInfo.accountId;

	if(!accountId){
		return;
	}
	let endUrl = Config.GET_WEEKLY_ACHIEVEMENT;
	if(mode === 1){
		endUrl = Config.GET_MONTHLY_ACHIEVEMENT;
	}else if(mode === 2){
		endUrl = Config.GET_YEARLY_ACHIEVEMENT;
	}
	const query = {
		goalId : goalId
	};
	const onSuccess = (response) => {
		if(response.data && response.data.data){
			//for player log popup
			if(mode === 3 && goalAchievement[goalId] && date === goalAchievement[goalId].startOfWeek){
				if(goalAchievement[goalId] && goalAchievement[goalId].achievements < response.data.data.achievements){
					response.data.data.newAchievement = true;
				}else{
					response.data.data.newAchievement = false;
				}
			}
			if(mode === 3){
				dispatch({ type: types.GET_GOAL_ACHIEVEMENT_WEEK, payload: {[goalId]: response.data.data}});
			}else{
				dispatch({ type: types.GET_GOAL_ACHIEVEMENT, payload: {[goalId]: response.data.data}});
			}
		}
	};
	const onFail = () => {
		dispatch({ type: types.GET_GOAL_ACHIEVEMENT, payload: {[goalId]: null}});
		if(mode === 3){
			dispatch({ type: types.GET_GOAL_ACHIEVEMENT_WEEK, payload: {[goalId]: null}});
		}
	};
	TAxios(dispatch, getState, "get", [endUrl, accountId, date], query, onSuccess, onFail);
};

let getContentUsagesOfAccountKey=null;
export const getContentUsagesOfAccount = (firstDate, lastDate, page=0, key) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const query = {
		start : firstDate,
		end: lastDate,
		size: Config.CMS_SIZE_LIMIT,
		page: page
	};
	if(!accountId){
		return;
	}
	let currentKey = key;
	const onSuccess = (response) => {
		if(response.data && response.data.data ){
			if(page === 0){
				getContentUsagesOfAccountKey = new Date();
				currentKey = getContentUsagesOfAccountKey;
				dispatch({ type: types.GET_CONTENT_USAGES, payload: response.data.data.usages});
			}else if(getContentUsagesOfAccountKey === currentKey){
				dispatch({type: types.GET_CONTENT_USAGES, payload: response.data.data.usages, append: true});
			}
			if(getContentUsagesOfAccountKey === currentKey && response.data.data.totalElements > (page+1)*Config.CMS_SIZE_LIMIT){
				dispatch(getContentUsagesOfAccount(firstDate, lastDate, page+1, currentKey));
			}
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_CONTENT_USAGES, accountId], query, onSuccess);
};

export const addToFavorites = (contentId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = () => {
		dispatch(getContentsOfFavorites());
	};
	TAxios(dispatch, getState, "post", [Config.MY_FAVORITES, contentId, 'addTo', accountId], {}, onSuccess);
};
export const removeFromFavorites = (contentId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const onSuccess = () => {
		dispatch(getContentsOfFavorites());
	};
	TAxios(dispatch, getState, "post", [Config.MY_FAVORITES, contentId, 'removeFrom', accountId], {}, onSuccess);
};
export const addOrRemoveFavorites = (contentId) => (dispatch, getState) => {
	const contentsMyFavorites = getState().contentsMyFavorites;
	let contain = false;
	for(let index in contentsMyFavorites){
		if( contentId === contentsMyFavorites[index].contentId){
			contain = true;
			break;
		}
	}
	if(contain){
		dispatch(removeFromFavorites(contentId));
	}else{
		dispatch(addToFavorites(contentId));
	}
};

export const getPlansOfAccount = () => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const contentInfos = getState().contentInfos;
	const accountId = accountInfo.accountId;

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			for (let i = 0; i < response.data.data.length; i++) {
				let totalCalorie = 0, totalplayTime = 0;
				for (let j = 0; j < response.data.data[i].items.length; j++) {
					dispatch(getContentInfo(response.data.data[i].items[j].contentId, false));
					totalCalorie+= (response.data.data[i].items[j].calorie * response.data.data[i].items[j].repeatCount);
					totalplayTime+= (response.data.data[i].items[j].playTime * response.data.data[i].items[j].repeatCount);
					if(response.data.data[i].items[j].contentId && contentInfos[response.data.data[i].items[j].contentId]){
						response.data.data[i].items[j] = {...response.data.data[i].items[j], ...contentInfos[response.data.data[i].items[j].contentId]};
					}
				}
				response.data.data[i].contentType = ContentType.PLAN;
				response.data.data[i].playtime = totalplayTime;
				response.data.data[i].calorie = totalCalorie;
				if(response.data.data[i] && response.data.data[i].items[0]){
					response.data.data[i].thumbnailImageUrl = response.data.data[i].items[0].thumbnailImageUrl;
					response.data.data[i].postImageUrl = response.data.data[i].items[0].postImageUrl;
				}
			}
			if(response.data.data.length < 5){
				response.data.data.push({
					contentType: ContentType.ADD_PLAN,
					title: $L('Make a Workout Routine'),
					description: $L('You can customize your workout routine.{br}Don’t stop your workout to search for the next ones.')
				})
			}
			dispatch({ type: types.GET_PLANS_OF_ACCOUNT, payload: response.data.data });
		}
	};
	TAxios(dispatch, getState, "get", [Config.GET_PLANS_OF_ACCOUNT,accountId], {}, onSuccess);
};

export const createPlanOfAccount = (title, items) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const params = {
		title: title,
		items: items,
	}
	const onSuccess = () => {
		dispatch(getPlansOfAccount());
	};
	TAxios(dispatch, getState, "post", [Config.GET_PLANS_OF_ACCOUNT,accountId], params, onSuccess);
};

export const updatePlanOfAccount = (title, items, planId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;

	const params = {
		title: title,
		items: items,
	}
	const onSuccess = () => {
		dispatch(getPlansOfAccount());
	};
	TAxios(dispatch, getState, "put", [Config.GET_PLANS_OF_ACCOUNT,accountId,planId], params, onSuccess);
};

export const deletePlanOfAccount = (planId) => (dispatch, getState) => {
	const accountInfo = getState().accountInfo;
	const accountId = accountInfo.accountId;
	const goalsOfAccount = getState().goalsOfAccount
	let isDisabledDelete = false;
	for (let i = 0; i < goalsOfAccount.length; i++) {
		for (let j = 0; j <goalsOfAccount[i].items.length; j++) {
			if ("planId" in goalsOfAccount[i].items[j]) {
				if (goalsOfAccount[i].items[j].planId === planId) {
					isDisabledDelete = true
				}
			}
		}
	}
	if (isDisabledDelete) {
		dispatch(CommonActions.alertToast($L("Content set as a goal cannot be deleted. Please edit your goal and try again")));
	} else {
		const onSuccess = () => {
			dispatch(getPlansOfAccount());
		};
		TAxios(dispatch, getState, "delete", [Config.GET_PLANS_OF_ACCOUNT,accountId,planId], {}, onSuccess);
	}
};

export const getNotice = (goalId) => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	const localNoticeid = Utils.readLocalStorage('noticeId', -1 );
	if (Config.ADMIN_PREVIEW_MODE) {
		if (adminPreviewData.notice) {
			let _adminPreviewData = [];
			if (Object.keys(adminPreviewData.notice).length > 0) {
				if (adminPreviewData.notice.notice) {
					_adminPreviewData.push(adminPreviewData.notice);
					dispatch(CommonActions.changeAppStatus({noticeAlert: true, noticeAlertText:adminPreviewData.notice.notice}));
					dispatch({type: types.GET_NOTICE_LIST, payload: _adminPreviewData});
					return;
				} else {
					return;
				}
			}
		} else {
			return;
		}
	}
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			if(response && response.data.data.notice){
				const openFrom = new Date(response.data.data.openFrom);
				const openTo = new Date(response.data.data.openTo);
				const now = Utils.convertLocalTimetoUTC(new Date());
				const titleAndNotice = response.data.data.title + '\n' + response.data.data.notice;
				const noticeId = response.data.data.noticeId
				if (openFrom < now && openTo > now) {
					if (noticeId !== localNoticeid) {
						dispatch(CommonActions.changeAppStatus({ noticeAlert: true, noticeId: noticeId, noticeAlertText: titleAndNotice }));
					}
				}
			}
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_NOTICE, {}, onSuccess);
};

let getNoticeListKey=null;
export const getNoticeList = (page=0, key) => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if (adminPreviewData.notice) {
		return;
	}
	const query = {
		size: Config.CMS_SIZE_LIMIT,
		page: page
	};
	let currentKey = key;
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			if(page === 0){
				getNoticeListKey = new Date();
				currentKey = getNoticeListKey;
				dispatch({type: types.GET_NOTICE_LIST, payload: response.data.data});
			}else if(getNoticeListKey === currentKey){
				dispatch({type: types.GET_NOTICE_LIST, payload: response.data.data, append: true});
			}
			if(getNoticeListKey === currentKey && response.data.totalElements > (page+1)*Config.CMS_SIZE_LIMIT){
				dispatch(getNoticeList(page+1, currentKey));
			}
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_NOTICE_LIST, query, onSuccess);
};

export const getTermsModification = () => async (dispatch, getState) => {
	const localTermsModification = Utils.readLocalStorage("termsModification", { termsModificationId: -1 });
	const preTermsModificationId = localTermsModification.termsModificationId;
	const adminPreviewData = getState().adminPreviewData;

	if (Config.ADMIN_PREVIEW_MODE) {
		if (adminPreviewData.termsModification) {
			dispatch({ type: types.GET_TERMS_MODIFICATION, payload: adminPreviewData.termsModification });
			return;
		} else {
			return;
		}
	}
	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			if(response && response.data.data){
				const openFrom = new Date(response.data.data.openFrom);
				const openTo = new Date(response.data.data.openTo);
				const now = Utils.convertLocalTimetoUTC(new Date());
				if (preTermsModificationId !== response.data.data.termsModificationId && openFrom < now && openTo > now) {
					dispatch({ type: types.GET_TERMS_MODIFICATION, payload: response.data.data });
					dispatch(PanelActions.pushPanel("termsModification"));
				}
			}
		}
	};
	TAxios(dispatch, getState, "get", Config.GET_TERMSMODIFICATION, {}, onSuccess);
};

export const getThisWeekSummaryInfo = () => (dispatch, getState) => {
	const goals = getState().goalsOfAccount;
	const firstDateOfThisWeek = new Date();
	firstDateOfThisWeek.setDate(firstDateOfThisWeek.getDate()-firstDateOfThisWeek.getDay());
	const firstDate = Utils.timeToISO8601DateStr(firstDateOfThisWeek);
	dispatch(getWorkoutSummary(firstDate, 3));
	if(goals){
		if(goals[0] && goals[0].goalId){
			dispatch(getGoalAchievement(firstDate, 3, goals[0].goalId));
		}
		if(goals[1] && goals[1].goalId){
			dispatch(getGoalAchievement(firstDate, 3, goals[1].goalId));
		}
	}
};


export const getCustomizedWorkOutOfAccountInfo = () => (dispatch, getState) => {
  const accountId = getState().accountInfo.accountId;
  const initialCustomizedProgramGuideShown = getState().localSettings.initialCustomizedProgramGuideShown;
  const homeGroupHasCustomizedWorkout = getState().appStatus.homeGroupHasCustomizedWorkout;

  const onSuccess = (response) => {
    if (response && response.data.message === "OK") {
      console.log('getCustomizedWorkOutOfAccountInfo', response.data.data)
      let userInfo = response.data.data
      userInfo.program = userInfo.programNumber
      userInfo.level = userInfo.programLevel
      userInfo.tall = userInfo.height
      userInfo.gender = userInfo.gender == 'MALE' ? 1 : 2
      if (userInfo.program <= 9){
        userInfo.goal = CP875Table.GOAL_DIAT;
      }else if (userInfo.program <= 18){
        userInfo.goal = CP875Table.GOAL_STRENGTH;
      }else if (userInfo.program <= 21){
        userInfo.goal = CP875Table.GOAL_PHYSICAL;
      } else{
        userInfo.goal = CP875Table.GOAL_POSTURE;
      }
      dispatch({ type: types.GET_CUSTOMIZED_USERINFO, payload: userInfo });
	  setTimeout(() => {
		  dispatch(getCustomizedWorkOutSchedule());
	  }, 10);
    }
  };
  const onFail = (err) => {
	//customizedInfo not found
	if(err.response && err.response.status === 404 && !initialCustomizedProgramGuideShown && homeGroupHasCustomizedWorkout !== false){
		dispatch(PanelActions.pushPanel('interview'));
	}
	setTimeout(() => {
		dispatch(getCustomizedWorkOutSchedule());
	}, 10);
};
  TAxios(dispatch, getState, "get", Config.GET_CUSTOMIZED_USERINFO, { accountId }, onSuccess, onFail);
};


export const getCustomizedWorkOutSchedule = () => (dispatch, getState) => {
  const accountId = getState().accountInfo.accountId;
  const language = getState().deviceAccountInfo.language;
  const adminPreviewData = getState().adminPreviewData;
  const customizedProgramInfoCreatedAt = getState().localSettings.customizedProgramInfoCreatedAt;

  const onSuccess = (response) => {
    let workoutItems = []
		if (response && response.data && response.data.data) {
			// 설문을 진행하지 않은경우 기본프로그램 노출
			const programNumber = response.data.data.programNumber;
			const now = new Date();
			const createdAt = customizedProgramInfoCreatedAt ? new Date(customizedProgramInfoCreatedAt) : now;

			const day = ((Math.floor((now - createdAt) / (1000 * 60 * 60 * 24)))) + 1;
			const postList = [];
			const tailList = [];
			let currentList = tailList;
			Object.entries(response.data.data.schedule).map(([k, v]) => {
				if(programNumber > 0){
					if (Number(k) === day || (Config.ADMIN_PREVIEW_MODE && Number(k) === 1)){
						v.isToday = true;
						currentList = postList;
					}
					v.dayTitle = $L('Day {index}: ').replace('{index}', k)+ v.title;
					if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.customizedWorkOutScheduleContents){
						v.contentId = Number(v.contentId);
						dispatch(getContentInfo(v.contentId, true));
					}
				}
				currentList.push(v);
			});
			workoutItems = postList.concat(tailList);
			dispatch({ type: types.GET_CUSTOMIZED_WORKOUT_SCHEDULE, payload: workoutItems });
			if(response.data.data.programNumber >0 && day > 28){
			dispatch(CommonActions.changeAppStatus({ customScheduleFinished: true }));
			}
		}
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.customizedWorkOutScheduleContents){
		onSuccess({data:{data: {schedule: adminPreviewData.customizedWorkOutScheduleContents, programNumber: 1}}});
		return;
	}
  TAxios(dispatch, getState, "get", Config.GET_CUSTOMIZED_WORKOUT_OF_ACCOUNT, { accountId, language }, onSuccess);
};

export const getThirdPartyApps = () => (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const query = {
		language: deviceAccountInfo.language,
		size: Config.HLIST_HOME_MAX
	};
	const onSuccess = (response) => {
		if (response && response.data.message === 'OK') {
			for (let i = 0; i < response.data.data.length; i++) {
				response.data.data[i].thumbnailImageUrl = response.data.data[i].icomImageUrl ||  response.data.data[i].iconImageUrl; //todo spelling
				response.data.data[i].contentType = ContentType.APP;
				response.data.data[i].postImageUrl = response.data.data[i].previewImageUrl;

			}
			dispatch({type: types.GET_CONTENTS_THIRDPARTY_APPS, payload: response.data.data});
		}
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.thirdparty){
		onSuccess({data:{data: adminPreviewData.thirdparty}});
		return;
	}
	TAxios(dispatch, getState, "get", Config.GET_THIRD_PARTY_APPS, query, onSuccess);
  };

/** Update CustomWorkOut User Setting (Userinfo & Program & Level) */
export const updateCustomizedWorkOutOfAccount = (data) => (dispatch, getState) => {

  const onSuccess = (response) => {
    if (response && response.data.message === "OK") {
      dispatch(getCustomizedWorkOutOfAccountInfo());
	  dispatch(appLog(LogTypes.Types.UPDATE_CUSTOMIZEDPROGRAM_INFO, data.programNumber, data.programLevel));
    }
  };
  TAxios(dispatch, getState, "post", Config.GET_CUSTOMIZED_WORKOUT_OF_ACCOUNT, data, onSuccess);
};


export const getAdminPreviewData = (previewId, onComplete) => (dispatch, getState) => {
	const {useQAServerURL} = getState().localSettings;
	const language = Utils.getLanguageCode(Config.DEBUG_WINDOW_COUNTRY);
	const url = Config.GYMURL(language, Config.GET_ADMIN_PREVIEW, useQAServerURL) + '/' + previewId;
	console.log('getAdminPreviewData url...', url);
	axios.get(url, AUTHORIZATION)
	.then((response) => {
		console.log('getAdminPreviewData response....', response);
		const data={};
		if(response && response.data.data.url){
			const previewData = response.data.data.url;
			const vars = previewData.split('&');
			for (let i = 0; i < vars.length; i++) {
				const pair = vars[i].split('=');
				try {
					data[pair[0]] = JSON.parse(decodeURIComponent(pair[1]));
				}catch(err){
					data[pair[0]] = decodeURIComponent(pair[1]);
				}
			}
			console.log('getAdminPreviewData data....', data);
			dispatch(CommonActions.updateAdminPreviewData(data));
		}
		if(onComplete){
			setTimeout(() => {
				onComplete(false, data);
			}, 100);
		}
	})
	.catch((err) => {
		console.error('getAdminPreviewData error', err);
		if(onComplete){
			onComplete(false,{});
		}
	});
}

/** 유저 맞춤형 운동 생성 및 업데이트(=변경) */
export const updateCustomizedUserInfo = (userInfo) => (dispatch, getState) => {

  const now = Utils.timeToISO8601Str(new Date()); // 설문일자
  const data = {
    "id": getState().accountInfo.accountId,
    "programNumber": userInfo.program,
    "programLevel": userInfo.level,
    "age": userInfo.age,
    "gender": userInfo.gender == 1 ? "MALE" : "FEMALE",
    "height": userInfo.tall,
    "weight": userInfo.weight
  }

  dispatch({ type: types.GET_CUSTOMIZED_USERINFO, payload: userInfo });

  dispatch(CommonActions.changeLocalSettings({ customizedProgramInfoCreatedAt: now }));
  dispatch(updateCustomizedWorkOutOfAccount(data));
}

export const getFloatingMessages = () => async (dispatch, getState) => {
	const adminPreviewData = getState().adminPreviewData;
	if(Config.USE_DUMMY) {
		let response = dummyFloatingMessage
		if(response.data.length > 0) {
			dispatch(CommonActions.changeAppStatus({showTooltip: true}));
		}
		if (response && response.message === "OK") {
			dispatch({ type: types.GET_FLOATING_MESSAGE_LIST, payload: response.data[0] });
			return;
		}
	}

	const onSuccess = (response) => {
		if (response && response.data.message === "OK") {
			if(response.data.data.length > 0) {
				dispatch(CommonActions.changeAppStatus({showTooltip: true}));
			}
			dispatch({ type: types.GET_FLOATING_MESSAGE_LIST, payload: response.data.data[0] });
		}
	};
	const onFail = (err) => {
		dispatch(CommonActions.changeAppStatus({showTooltip: false}));
	};
	if(Config.ADMIN_PREVIEW_MODE && adminPreviewData.floatingMessages){
		if(adminPreviewData.floatingMessages.length > 0){
			dispatch(CommonActions.changeAppStatus({showTooltip: true}));
			dispatch({ type: types.GET_FLOATING_MESSAGE_LIST, payload: adminPreviewData.floatingMessages[0] });
		}
		return;
	}
	TAxios(dispatch, getState, "get", Config.GET_FLOATING_MESSAGES, '', onSuccess, onFail);
};