/**
 * CategoriesView
 *
 * @module CategoriesView
 */
import classNames from 'classnames';
import css from './CategoriesView.module.less';
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import platform from '@enact/core/platform';
import Scroller from '@enact/sandstone/Scroller';
import * as CmsActions from '../../actions/cmsActions';
import * as CommonActions from '../../actions/commonActions';
import * as Utils from '../../utils/common';
import usePrevious from '../../utils/usePrevious';
import MediaList from '../../components/MediaList';
import VerticalScrollGuide from '../../components/VerticalScrollGuide';
import TableList from '../../components/TableList';
import SpotlightIds from '../../data/SpotlightIds';
import * as Config from '../../data/Config';
import Spotlight from '@enact/spotlight';
import {Job} from '@enact/core/util';
import CategoryDetail from '../../components/TableList/CategoryDetail';
import * as ContentType from '../../data/ContentType';

const calculateVisibleListJob = new Job((func) => {
	func();
},2000);
const CategoriesView = ({isOnTop, pickerMode=false, pickOnlySingle = false, onItemClick}) => {
	const dispatch = useDispatch();
	const scrollTo = useRef(null);
	const showCategoryDetail = useSelector(state => state.appStatus.showCategoryDetail);
	const {accountId} = useSelector(state => state.accountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const categoriesTableSelected = useSelector(state => state.categoriesTableSelected);
	const categoriesTable = useSelector(state => state.categoriesTable);
	const contentsOfCategory = useSelector(state => state.contentsOfCategory);
	const contentsMyFavorites  = useSelector(state => state.contentsMyFavorites, shallowEqual);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible);
	const panels = useSelector(state => state.panels);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [scrollOnTop, setScrollOnTop] = useState(true);
	const [visibleList, setVisibleList] = useState([]);
	const [pickerSelectedId, setPickerSelectedId] = useState(-1);
	const scrollTop = useRef(0);
	const preScrollTop = useRef(0);
	const prevPanels = usePrevious(panels);

	const logHint = useMemo(() => {
		let categoryTitle = "";
		for(let i=0; i<categoriesTable.length; i++){
			if(categoriesTable[i].categoryId === categoriesTableSelected){
				categoryTitle = categoriesTable[i].title;
			}
		}
		return SpotlightIds.LIST_CATEGORY+"_"+categoriesTableSelected+"("+categoryTitle+")";
	}, [categoriesTableSelected, categoriesTable]);

	const contents = useMemo(() => {
		if(pickerMode){
			if(pickerSelectedId < 0){
				if(pickOnlySingle){
					return contentsMyFavorites.filter(item => item.contentType === ContentType.SINGLE);
				}else{
					return contentsMyFavorites;
				}
			}else{
				if(contentsOfCategory[pickerSelectedId]){
					return contentsOfCategory[pickerSelectedId];
				}
			}
		}else{
			if(contentsOfCategory[categoriesTableSelected]){
				return contentsOfCategory[categoriesTableSelected];
			}
		}
		return [];
	}, [categoriesTableSelected, contentsOfCategory, pickerMode, pickerSelectedId, pickOnlySingle]);

	const supportFavBtn = useMemo(() => {
		return supportLogin && accountId && !pickerMode;
	}, [supportLogin, accountId, pickerMode]);

	useEffect(() => {
		dispatch(CommonActions.changeAppStatus({showCategoryDetail: false}));
		Spotlight.focus(SpotlightIds.MAIN_CATEGORY_BTN);
		Spotlight.focus(SpotlightIds.LIST_CATEGORY_TABLE);
		on('wheel', handleWheel);
		return () => {
			off('wheel', handleWheel);
			calculateVisibleListJob && calculateVisibleListJob.stop();
		};
	}, []);

	useEffect(() => {
		let selectedId = -1;
		if(pickerMode){
			if(pickerSelectedId !== -1){
				selectedId = pickerSelectedId;
			}
		}else{
			if(categoriesTableSelected !== -1){
				selectedId = categoriesTableSelected;
			}
		}
		if(selectedId !== -1){
			dispatch(CmsActions.getContentsOfCategory(selectedId, accountId));
			if (scrollTo && scrollTo.current) {
				scrollTo.current({ position: { x: 0, y: 0 }, animate: true });
			}
			calculateVisibleListJob.start(calculateVisibleList);
		}
	}, [categoriesTableSelected, pickerSelectedId]);

	useEffect(() => {
		if (panels && panels.length > 0) {
			preScrollTop.current = scrollTop.current
		}
		setTimeout(() => {
			scrollTo.current({ position: { x: 0, y: preScrollTop.current }, animate: true });
		}, 0);
	}, [panels]);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="scroller"]`);
		const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_CATEGORY}"]`);
		let listArray = listView.children[0].children;
		const itemList = [];
		for(let index in listArray){
			if(!isNaN(index)){
				const item = listArray[index];
				if(item.offsetTop >= scrollTop.current && (item.offsetTop+item.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
					itemList.push(item.getAttribute('data-spotlight-id'));
				}
			}
		}
		setVisibleList(itemList);
	}, []);

	const spotMedia = useCallback((ev) => {
		if(ev.key === 'ArrowDown'){
			if(contents.length >0){
				Spotlight.focus(SpotlightIds.LIST_CATEGORY);
			}
		}else if(ev.key === 'ArrowUp'){
			Spotlight.focus(SpotlightIds.MAIN_CATEGORY_BTN);
		}
	}, [contents]);
	const onListFocus = useCallback(() => {
		if(!pickerMode){
			dispatch(CommonActions.changeAppStatus({showCategoryDetail: true}));
		}
	}, [dispatch, pickerMode]);

	const showCategoryTable = useCallback(() => {
		dispatch(CommonActions.changeAppStatus({showCategoryDetail: false}));
	}, [dispatch]);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, [scrollTo]);

	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="scroller"]`);
		if(scrollTop.current + scroller.offsetHeight + 300/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		if(scrollTop.current <=0){
			setScrollOnTop(true);
		}else{
			setScrollOnTop(false);
		}
		calculateVisibleList();
	}, [scrollTop, setScrollOnBottom, setScrollOnTop, calculateVisibleList]);

	const moveBottom = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="scroller"]`);
			const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_CATEGORY}"]`);
			let listArray = listView.children[0].children;
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('down', currentItem);
				if(currentItem && nextItem){
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						const targetOffset = currentItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for(let i=0; i<listArray.length;i++){
				if(scrollTop.current < listArray[i].offsetTop){
					//check last line
					const lastLine = Math.ceil(listArray.length/5); // 5 items per line
					if(lastLine > 2 && Math.ceil((i+1)/5) < lastLine){
						scrollTop.current = listArray[i].offsetTop;
						scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
					}
					return true;
				}
			}
		}
	}, []);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_CATEGORY}"]`);
			let listArray = listView.children[0].children;
			if(!wheel){
				const focusedIndex = listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('up', currentItem);
				if(currentItem && nextItem){
					if(nextItem.offsetTop === scrollTop.current){ //contain
						return true;
					}else{
						if(nextItem.offsetTop < scrollTop.current){
							scrollTop.current = nextItem.offsetTop;
							scrollTo.current({position:{y: nextItem.offsetTop}, animate: true});
						}
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if(listArray[i].offsetTop < scrollTop.current){
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y:listArray[i].offsetTop},animate: true});
					return true;
				}
			}
		}
	}, []);

	const onClickTopGuide = useCallback(() => {
			moveTop(true);
	}, [moveTop]);

	const onClickBottomGuide = useCallback(() => {
			moveBottom(true);
	}, [moveBottom]);

	const onKeyDown = useCallback((ev) => {
		if(ev.key === 'ArrowDown' ||ev.key === 'PageDown'){
			return moveBottom(false);
		}else if(ev.key === 'ArrowUp' || ev.key === 'PageUp'){
			return moveTop(false);
		}
		return false;
	}, [moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		console.log('onWheel ',ev);
		if(prevPanels && prevPanels.length > 0){
			return;
		}
		if(ev.deltaY < 0){
			onClickTopGuide();
		}else{
			onClickBottomGuide()
		}
	}, [prevPanels, onClickTopGuide, onClickBottomGuide]);

	const pickerOnSelect = useCallback((ev) => {
		setPickerSelectedId(ev);
	}, []);

	return (
		<div className={css.bodyarea}>
			<CategoryDetail className={classNames(css.categorylist, css.categorydetail, !showCategoryDetail ? css.hide: null)} />
			<TableList isOnTop={isOnTop} onFocus={showCategoryTable} onPointerEnter={showCategoryTable} spotlightId={SpotlightIds.LIST_CATEGORY_TABLE}
				className={classNames(css.categorylist, showCategoryDetail ? css.hide: null)} onKeyDown={spotMedia}
				pickerMode={pickerMode} pickerSelectedId={pickerSelectedId} pickerOnSelect={pickerOnSelect}/>
			<Scroller
				cbScrollTo={getScrollTo}
				className={css.medialistlayer}
				spotlightId={"scroller"}
				direction="vertical"
				horizontalScrollbar="hidden"
				verticalScrollbar="hidden"
				scrollMode="translate"
				noScrollByWheel
				noScrollByDrag
				onScrollStop={onScrollStop}
				onPointerLeave={showCategoryTable}
			>
				<MediaList visibleList={panels.length <=0 ? visibleList: []} orientation={'vertical'} logHint={logHint}
					spotlightId={SpotlightIds.LIST_CATEGORY} viewList={contents} supportFavBtn={supportFavBtn} onKeyDown={onKeyDown} onListFocus={onListFocus}
					selectMode={!!pickerMode} onListItemClick={onItemClick}/>
				<div className={css.dummy}/>
			</Scroller>
			{(cursorVisible || platform.touchscreen) && contents.length > 10 && !scrollOnTop &&
				<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
			}
			{ (cursorVisible || platform.touchscreen) && contents.length > 10 && !scrollOnBottom &&
				<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
			}
		</div>
	);
};

export default CategoriesView;
