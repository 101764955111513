import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import TopLayer from './TopLayer';
import TPanel from '../../components/TPanel';
import SettingsPopup from '../../components/SettingsPopup';
import {MAIN_INDEX} from '../../actions/panelActions';
import CategoriesView from '../CategoriesView';
import HomeView from '../HomeView';
import DashboardsView from '../DashboardsView';
import * as Config from '../../data/Config';
import * as CommonActions from '../../actions/commonActions';
import SpotlightIds from '../../data/SpotlightIds';
import css from './MainPanel.module.less';

const MainPanel = ({isOnTop,...props}) => {
	const dispatch = useDispatch();
	const [isSettingsPopupOpen, setSettingPopupOpen] = useState(false);
	const mainIndex = useSelector(state => state.mainIndex);
	const mainCurrentSpotlightId = useSelector(state => state.mainCurrentSpotlightId);
	const homeBannerSize = useSelector(state => state.appStatus.homeBannerSize);
	const showCategoryDetail = useSelector(state => state.appStatus.showCategoryDetail);
	const panels = useSelector(state => state.panels);
	const isInternetConnected = useSelector(state => state.appStatus.isInternetConnected);
	const connectionFailed = useSelector(state => state.appStatus.connectionFailed);
	const hideChildren = useRef(true);

	useEffect(() => {
		if (!isInternetConnected || connectionFailed) {
			setSettingPopupOpen(false);
		}
	},[isInternetConnected, connectionFailed]);

	const extended = useMemo(() => {
		let ret = true;
		if(mainIndex === MAIN_INDEX.HOME_VIEW){
			if(homeBannerSize ==='medium'){
				ret = true;
			}else{
				ret = false;
			}
		}else if(mainIndex === MAIN_INDEX.CATEGORIES_VIEW){
			if( showCategoryDetail){
				ret = false;
			}else{
				ret = true;
			}
		}
		return ret;
	}, [mainIndex, homeBannerSize, showCategoryDetail]);
	useEffect(() => {
		// Spotlight.focus(SpotlightIds.BANNER_PLAYBTN);
		return () => {
			console.log('willUnmount');
		};
	}, []);

	useEffect(() => {
		console.log('panels changed..', panels);
		if(panels.length <=0){
			try{
				Spotlight.focus(SpotlightIds.MAIN_PANEL);
			}catch(err){
				console.log('MainPanel err',err);
			}
		}
		setSettingPopupOpen(false);
	}, [panels]);
	useEffect(() => {
		console.log('mainIndex changed ', mainIndex);
	}, [mainIndex]);


	useEffect(() => {
		console.log('hideChildren Changed ', props.hideChildren);
		hideChildren.current = props.hideChildren;
		if (hideChildren.current) {
			// Spotlight.pause();
		} else {	// restore spot
			const current = Spotlight.getCurrent();
			if (!current || current.getAttribute('data-spotlight-id') !== mainCurrentSpotlightId) {
				console.log('hideChildren...restoreSpot..... ', mainCurrentSpotlightId, hideChildren.current);
				// new Job(() => {
				// Spotlight.resume();
				// Spotlight.focus('[data-spotlight-id="' + mainCurrentSpotlightId + '"]');
				// Spotlight.focus(mainCurrentSpotlightId);
				// }, 2000).start();
			}
		}
	}, [mainCurrentSpotlightId, props.hideChildren]);

	const onFocus = useCallback(() => {
		const current = Spotlight.getCurrent();
		//only keyboard move
		if(!Spotlight.getPointerMode() && current && (current.getAttribute('data-spotlight-id') === SpotlightIds.BANNER_PLAYBTN
			|| current.getAttribute('data-spotlight-id') === SpotlightIds.BANNER_ADDFAVBTN)){
				dispatch(CommonActions.changeAppStatus({homeBannerSize: 'medium'}));
		}
		if(current && current.getAttribute('data-webos-voice-intent') === 'Select'){
			dispatch(CommonActions.clearFocusedBannerItem());
		}
		// if (!isSettingsPopupOpen && !hideChildren.current) {
		// 	console.log('onFocus Spotlight.getCurrent ', current);
		// 	if (current) {
		// 		dispatch(PanelActions.setMainPanelCurrentSpot(current.getAttribute('data-spotlight-id')));
		// 	}
		// }
	}, [dispatch]);
	const onBlur = useCallback(() => {
		// console.log('onBlur hideChildren ', hideChildren.current);
		// if (!isSettingsPopupOpen && !hideChildren.current) {
		// 	const current = Spotlight.getCurrent();
		// 	console.log('onBlur Spotlight.getCurrent ', current);
		// 	if (!current) {
		// 		dispatch(PanelActions.setMainPanelCurrentSpot(''));
		// 	}
		// }
	}, []);
	const openSettingPopup = useCallback(() => {
		setSettingPopupOpen(true);
	}, []);

	const onCloseSettingPopup = useCallback(() => {
		setSettingPopupOpen(false);
		Spotlight.focus(SpotlightIds.MAIN_SETTINGSBTN);
	}, []);

	const handleCancel = useCallback(() => {
		if(Config.ADMIN_PREVIEW_MODE){
			return;
		}
		if (typeof window === 'object' && window.PalmSystem) {
			window.PalmSystem.platformBack();
		}else if(typeof window === 'object'){
			window.location.reload();
		}
	}, []);

	return (
		<TPanel {...props} spotlightId={SpotlightIds.MAIN_PANEL} className={classNames(panels.length > 0 ? css.hide: null)} onFocus={onFocus} onBlur={onBlur} handleCancel={handleCancel} >
			<TopLayer isOnTop={isOnTop && !isSettingsPopupOpen} mainIndex={mainIndex} onOpenSettingPopup={openSettingPopup} extended={extended}/>
			{mainIndex === MAIN_INDEX.CATEGORIES_VIEW && <CategoriesView isOnTop={isOnTop && !isSettingsPopupOpen}/>}
			{mainIndex === MAIN_INDEX.HOME_VIEW && <HomeView isOnTop={isOnTop && !isSettingsPopupOpen}/>}
			{mainIndex === MAIN_INDEX.DASHBOARDS_VIEW && <DashboardsView isOnTop={isOnTop && !isSettingsPopupOpen}/>}
			<SettingsPopup onClosePopup={onCloseSettingPopup} open={isSettingsPopupOpen} />
		</TPanel>
	);
};

export default MainPanel;
