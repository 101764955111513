import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PanelActions from '../../../actions/panelActions';
import * as CmsActions from '../../../actions/cmsActions';
import css from './AlarmSettingsPanel.module.less';
import Spotlight from '@enact/spotlight';
import SpotlightIds from '../../../data/SpotlightIds';
import {$L} from '../../../utils/common';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton/TButton';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import TTimePicker from '../../../components/TTimePicker/TTimePicker';
import TDayPicker from '../../../components/TDayPicker/TDayPicker';
import TBody from '../../../components/TPanel/TBody';

const AlarmSettingsPanel = ({...props }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [activated, setActivated] = useState(true);
    const [weekdays, setWeekdays] = useState([]);
    const [time, setTime] = useState(new Date(2000, 1, 1, 0, 0));
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);

    const panels = useSelector(state => state.panels);

    const alarmTitle = props.panelInfo.alarmTitle;
    const alarmInfo = props.panelInfo.alarmInfo;
    const headerTitle = isEditMode ? $L('Alarm Settings')+" > "+$L('Edit') : $L('Alarm Settings')+" > "+$L('Add');

    useEffect(() => {
        if (!props.hideChildren) {
            Spotlight.focus('[data-webos-voice-group-label="hour"]');
            Spotlight.focus('[data-webos-voice-group-label="오전 / 오후"]');
        }
    }, [props.hideChildren]);

    useEffect(() => {
        if (alarmInfo && alarmInfo.time) {
            setIsEditMode(true);
            const hour = parseInt(alarmInfo.time.substring(0, 2));
            const minutes = parseInt(alarmInfo.time.substring(3, 5));
            let date = new Date(2000, 1, 1, hour, minutes);
            setTime(date);
            const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
            let day = [];
            for (let i = 0; i < alarmInfo.weekdays.length; i++) {
                day.push(DAYS.indexOf(alarmInfo.weekdays[i]));
            }
            setWeekdays(day);
            setActivated(alarmInfo.activated);
        }
    }, [alarmInfo]);

    const onActivatedState = useCallback((ev) => {
        setActivated(ev.selected);
        if (weekdays.length > 0) {
            setIsBtnDisabled(false);
        }
    },[weekdays]);

    const onWeekdays = useCallback((ev) => {
        if (!ev.selected) {
            setWeekdays([]);
            setIsBtnDisabled(true);
        } else {
            setWeekdays(ev.selected);
            setIsBtnDisabled(false);
        }
    }, []);

    const onTime = useCallback((ev) => {
        setTime(ev.value);
        if (weekdays.length > 0) {
            setIsBtnDisabled(false);
        }
    }, [weekdays]);

    const onDone = useCallback(() => {
        if (isEditMode) {
            if (!isBtnDisabled && weekdays.length > 0) {
                dispatch(CmsActions.updateAlarmOfAccount(alarmInfo.alarmId, alarmTitle, activated, weekdays, time));
            }
        } else {
            if (!isBtnDisabled && weekdays.length > 0) {
                dispatch(CmsActions.createAlarmOfAccount(alarmTitle, activated, weekdays, time));
            }
        }
        dispatch(PanelActions.pushPanel('alarmlist', { spotlightId: alarmTitle }));
        dispatch(PanelActions.popPanel('alarmsettings'));
    }, [dispatch, alarmInfo, activated, weekdays, time, isEditMode, alarmTitle, isBtnDisabled]);

    const onBack = useCallback((ev) => {
        if (ev) {
        ev.stopPropagation();
        }
        setTimeout(() => {
            if (panels && panels.length > 1) {
                dispatch(PanelActions.pushPanel('alarmlist', { spotlightId: alarmTitle }));
                dispatch(PanelActions.popPanel('alarmsettings'));
            } else {
                dispatch(PanelActions.popPanel('alarmsettings'));
            }
        }, 100);
    },[dispatch, alarmTitle, panels]);

    const onComplete = useCallback(() => {
        Spotlight.focus('[data-webos-voice-intent="SelectCheckItem"]');
    }, []);

    const onHandleBtn = useCallback((ev) => {
        if (ev.key === 'ArrowUp') {
            Spotlight.focus('[data-webos-voice-intent="SelectCheckItem"]');
            ev.stopPropagation();
            ev.preventDefault();
        }
    }, []);

    const onSpotlightLeft = useCallback((ev) => {
        Spotlight.focus('switchBtn');
        ev.stopPropagation();
        ev.preventDefault();
    }, []);

    const onSpotlightRight = useCallback((ev) => {
        Spotlight.focus('[data-webos-voice-intent="SelectCheckItem"]');
        ev.stopPropagation();
        ev.preventDefault();
    }, []);

    const onKeyHandleTHeader = useCallback((ev) => {
        if (ev.key === "ArrowDown") {
            Spotlight.focus(SpotlightIds.LOGIN_GUIDE_BUTTON)
            Spotlight.focus('switchBtn');
            ev.stopPropagation();
        }
    },[]);

    return (
        <TPanel {...props} className={css.panel} handleCancel={onBack}>
            <THeader title={headerTitle} onClick={onBack} onKeyDown={onKeyHandleTHeader}/>
            <TBody>
              <div className={css.title}><h2>{alarmTitle === 'alarm1' ? $L('Alarm 1') : $L('Alarm 2')}</h2></div>
              <div className={css.alarm}>
                  <span className={css.alarmTitle}>{$L('Alarm')}</span>
                  <TSwitchItem
                      className={css.alarmSwitch}
                      data-webos-voice-label={$L('Alarm')}
                    spotlightId={'switchBtn'}
                    selected={activated}
                    onToggle={onActivatedState}
                >
                    {activated ? $L('On') : $L('Off')}
                </TSwitchItem>
            </div>
        <div className={css.time}>
            <span className={css.timeTitle}>{$L('Time')}</span>
            <div className={css.timePicker}>
                <TTimePicker
                    value={time}
                    onSpotlightRight={onSpotlightRight}
                    onSpotlightLeft={onSpotlightLeft}
                    onChange={onTime}
                    onComplete={onComplete}
                />
            </div>
        </div>
        <div className={css.repeat}>
            <div className={css.repeatTitle}>{$L('Repeat')}</div>
            <div className={css.dayPicker}><TDayPicker selected={weekdays} onSelect={onWeekdays}/></div>
        </div>
        <div onKeyDown={onHandleBtn} className={css.buttonContainer} >
            <TButton
                className={classNames(css.doneBtn)}
                disabled={isBtnDisabled}
                  spotlightId={'btn'}
                  onClick={onDone}
              >
                  {$L('Done')}
              </TButton>
          </div>
        </TBody>
        </TPanel>
    );
};

export default AlarmSettingsPanel;