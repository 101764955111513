import Image from '@enact/sandstone/Image';
import { useSelector } from 'react-redux';
import Marquee from '@enact/sandstone/Marquee';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import css from './GoalContentList.module.less'
import * as Utils from '../../utils/common';
import * as ContentType from '../../data/ContentType';
import {$L} from '../../utils/common';

const GoalContentList = ({ contentInfo, forceFocus}) => {
	const appStatus  = useSelector(state => state.appStatus);
	const title = contentInfo.items && Utils.convertUtf8ToUni(contentInfo.items.title);
	let time = new Date(2000, 1, 1, contentInfo.alarm.hours, contentInfo.alarm.minutes);
	time = time.toLocaleTimeString(appStatus.country === 'KR' ? 'ko-KR' : 'en-US', { hour: '2-digit', minute: '2-digit' });

	const thumbnailUrl = useCallback(() => {
		if (contentInfo.items) {
			if ("thumbnailUrl" in contentInfo.items) {
				return contentInfo.items.thumbnailUrl;
			} else if ("thumbnailImageUrl" in contentInfo.items) {
				return contentInfo.items.thumbnailImageUrl;
			} else {
				return false;
			}
		}
	}, [contentInfo]);

	const alarm = useCallback(() => {
		const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
		const day = $L(DAYS[contentInfo.day]);
		if (appStatus.country === 'KR') {
			return day + " | " + time
		} else {
			return time + " | " + day
		}
	}, [contentInfo, time, appStatus]);



	return (
		<div className={css.list}>
			{thumbnailUrl() ?
				<>
					<Image className={css.img} src={thumbnailUrl()} placeholder={"thumbnailUrl"} />
					{contentInfo.items && contentInfo.items.contentType === ContentType.YOUTUBE_VIDEO &&
						<div className={css.youtubelogo} />
					}
				</>
				: <div className={css.noneImg} />
			}
			<Marquee className={css.title} marqueeOn={forceFocus ? "render" : null}>{title ? title : ' '}</Marquee>
			<div className={classNames(css.alarm, !thumbnailUrl() && forceFocus ? css.noneContents: null)}>
				{alarm()}
			</div>
		</div>
	);
};

export default GoalContentList;