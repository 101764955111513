import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Alert from '@enact/sandstone/Alert';
import Marquee from '@enact/sandstone/Marquee';
import CheckBoxItem from '@enact/sandstone/CheckboxItem';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import TButton from '../../../components/TButton/TButton';
import TSwitchItem from '../../../components/TSwitchItem/TSwitchItem';
import * as CmsActions from '../../../actions/cmsActions';
import * as CommonActions from '../../../actions/commonActions';
import * as LogTypes from '../../../data/LogTypes';
import css from './CameraSettingPopup.module.less';
import {$L} from '../../../utils/common';

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const CameraSettingPopup = ({open, onClose, onClickDone,...rest}) => {
	const dispatch = useDispatch();
	const [activated, setActivated] = useState(true);
	const localSettings = useSelector(state => state.localSettings);
	const cameraDeviceName = useSelector(state => state.appStatus.cameraDeviceName);
	const cameraDetected = useSelector(state => state.appStatus.cameraDetected);
	const [size, setSize] = useState('small');
	const [position, setPosition] = useState('rt');
	const [isBtnDisabled, setIsBtnDisabled] = useState(true);

	useEffect(() => {
		if(open && localSettings){
			setTimeout(() => {
				Spotlight.focus('switch');
				setActivated(localSettings.cameraActivated);
				setSize(localSettings.cameraSize);
				setPosition(localSettings.cameraPosition);
			}, 1);
		}
    },[open, localSettings]);

	const spotSwitch = useCallback((ev)=>{
		if(Spotlight.focus('switch')){
			ev.stopPropagation();
		}
	},[]);

	const _onClose = useCallback((ev) => {
		if(onClose){
			onClose(ev);
		}
    }, [onClose]);

	const onState = useCallback((ev) => {
		setActivated(ev.selected);
		setIsBtnDisabled(false);
	},[]);

	const onSelecSize = useCallback((_size) => (ev) => {
		if(ev.selected){
			setSize(_size);
			setIsBtnDisabled(false);
		}
	},[]);
	const onSelecPosition = useCallback((_position) => (ev) => {
		if(ev.selected){
			setPosition(_position);
			setIsBtnDisabled(false);
		}
	},[]);

	const connectionText = useMemo(() => {
		let text=$L('Not Connected');
		if(cameraDeviceName){
			text = $L('{Model Name} Connected.').replace('{Model Name}', cameraDeviceName);
		}
		else if(cameraDetected){
			text=$L('Connected');
		}
		return text;
	}, [cameraDeviceName, cameraDetected]);

	const onDone = useCallback(() => {
		dispatch(CommonActions.changeLocalSettings({cameraActivated: activated, cameraSize: size, cameraPosition: position}))
		dispatch(CmsActions.appLog(LogTypes.Types.CAMERASETTING,"Settings",activated,size,position));
		if(onClickDone){
			onClickDone();
		}
	  }, [dispatch, activated, size, position, onClickDone]);

	return (
		<Alert {...rest}
			className={classNames(css.infopopup)}
			open={open}
			type={"overlay"}
			onClose={_onClose}>
			<div className={css.titlelayer}>
				<TButton className={css.backBtn} allowClickOnPreview type={"back"} onClick={onClose} onSpotlightDown={spotSwitch}/>
				<Marquee  className={css.title} marqueeOn="render">
					{$L('Camera Settings')}
				</Marquee>
			</div>
			<div className={css.row}>
				<div className={css.title}>{$L('Connection')}</div>
				<div className={css.item}>{connectionText}</div>
			</div>

			<Container className={css.row}>
				<div className={css.title}>{$L('Camera Access')}</div>
				<TSwitchItem spotlightId={'switch'} data-webos-voice-label={$L('Camera')} selected={activated} onToggle={onState} className={css.accessSwitch}>{activated ? $L('On') : $L('Off')}</TSwitchItem>
			</Container>
			<Container className={classNames(css.row, css.size)}>
				<div className={css.title}>{$L('Screen Size')}</div>
				<Container className={css.cameraArea}>
					<CheckBoxItem selected={size==='big'} className={css.simpleCheckBox} onToggle={onSelecSize('big')}>
						{$L("Big Screen")}
					</CheckBoxItem>
					<CheckBoxItem selected={size==='medium'} className={css.simpleCheckBox} onToggle={onSelecSize('medium')}>
						{$L("Medium Screen")}
					</CheckBoxItem>
					<CheckBoxItem selected={size==='small'} className={css.simpleCheckBox} onToggle={onSelecSize('small')}>
						{$L("Small Screen")}
					</CheckBoxItem>
				</Container>
			</Container>
			<Container className={classNames(css.row, css.position)}>
				<div className={css.title}>{$L('Position')}</div>
				<Container className={css.cameraArea}>
					<div className={css.cmBox}>
						<div className={css.bgBox}>
							<div className={classNames(css.miniBox, css.subboxTopLeft)}/>
						</div>
						<CheckBoxItem selected={position==='lt'} className={css.checkBox} onToggle={onSelecPosition('lt')}>
							{$L("Upper Left")}
						</CheckBoxItem>
					</div>
					<div className={css.cmBox}>
						<div className={css.bgBox}>
							<div className={classNames(css.miniBox, css.subboxTopRight)}/>
						</div>
						<CheckBoxItem selected={position==='rt'} className={css.checkBox} onToggle={onSelecPosition('rt')}>
							{$L("Upper Right")}
						</CheckBoxItem>
					</div>
					<div className={css.cmBox}>
						<div className={css.bgBox}>
							<div className={classNames(css.miniBox, css.subboxBottomLeft)}/>
						</div>
						<CheckBoxItem selected={position==='lb'} className={css.checkBox} onToggle={onSelecPosition('lb')}>
							{$L("Lower Left")}
						</CheckBoxItem>
					</div>
					<div className={css.cmBox}>
						<div className={css.bgBox}>
							<div className={classNames(css.miniBox, css.subboxBottomRight)}/>
						</div>
						<CheckBoxItem selected={position==='rb'} className={css.checkBox} onToggle={onSelecPosition('rb')}>
							{$L("Lower Right")}
						</CheckBoxItem>
					</div>
				</Container>
			</Container>
			<div className={css.buttonlayer}>
				<TButton allowClickOnPreview spotlightId={'doneBtn'} disabled={isBtnDisabled} onClick={onDone}>{$L("Done")} </TButton>
			</div>
		</Alert>
	);
};

export default CameraSettingPopup;
