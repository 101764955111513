import React, { useCallback, useEffect, useState } from 'react';
import Spotlight from '@enact/spotlight';
import { useDispatch, useSelector } from 'react-redux';
import css from './GoalListPanel.module.less';
import * as PanelActions from '../../../actions/panelActions';
import * as Utils from '../../../utils/common';
import {$L} from '../../../utils/common';
import { types } from '../../../actions/actionTypes';
import TPanel from '../../../components/TPanel';
import THeader from '../../../components/TPanel/THeader';
import GoalListItem from '../../../components/GoalItem/GoalListItem';

const GoalListPanel = ({ panelInfo, hideChildren, ...rest }) => {
	const dispatch = useDispatch();

	const goalsOfAccount = useSelector(state => state.goalsOfAccount);
	const contentInfos = useSelector(state => state.contentInfos);
	const planInfo = useSelector(state => state.plansOfAccount);

	const [goal1, setGoal1] = useState([]);
	const [goal2, setGoal2] = useState([]);

	const goal1EditMode = goal1 && goal1.length !==0;
	const goal2EditMode = goal2 && goal2.length !== 0;
	const defaultFocus = panelInfo.spotId ? panelInfo.spotId : 'goal1';

	const updatedContentInfo = useSelector(state => state.updatedContentInfo, (newState) => {
		let matched = false;
		const goals = [goal1, goal2];
		for (let i = 0; i < goals.length; i++) {
			for (let j = 0; j < goals[i].length; j++) {
				if ("planId" in goals[i][j].items) {
					planInfo.map(item => {
						if (goals[i][j].items.planId === item.planId) {
							if(item.items[0].contentId === newState.contentId){
								item.thumbnailImageUrl = newState.thumbnailImageUrl;
								goals[i][j].items.thumbnailUrl = item.thumbnailImageUrl;
								goals[i][j].items.title = item.title;
								matched = true;
							}
						}
					})
				}
				if ("contentId" in goals[i][j].items) {
					if(goals[i][j].items.contentId === newState.contentId){
						matched = true;
					}
				}
			}
		}
		if(matched){
			return false;
		}
		return true;//it's same item. Will not update.
	});

	useEffect(() => {
		if(!hideChildren){
			dispatch({ type: types.RESET_TEMPORARY_GOAL });
			Spotlight.focus(defaultFocus);
		}
	}, [dispatch, hideChildren]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		dispatch(PanelActions.popPanel());
	}, [dispatch]);

	useEffect(() => {
		let content = [];
		let _goal1 = [];
		let _goal2 = [];
		const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
		if (goalsOfAccount && goalsOfAccount.length > 0) {
			for (let i = 0; i < goalsOfAccount.length; i++) {
				for (let j = 0; j < goalsOfAccount[i].items.length; j++) {
					let items = "contentId" in goalsOfAccount[i].items[j] ? contentInfos[goalsOfAccount[i].items[j].contentId] : goalsOfAccount[i].items[j];
					if(!items){ // for youtube
						items = goalsOfAccount[i].items[j];
					}
					const disabled = !goalsOfAccount[i].alarmActivated;
					const day = DAYS.indexOf(goalsOfAccount[i].items[j].weekday);
					const alarm = { hours: parseInt(goalsOfAccount[i].alarms[j].time.slice(0, 2)), minutes: parseInt(goalsOfAccount[i].alarms[j].time.slice(3, 5)) };
					const title = Utils.convertUtf8ToUni(goalsOfAccount[i].title);
					const goalId = goalsOfAccount[i].goalId;
					const alarmActivated = goalsOfAccount[i].alarmActivated;
					const order = i;
					content.push({ order, disabled, day, alarm, items, title, goalId, alarmActivated });
				}
			}
		}
		content.map(goal => {
			if(goal.title === 'goal1') {
				_goal1.push(goal);
			} else if(goal.title === 'goal2') {
				_goal2.push(goal);
			}
		});
		const goals = [_goal1, _goal2]
		for (let i = 0; i < goals.length; i++) {
			for (let j = 0; j < goals[i].length; j++) {
				if (goals[i] && goals[i][j] && "planId" in goals[i][j].items) {
					planInfo.map(item => {
						if (goals[i][j].items.planId === item.planId) {
							goals[i][j].items.thumbnailUrl = item.thumbnailImageUrl;
							goals[i][j].items.title = item.title;
							goals[i][j].items.calorie = item.calorie;
							goals[i][j].items.playtime = item.playtime;
						}
					})
				}
			}
		}
		setGoal1(_goal1);
		setGoal2(_goal2);
	}, [goalsOfAccount, contentInfos, updatedContentInfo, planInfo]);

	const onHandleBackBtn = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			Spotlight.focus('goal1');
			ev.stopPropagation();
		}
	}, []);

	return (
		<TPanel className={css.panel} handleCancel={onBack} {...rest}>
			<THeader title={$L('Goal Settings')} onClick={onBack} onKeyDown={onHandleBackBtn}/>
			<div className={css.text}>{$L('Set a goal to get motivated.')}</div>
			<div className={css.goalListBg}>
				<div className={css.goalListContainer}>
					<GoalListItem spotlightId={'goal1'} title={"goal1"} info={goal1} isEditMode={goal1EditMode}/>
					<GoalListItem spotlightId={'goal2'} title={"goal2"} info={goal2} isEditMode={goal2EditMode}/>
				</div>
			</div>
		</TPanel>
	);
};
export default GoalListPanel;