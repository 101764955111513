export const HOME_GROUP_IDS = {
	HOME_TODAY_TOP: "HOME_TODAY_TOP",
	HOME_RECENTLY_WATHCHED: "HOME_RECENTLY_WATHCHED",
	HOME_RECOMMEND: "HOME_RECOMMEND",
	HOME_MY_FAVORITE: "HOME_MY_FAVORITE",
	HOME_MY_TRAINING_PLAN: "HOME_MY_TRAINING_PLAN",
	HOME_RECENTLY_ADDED: "HOME_RECENTLY_ADDED",
	HOME_YOUTUBE: "HOME_YOUTUBE",
	CUSTOMIZED_WORKOUT: "CUSTOMIZED_WORKOUT",
	HOME_THIRD_PARTY: "THIRD_PARTY"
};
