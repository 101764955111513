import { types } from './actionTypes';
import axios from 'axios';
import {Job} from '@enact/core/util';
import * as PanelActions from './panelActions';
import * as CmsActions from './cmsActions';
import {$L} from '../utils/common';
import * as LogTypes from '../data/LogTypes';
import * as ContentType from '../data/ContentType';
import * as Utils from '../utils/common';
import * as Config from '../data/Config';
import * as lunaSend from '../lunaSend';
import appinfo from '../../webos-meta/appinfo.json';
import { TAxios } from './TAxios';

export const changeAppStatus = (status) => ({type: types.CHANGE_APP_STATUS, status});
export const changeLocalSettings = (status) => ({type: types.CHANGE_LOCAL_SETTINGS, status});

export const updateAdminPreviewData = (status) => ({type: types.UPDATE_ADMIN_PREVIEW_DATA, status});

export const initLoadingStatus = () => ({type: types.INIT_LOADING_STATUS});
export const addLoadingStatus = (status) => ({type: types.ADD_LOADING_STATUS, payload: status});
export const removeLoadingStatus= (status) => (dispatch) =>{
	setTimeout(() => {
		dispatch({type: types.REMOVE_LOADING_STATUS, payload: status});
	}, 100);
};
export const initDeviceAccountInfo = () => (dispatch, getState) => {
	dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {accountId: '', language: ''}});
};

export const accountError = (deviceAccount) => (dispatch, getState) => {
	const deviceId = getState().appStatus.deviceId;
	if(deviceAccount){
		dispatch({type: types.CHANGE_APP_STATUS, status: {checkingAccountId: false}});
		dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {accountId: ''}});
	}else{
		dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {accountId: '', language: 'en'}});
	}
	dispatch({type: types.GET_ACCOUNT_INFO, payload: {accountId: '', workoutStyleId: Config.UNKNOWN_WORKOUTSTYLEID}});
};

export const getSystemSettings = (onComplete)  => (dispatch, getState) => {
	const useQAServerURL = getState().localSettings.useQAServerURL;
	lunaSend.getSystemSettings({"category":"option", "keys": ["smartServiceCountryCode2", "cameraResourcePermission"]}, {
		onSuccess: (res) => {
			console.log("getSystemSettings onSuccess ",res);
		},
		onFailure: (err) => {
			console.log("getSystemSettings onFailure ",err);
		},
		onComplete: (res) => {
			console.log("getSystemSettings onComplete",res);
			let country=null;
			if(res && res.settings){
				if(res.settings.smartServiceCountryCode2){
					country = res.settings.smartServiceCountryCode2;
				}
				if(res.settings.cameraResourcePermission){
					for(let i=0; i<res.settings.cameraResourcePermission.length; i++){
						const data = res.settings.cameraResourcePermission[i];
						if(data.appId === appinfo.id){
							for(let j=0; j<data.permissions.length; j++){
								if( data.permissions[j].resource === 'camera'){
									dispatch(changeAppStatus({cameraPermissionRequired: data.permissions[j].permission}));
									break;
								}
							}
							break;
						}
					}
				}
			}
			if(country){
				dispatch(changeAppStatus({country: country}));
				dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {language: Utils.getLanguageCode(country)}});
			}
			if(useQAServerURL === 'qa' || useQAServerURL === 'dev' || useQAServerURL === 'twin'){
				dispatch(changeAppStatus({country: "KR"}));
				dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {language: Utils.getLanguageCode("KR")}});
			}else if(useQAServerURL === 'prd_us'){
				dispatch(changeAppStatus({country: "US"}));
				dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {language: Utils.getLanguageCode("US")}});
			}
			if (onComplete)	onComplete();
		}
	});
};
export const getSystemSettings2 = ()  => (dispatch) => {
	lunaSend.getSystemSettings({"category":"caption", "keys": ["captionEnable"]}, {
		onSuccess: (res) => {
			console.log("getSystemSettings2 onSuccess ",res);
		},
		onFailure: (err) => {
			console.log("getSystemSettings2 onFailure ",err);
		},
		onComplete: (res) => {
			console.log("getSystemSettings2 onComplete",res);
			if(res && res.settings){
				if(typeof res.settings.captionEnable !== 'undefined'){
					dispatch(changeAppStatus({captionEnable: res.settings.captionEnable}));
				}
			}
		}
	});
};

let updateNetworkStateJob = new Job((dispatch, connected) => {
	dispatch(changeAppStatus({isInternetConnected: connected}));
});

export const getConnectionStatus = () => (dispatch, getState) => {
	lunaSend.getConnectionStatus({
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const isInternet = ((res.wifi && res.wifi.onInternet === 'yes') || (res.wired && res.wired.onInternet === 'yes'));
				const isInternetConnected = ((res.wifi && res.wifi.state === 'connected') || (res.wired && res.wired.state === 'connected'));
				console.log('internetconnected.............',isInternet,isInternetConnected, res);
				const connected = isInternet&&isInternetConnected;
				updateNetworkStateJob.startAfter(connected ? 100 : 3000, dispatch, connected);
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: (res) => {
			console.log('getConnectionStatus done', res);
		}
	});
};
export const getSystemInfo = (onComplete)  => (dispatch, getState) => {
	lunaSend.getSystemInfo({"keys": ["sdkVersion", "otaId"]}, {
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const version = res.sdkVersion;
				if(version === 'local'){
					dispatch(changeAppStatus({webOSVersion: "local", webOSVersionReal: 'local', otaId: 'local'}));
				}else{
					dispatch(changeAppStatus({webOSVersion: version.substring(0,version.lastIndexOf('.')), webOSVersionReal: version, otaId: res.otaId}));
				}
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: () => {
			console.log('getSystemInfo done');
			if (onComplete)	onComplete();
		}
	});
};
export const getDeviceId = (onComplete)  => (dispatch, getState) => {
	lunaSend.getDeviceId({"idType": ["LGUDID"]}, {
		onSuccess: (res) => {
			console.log(res);
			if(res.returnValue){
				const deviceId = res.idList[0].idValue;
				dispatch(changeAppStatus({deviceId: deviceId}));
			}
		},
		onFailure: (err) => {
			console.log(err);
		},
		onComplete: () => {
			console.log('getDeviceId done');
			if (onComplete)	onComplete();
		}
	});
};
export const getHttpHeaderForServiceRequest = onComplete => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const supportLogin = getState().supportLogin;
	console.log('getHttpHeaderForServiceRequest webOSVersion ',webOSVersion, supportLogin);
	dispatch({type: types.CHANGE_APP_STATUS, status: {checkingAccountId: true}});
	lunaSend.getHttpHeaderForServiceRequest(webOSVersion,deviceAccountInfo.language, {
		onSuccess: (res) => {
			console.log("getHttpHeaderForServiceRequest", res);
			if(Number(webOSVersion) >= 6 || webOSVersion === 'local'){
				if(res["X-User-Number"] && supportLogin){
					dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {accountId: res["X-User-Number"]}});
				}else{
					dispatch(accountError(true));
				}
			}
			dispatch({ type: types.GET_HTTP_HEADER, payload: res });
		},
		onFailure: (err) => {
			console.log("getHttpHeaderForServiceRequest",err);
			if(Number(webOSVersion) >= 6 || webOSVersion === 'local'){
				dispatch(accountError(true));
			}
		},
		onComplete: () => {
			console.log('getHttpHeaderForServiceRequest done');
			if (onComplete)	onComplete();
		}
	});
};
export const getDeviceAccountInfo = onComplete => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const supportLogin = getState().supportLogin;
	const deviceId = getState().appStatus.deviceId;
	const supportLoginWithDeviceId = getState().supportLoginWithDeviceId;
	if (supportLoginWithDeviceId) {
		dispatch(CmsActions.getAccount(deviceId));
		if (onComplete)	onComplete();
		return;
	}
	if (Number(webOSVersion) < 6) {
		dispatch({type: types.CHANGE_APP_STATUS, status: {checkingAccountId: true}});
	}
	lunaSend.getDeviceAccountInfo(webOSVersion, deviceAccountInfo.language, {
		onSuccess: (res) => {
			console.log("getDeviceAccountInfo",res);
			if(res.returnValue && supportLogin){
				if(Number(webOSVersion) < 6){
					if(res.value){
						dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {accountId: res.value.userNo, displayName: res.value.displayUserID}});
					}else{
						dispatch(accountError(true));
					}
				}else{
					dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {displayName: res.id}});
				}
			}else{
				if(Number(webOSVersion) < 6){
					dispatch(accountError(true));
				}else{
					dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {displayName: ""}});
				}
			}
		},
		onFailure: (err) => {
			console.log("getDeviceAccountInfo",err);
			if(Number(webOSVersion) < 6){
				dispatch(accountError(true));
			}else{
				dispatch({type: types.GET_DEVICE_ACCOUNT_INFO, payload: {displayName: ""}});
			}
		},
		onComplete: () => {
			console.log('getDeviceAccountInfo done');
			if (onComplete)	onComplete();
		}
	});
};

export const changePlayInfo = (info) => (dispatch, getState) => {
	dispatch({type: types.GET_PLAYER_INFO, payload: info});
};
// banner, inapp search, favorite, nudge, deeplink, replay,
export const setPlayLaunchPath = (str) => (dispatch, getState) => {
	dispatch(changeAppStatus({playerLaunchPath: str}));
};
const YOUTUBE_OR_CP_PLAY_LOG = {
	timeSpent: 1,
	calorieBurned: 1,
	lastPositionSec: 1,
	reachedEnd: true
};
export const handlePlayItem = (item, relatedContents) => async (dispatch, getState) => {
	const useQAServerURL = getState().localSettings.useQAServerURL;
	const contentInfos = getState().contentInfos;
	const accountInfo = getState().accountInfo;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const {webOSVersion, otaId} = getState().appStatus;
	let playList, playIndex = 0, hasRelated = false, planId = null, listTitle = "";
	let lunchAppId = "youtube.leanback.v4";
	if(Number(webOSVersion) >=8 && otaId.startsWith('HE_MNT')) {
		lunchAppId = "youtube.leanback.v4-pjtr";
	}
	if(item.contentType && item.contentType.startsWith('youtube')){
		lunaSend.launchYoutubeApp(item.contentType, item.youtubeId, lunchAppId, {
			onSuccess: (res) => {
				console.log('launchYoutubeApp',res);
			},
			onFailure: (err) => {
				console.log(err);
			},
			onComplete: () => {
				console.log('launchYoutubeApp done');
			}
		});
		if(item.contentType === ContentType.YOUTUBE_VIDEO){
			if(isNaN(item.contentId)){
				dispatch(CmsActions.registYoutubeVideo(item, true, (data) => {
					const log = {...YOUTUBE_OR_CP_PLAY_LOG, contentId: data.contentId};
					dispatch(CmsActions.updatePlayLog(log));
				}));
			}else{
				const log = {...YOUTUBE_OR_CP_PLAY_LOG, contentId: item.contentId};
				dispatch(CmsActions.updatePlayLog(log));
			}
		}
		return;
	}else if(item.contentType === ContentType.CP_CONTENT){
		const splitedValue = item.cpContentId.split('/');
		const valid = splitedValue.length === 2;
		if(valid){
			dispatch(launchPromotionApp(splitedValue[0], splitedValue[1]));
			if(isNaN(item.contentId)){
				dispatch(CmsActions.registCpContent(item, (data) => {
					const log = {...YOUTUBE_OR_CP_PLAY_LOG, contentId: data.contentId};
					dispatch(CmsActions.updatePlayLog(log));
				}));
			}else{
				const log = {...YOUTUBE_OR_CP_PLAY_LOG, contentId: item.contentId};
				dispatch(CmsActions.updatePlayLog(log));
			}
		}
		return;
	}else if(item.contentType === ContentType.SINGLE){
		playList = [item];
		playIndex = 0;
		dispatch({type: types.CLEAR_CONTENTS_RELATED});
		if(!Config.ADMIN_PREVIEW_MODE){
			if(relatedContents){
				playList = relatedContents;
				playIndex = relatedContents.indexOf(item);
			}else{
				const _relatedContents = await CmsActions.getRelatedContents(item.contentId, accountInfo, deviceAccountInfo, useQAServerURL);
				playList = playList.concat(_relatedContents);
			}
			dispatch(setRecentlyWatchedContent(item));
			hasRelated = true;
		}
		listTitle = $L("Related");
	}else if(item.contentType === ContentType.SERIES){
		const info = contentInfos[item.contentId];
		if(info){
			playList =JSON.parse(JSON.stringify(info.clipList));
			playIndex = 0;
			listTitle = $L("Playlist");
		}else{
			console.error('invalid state');
		}
	}else if(item.contentType === ContentType.PLAN){
		playList = JSON.parse(JSON.stringify(item.items));
		playIndex = 0;
		planId = item.planId;
		listTitle = item.title;
		dispatch({type: types.CLEAR_CONTENTS_RELATED});
	}else if(item.contentType === ContentType.APP){
		dispatch(launchApp(item.appId));
		return;
	}else{
		//todo
		console.log('invalid item...........');
		return;
	}
	if(!(playIndex >= 0)){
		playIndex = 0;
	}
	const pInfo = {
			playList,
			playIndex,
			hasRelated,
			listTitle,
			planId
	};
	console.log('handlePlayItem ',item, pInfo);
	dispatch(changePlayInfo(pInfo));
	for ( let index in playList) {
		dispatch(CmsActions.getContentInfo(playList[index].contentId,false));
	}
	if(Config.ADMIN_PREVIEW_MODE){
		if(item.playUrl){
			dispatch(PanelActions.pushPanel('player'));
		}
	}else{
		dispatch(PanelActions.pushPanel('player'));
	}
};
export const launchApp = (appId) => (dispatch, getState) => {
	const {webOSVersion, otaId} = getState().appStatus;
	let lunchAppId = appId;
	if(Number(webOSVersion) >=8 && otaId.startsWith('HE_MNT') && lunchAppId === "youtube.leanback.v4") {
		lunchAppId = "youtube.leanback.v4-pjtr";
	}
	dispatch(CmsActions.appLog(LogTypes.Types.LAUNCH_APP, lunchAppId));
	lunaSend.launchApp(lunchAppId, {
		onSuccess: (res) => {
			console.log(res);
		},
		onFailure: (err) => {
			console.log(err);
		}
	});
};
export const launchPromotionApp = (id,target) => (dispatch, getState) => {
	const {webOSVersion, otaId} = getState().appStatus;
	let lunchAppId = id;
	if(Number(webOSVersion) >=8 && otaId.startsWith('HE_MNT') && lunchAppId === "youtube.leanback.v4") {
		lunchAppId = "youtube.leanback.v4-pjtr";
	}
	dispatch(CmsActions.appLog(LogTypes.Types.LAUNCH_PROMOTION, lunchAppId, target));
	lunaSend.launchPromotionApp(lunchAppId, target, {
		onSuccess: (res) => {
			console.log(res);
		},
		onFailure: (err) => {
			console.log(err);
		}
	});
};
export const launchMembershipApp = (from) => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	const accountInfo = getState().accountInfo;
	if(!accountInfo.accountId){
		dispatch(CmsActions.appLog(LogTypes.Types.LOGINBUTTON, from));
	}
	if(Number(webOSVersion) >=9 ){ //new api  o24
		lunaSend.launchMembershipAppNew(webOSVersion, {
			onSuccess: (res) => {
				console.log(res);
			},
			onFailure: (err) => {
				console.log(err);
				lunaSend.launchMembershipApp(webOSVersion, {
					onSuccess: (res) => {
						console.log(res);
					},
					onFailure: (err) => {
						console.log(err);
					}
				});
			}
		});
		return;
	}else{
		lunaSend.launchMembershipApp(webOSVersion, {
			onSuccess: (res) => {
				console.log(res);
			},
			onFailure: (err) => {
				console.log(err);
			}
		});
	}
};
export const cancelGetVideoThumbnail = () => () => {
	lunaSend.cancelGetVideoThumbnail({
		onSuccess: (res) => {
			console.log('cancelGetVideoThumbnail', res);
		},
		onFailure: (err) => {
			console.log('cancelGetVideoThumbnail', err);
		}
	});
};
export const getVideoThumbnail = (sourceUri, time) => (dispatch, getState) => {
	lunaSend.getVideoThumbnail(sourceUri, time, {
		onSuccess: (res) => {
			console.log('getVideoThumbnail', res);
			dispatch({type: types.GET_VIDEO_PROGRESS_THUMBNAIL, payload: res});
		},
		onFailure: (err) => {
			console.log('getVideoThumbnail', err);
		}
	});
};
export const clearVideoThumbnails = () => (dispatch, getState) => {
	dispatch({type: types.CLEAR_VIDEO_PROGRESS_THUMBNAIL});
};
const doItemFocus = async (dispatch, getState, item) => {
	dispatch({type: types.GET_FOCUSED_BANNER_ITEM, payload: item});
}
let itemFocusJob = new Job(doItemFocus, 500);
export const handleItemFocus = (item) => async (dispatch, getState) => {
	const mainIndex = getState().mainIndex;
	const panels = getState().panels;
	if(mainIndex === PanelActions.MAIN_INDEX.HOME_VIEW && panels.length <= 0){
		itemFocusJob.start(dispatch, getState, item);
	}
}
export const clearFocusedBannerItem = () => async (dispatch, getState) => {
	itemFocusJob.stop();
	dispatch({type: types.GET_FOCUSED_BANNER_ITEM, payload: null});
}
export const getHomegymServiceInfo = payload => (dispatch, getState) => {
	lunaSend.getHomegymServiceInfo({}, {
		onSuccess: (res) => {
			dispatch(changeAppStatus({homeServiceVersion: res.version, activeServiceDebug: res.activeServiceDebug}));
		},
		onFailure: (err) => {
			console.log(err);
		}
	});
};
export const activeServiceDebug = payload => (dispatch, getState) => {
	lunaSend.activeServiceDebug({}, {
		onSuccess: (res) => {
			dispatch(changeAppStatus({activeServiceDebug: res.activeServiceDebug}));
		},
		onFailure: (err) => {
			console.log(err);
		}
	});
};
export const toastTest = payload => (dispatch, getState) => {
	lunaSend.toastTest({}, {
		onSuccess: (res) => {
			console.log(res);
		},
		onFailure: (err) => {
			console.log(err);
		}
	});
};

export const alertToast = payload => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	if (webOSVersion === "local" && typeof window === 'object') {
		dispatch(changeAppStatus({toast: true, toastText:payload}));
	}else{
		lunaSend.alertToast(payload, {
			onSuccess: (res) => {
				console.log(res);
			},
			onFailure: (err) => {
				console.log(err);
			}
		});
	}
};

export const setLaunchAction = (info) => (dispatch, getState) => {
	dispatch({type: types.GET_LAUNCH_ACTION, payload: info});
}
export const clearLaunchAction = () => (dispatch, getState) => {
	dispatch({type: types.CLEAR_LAUNCH_ACTION});
}
export const setActiveAlarmData = (alarmInfo, goalInfo) => (dispatch, getState) => {
	const {webOSVersion, country, otaId, deviceId} = getState().appStatus;
	const {useQAServerURL} = getState().localSettings;
	const accountInfo = getState().accountInfo;
	const deviceAccountInfo = getState().deviceAccountInfo;
	const contentInfos = getState().contentInfos;
	const alarms = [];
	const keys = Object.keys(alarmInfo);
	console.log('setActiveAlarmData ', alarmInfo, goalInfo);
	//alarm
	for(let i=0; i<keys.length; i++){
		const alarm = alarmInfo[keys[i]];
		if(alarm && alarm.activated){
			const data = {};
			const hm = alarm.time.split(':');
			data.hour = String(hm[0]);
			data.minute = String(hm[1]);
			data.weekday = alarm.weekdays;
			data.title = alarm.title;
			data.alarmId = alarm.alarmId;
			data.type='alarm';
			alarms.push(data);
		}
	}
	//goal
	for(let i=0; i<2; i++){
		const goal = goalInfo[i];
		if(goal && goal.alarmActivated){
			for(let j=0; j<goal.alarms.length; j++){
				if(goal.alarms[j] && goal.alarms[j].activated){
					const data = {};
					const hm = goal.alarms[j].time.split(':');
					data.hour = String(hm[0]);
					data.minute = String(hm[1]);
					const weekDay = [goal.items[j].weekday];
					data.weekday = weekDay;
					data.title = goal.title;
					data.alarmId = goal.goalId;
					if(goal.items[j].contentId){
						const info = contentInfos[goal.items[j].contentId];
						if(info && info.contentType === ContentType.YOUTUBE_VIDEO){
							data.type='youtube';
							data.youtubeId = info.youtubeId;
						}else{
							data.type='content';
						}
						data.contentId=goal.items[j].contentId;
					}else if(goal.items[j].planId){
						data.type='plan';
						data.planId=goal.items[j].planId;
					}
					alarms.push(data);
				}
			}
		}
	}
	console.log('setActiveAlarmData alarms ', alarms);
	const pathname = typeof window === 'object' && window.document.location.pathname;
	//pathname: "/media/cryptofs/apps/usr/palm/applications/com.twin.app.homegymtest/index.html"
	const params = {
		"title": $L("Now it's time to exercise. Let's get started!"),
		"titleCount": $L("It looks like you missed last {count} workouts you planned. What's stopping you? Let's get going."),
		"button1Text": $L("Let's Do This"),
		"button2Text": $L('Remind me in 10 min'),
		"button3Text": $L('Alarm Settings'),
		"button3TextGoal": $L('Goal Settings'),
		"accountId": accountInfo.accountId,
		"webOs": webOSVersion,
		"country": country,
		"platformCode": otaId,
		"iconUrl": {"2k": pathname.replace(/index(.+)html/,"icon.png"), "4k": pathname.replace(/index(.+)html/,"icon-large.png")},
		"alarms": alarms,
		"deviceId": deviceId,
		"serverUrl": Config.GYMURL(deviceAccountInfo.language,"", useQAServerURL)
	};
	lunaSend.setActiveAlarmData(params, {
		onSuccess: (res) => {
			console.log("setActiveAlarmData",res);
		},
		onFailure: (err) => {
			console.log("setActiveAlarmData",err);
		}
	});
}

export const getResultOfLGFitness = (keyword, loading) => (dispatch, getState) => {
	const webOSVersion = getState().appStatus.webOSVersion;
	const useQAServerURL = getState().localSettings.useQAServerURL;
	const appStatus = getState().appStatus;
	if (!loading) {
		dispatch({ type: types.ADD_LOADING_SPINNER_STATUS, payload: "getResultOfLGFitness" });
	}
	const deviceHttpHeader = getState().deviceHttpHeader;
	let country = deviceHttpHeader["X-Device-Country"];
	let commonHeader = {
		"X-Authentication": deviceHttpHeader["X-Authentication"],
		"X-Device-ID": deviceHttpHeader["X-Device-ID"],
		"X-Device-Product": deviceHttpHeader["X-Device-Product"],
		"X-Device-Platform": deviceHttpHeader["X-Device-Platform"],
		"X-Device-Model": deviceHttpHeader["X-Device-Model"],
		"X-Device-Eco-Info": deviceHttpHeader["X-Device-Eco-Info"],
		"X-Device-Country": deviceHttpHeader["X-Device-Country"],
		"X-Device-Country-Group": deviceHttpHeader["X-Device-Country-Group"],
		"X-Device-Language": deviceHttpHeader["X-Device-Language"],
		"X-Device-Netcast-Platform-Version": deviceHttpHeader["X-Device-Netcast-Platform-Version"],
		"X-Device-Publish-Flag": deviceHttpHeader["X-Device-Publish-Flag"],
		"X-Device-FCK": deviceHttpHeader["X-Device-FCK"],
		"X-Device-Eula": deviceHttpHeader["X-Device-Eula"],
		"X-Device-Personalization": deviceHttpHeader["X-Device-Personalization"]
	};
	if(webOSVersion === 'local'){
		country=appStatus.country;
		commonHeader = {
			"X-Authentication": "I6K94XU88pUv9T6ClBUBU6KESI4=",
			"X-Device-ID": "e3yut8xKghfbvBu3gUjp0Mns9hNCiRgTFfSsjPsaJWdt7PGg2howA8EJa3HbdtgQSGH4QUHFHcSN8sB5Foax5wf5Nj9slFv6wnNzR0q92Y9edao1wJTR4Fk8/pHchDe3",
			"X-Device-Product": "webOSTV 6.0",
			"X-Device-Platform": "W21K",
			"X-Device-Model": "HE_DTV_W21K_AFADATAA",
			"X-Device-Eco-Info": "1",
			"X-Device-Country": appStatus.country,
			"X-Device-Country-Group": appStatus.country,
			"X-Device-Language": appStatus.country === "KR" ? "ko-KR":"en-US",
			"X-Device-Netcast-Platform-Version": "6.2.0",
			"X-Device-Publish-Flag": "N",
			"X-Device-FCK": "274",
			"X-Device-Eula": "additionalDataAllowed,takeOnAllowed,networkAllowed,generalTermsAllowed,chpAllowed,customAdAllowed,acrOnAllowed,shoppingOnAllowed,voice2Allowed,voiceAllowed,acrAdAllowed",
			"X-Device-Personalization": undefined
		};
	}

	const params = {
		service: "com.webos.app.lgfitness",
		query: keyword,
		startIndex: 1,
		maxResults: Config.HLIST_HOME_MAX,
		domain: "fitness"
	};

  let url = `http://qt2-${country}.lgtvsdp.com/rest/sdp/search/lgfitness/v1/retrieval`;
	if(useQAServerURL === 'prd'){
		url = `http://${country}.lgtvsdp.com/rest/sdp/search/lgfitness/v1/retrieval`;
	}
	if(useQAServerURL === 'qa'){
		url = `http://qt-${country}.lgtvsdp.com/rest/sdp/search/lgfitness/v1/retrieval`
	}
	console.log("getResultOfLGFitness webOSVersion", webOSVersion)
	console.log("getResultOfLGFitness deviceHttpHeader", deviceHttpHeader)
	console.log("getResultOfLGFitness commonHeader", commonHeader)
	console.log("getResultOfLGFitness params", params)
	console.log("getResultOfLGFitness url...", url)
	axios.post(url, null, {headers: {common: commonHeader, "content-Type": "application/x-www-form-urlencoded; charset=utf-8"}, params: params})
	.then((response) => {
		console.log('getResultOfLGFitness....', response.data.results);
		if(response.data.results[0] && response.data.results[0].docs){
			const result = [];
			for (let i = 0; i < response.data.results[0].docs.length; i++) {
				const item = response.data.results[0].docs[i];
				const content = {};
				content.contentId = Number(item.id);
				content.contentType = ContentType.SINGLE;
				content.title = item.title;
				content.thumbnailImageUrl = item.thumbnail;
				content.postImageUrl = item.thumbnail;
				result.push(content);
			}
			dispatch({ type: types.GET_CONTENTS_KEYWORD, payload: {keyword: keyword, data: result } });
		}else{
			dispatch({ type: types.GET_CONTENTS_KEYWORD, payload: {keyword: "", data: []} });
		}
		dispatch({ type: types.REMOVE_LOADING_SPINNER_STATUS, payload: "getResultOfLGFitness" });
	})
	.catch((err) => {
		console.error('getResultOfLGFitness', err);
		dispatch({ type: types.GET_CONTENTS_KEYWORD, payload: {keyword: "", data: []} });
		dispatch({ type: types.REMOVE_LOADING_SPINNER_STATUS, payload: "getResultOfLGFitness" });
	});
};

// TODO 마지막으로 재생한 컨텐츠 있는지 확인
export const getRecentlyWatchedContent = (params) => (dispatch, getState) => {
	const query = {
		accountId: params.accountId || '',
		deviceId: params.deviceId,
	}
	const onSuccess = (response) => {
		console.log("getRecentlyWatchedContent Success", response);

		// NOTE[CS] 최근 시청한 컨텐츠가 없는 경우에만 Luna call을 함
		// 기존 유저의 경우 아무 동작도 하지 않음
		const result = response.data.data || [];
		if(result.length <= 0) {
			dispatch(setRecentlyWatchedContent(result[0]));
		}
	}
	const onFail = (error) => {
		console.log("getRecentlyWatchedContent Fail", error);
	}

	TAxios(dispatch, getState, "get", Config.GET_RECENTLY_WATCHED_CONTENT, query, onSuccess, onFail);
}

// TODO 재생한 동영상 저장
export const setRecentlyWatchedContent = (params) => (dispatch, getState) => {
	lunaSend.updateQCard(params, {
		onSuccess: (res) => {
			console.log('updateQCard Success', res);
		},
		onFailure: (err) => {
			console.log('updateQCard Failure', err);
		},
		onComplete: () => {
			console.log('updateQCard Complate');
		},
	})
}

