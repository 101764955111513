/**
 * HistoryItem
 *
 * @module HistoryItem
 */
import React, {useMemo} from 'react';
import {shallowEqual} from 'react-redux';
import classNames from 'classnames';
import Marquee from '@enact/sandstone/Marquee';
import * as Utils from '../../../utils/common';
import css from './History.module.less';
import YOUTUBE_BADGE_LOGO from '../../../../assets/list/icon/icon_youtube.png';

const HistoryItem = ({historyData, index, hideDate}) => {
	const badgeStyle = useMemo(() => {
		if(historyData){
			if(historyData.openStatus === "CP_CONTENT" && historyData.badgeIconUrl){
				return {backgroundImage: `url(${historyData.badgeIconUrl})`};
			}else if(historyData.openStatus === "YOUTUBE"){
				return {backgroundImage: `url(${YOUTUBE_BADGE_LOGO})`};
			}
		}
		return null;
	},[historyData]);

	if(historyData){
		const dateStr = new Date(historyData.date.replace(/(-)/g,',')).toLocaleDateString("default",{ year: 'numeric', month: 'short', day: 'numeric' });
		const playtimeStr = Utils.durationText(historyData.playtime);
		const calorieStr = !historyData.calorie ? "" : historyData.calorie+" kcal";
		const categoryStr = historyData.categoryList.length > 0 ? historyData.categoryList[0].title: "";

		return (
			
			<div className={classNames(css.itemContainer, index%2===0 ? css.odd: null)}>
				<div className={classNames(css.text, css.date, hideDate ? css.hide: null)}>{dateStr}</div>
				{badgeStyle ?
					<div className={css.detail}>
						{ badgeStyle && <div className={css.badgeStyle} style={badgeStyle}></div> }
					</div>
					:
					<div className={css.detail}>
						<div className={classNames(css.text, css.playtime)}>{playtimeStr}</div>
						{calorieStr &&
							<div className={classNames(css.text, css.seperator)}>{" | "}</div>
						}
						<div className={classNames(css.text, css.calorie)}>{calorieStr}</div>
						{categoryStr &&
							<div className={classNames(css.text, css.seperator)}>{" | "}</div>
						}
						<div className={css.text}>{categoryStr}</div>
					</div>
				}
				<Marquee  className={classNames(css.text, css.title)} marqueeOn={"render"}>
					{Utils.replaceHtmlEntites(Utils.convertUtf8ToUni(historyData.title))}
				</Marquee>
			</div>
		);
	}
	return null;
};



export default React.memo(HistoryItem, shallowEqual);
