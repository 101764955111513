 import css from './OneMinuitButton.module.less';
 import classNames from 'classnames';
 import React, {useCallback} from 'react';
 import Spottable from '@enact/spotlight/Spottable';

 const SpottableComponent = Spottable('div')

 const OneMinuitButton = ({type, children, spotlightId, className, onClick, disabled, ...rest}) => {
   const _onClick = useCallback((ev) => {
     if (onClick) {
       onClick(ev);
     }
     ev.persist();
   }, [ onClick, disabled ]);

   return (
     <SpottableComponent
       {...rest}
       className={classNames(css.startStopReset, css[type], className)}
       onClick={_onClick}
       data-webos-voice-intent={'Select'}
       data-webos-voice-label={children}
       spotlightId={spotlightId}
     >
      <div className={css.text}>
        {children}
      </div>
     </SpottableComponent>
   );
 };
 export default OneMinuitButton;