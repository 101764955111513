/**
 * MyInfo
 *
 * @module MyInfo
 */

import classNames from 'classnames';
import {Image} from '@enact/sandstone/Image';
import React, {useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import Marquee from '@enact/sandstone/Marquee';
import css from './MyInfo.module.less';
import {$L} from '../../../utils/common';
import ChartTitle from '../ChartTitle/ChartTitle';

import ICON_TIME from '../../../../assets/dashboard/Status/ICN_Time.png';
import ICON_COMPLETE from '../../../../assets/dashboard/Status/ICN_Complete.png';
import ICON_BURN from '../../../../assets/dashboard/Status/ICN_Burn.png';
import ICON_SPENT from '../../../../assets/dashboard/Status/ICN_Spent.png';
import dummyNormal from '../../../../assets/list/BodyShaper_on.png';

import basicIcon from '../../../../assets/dashboard/icon/default.png';
import dietIcon from '../../../../assets/dashboard/icon/diet.png';
import healthIcon from '../../../../assets/dashboard/icon/health.png';
import strengthIcon from '../../../../assets/dashboard/icon/strength.png';
import postureIcon from '../../../../assets/dashboard/icon/posture.png';
import * as CP875Table from '../../../data/CP875Table';

const MyInfo = ({className, workoutDate}) => {
	const workoutSummary = useSelector(state => state.workoutSummary);
	const customizedUserInfo = useSelector(state => state.customizedUserInfo);
	const CUSTOMIZED_WORKOUT = [{icon: basicIcon}, {icon: dietIcon}, {icon: healthIcon}, {icon: strengthIcon}, {icon: postureIcon}];

	const workoutGoal = useMemo(()=>{
		let iconUrl = "", title = CP875Table.getGoals()[customizedUserInfo.goal];
		CUSTOMIZED_WORKOUT.map((goal, index)=>{
			if(index === customizedUserInfo.goal){
				iconUrl = goal.icon;
			}
		})
		return {title, iconUrl}
		},[customizedUserInfo.goal]);

	const statusList = useMemo(() => {
		const list=[
			{title: $L("Total Workout Time"), value: ["0", "0"], value2: [$L({value: 'h', key: '__h'}),$L({value: 'm', key: '__m'})], icon: ICON_TIME},
			{title: $L("Workouts Completed"), value: [0], value2: [$L("time")], icon: ICON_COMPLETE},
			{title: $L("Calories Burned"), value: [0], value2: ["kcal"], icon: ICON_BURN},
			{title: $L("Spent most time on"), value: [ $L("None")], icon: ICON_SPENT},
		];
		const summary = workoutSummary[workoutDate.firstDate];
		if(!summary){
			return list;
		}
		if(summary.timeSpent){
			let totalTime = 0;
			for(let i=0; i<summary.timeSpent.length; i++){
				totalTime+=summary.timeSpent[i];
			}
			const h = Math.floor(totalTime/3600);
			const m = Math.floor((totalTime-h*3600)/60);
			list[0].value = [h,m];
		}
		list[1].value = summary.completedNumber ? [summary.completedNumber]:[0];
		list[2].value = summary.calorieBurned ? [summary.calorieBurned]:[0];
		if(summary.categoryUsages){
			let maxTimeSpent = 0, categoryName = $L("None");
			for(let i=0; i<summary.categoryUsages.length; i++){
				if( maxTimeSpent < summary.categoryUsages[i].timeSpent){
					maxTimeSpent = summary.categoryUsages[i].timeSpent;
					categoryName = summary.categoryUsages[i].title;
				}
			}
			list[3].value = [categoryName];
		}
		return list;
	}, [workoutSummary, workoutDate]);

	return (
		<div className={className}>
			<ChartTitle>{$L('Status')}</ChartTitle>
			<div className={css.chart}>
				<div className={css.left}>
					<Image src={workoutGoal.iconUrl} className={css.image}/>
					<div className={css.styletext}>
						{workoutGoal.title}
					</div>
				</div>
				<div className={css.right}>
					{statusList.map((item, index) => (
						<div key={index} className={css.itemContainer}>
							<Image className={css.iconImg} src={item.icon}/>
							<div className={css.textBlock}>
								<div className={css.title} >{item.title}</div>
								{index !== 3 && item.value.map((t, i) => (
									<React.Fragment key={i}>
										<div className={css.value}>{item.value[i]}</div>
										<div className={css.value2}>{item.value2[i]}</div>
									</React.Fragment>
								))}
								{index === 3 &&
									<Marquee  className={classNames(css.value, css.marquee)} marqueeOn={"render"}>
										{item.value[0]}
									</Marquee>
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default React.memo(MyInfo, shallowEqual);
