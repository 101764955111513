import classNames from 'classnames';
import React,{useCallback, useEffect } from 'react';
import Alert from '@enact/sandstone/Alert';
import TButton from '../TButton/TButton';
import css from './TAlert.module.less';
import Spotlight from '@enact/spotlight';

const TAlert = ({title, message, selected, button1text, button2text, button3text, onClick, onClose, noticeAlert=false,...rest}) => {
	useEffect(() => {
		setTimeout(() => {
			Spotlight.focus('button3');
			Spotlight.focus('button2');
			Spotlight.focus('button1');
		}, 0);
	}, []);

	const onClickBtn1 = useCallback((ev) => {
		if(onClick){
			onClick(0, ev);
		}
    }, [onClick]);
	const onClickBtn2 = useCallback((ev) => {
		console.log(ev.type)
        if (onClick) {
			onClick(1, ev);
		}
	}, [onClick]);
	const onClickBtn3 = useCallback((ev) => {
		if(onClick){
			onClick(2, ev);
		}
    }, [onClick]);
    const _onClose = useCallback((ev) => {
        console.log('onClose', ev);
		if(onClose){
			onClose(ev);
		}
    }, [onClose]);

	const hasButton = button1text || button2text;

	return (
		<Alert {...rest}
			className={classNames(css.infopopup)}
			open
			onClose={_onClose}
			type={"overlay"}
		>
			<div className={classNames(css.messagelayer, hasButton? css.hasButton:css.noButton)}>
				{message &&
					<div className={classNames(css.message, selected && css.selected, noticeAlert && css.noticeAlert)}>
					<div style={{ width: "100%" }}>{message}</div>
					</div>
				}
			</div>
			<div className={css.buttonlayer}>
				<div className={classNames(css.buttonWrapper)}>
					{button1text &&
						<TButton allowClickOnPreview spotlightId={'button1'} onClick={onClickBtn1}>{button1text} </TButton>
					}
					{button2text &&
						<TButton allowClickOnPreview spotlightId={'button2'} onClick={onClickBtn2}>{button2text}</TButton>
					}
					{button3text &&
						<TButton allowClickOnPreview spotlightId={'button3'} onClick={onClickBtn3}>{button3text}</TButton>
					}
				</div>
			</div>
		</Alert>
	);
};

export default TAlert;
