/**
 * SettingsPopup
 *
 * @module SettingsPopup
 */

import React, {useCallback, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FixedPopupPanels, Header, Panel} from '@enact/sandstone/FixedPopupPanels';
import Item from '@enact/sandstone/Item';
import Icon from '@enact/sandstone/Icon';
import * as PanelActions from '../../actions/panelActions';
import * as CommonActions from '../../actions/commonActions';
import * as Config from '../../data/Config';
import {$L} from '../../utils/common';
import css from './SettingsPopup.module.less';
import TButton from '../../components/TButton';
import SpotlightIds from '../../data/SpotlightIds';
import { types } from '../../actions/actionTypes';
import * as CmsActions from '../../actions/cmsActions';
import * as Utils from '../../utils/common';
// import {changeMusicGroupTypeOption} from '../../actions/listActions';

const SettingsPopup = ({open, onClosePopup, ...rest}) => {
	const deviceAccountInfo = useSelector(state => state.deviceAccountInfo);
	const dispatch = useDispatch();
	const webOSVersion  = useSelector(state => state.appStatus.webOSVersion);
	const supportLogin = useSelector(state => state.supportLogin);
	// const supportPIPCamera  = useSelector(state => state.supportPIPCamera);
	const alarmInfo = useSelector(state => state.alarmOfAccount);
	const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const accountInfo = useSelector(state => state.accountInfo);
	const goalsOfAccount = useSelector(state => state.goalsOfAccount);
	const planInfo = useSelector(state => state.plansOfAccount);
	const customizedUserInfo = useSelector(state => state.customizedUserInfo);
	const {homeGroupHasRoutine,homeGroupHasCustomizedWorkout} = useSelector(state => state.appStatus);

	const groupList = useMemo(() => {
		let logOutText = $L('Account');
		let userName = deviceAccountInfo.displayName;
		if(Number(webOSVersion) >= 6){
			userName = Utils.isEmailAddress(deviceAccountInfo.displayName) ? deviceAccountInfo.displayName : "";
		}
		if(userName){
			logOutText = $L('Account')+" ("+userName+")";
		}else{
			logOutText = $L('Account')+" ("+$L("Logged in via SNS")+")";
		}

		let list = [];
		if (supportLogin) {
			if(accountInfo.accountId){
				list = [
					{ 'id': 'goal', children: $L('Goal Settings') },
					{ 'id': 'alarm', children: $L('Alarm Settings') }
				];
				if(Config.SUPPORT_WORKOUTSTYLE){
					list.push({ 'id': 'workout', children: $L('My Workout') });
				}
				if(homeGroupHasRoutine){
					list.push({ 'id': 'routine', children: $L('My Workout Routine') });
				}
				list.push({ 'id': 'favorite', children: $L('My Favorite') });
				if(Config.SUPPORT_INTERVIEW && homeGroupHasCustomizedWorkout){
					list.unshift({ 'id': 'interview', children: $L('Customized Workout Settings') });
				}
			} else {
				list = list.concat([
					{ 'id': 'goal', children: $L('Goal Settings') },
					{ 'id': 'alarm', children: $L('Alarm Settings') }
				]);
				if(homeGroupHasRoutine){
					list.push({ 'id': 'routine', children: $L('My Workout Routine') });
				}
				if(Config.SUPPORT_INTERVIEW && homeGroupHasCustomizedWorkout){
					list.unshift({ 'id': 'interview', children: $L('Customized Workout Settings') });
				}
			}
			list.push({ 'id': 'terms', children: $L('Terms & Policies') });
			list.push({ 'id': 'notice', children: $L('Notice') });
			list.push({ 'id': 'logout', children: deviceAccountInfo.accountId ? logOutText : $L('Login'), voicelabel: deviceAccountInfo.accountId ? $L('Account') : $L('Login') });
		}else{
			if(accountInfo.accountId && Config.SUPPORT_WORKOUTSTYLE){
				list.push({ 'id': 'workout', children: $L('My Workout') });
			}
			list.push({ 'id': 'terms', children: $L('Terms & Policies') });
			list.push({ 'id': 'notice', children: $L('Notice') });
		}
		return list;
	}, [supportLogin, deviceAccountInfo, accountInfo, webOSVersion, homeGroupHasRoutine, homeGroupHasCustomizedWorkout ]);

	const onSelectMenu = useCallback((ev) => {
		console.log('onSelectMenu', ev);
		console.log('onSelectMenu', ev.currentTarget.dataset.spotlightId);
		switch (ev.currentTarget.dataset.spotlightId) {
			case 'terms': dispatch(PanelActions.pushPanel('termsConditions', {panelState:'withdraw'}));
				break
			case 'goal': {
				if (goalsOfAccount && goalsOfAccount.length > 0) {
					dispatch(PanelActions.pushPanel('goallist'));
				} else {
					dispatch({ type: types.RESET_TEMPORARY_GOAL });
					dispatch(PanelActions.pushPanel('goalsettings', { title: "goal1", activated: true, isEditMode:false}));
				}
			}
				break;
			case 'alarm': {
					if(alarmInfo && alarmInfo.alarm1 !== undefined) {
						dispatch(PanelActions.pushPanel('alarmlist'));
					} else {
						dispatch(PanelActions.pushPanel('alarmsettings', {alarmTitle: "alarm1"}));
					}
				}
				break;
			case 'logout': {
					dispatch(CommonActions.launchMembershipApp('Settings'));
				}
				break;
			case 'workout': dispatch(PanelActions.pushPanel('workoutstyle'));
				break;
			case 'routine': {
					if (planInfo && planInfo.length > 0) {
						dispatch(PanelActions.pushPanel('myworkoutroutinelist'));
					} else {
						dispatch(PanelActions.pushPanel('myworkoutroutinesettings'));
					}
				}
				break;
			case 'favorite':
				dispatch(PanelActions.pushPanel('morelist', {title: $L('My Favorite'), list: contentsMyFavorites, listId: SpotlightIds.LIST_MYFAVORITE}));
				break;
			case 'interview':
				if(customizedUserInfo.age > 0 && customizedUserInfo.program > 0){
					dispatch(PanelActions.pushPanel('interviewModify'));
				}else{
					dispatch(PanelActions.pushPanel('interview'));
				}
				break;
			case 'camera': dispatch(PanelActions.pushPanel('cameraSetting'));
				break;
			case 'servicetest': dispatch(PanelActions.pushPanel('servicetest'));
				break;
			case 'notice': {
				dispatch(CmsActions.getNoticeList());
				dispatch(PanelActions.pushPanel('notice'));
			}
				break;
		}
		if (onClosePopup) {
			onClosePopup();
		}
	}, [dispatch, onClosePopup, alarmInfo, goalsOfAccount, planInfo, contentsMyFavorites, customizedUserInfo]);
	const onHide = useCallback((ev) => {
		console.log('onHide', ev);
	}, []);

	const onClose = useCallback((ev) => {
		console.log('onClose', ev);
		if (onClosePopup) {
			onClosePopup();
		}
	}, [onClosePopup]);

	return (
		<FixedPopupPanels width="narrow" open={open} className={css.setpopupPanel} onHide={onHide} onClose={onClose} position="right">
			<Panel {...rest} className={css.panel}>
				<Header type="compact" className={css.title} noCloseButton noBackButton>
				<TButton type={"back"} className={css.cancelBtn} onClick={onClose}/>
					<title>
						{$L('Settings')}
					</title>
				</Header>
				<div className={css.lineBar} />
				{
					groupList.map(menu => (
						<Item
							className={css.subBtn}
							onClick={onSelectMenu}
							data-webos-voice-label={menu.voicelabel? menu.voicelabel: menu.children}
							key={menu.id} spotlightId={menu.id} labelPosition="below" size="small" slotBefore={<Icon size="small">arrowlargeright</Icon>}
						>
							{menu.children}
						</Item>
					))
				}
			</Panel>
		</FixedPopupPanels>
	);
};

export default SettingsPopup;
