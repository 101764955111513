import classNames from 'classnames';
import React, {useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {on, off} from '@enact/core/dispatcher';
import {Job} from '@enact/core/util';
import platform from '@enact/core/platform';
import Scroller from '@enact/sandstone/Scroller';
import Spotlight from '@enact/spotlight';
import css from './MoreListPanel.module.less';
import TPanel from '../../components/TPanel/TPanel';
import THeader from '../../components/TPanel/THeader';
import MediaList from '../../components/MediaList/MediaList';
import * as Utils from '../../utils/common';
import * as Config from '../../data/Config';
import * as PanelActions from '../../actions/panelActions';
import VerticalScrollGuide from '../../components/VerticalScrollGuide/VerticalScrollGuide';
import SpotlightIds from '../../data/SpotlightIds';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';

const SCROLLER_ID="detail_scroller";
const MAX_VISIBLE_ITEM = 15;
const calculateVisibleListJob = new Job((func) => {
	func();
}, 2000);

const Container = SpotlightContainerDecorator({ leaveFor: { left: '', right: '', down: '' }, enterTo: '', spotlightRestrict: 'self-only' }, 'div');

const MoreListPanel = ({hideChildren, panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const scrollTo = useRef(null);
	const cursorVisible = useSelector(state => state.appStatus.cursorVisible);
	const {accountId} = useSelector(state => state.deviceAccountInfo);
	const supportLogin = useSelector(state => state.supportLogin);
	const contentsMyFavorites = useSelector(state => state.contentsMyFavorites);
	const [scrollOnBottom, setScrollOnBottom] = useState(false);
	const [scrollOnTop, setScrollOnTop] = useState(true);
	const [visibleList, setVisibleList] = useState([]);
	const [restoreIndex, setRestoreIndex] = useState(-1);
	const scrollTop = useRef(0);

	const contentsList = useMemo(() => {
		console.log('panelInfo ',panelInfo);
		if(panelInfo.listId === SpotlightIds.LIST_MYFAVORITE){
			return contentsMyFavorites;
		}else{
			return panelInfo.list;
		}
	}, [contentsMyFavorites, panelInfo]);

	const logHint = useMemo(() => {
		return panelInfo.listId+"_morelist("+panelInfo.title+")";
	}, [panelInfo]);

	const supportFavBtn = useMemo(() => {
		if(supportLogin && accountId){
			return true;
		}
		return false;
	}, [supportLogin, accountId]);

	useEffect(() => {
		if(!contentsList || contentsList.length <=0){
			Spotlight.focus(SpotlightIds.BACK_BUTTON);
		}
	}, [contentsList]);

	useEffect(() => {
		Spotlight.focus(SpotlightIds.LIST_MORE);
		on('wheel', handleWheel);
		calculateVisibleListJob.start(calculateVisibleList);
		return () => {
			off('wheel', handleWheel);
			if(calculateVisibleListJob){
				calculateVisibleListJob.stop();
			}
		};
	}, []);

	useEffect(() => {
		if(!hideChildren){
			Spotlight.focus(SpotlightIds.BACK_BUTTON);
			setRestoreIndex(panelInfo.restoreIndex);
			setTimeout(() => {
				Spotlight.focus(SpotlightIds.LIST_MORE);
				if(panelInfo.scrollTop && scrollTo.current){
					scrollTo.current({position:{y: panelInfo.scrollTop}, animate: true});
				}
			}, 100);
			setTimeout(() => {
				setRestoreIndex(-1);
			}, 500);
		}
	}, [hideChildren]);

	const onBack = useCallback(() => {
        dispatch(PanelActions.popPanel('morelist'));
	}, [dispatch]);

	const calculateVisibleList= useCallback(() => {
		const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
		const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_MORE}"]`);
		let listArray = listView ? listView.children[0].chilchildrendNodes: [];
		const itemList = [];
		for(let index in listArray){
			if(!isNaN(index)){
				const item = listArray[index];
				if(item.offsetTop >= scrollTop.current && (item.offsetTop+item.offsetHeight)<= scrollTop.current+scroller.offsetHeight){
					itemList.push(item.getAttribute('data-spotlight-id'));
				}
			}
		}
		setVisibleList(itemList);
	}, []);

	const getScrollTo = useCallback((cbScrollTo) => {
		scrollTo.current = cbScrollTo;
	}, [scrollTo]);

	const onScrollStop = useCallback((ev) => {
		scrollTop.current = Math.round(ev.scrollTop);
		const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
		if(scrollTop.current + scroller.offsetHeight + 300/*dummy*/ > scroller.children[0].scrollHeight){
			setScrollOnBottom(true);
		}else{
			setScrollOnBottom(false);
		}
		if(scrollTop.current <=0){
			setScrollOnTop(true);
		}else{
			setScrollOnTop(false);
		}
		calculateVisibleList();
		dispatch(PanelActions.updatePanel('morelist', {scrollTop: scrollTop.current}));
	}, [scrollTop, setScrollOnBottom, setScrollOnTop, calculateVisibleList, dispatch]);

	const moveBottom = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const scroller = document.querySelector(`[data-spotlight-id="${SCROLLER_ID}"]`);
			const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_MORE}"]`);
			let listArray = listView ? listView.children[0].children: [];
			if(!wheel){
				const focusedIndex = listView && listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('down', currentItem);
				if(currentItem && nextItem){
					const offsetBottom = nextItem.offsetTop+nextItem.offsetHeight;
					if(offsetBottom < (scrollTop.current+scroller.offsetHeight)){ //contain
						return true;
					}else{
						const targetOffset = currentItem.offsetTop;
						scrollTop.current = targetOffset;
						scrollTo.current({position:{y: targetOffset}, animate: true});
						return true;
					}
				}
				return false;
			}
			for(let i=0; i<listArray.length;i++){
				if(scrollTop.current < listArray[i].offsetTop){
					//check last line
					const lastLine = Math.ceil(listArray.length/5); // 5 items per line
					if(lastLine > 3 && Math.ceil((i+1)/5) < lastLine-1){
						scrollTop.current = listArray[i].offsetTop;
						scrollTo.current({position:{y: listArray[i].offsetTop}, animate: true});
					}
					return true;
				}
			}
		}
	}, []);
	const moveTop = useCallback((wheel = false) => {
		if(scrollTo.current !== null){
			const listView = document.querySelector(`[data-spotlight-id="${SpotlightIds.LIST_MORE}"]`);
			let listArray = listView ? listView.children[0].children: [];
			if(!wheel){
				const focusedIndex = listView && listView.getAttribute('data-focused-index');
				const currentItem = listArray[focusedIndex];
				const nextItem = currentItem && Utils.getNearestTarget('up', currentItem);
				if(currentItem && nextItem){
					if(nextItem.offsetTop === scrollTop.current){ //contain
						return true;
					}else{
						if(nextItem.offsetTop < scrollTop.current){
							scrollTop.current = nextItem.offsetTop;
							scrollTo.current({position:{y: nextItem.offsetTop}, animate: true});
						}
						return true;
					}
				}
				return false;
			}
			for(let i=listArray.length-1; i>=0;i--){
				if(listArray[i].offsetTop < scrollTop.current){
					scrollTop.current = listArray[i].offsetTop;
					scrollTo.current({position:{y:listArray[i].offsetTop},animate: true});
					return true;
				}
			}
		}
	}, []);

	const onClickTopGuide = useCallback(() => {
			moveTop(true);
	}, [moveTop]);

	const onClickBottomGuide = useCallback(() => {
			moveBottom(true);
	}, [moveBottom]);

	const onKeyDown = useCallback((ev) => {
		if(ev.key === 'ArrowDown' ||ev.key === 'PageDown'){
			return moveBottom(false);
		}else if(ev.key === 'ArrowUp' || ev.key === 'PageUp'){
			return moveTop(false);
		}
		return false;
	}, [moveTop, moveBottom]);

	const handleWheel = useCallback((ev) => {
		console.log('onWheel ',ev);
		if(ev.deltaY < 0){
			onClickTopGuide();
		}else{
			onClickBottomGuide()
		}
	}, [onClickTopGuide, onClickBottomGuide]);

	const onFocusedIndexChanged = useCallback((id, index) => {
		dispatch(PanelActions.updatePanel('morelist', {restoreIndex: index}));
	}, [dispatch]);

	return (
		<TPanel {...props} className={css.panel} handleCancel={onBack}>
			<THeader allowClickOnPreview title={panelInfo.title} onClick={onBack}/>
			{contentsList && contentsList.length > 0 &&
				<Scroller
					cbScrollTo={getScrollTo}
					className={css.medialistlayer}
					spotlightId={SCROLLER_ID}
					direction="vertical"
					horizontalScrollbar="hidden"
					verticalScrollbar="hidden"
					scrollMode="translate"
					noScrollByWheel
					noScrollByDrag
					onScrollStop={onScrollStop}
				>
					<Container>
						<MediaList visibleList={visibleList} orientation={'vertical'} restoreIndex={restoreIndex} logHint={logHint}
							spotlightId={SpotlightIds.LIST_MORE} viewList={contentsList} supportFavBtn={supportFavBtn}  onKeyDown={onKeyDown} onFocusedIndexChanged={onFocusedIndexChanged}/>
						<div className={css.dummy}/>
					</Container>
				</Scroller>
			}
			{(cursorVisible || platform.touchscreen) && contentsList.length > MAX_VISIBLE_ITEM && !scrollOnTop &&
				<VerticalScrollGuide type={"top"} className={classNames(css.topBottomScrollArea, css.topScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickTopGuide}/>
			}
			{ (cursorVisible || platform.touchscreen) && contentsList.length > MAX_VISIBLE_ITEM && !scrollOnBottom &&
				<VerticalScrollGuide type={"bottom"} className={classNames(css.topBottomScrollArea, css.bottomScrollArea, Config.SHOW_TOUCH_GUIDE ? css.touchGuide: null)} onClick={onClickBottomGuide}/>
			}
		</TPanel >
	);
};

export default MoreListPanel;
