import {$L} from '../utils/common';

//gender type
export const GENDER_MAN = 1;
export const GENDER_WOMAN = 2;

//goal type
export const GOAL_DIAT = 1;  //다이어트
export const GOAL_STRENGTH = 2;  //근력키우기
export const GOAL_PHYSICAL = 3;  //체력관리
export const GOAL_POSTURE = 4;  //자세교정

//plan type
export const PLAN_DIAT_1 = 1;	//주제별
export const PLAN_DIAT_2 = 2;	//상/하체
export const PLAN_DIAT_3 = 3;	//전신
export const PLAN_STRENGTH_1 = 4;	//상체
export const PLAN_STRENGTH_2 = 5;	//하체
export const PLAN_STRENGTH_3 = 6;	//코어/전신
export const PLAN_PHYSICAL_1 = 7;	//전신
export const PLAN_POSTURE_1 = 8;	//부위별

let goals = null, programNames = null, sequence = null;
export const getGoals = () => {
	if(!goals){
		goals = [$L("Basic"), $L("Diet"),$L("Building Muscles"),$L("Building Stamina"),$L("Posture Correcting")];
	}
	return goals;
}
export const getProgramNames = () => {
	if(!programNames){
		programNames = [
			$L("Basic Information"), $L("Quick Fat Loss"), $L("Forming Work-out Habit"), $L("Relieving Stress"), $L("Abs"), $L("Arms, Back"), $L("Lower Body"), $L("Circular Routine"), $L("Tabata Training"), $L("All-in-one"),
			$L("Chest / Shoulders"), $L({value: "Back", key: "__Back"}), $L("Upper Body Tooning"), $L("Butt Toning"), $L("Thigh, Calf"), $L("Lower Body Toning"), $L("Core Exercise"), $L("Elasticity Routine"), $L("Power Routine"),
			$L("Cardiorespiratory Endurance"), $L("Basal Metabolism"), $L("Flexibility"),
			$L("Upper Body"), $L("Lower Body"), $L("Pelvis")
		];
	}
	return programNames;
}
export const getSequence = () => {
	const PROGRAMNAMES = getProgramNames();
	if(!sequence){
		sequence = {
			"basic":{
				"title": $L("Basic Information"),
				"type": "basic",
				"nextId": "Q1",
				"userInfo": {"gender":0, "tall": 0, "weight": 0, "age": 0}
			},
			"Q1":{
				"title": $L('What is the purpose of your exercise?'),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to go on a diet."), "nextId": "Q2-1", goal: GOAL_DIAT },
					{ "title": $L("I want to gain muscles."), "nextId": "Q3-1", goal: GOAL_STRENGTH },
					{ "title": $L("I want to increase my stamina."), "nextId": "Q4-1", goal: GOAL_PHYSICAL },
					{ "title": $L("I want to correct my posture."), "nextId": "Q5-1", goal: GOAL_POSTURE }
				]
			},
			"Q2-1": {
				"title": $L("What kind of exercise do you want to do?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to exercise that suits my situation."), "nextId": "Q2-2-1", plan: PLAN_DIAT_1 },
					{ "title": $L("I want to exercise to improve my quality of life."), "nextId": "Q2-2-1", plan: PLAN_DIAT_1 },
					{ "title": $L("I want to focus on the areas that needs improvement in my exercise."), "nextId": "Q2-2-2", plan: PLAN_DIAT_2 },
					{ "title": $L("I want to exercise to reduce body fat."), "nextId": "Q2-2-3", plan: PLAN_DIAT_3 },
					{ "title": $L("I want to exercise that consumes a lot of physical strength in a short period of time."), "nextId": "Q2-2-3", plan: PLAN_DIAT_3}
				]
			},
			"Q2-2-1": {
				"title": $L('What are your concerns?'),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I suddenly gained weight and want to lose it quickly."), program: 1, nextId: "Q2-3" },
					{ "title": $L("I urgently need a diet."), program: 1, nextId: "Q2-3" },
					{ "title": $L("I want to exercise regulary every day."), program: 2, nextId: "Q2-3" },
					{ "title": $L("I want to feel a sense of accomplishment through my exercise."), program: 2, nextId: "Q2-3" },
					{ "title": $L("I'm under a lot of stress with my diet these days."), program: 3, nextId: "Q2-3" }
				]
			},
			"Q2-2-2": {
				"title": $L('What are your concerns?'),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I gain weight, especially in my stomach and sides."), program: 4, nextId: "Q2-3" },
					{ "title": $L("I have fat on my forearms and armpits."), program: 5, nextId: "Q2-3" },
					{ "title": $L("I want to have a smooth back line."), program: 5, nextId: "Q2-3" },
					{ "title": $L("My legs gets heavy and easily swollen."), program: 6, nextId: "Q2-3" },
					{ "title": $L("Even though I lose weight, my hips and thighs stay the same."), program: 6, nextId: "Q2-3" }
				]
			},
			"Q2-2-3": {
				"title": $L("What are your concerns?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("My body gets tired easily because I usually don't do a lot of activities."), program: 7, nextId: "Q2-3" },
					{ "title": $L("I want to exercise regulary."), program: 7, nextId: "Q2-3" },
					{ "title": $L("I want to burn a lot of calories in a short amount of time."), program: 8, nextId: "Q2-3"},
					{ "title": $L("I have low muscle mass and want to lose body fat.") , program: 8, nextId: "Q2-3"},
					{ "title": $L("I don't have time to exercise."), program: 9, nextId: "Q2-3" }
				]
			},
			"Q2-3": {
				"title": $L("Have you experienced working out before?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I have no experience in exercising."), "level": 1, nextId: "Q2-4" },
					{ "title": $L("I have only done light exercises like walking and riding a bicycle."), "level": 2, nextId: "Q2-4" },
					{ "title": $L("I know I need more strength training, but I don't know where to start."), "level": 2, nextId: "Q2-4" },
					{ "title": $L("I exercise regularly while watching exercise videos at home."), "level": 3, nextId: "Q2-4" },
					{ "title": $L("I have my own workout routine."), "level": 3, nextId: "Q2-4" }
				]
			},
			"Q2-4": {
				"title": $L("Measure how long you can hold the plank position!"),
				"type": 'selftest',
				"nextId": "complete",
				"oneMinuitTest": {
					"title": $L("Would you like to measure how long you can hold the plank position?"),
					"image": "diet"
				}
			},
			"Q3-1": {
				"title": $L("Which area would you like to focus on while exercising?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Upper Body"), "nextId": "Q3-2-1", plan: PLAN_STRENGTH_1},
					{ "title": $L("Lower Body"), "nextId": "Q3-2-2", plan: PLAN_STRENGTH_2},
					{ "title": $L("Core / Whole Body"), "nextId": "Q3-2-3", plan: PLAN_STRENGTH_3}
				]
			},
			"Q3-2-1": {
				"title": $L("What concerns do you have?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to have broad shoulders."), nextId: "Q3-3", program: 10 },
					{ "title": $L("I want to make a firm chest and shoulder line."), nextId: "Q3-3", program: 10 },
					{ "title": $L("I want to have a smooth back line."), nextId: "Q3-3", program: 11 },
					{ "title": $L("I want to make a strong back."), nextId: "Q3-3", program: 11 },
					{ "title": $L("I want to complete a balanced upper body fit."), nextId: "Q3-3", program: 12 }
				]
			},
			"Q3-2-2": {
				"title": $L("What concerns do you have?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to increase the volume of my butt."), nextId: "Q3-3", program: 13 },
					{ "title": $L("I want to make a stronger butt."), nextId: "Q3-3", program: 13 },
					{ "title": $L("I want to have an attractive thigh line."), nextId: "Q3-3", program: 14 },
					{ "title": $L("I want to have stronger legs."), nextId: "Q3-3", program: 14 },
					{ "title": $L("I want to complete a balanced lower body fit."), nextId: "Q3-3", program: 15 }
				]
			},
			"Q3-2-3": {
				"title": $L("What concerns do you have?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to increase strength in my core."), nextId: "Q3-3", program: 16 },
					{ "title": $L("I want to exercise the larger and inner muscles at the same time."), nextId: "Q3-3", program: 17 },
					{ "title": $L("I want to have an overall muscle balance."), nextId: "Q3-3", program: 17 },
					{ "title": $L("I want to create more muscles overall."), nextId: "Q3-3", program: 18 },
					{ "title": $L("I want to improve muscle coordination."), nextId: "Q3-3", program: 18 }
				]
			},
			"Q3-3": {
				"title": $L("What kind of body line do you want?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I want to have a slim body that goes well with any clothes."), "level": 1, nextId: "Q3-4" },
					{ "title": $L("I want to have a healthy body with strong muscles."), "level": 2, nextId: "Q3-4" },
					{ "title": $L("I want to have a sculpted body with clear muscle lines."), "level": 3, nextId: "Q3-4" }
				]
			},
			"Q3-4": {
				"title": $L("Measure how many push-ups you can do!"),
				"type": 'selftest',
				"nextId": "complete",
				"oneMinuitTest": {
					"title": $L("Would you like to measure how many push-ups you can do?"),
					"image": "strength"
				}
			},
			"Q4-1": {
				"title": $L("What kind of exercise do you want to do?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Exercises to help improve heart and lung function"), nextId: "Q4-2-1", plan: PLAN_PHYSICAL_1, program: 19 },
					{ "title": $L("Exercises that give you energy and help you recover from fatigue"), nextId: "Q4-2-1", plan: PLAN_PHYSICAL_1, program: 19 },
					{ "title": $L("Exercise that boosts metabolism"), nextId: "Q4-2-2", plan: PLAN_PHYSICAL_1, program: 20 },
					{ "title": $L("Exercises to increase calorie burn"), nextId: "Q4-2-2", plan: PLAN_PHYSICAL_1, program: 20 },
					{ "title": $L("Exercise that improve muscle flexibility"), nextId: "Q4-2-3", plan: PLAN_PHYSICAL_1, program: 21 }
				]
			},
			"Q4-2-1": {
				"title": $L("What is your day to day routine?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I sleep less than 5 hours."), "level": 1, nextId: "Q4-3"},
					{ "title": $L("My daily routine is irregular."), "level": 1, nextId: "Q4-3" },
					{ "title": $L("I make time to be active for 30 mins everyday."), "level": 2, nextId: "Q4-3" },
					{ "title": $L("I lightly stretch everyday."), "level": 2, nextId: "Q4-3" },
					{ "title": $L("I try to be active during the week and weekends."), "level": 3, nextId: "Q4-3" }
				]
			},
			"Q4-2-2": {
				"title": $L("How many times a week do you usually exercise?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Never"), "level": 1, nextId: "Q4-3" },
					{ "title": $L("1-2 times a week"), "level": 2, nextId: "Q4-3" },
					{ "title": $L("3-4 times a week"), "level": 2, nextId: "Q4-3" },
					{ "title": $L("5 times a week"), "level": 3, nextId: "Q4-3" }
				]
			},
			"Q4-2-3": {
				"title": $L("How are your movements usually?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("My body is stiff when I move around."), "level": 1, nextId: "Q4-3" },
					{ "title": $L("My ankles, knee, back , shoulders feels stiff when I move around."), "level": 1, nextId: "Q4-3" },
					{ "title": $L("I lightly stretch everyday."), "level": 2, nextId: "Q4-3" },
					{ "title": $L("I have difficulty performing movements due to lack of flexibility during exercise."), "level": 2, nextId: "Q4-3" },
					{ "title": $L("I try to exercise regularly to prevent from getting hurt easily."), "level": 3, nextId: "Q4-3" }
				]
			},
			"Q4-3": {
				"title": $L("Measure how long you can stand on one foot by closing your eyes!"),
				"type": 'selftest',
				"nextId": "complete",
				"oneMinuitTest": {
					"title": $L("Would you like to measure how long you can stand on one foot by closing your eyes?"),
					"image": "health"
				}
			},
			"Q5-1": {
				"title": $L("Which area on your body do you need help on with?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Neck"), "nextId": "Q5-2-1", plan: PLAN_POSTURE_1 },
					{ "title": $L("Shoulders / Back"), "nextId": "Q5-2-1", plan: PLAN_POSTURE_1 },
					{ "title": $L("Pelvis / Hips"), "nextId": "Q5-2-2", plan: PLAN_POSTURE_1 },
					{ "title": $L("Legs"), "nextId": "Q5-2-2", plan: PLAN_POSTURE_1 }
				]
			},
			"Q5-2-1": {
				"title": $L("Which of your experiences would you most like to improve?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("I usually have back pain so I can't concentrate while I work / study."), "nextId": "Q5-3", program: 22 },
					{ "title": $L("The neck looks short, and the part where the neck and shoulders connect is stiff."), "nextId": "Q5-3", program: 22 },
					{ "title": $L("My shoulders height is different."), "nextId": "Q5-3", program: 22 },
					{ "title": $L("My shoulders are rounded."), "nextId": "Q5-3", program: 22 },
					{ "title": $L("I often hear that my posture is slouched."), "nextId": "Q5-3", program: 22 },
					{ "title": $L("When I raise my arms my shoulders feel uncomfortable."), "nextId": "Q5-3", program: 22 }
				]
			},
			"Q5-2-2": {
				"title": $L("Which of your experiences would you most like to improve?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Regularly my knees and ankles feels uncomfortable."), "nextId": "Q5-3", program: 23 },
					{ "title": $L("When lying down, the angles of both feet apart from the center line are different."), "nextId": "Q5-3", program: 23 },
					{ "title": $L("I feel like my body is out of order."), "nextId": "Q5-3", program: 24 },
					{ "title": $L("The degree of wear of the sole of the shoe is different on both sides."), "nextId": "Q5-3", program: 24 },
					{ "title": $L("When I wake up from my sleep my back feels uncomfortable."), "nextId": "Q5-3", program: 24 },
					{ "title": $L("It's hard to keep a straight posture daily."), "nextId": "Q5-3", program: 24 }
				]
			},
			"Q5-3": {
				"title": $L("Regularly how uncomfortable do you feel?"),
				"type": 'radio',
				"nextId": null,
				"selected": -1,
				"radio": [
					{ "title": $L("Exercises that the hospital recommends."), "level": 1, nextId: "complete" },
					{ "title": $L("My body feels uncomfortable when I wake up from my sleep."), "level": 1, nextId: "complete" },
					{ "title": $L("It's hard to stand still in the same position for a long period of time."), "level": 2, nextId: "complete" },
					{ "title": $L("I don't feel uncomfortable, but I would like to have a straight posture."), "level": 3, nextId: "complete" },
					{ "title": $L("I feel uncomfortable once or twice throughout the year."), "level": 3, nextId: "complete" }
				]
			},
			"complete": {
				"title": $L("The Fitness questionnaire is completed."),
				'type': 'complete',
				"nextId": null
			},
			/* Singletone Modify */
			"modifyLevel": {
				"title": $L("Level Change"),
				'type': 'radio',
				"radio": [
					{ "title": $L("Level 1"), "level": 1},
					{ "title": $L("Level 2"), "level": 2},
					{ "title": $L("Level 3"), "level": 3}
				]
			},
			"modifyProgram1": { //GOAL_DIAT
				"title": $L("Program Change"),
				'type': 'radio',
				"radio": [
					{ "title": PROGRAMNAMES[1], program: 1, plan: PLAN_DIAT_1},
					{ "title": PROGRAMNAMES[2], program: 2, plan: PLAN_DIAT_1},
					{ "title": PROGRAMNAMES[3], program: 3, plan: PLAN_DIAT_1},
					{ "title": PROGRAMNAMES[4], program: 4, plan: PLAN_DIAT_2},
					{ "title": PROGRAMNAMES[5], program: 5, plan: PLAN_DIAT_2},
					{ "title": PROGRAMNAMES[6], program: 6, plan: PLAN_DIAT_2},
					{ "title": PROGRAMNAMES[7], program: 7, plan: PLAN_DIAT_3},
					{ "title": PROGRAMNAMES[8], program: 8, plan: PLAN_DIAT_3},
					{ "title": PROGRAMNAMES[9], program: 9, plan: PLAN_DIAT_3}
				]
			},
			"modifyProgram2": { //GOAL_STRENGTH
				"title": $L("Program Change"),
				'type': 'radio',
				"radio": [
					{ "title": PROGRAMNAMES[10], program: 10, plan: PLAN_STRENGTH_1},
					{ "title": PROGRAMNAMES[11], program: 11, plan: PLAN_STRENGTH_1},
					{ "title": PROGRAMNAMES[12], program: 12, plan: PLAN_STRENGTH_1},
					{ "title": PROGRAMNAMES[13], program: 13, plan: PLAN_STRENGTH_2},
					{ "title": PROGRAMNAMES[14], program: 14, plan: PLAN_STRENGTH_2},
					{ "title": PROGRAMNAMES[15], program: 15, plan: PLAN_STRENGTH_2},
					{ "title": PROGRAMNAMES[16], program: 16, plan: PLAN_STRENGTH_3},
					{ "title": PROGRAMNAMES[17], program: 17, plan: PLAN_STRENGTH_3},
					{ "title": PROGRAMNAMES[18], program: 18, plan: PLAN_STRENGTH_3}
				]
			},
			"modifyProgram3": { //GOAL_PHYSICAL
				"title": $L("Program Change"),
				'type': 'radio',
				"radio": [
					{ "title": PROGRAMNAMES[19], program: 19, plan: PLAN_PHYSICAL_1},
					{ "title": PROGRAMNAMES[20], program: 20, plan: PLAN_PHYSICAL_1},
					{ "title": PROGRAMNAMES[21], program: 21, plan: PLAN_PHYSICAL_1}
				]
			},
			"modifyProgram4": { //GOAL_POSTURE
				"title": $L("Program Change"),
				'type': 'radio',
				"radio": [
					{ "title": PROGRAMNAMES[22], program: 22, plan: PLAN_POSTURE_1},
					{ "title": PROGRAMNAMES[23], program: 23, plan: PLAN_POSTURE_1},
					{ "title": PROGRAMNAMES[24], program: 24, plan: PLAN_POSTURE_1}
				]
			}
		};
	}
	return sequence;
}
