import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';
import commonReducer from '../reducers/commonReducer';
import panelReducer from '../reducers/panelReducer';
import cmsReducer from '../reducers/cmsReducer';

const rootReducer = combineReducers(Object.assign(commonReducer, panelReducer, cmsReducer));

export default function configureStore (initialState) {
	const store = createStore(
		rootReducer,
		initialState,
		applyMiddleware(thunkMiddleware) // lets us dispatch functions
	);
	return store;
}
