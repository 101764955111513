import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spottable from '@enact/spotlight/Spottable';
import Icon from '@enact/sandstone/Icon';
import classNames from 'classnames';
import Image from '@enact/sandstone/Image';
import Marquee from '@enact/sandstone/Marquee';
import css from './PlanAddContent.module.less';
import { types } from '../../actions/actionTypes';
import { $L } from '../../utils/common';
import * as PanelActions from '../../actions/panelActions';
import * as Utils from '../../utils/common';
import TButton from '../TButton/TButton';
import TAlert from '../TAlert/TAlert';
import Spotlight from '@enact/spotlight';
import RepeatSettingPopup from '../Popup/RepeatSettingPopup';

const SpottableComponent = Spottable('div');

const PlanAddContent = ({ type, title, planId, isEditMode, contentInfo, isReoderMode, order, isFocus, currentOrder, contentContainer, contentChangeBtn, previousFocusNum, currentFocusNum,
	onReorderMode, onKeyHandleAddContent, onKeyHandReorderBtn, onKeyHandleContentContainer, onKeyHandleRepeatBtn, reOrderContents, onContentFocus, onContentBlur, ...rest }) => {

	const dispatch = useDispatch();
	const previousFocus = useRef();

	const tempInfo = useSelector(state => state.tempPlanInfo);

	const [checkPopupOpen, setCheckPopupOpen] = useState({ open: false, target: "" });
	const [repeatSettingPopupOpen, setRepeatSettingPopupOpen] = useState(false);

	const detailInfo = contentInfo && Object.keys(contentInfo.info).length > 0 && contentInfo.info;
	const contentTitle = detailInfo && detailInfo.title;
	const disabled = contentInfo && Object.keys(contentInfo.info).length <= 0 && isReoderMode ? true : contentInfo && contentInfo.disabled;
	const repeatCount = contentInfo && detailInfo.repeatCount && detailInfo.repeatCount;
	const playtime = contentInfo && detailInfo.playtime ? Utils.transSecToText(detailInfo.playtime) : "";
	let message = $L("Repeat {repeatCount} Times");
	message = message.replace('{repeatCount}', repeatCount);

	const panelInfo = useMemo(() => {
		return { type, isEditMode, planId, title, order };
	}, [ type, isEditMode, planId, title, order ]);

	/* state of list focus  */
	const onListFocus = useCallback(() => {
		onContentFocus(order, true)
		if(isReoderMode) {
			reOrderContents(currentOrder, order)
		}
	}, [onContentFocus, reOrderContents, isReoderMode, currentOrder, order]);

	const onListBlur = useCallback(() => {
		onContentBlur();
	}, [onContentBlur]);

	const onKeyHandleContent = useCallback((ev) => {
		if (ev.key === 'ArrowDown') {
			if (onKeyHandleAddContent) {
				const isContent = contentInfo.info.length !== 0;
				onKeyHandleAddContent(ev, isContent);
			}
		} else if (ev.key === 'ArrowUp'){
			if (!isReoderMode) {
				onKeyHandleContentContainer();
				onListBlur();
				ev.stopPropagation();
			} else {
				ev.stopPropagation();
			}
		}
	},[onKeyHandleAddContent, onListBlur, contentInfo, isReoderMode, onKeyHandleContentContainer]);

	/* onClick Button function */
	const onAddContent = useCallback(() => {
		if (!isReoderMode) {
			dispatch(PanelActions.pushPanel('settingsMediaPicker', panelInfo));
		}
	}, [dispatch, panelInfo, isReoderMode]);

	const onExitReorderMode = useCallback(() => {
		if (isReoderMode) {
			onReorderMode(false);
		}
	}, [onReorderMode, isReoderMode]);

	const onStartReorderMode = useCallback((ev) => {
		onContentFocus(order, true)
		if (ev.type === 'keyup') {
			onReorderMode(true, order);
		} else {
			if (previousFocusNum === currentFocusNum) {
				onReorderMode(true, order);
			}
		}
	}, [onContentFocus, onReorderMode, order, previousFocusNum, currentFocusNum]);

	const onChangeRepeat = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		onContentFocus(order, true)
		if (ev.type === 'keyup') {
			setRepeatSettingPopupOpen(true);
		} else {
			if (previousFocusNum === currentFocusNum) {
				setRepeatSettingPopupOpen(true);
			}
		}
	}, [onContentFocus, order, previousFocusNum, currentFocusNum]);

	/* Pop-up open/close/work function */
	const onCloseRepeatSettingPopup = useCallback((ev) => {
		Spotlight.focus(previousFocus.current);
		setRepeatSettingPopupOpen(false);
		if (ev.type === 'click') {
			onContentFocus(-1);
		}
	}, [onContentFocus]);

	const onCheckPopupCancel = useCallback((ev) => {
		if (ev.type === 'onDismiss') {
			Spotlight.focus(previousFocus.current);
		} else {
			Spotlight.focus(contentContainer);
		}
		setCheckPopupOpen({open: false});
		if (ev.type === 'click') {
			onContentFocus(-1);
		}
	}, [onContentFocus, contentContainer]);

	const openCheckPopup = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		onContentFocus(order, true)
		if (ev.type === 'keyup') {
			setCheckPopupOpen({ open: true });
		} else {
			if (previousFocusNum === currentFocusNum) {
				setCheckPopupOpen({ open: true });
			}
		}
	}, [onContentFocus, order, previousFocusNum, currentFocusNum]);

	const onCloseCheckPopup = useCallback((num, ev) => {
		if (num === 0) {
			dispatch(PanelActions.pushPanel('settingsMediaPicker', panelInfo));
			onCheckPopupCancel(ev);
		} else if (num === 1) {
			if (type === "routine") {
				let newPlanInfo = [];
				tempInfo.plans.map((item, i) => {
					if (order === i) {
						newPlanInfo.push({ ...item, ["info"]: [] });
					} else {
						newPlanInfo.push({ ...item });
					}
				});
				const sortNewPlanInfo = newPlanInfo.sort(function (a, b) {
					return Object.keys(b.info).length - Object.keys(a.info).length;
				});
				for (let i = 0; i < 5; i++) {
					sortNewPlanInfo[i].order = i;
				}
				dispatch({ type: types.SET_TEMPORARY_PLAN, payload: { plans: newPlanInfo } });
			}
			onCheckPopupCancel(ev);
		} else {
			onCheckPopupCancel(ev);
			Spotlight.focus(previousFocus.current);
		}
	}, [dispatch, type, onCheckPopupCancel, tempInfo, panelInfo, order]);

	const contentSubInfo = useCallback(() => {
		let calorie = detailInfo.calorie ? detailInfo.calorie + " kcal"  : "";
		if(playtime && calorie){
			calorie = " | " + calorie;
		}
		let exerciseType = detailInfo.exerciseType ? contentInfo.info.exerciseType : "";
		if((playtime || calorie) && exerciseType){
			exerciseType = " | " + exerciseType;
		}
		return playtime + calorie + exerciseType
	}, [contentInfo, playtime, detailInfo]);

	return (
		<>
			<div className={css.itemContainer} onFocus={onListFocus} onBlur={onListBlur} {...rest}>
				<div className={classNames(css.planOrder, isFocus ? css.planOrderFocus : null)}>{order + 1}</div>
				<SpottableComponent
					className={classNames(css.list, isFocus && css.focusList, disabled && css.disabledList)}
					onKeyDown={onKeyHandleContent}
					spotlightId={contentContainer}
					spotlightDisabled={disabled}
					onClick={detailInfo ? onExitReorderMode : onAddContent}
					data-webos-voice-intent={'Select'}
					data-webos-voice-label={detailInfo ? contentTitle : $L('Add Content') }
				>
					{detailInfo ?
						<Image
							className={classNames(css.content, css.contentUrl)}
							src={contentInfo.info.thumbnailImageUrl}
							placeholder={"thumbnailImageUrl"}>
							<div className={css.cover} />
							{playtime &&
								<div className={classNames(css.playtime, isFocus && css.hide)}>{playtime}</div>
							}
							<div className={classNames(css.repeatCount, isFocus && css.hide)}>
								{message}
							</div>
						</Image>
						:
						<div className={classNames(css.content, css.addContent)}>
							<div className={css.iconWrap}>
								<Icon className={css.icon} size={150}>plus</Icon>
							</div>
							<div className={css.text}>{$L('Add Content')}</div>
						</div>
					}
					{detailInfo &&
						<div className={css.infoContainer}>
							<Marquee className={css.title} marqueeOn={isFocus ? "render" : null}>{contentInfo.info.title}</Marquee>
							<Marquee className={css.subtitle}>{contentSubInfo()}</Marquee>
						</div>
					}
				</SpottableComponent>
				{detailInfo && !isReoderMode &&
					<div className={classNames(css.buttonWrap)}>
						<TButton
							className={classNames(css.deleteBtn, isFocus && !isReoderMode ? css.focusBtn : null)}
							disabled={disabled}
							onClick={openCheckPopup}
							spotlightId={contentChangeBtn}
							data-webos-voice-label={isFocus ? $L('Delete') : " "}
						>
							{$L('Delete')}
						</TButton>
						<TButton
							className={classNames(css.reorderBtn, isFocus && !isReoderMode ? css.focusBtn : null)}
							disabled={disabled}
							onClick={onStartReorderMode}
							onKeyDown={onKeyHandReorderBtn}
							data-webos-voice-label={isFocus ? $L('Reorder') : " "}
						>
							{$L('Reorder')}
						</TButton>
						<TButton
							className={classNames(css.repeatBtn, isFocus && !isReoderMode ? css.focusBtn : null)}
							disabled={disabled}
							onClick={onChangeRepeat}
							onKeyDown={onKeyHandleRepeatBtn}
							data-webos-voice-label={isFocus ? $L('Repeat ') : " "}
						>
							{message}
						</TButton>
					</div>
				}
			</div>
			{checkPopupOpen.open &&
				<TAlert
					message={$L("Do you want to delete this content And set another exercise?")}
					button1text={$L('Set another exercise')}
					button2text={$L('Just Delete')}
					button3text={$L('Cancel')}
					onClose={onCheckPopupCancel}
					onClick={onCloseCheckPopup}
				/>
			}
			{repeatSettingPopupOpen &&
				<RepeatSettingPopup
					type={'onlyEditRepeatCount'}
					open={repeatSettingPopupOpen}
					onlyEditRepeatCountContent={contentInfo}
					onClosePopup={onCloseRepeatSettingPopup}
				/>
			}
		</>
	)
};

export default PlanAddContent;