/**
 * TopLayer
 *
 * @module TopLayer
 */
import React, {useState, useCallback} from 'react';
import Dropdown from '@enact/sandstone/Dropdown';
import Spotlight from '@enact/spotlight';
import {SpotlightContainerDecorator} from '@enact/spotlight/SpotlightContainerDecorator';
import css from './TopLayer.module.less';
import SpotlightIds from '../../../data/SpotlightIds';
import {$L} from '../../../utils/common';
import DatePicker from './DatePicker';

export const MODE_WEEKLY = 0;
export const MODE_MONTHLY = 1;
export const MODE_YEARLY = 2;

const Container = SpotlightContainerDecorator({enterTo: 'last-focused', preserveId: true}, 'div');

const TopLayer = ({onDataChanged}) => {
	const [mode, setMode] = useState(MODE_WEEKLY);
	const [firstDate, setfirstDate] = useState("");
	const [lastDate, setlastDate] = useState("");
	const [previousDate, setPreviousDate] = useState("");

	const callbackDataChanged = useCallback((_mode, _firstDate, _lastDate, _previousDate) => {
		if(onDataChanged){
			onDataChanged({mode: _mode,firstDate: _firstDate, lastDate: _lastDate, previousDate: _previousDate});
		}
	}, [onDataChanged]);

	const onSelectMode = useCallback(({selected}) => {
		setMode(selected);
		callbackDataChanged(selected, firstDate, lastDate, previousDate);
	}, [firstDate, lastDate, previousDate, callbackDataChanged]);

	const onSelectDate = useCallback(({first, last, previous}) => {
		setfirstDate(first);
		setPreviousDate(previous);
		setlastDate(last);
		callbackDataChanged(mode, first, last, previous);
	}, [mode, callbackDataChanged]);

	const spotMedia = useCallback((ev) => {
		if(ev.key === 'ArrowUp'){
			Spotlight.focus(SpotlightIds.MAIN_DASHBOARD_BTN);
		}
	}, []);

	return (
		<Container className={css.layer} onKeyDown={spotMedia}>
			<div className={css.title}>
				<div>{$L("Workout")}</div>
				<div>{$L("Summary")}</div>
			</div>
			<DatePicker className={css.datePicker} mode={mode} onSelect={onSelectDate}/>
			<div className={css.modePicker}>
				<Dropdown
					className={css.listplan}
					direction="below"
					size="small"
					width="small"
					selected={mode}
					onSelect={onSelectMode}
					>
					{[$L("Weekly"), $L("Monthly"), $L("Yearly") ]}
				</Dropdown>
			</div>
		</Container>
	);
};

export default TopLayer;
