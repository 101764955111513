import {types} from '../actions/actionTypes';
import * as Config from '../data/Config';
import * as ContentType from '../data/ContentType';
import * as Utils from '../utils/common'

const initAppStatus = {
	checkingAccountId: true,
	isAppForeground: true,
	homeBannerSize: 'medium', //big, medium, small
	showCategoryDetail: false,
	webOSVersion:"",
	webOSVersionReal: "",
	homeServiceVersion: "",
	activeServiceDebug: false,
	deviceId: "",
	isInternetConnected: true,
	connectionFailed: false,
	cursorVisible: false,
	cameraDeviceName: "",
	cameraDetected: false,
	extendHomeBannerSummary: false,
	captionEnable: false,
	customScheduleFinished: false,
	homeGroupHasRoutine: null, //will be set false or true
	homeGroupHasCustomizedWorkout: null, //will be set false or true
	showTooltip: false
};

const initialLocalSettings = {
	useQAServerURL: Config.DEFAULT_SERVERTYPE,
	useForceCamera: false,
	virtualPermission: false,
	cameraActivated: true,
	cameraSize: 'small',
	cameraPosition: 'rt',
	recentSearch: [],
	customizedProgramInfoCreatedAt: null,
	initialCustomizedProgramGuideShown: false
};

const showLoadingView =  (state = {loadingArray: [], show: true}, action) => {
	switch (action.type) {
		case types.INIT_LOADING_STATUS:{
			return Object.assign({}, state, {loadingArray: []});
		}
		case types.ADD_LOADING_STATUS:
			state.loadingArray.push(action.payload);
			state.show = true;
			//console.log('yhcho showLoadingView',JSON.stringify(state));
			return Object.assign({}, state);
		case types.REMOVE_LOADING_STATUS:{
			const index = state.loadingArray.indexOf(action.payload);
			if(index >=0){
				state.loadingArray.splice(index,1);
			}
			if(state.loadingArray.length > 0){
				state.show = true;
			}else{
				state.show = false;
			}
			//console.log('yhcho showLoadingView REMOVE_LOADING_STATUS',action.payload, state);
			return Object.assign({}, state);
		}
		default:
			return state;
	}
};
const appStatus = (state = initAppStatus, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			return Object.assign({}, state, action.status);
		default:
			return state;
	}
};
/*
?content={"contentId":"999"}
?category=[{"categoryId":"5"}]
?categoryItems={"5":[...]}
?category=[{"categoryId":"5"}]&categoryItems={"5":[...]}
?terms="slkjfal"
?youtube=["UCsLF0qPTpkYKq81HsjgzhwQ", "UCvGEK5_U-kLgO6-AMDPeTUQ"]
*/
const adminPreviewData = (state = {}, action) => {
	switch (action.type) {
		case types.UPDATE_ADMIN_PREVIEW_DATA:
			if(action.status.content){
				if(!action.status.content.contentId) action.status.content.contentId = 999999;
				if(!action.status.content.title) action.status.content.title = "NO TITLE";
				if(!action.status.content.contentType) action.status.content.contentType = ContentType.SINGLE;
			}
			// <--  content  -->
  			// state.content={"contentId":"87"};
			// state.contents=[{"contentId":"87"}]
			// <--  Youtube  -->
			// state.homeGroup=[{"id":"HOME_YOUTUBE","homeGroupType":"MENU","openStatus":"OPEN","title":"유튜브","openFrom":"2021-09-08T10:37:35","updatedAt":"2021-09-07T10:37:36.747","cpContentsApi":null,"cpContentsAuthKey":null,"cpContentsSubtitle":null}];
			// state.youtube=[{
			// 	"contentId": "UCXH1-gZuuOPHvwBUrAvTpvw",
			// 	"contentType": "youtube#channel", "postImageUrl": "https://yt3.ggpht.com/ytc/AMLnZu_lMi8splpaxGSmHpB6eAvBzh0PRZa09MIWk6dxjQ=s800-c-k-c0x00ffffff-no-rj",
			// 	"thumbnailImageUrl": "https://yt3.ggpht.com/ytc/AMLnZu_lMi8splpaxGSmHpB6eAvBzh0PRZa09MIWk6dxjQ=s240-c-k-c0x00ffffff-no-rj",
			// 	"title": "바디메이트TV",
			// 	"youtubeId": "UCXH1-gZuuOPHvwBUrAvTpvw"},
			// 	{"contentId": "IfJcq4LDXKE",
			// 	"contentType": "youtube#video", "postImageUrl": "https://i.ytimg.com/vi/IfJcq4LDXKE/hqdefault.jpg",
			// 	"thumbnailImageUrl": "https://i.ytimg.com/vi/IfJcq4LDXKE/mqdefault.jpg",
			// 	"title": "(진짜 효과있는)스쿼트 자세 올바르게 하는법",
			// 	"youtubeId": "IfJcq4LDXKE"}];
			// <--  3rd Party -->
			// state.homeGroup=[{"id":"THIRD_PARTY","homeGroupType":"MENU","openStatus":"OPEN","title":"3rd Party 앱","openFrom":"2022-12-12T12:39:51.939","updatedAt":"2022-12-12T12:39:51.978","cpContentsApi":null,"cpContentsAuthKey":null,"cpContentsSubtitle":null}];
			// state.thirdparty=[{"appId": "test2", description: "test2"}];
			// <--  배너 이미지 -->
			// state.activeBanner=[{bannerId: 35, bannerImageUrl:"https://d3rhknz2mzghzz.cloudfront.net/images/bannerv2/undefined.000000020221223132921.jpg"
			// , title: '세팅메뉴', openStatus: 'OPEN', bannerLinkType: 'SETTING_MENU',  bannerLinkTitle: "맞춤형 이동", bannerLinkId: "interview"
			// }, {bannerId: 35, bannerImageUrl:"https://d3rhknz2mzghzz.cloudfront.net/images/bannerv2/undefined.000000020230512141444.png"
			// , title: 'Youtube', openStatus: 'OPEN', bannerLinkType: 'HOME_GROUP',  bannerLinkTitle: "Youtube 쉘프로 이동", bannerLinkId: "HOME_YOUTUBE"
			// }];
			// // <--  플로팅 메세지 -->
			// state.floatingMessages=[{message: "23바이트 테스트 \n 테스트", openStatus: "OPEN", openCondition:"ALL"}];
			// <--  맞춤형 컨텐츠 -->
			// state.homeGroup=[{"id":"CUSTOMIZED_WORKOUT","homeGroupType":"MENU","openStatus":"OPEN","title":"맞춤 콘텐츠","openFrom":"2022-12-29T16:04:51.915","updatedAt":"2023-01-02T14:58:12.372","cpContentsApi":null,"cpContentsAuthKey":null,"cpContentsSubtitle":null}];
			// state.customizedWorkOutScheduleContents={"1": {"contentId": 87, title: "오리"}, "2":{"contentId": 86, title: "우주"}, "3":{"contentId": 75, title: "ttt"}, "4":{"contentId": 316, title: "316"}};
			return Object.assign({}, state, action.status);
		default:
			return state;
	}
};
const getTermsStatus = (state = {}, action) => {
	switch (action.type) {
		case types.GET_TERMS_STATUS:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const getTermsDoc = (state = {}, action) => {
	switch (action.type) {
		case types.GET_TERMS_DOC:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const deviceAccountInfo = (state = {accountId: undefined, language: 'ko', displayName: ""}, action) => {
	switch (action.type) {
		case types.GET_DEVICE_ACCOUNT_INFO:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const serviceTestResult = (state = "", action) => {
	switch (action.type) {
		case types.HOMEGYM_SERVICE_TEST:
			return action.payload;
		default:
			return state;
	}
};
const focusedBannerItem = (state = null, action) => {
	switch (action.type) {
		case types.GET_FOCUSED_BANNER_ITEM:
			return action.payload;
		default:
			return state;
	}
};
const playListInfo = (state = {playList:[],playIndex:0}, action) => {
	switch (action.type) {
		case types.GET_PLAYER_INFO:
			return Object.assign({}, state, action.payload);
		default:
			return state;
	}
};

const videoPreviewThumbs = (state = {}, action) => {
	switch (action.type) {
		case types.GET_VIDEO_PROGRESS_THUMBNAIL:
			return Object.assign({}, state, {[action.payload.requestId]:action.payload.thumbnails[0]});
		case types.CLEAR_VIDEO_PROGRESS_THUMBNAIL:
			return {};
		default:
			return state;
	}
};

const launchAction = (state = {}, action) => {
	switch (action.type) {
		case types.GET_LAUNCH_ACTION:
			return Object.assign({}, state, action.payload);
		case types.CLEAR_LAUNCH_ACTION:
			return {};
		default:
			return state;
	}
};

const deviceHttpHeader = (state = {}, action) => {
	switch (action.type) {
		case types.GET_HTTP_HEADER:
			return action.payload;
		default:
			return state;
	}
};

const showLoadingSpinner =  (state = {loadingArray: [], show: false}, action) => {
	switch (action.type) {
		case types.ADD_LOADING_SPINNER_STATUS:
			state.loadingArray.push(action.payload);
			state.show = true;
			// console.log('yhcho add showLoadingSpinner',JSON.stringify(action.state));
			return Object.assign({}, state);
		case types.REMOVE_LOADING_SPINNER_STATUS:{
			const index = state.loadingArray.indexOf(action.payload);
			if(index >=0){
				state.loadingArray.splice(index,1);
				if(state.loadingArray.length > 0){
					state.show = true;
				}else{
					state.show = false;
				}
				// console.log('yhcho remove showLoadingView',JSON.stringify(state));
				return Object.assign({}, state);
			}else{
				return state;
			}
		}
		case types.DEFAULT_LOADING_SPINNER_STATUS: {
			return state = {loadingArray: [], show: false}
		}
		default:
			return state;
	}
};

const supportPIPCamera = (state = false, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			if (typeof window === 'object'){
				const webOSVersion = window.store.getState().appStatus.webOSVersion;
				const useForceCamera = window.store.getState().localSettings.useForceCamera; //승훈
				const otaId = window.store.getState().appStatus.otaId;
				if(useForceCamera || Number(webOSVersion) >= 6.2 || webOSVersion === 'local'){
					return true;
				}
				//stand by me model
				if(otaId === "HE_DTV_N21D_AFAAATAA"){
					return true;
				}
			}
			return state;
		default:
			return state;
	}
};

// 6.2 이상
// 5.3 이상 6.0 미만
const supportLogin = (state = false, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			if(action.status.webOSVersion){
				if(Config.SUPPORT_LOGIN){
					const webOSVersion = action.status.webOSVersion;
					const verNumber = Number(webOSVersion);
					const virtualPermission = (typeof window === 'object') ? window.store.getState().localSettings.virtualPermission : false; //paulkim const virtualPermission 승훈 수정
					if(webOSVersion === 'local' || virtualPermission){
						return true;
					} //paulkim Config.DEBUG_VIRTUAL_PERMISSION => virtualPermission
					if(verNumber >= 6.2){
						return true;
					}else if(verNumber >= 5.3 && verNumber < 6){
						return true;
					}
					return false;
				}
			}
			return state;
		default:
			return state;
	}
};

// not support login but support dashboard
const supportLoginWithDeviceId = (state = false, action) => {
	switch (action.type) {
		case types.CHANGE_APP_STATUS:
			if(action.status.webOSVersion){
				if(Config.SUPPORT_LOGIN){
					const webOSVersion = action.status.webOSVersion;
					if((Number(webOSVersion) < 5)){
						return true;
					}
				}
			}
			return state;
		default:
			return state;
	}
};

const updateInitialLocalSettings = () => {
	const data = Utils.readLocalStorage('localSettings',initialLocalSettings);
	if( Object.keys(data).length !== Object.keys(initialLocalSettings).length){
		Utils.writeLocalStorage('localSettings', initialLocalSettings);
		return initialLocalSettings;
	}else{
		return data;
	}
};
const localSettings = (state = updateInitialLocalSettings(), action) => {
	switch (action.type) {
		case types.CHANGE_LOCAL_SETTINGS:{
			const newState = Object.assign({}, state, action.status);
			Utils.writeLocalStorage('localSettings', newState);
			return newState;
		}
		default:
			return state;
	}
};

const commonReducer = {
	appStatus,
	adminPreviewData,
	showLoadingView,
	deviceAccountInfo,
	serviceTestResult,
	focusedBannerItem,
	playListInfo,
	launchAction,
	videoPreviewThumbs,
	getTermsStatus,
	getTermsDoc,
	deviceHttpHeader,
	showLoadingSpinner,
	supportPIPCamera,
	supportLogin,
	supportLoginWithDeviceId,
	localSettings
};

export default commonReducer;
