/**
 * TContainer
 *
 * @module TContainer
 */
import classNames from 'classnames';
import React, {useCallback, useEffect } from 'react';
import {$L} from '../../utils/common';
import Spottable from '@enact/spotlight/Spottable';
import css from './TContainer.module.less';;
import TSwitchItem from '../TSwitchItem/TSwitchItem';

const TYPES=["alarm", "goal"];

const SpottableComponent = Spottable('div');

const TContainer = ({ type = "goal", focus, children, switchDisabled, title, activated, isContentExist, onClick, onToggle, spotlightId, ...rest }) => {

	useEffect(() => {
		if(TYPES.indexOf(type) < 0 ){
			console.error('TButton type error');
		}
	}, [type]);

	const voiceLabels = useCallback(() => {
		return JSON.stringify([title, focus ? type === "alarm" ? $L("Set Another Alarm") : $L("Set another Exercise") : ""]);
	}, [title, focus, type]);

	return (
		<div className={classNames(css.list, css[type], focus && css[type+"Focus"])}
			{...rest}
			>
			<div className={css.listHead}>
				<div className={css.title}>{title}</div>
				<TSwitchItem className={css.switch} data-webos-voice-label={title} disabled={switchDisabled} spotlightId={spotlightId} selected={activated} onToggle={onToggle}>{activated ? $L('On') : $L('Off')}</TSwitchItem>
			</div>
			< SpottableComponent className={classNames(css.contentContainer, isContentExist ? css[type+"Container"] : css[type+"AddContainer"])}
				data-webos-voice-intent={'Select'}
				data-webos-voice-labels={voiceLabels()}
				onClick={onClick}
			>
				{children}
			</SpottableComponent>
		</div>

	);
};

export default TContainer;