import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spotlight from '@enact/spotlight';
import TPanel from '../../../components/TPanel/TPanel';
import THeader from '../../../components/TPanel/THeader';
import TButton from '../../../components/TButton/TButton';
import CategoriesView from '../../CategoriesView';
import TimePopup from '../../../components/Popup/TimePopup';
import RepeatSettingPopup from '../../../components/Popup/RepeatSettingPopup';
import { types } from '../../../actions/actionTypes';
import * as PanelActions from '../../../actions/panelActions';
import * as CommonActions from '../../../actions/commonActions';
import * as css from './SettingsMediaPicker.module.less';

const SettingsMediaPicker = ({panelInfo, ...props}) => {
	const dispatch = useDispatch();
	const previousFocus = useRef();
	const [repeatSettingPopupOpen, setRepeatSettingPopupOpen] = useState(false);
	const selectedContent = useSelector(state => state.selectContentInfo);
	const [timePopupOpen, setTimePopupOpen] = useState(false);

	useEffect(() => {
		if(!props.hideChildren){
            Spotlight.focus('SettingsMediaPicker');
        }
	}, [props]);

	const onBack = useCallback((ev) => {
		if (ev) {
			ev.stopPropagation();
		}
		if (panelInfo.type === "routine") {
			dispatch(PanelActions.pushPanel('myworkoutroutinesettings', { panelType: "onBack", title: panelInfo.title, isEditMode: panelInfo.isEditMode, planId: panelInfo.planId, order:panelInfo.order}));
			dispatch(PanelActions.popPanel('settingsMediaPicker'));
		} else if (panelInfo.type === "goal") {
			dispatch(PanelActions.pushPanel('goalsettings', { panelType: "onBack", type:panelInfo.type, isEditMode:panelInfo.isEditMode, goalId:panelInfo.goalId, title: panelInfo.title, day:panelInfo.day, selectDays:panelInfo.selectDays, activated:panelInfo.activated }));
			dispatch(PanelActions.popPanel('settingsMediaPicker'));
		}
	}, [dispatch, panelInfo]);

	const onItemClick = useCallback((ev) => {
		previousFocus.current = Spotlight.getCurrent();
		dispatch({ type: types.SELECT_CONTENT_INFO, payload: ev });
		if (panelInfo.type === 'routine') {
			setRepeatSettingPopupOpen(true);
		} else {
			setTimePopupOpen(true);
		}
	}, [dispatch, panelInfo]);

	const onCloseTimePopup = useCallback(() => {
		setTimePopupOpen(false);
	}, []);

	const onCloseRepeatSettingPopup = useCallback(() => {
		setRepeatSettingPopupOpen(false);
	}, []);

	return (
		<TPanel {...props} spotlightId={'SettingsMediaPicker'}  handleCancel={onBack}>
			<THeader onClick={onBack}/>
			<CategoriesView isOnTop pickerMode pickOnlySingle={panelInfo.type === "routine"} onItemClick={onItemClick}/>
			<TimePopup
				open={timePopupOpen}
				onClosePopup={onCloseTimePopup}
				previousFocus={previousFocus.current}
				selectedContent={selectedContent}
				isEditMode={panelInfo.isEditMode}
				goalId={panelInfo.goalId}
				title={panelInfo.title}
				day={panelInfo.day}
				selectDays={panelInfo.selectDays}
				activated={panelInfo.activated}
				preSetTime={panelInfo.alarm}
			/>
			{repeatSettingPopupOpen &&
				<RepeatSettingPopup
					open={repeatSettingPopupOpen}
					onClosePopup={onCloseRepeatSettingPopup}
					isEditMode={panelInfo.isEditMode}
					planId={panelInfo.planId}
					selectedContent={selectedContent}
					title={panelInfo.title}
					order={panelInfo.order}
					previousFocus={previousFocus.current}
				/>
			}
		</TPanel>
	);
};

export default SettingsMediaPicker;
