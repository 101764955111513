/**
 * MonthlyView
 *
 * @module MonthlyView
 */
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {shallowEqual} from 'react-redux';
import {Image} from '@enact/sandstone/Image';
import css from './GoalInfo.module.less';
import {$L} from '../../../utils/common';

const MonthlyView = ({firstDate, goalData}) => {
	const dummyDay = useMemo(() => {
		const date = new Date(firstDate.replace(/(-)/g,','));
		const firstDay = date.getDay();
		const data = [];
		for(let i=0; i<firstDay;i++){
			data.push("dummy");
		}
		return data;
	},[firstDate]);

	return (
		<div className={css.montlyView}>
			{dummyDay.map((item, index) => (
				<div key={index+item} className={classNames(css.datalayer,css.hide)}/>
			))}
			{goalData.map((item, index) => (
				<div key={index} className={classNames(css.datalayer)}>
					<Image className={classNames(css.iconImg)} src={item.icon}/>
					{item.today &&
						<div className={css.today}>{$L("Today").toUpperCase()}</div>
					}
				</div>
			))}
		</div>
	);
};

export default React.memo(MonthlyView, shallowEqual);